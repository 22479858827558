import axios from 'axios';
import objectToFormData from '../../lib/objectToFormData';

const URL_BASE = '/api/v2/applicant';
const URL = `${URL_BASE}/profile/dosier`;

export default {
  /**
   * get applicant dosier
   * URI[ GET ] /api/v2/applicant/dosier/${id}/${locale}
   * @param  {number} id     id to get dosier
   * @param  {string} locale locale to be translated
   * @return {object} applicant dosier
   */
  getDosier(id = 0, locale = 'de') {
    return new Promise((resolve, reject) => {
      const url = `${URL}/${id}/${locale}`;
      axios
        .get(url)
        .then((response) => {
          // handle success
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  },

  /**
   * get applicant dosier
   * URI[ GET ] /api/v2/applicant/dosier/${id}/${locale}
   * @param  {number} id     id to get dosier
   * @param  {string} formName formName
   * @param  {string} locale locale to be translated
   * @return {object} applicant dosier
   */
  getForm(id = 0, formName = '', locale = 'de') {
    return new Promise((resolve, reject) => {
      const url = `${URL}/${id}/${formName}/${locale}`;
      axios
        .get(url)
        .then((response) => {
          // handle success
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  },

  /**
   * get applicant dosier
   * URI[ PUT ] /api/v2/applicant/dosier/${id}/${locale}
   * @param  {number} id     id to get dosier
   * @param  {object} entity entity for updating values
   * @return {object} applicant dosier
   */
  updateDosier(id = 0, entity, hasFile = false) {
    return new Promise((resolve, reject) => {
      const url = `${URL}/${id}/de`;
      if (!hasFile) {
        axios
          .patch(url, entity, {
            headers: {
              'X-Requested-With': 'XMLHttpRequest'
            }
          })
          .then((response) => {
            // handle success
            resolve(response.data);
          })
          .catch((error) => {
            // handle error
            console.error(error);
            reject(error);
          });
      } else {
        let formData = new FormData();
        const options = {
          indices: true,
          nullsAsUndefineds: false
        };
        formData = objectToFormData(entity, options, formData, 'applicantProfile');
        axios
          .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-Requested-With': 'XMLHttpRequest'
            } /* ,
            onUploadProgress: progressCb */
          })
          .then((response) => {
            // handle success
            resolve(response.data);
          })
          .catch((error) => {
            // handle error
            console.error(error);
            reject(error);
          });
      }
    });
  }
};
