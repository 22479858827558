import Vue from 'vue';
import translations from '../../api/translations';

const types = {
  NAME: 'translations',
  MUTATIONS: {
    stockUpdates: 'stockUpdates',
    setDict: 'setDict',
    setUpdatesStatus: 'setUpdatesStatus'
  },
  STATUS: {
    NEED_TO_UPDATE: 'NEED_TO_UPDATE',
    EMPTY: 'EMPTY',
    UPDATED: 'UPDATED',
    SERVER_ERROR: 'SERVER_ERROR'
  }
};

// initial state
// shape: [{ id, quantity }]
const storeState = {
  dict: null,
  updates: {},
  updatesStatus: types.STATUS.EMPTY
};

// getters
const getters = {
  updatesStatus(state) {
    return state.updatesStatus;
  },
  dict(state) {
    return state.dict;
  }
};

// actions
const actions = {
  fetchAll({ commit }, payload) {
    const p = new Promise((resolve, reject) => {
      const result = getters.dict();
      if (result == null) {
        translations.getAll(
          payload.domain,
          payload.locale,
          (dict) => {
            commit(types.MUTATIONS.setDict, dict);
            commit(types.MUTATIONS.setUpdatesStatus, types.STATUS.UPDATED);
            resolve(dict);
          },
          (err) => {
            reject(err);
          }
        );
      } else {
        resolve(result);
      }
    });
    return p;
  },

  update({ commit, state }, payload) {
    translations.update(
      payload.domain,
      payload.locale,
      state.dict,
      () => {
        commit(types.MUTATIONS.setUpdatesStatus, types.STATUS.UPDATED);
        commit(types.MUTATIONS.setDict, { ...state.dict, ...state.updates });
        state.updates = {};
      },
      () => {
        commit('setUpdatesStatus', types.STATUS.SERVER_ERROR);
      }
    );
  }
};

const mutations = {
  /**
   * setDict
   * @type {function}
   * @param state given automatically by framework
   * @param dict updated dictionary object
   */
  [types.MUTATIONS.setDict](state, dict) {
    Vue.set(state, 'dict', dict);
  },

  /**
   * stockUpdates
   * @type {function}
   * @param state given automatically by framework
   * @param entry update value,
   */
  [types.MUTATIONS.stockUpdates](state, entry) {
    Object.keys(entry).map((key) => {
      if (key in state.updates) {
        delete state.updates[entry.key];
      }
      return delete state.dict[entry.key];
    });
    state.updates = { ...state.updates, ...entry };
    this[types.MUTATIONS.setUpdatesStatus](state, types.STATUS.NEED_TO_UPDATE);
  },

  /**
   * stockUpdates
   * @type {function}
   * @param state given automatically by framework
   * @param status updates status
   */
  [types.MUTATIONS.setUpdatesStatus](state, status) {
    state.updatesStatus = status;
  }
};

export default {
  namespaced: true,
  types,
  state: storeState,
  getters,
  actions,
  mutations
};
