import moment from 'moment';

const dateOrderUtils = {
  methods: {
    convertSelectBoxDate(value) {
      let m = moment();
      if (value instanceof Date) {
        // iso or now
        m = moment(value);
      } else if (value === 'now') {
        m = moment();
      } else if (typeof value === 'string' && value.length === 5) {
        m = moment(value, 'MM/YY');
      } else {
        m = moment(value);
      }
      return new Date(m.valueOf());
    },

    convertDateToMoment(value) {
      let m = null;
      if (value instanceof Date) {
        // iso or some other Date object
        m = moment(value);
      } else if (value === 'now') {
        m = moment();
      } else if (typeof value === 'string' && value.length === 5) {
        // String which has format MM/YY into 01.MM.YYYY
        m = moment(value, 'MM/YY');
      } else if (!value) {
        // null or undefined
        m = moment();
      } else {
        // other string format like UTC Time String YYYY-MM-DDT00:00:00+00:00
        // console.warn('unexpected value', value);
        m = moment(value);
      }
      return m;
    },

    sortDateOrderByMomentFormat(dateA, dateB) {
      const a = this.convertDateToMoment(dateA);
      const b = this.convertDateToMoment(dateB);
      return a.isBefore(b) ? [dateA, dateB] : [dateB, dateA];
    },

    compareWithFromDate(eva, evb) {
      return this.convertDateToMoment(evb.from).valueOf() - this.convertDateToMoment(eva.from).valueOf();
    },

    isOverlapped(periodA, periodB) {
      const periodAFrom = this.convertDateToMoment(periodA.from);
      const periodATill = this.convertDateToMoment(periodA.till);
      const periodBFrom = this.convertDateToMoment(periodB.from);
      const periodBTill = this.convertDateToMoment(periodB.till);
      return periodAFrom.isBefore(periodBTill) && periodATill.isAfter(periodBFrom);
    },

    eliminateOverlappedPeriods(periods) {
      const self = this;
      const result = periods.sort(this.compareWithFromDate);
      // const periodIndexes = Array(result.length);
      Array(result.length).fill().map((_elm, index) => {
        const periodA = result[index];
        const periodB = result[index + 1];
        if (periodB) {
          if (self.isOverlapped(periodA, periodB)) {
            periodB.till = periodA.from;
            result[index + 1] = periodB;
          }
        }
        return index;
      });
      return result;
    }
  }
};

export default dateOrderUtils;
