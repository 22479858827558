const scrollToTopMixin = {
  methods: {
    scrollToTop() {
      if (this.$refs.careerboardPage) {
        this.$refs.careerboardPage.scrollTop = 0;
      } else {
        console.error('mixin scrollToTop need $refs.careerboardPage');
      }
    }
  }
};

export default scrollToTopMixin;
