<template>
  <li class="further-qualification">
    <div class="title">
      <span>{{ value.qualification }}</span>
    </div>
    <div class="data">
      {{ value.comment }}
    </div>
    <div class="provider" v-if="'provider' in value">
      {{ value.provider }}
    </div>
  </li>
</template>

<script>
export default {
  name: 'FurtherQualification',

  data() {
    return {
      form: {},
      bufferedValue: null
    };
  },

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  methods: {}
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_typographie';
@import '../../../scss/atoms/_icons';
@import '../../../scss/atoms/_breakpoints.mixin';

.further-qualification {
  border-bottom: 1px solid $color-silvergrey;
  margin-bottom: 15px;

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }

  > div:last-of-type {
    padding-bottom: 16px;
  }

  .title {
    font-size: 17px;
    line-height: 1.46em;
    font-weight: 700;
    color: $color-blacky;

    span:after {
      content: ':';
    }
  }

  .data {
    // margin-bottom: 15px;
    color: $color-blue-light;
    font-weight: 700;
  }

  /*
  .provider {
    span {
      display: block;
      font-weight: $font-regular;
      color: $color-darkgrey;
    }
  }
  */
}
</style>
