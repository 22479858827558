<template>
  <div class="study">
    <div class="degree-label">
      <i class="fontello"></i>
      <!-- Translation resource_key="applicant.profile.college.degree" / -->
      {{ value.degree }}
    </div>
    <div class="degree-name">{{ value.nameText }}</div>
    <div class="degree-period" v-if="definedPeriod">{{ value.from }}-{{ value.till}} </div>
    <div class="university">{{ value.employer }}</div>
    <div class="mastercraftsman" v-if="value.mastercraftsman === 'yes'">(Meister/Techniker)</div>
  </div>
</template>

<script>
import moment from 'moment';
// import Translation from '../../atoms/Translation';

export default {
  name: 'VocationalTraining',
  /* FIXME: Meister/Techniker should be in locale resource
  components: {
    Translation
  },
  */
  data() {
    return {
      form: {},
      bufferedValue: null
    };
  },

  computed: {
    definedPeriod() {
      return this.value.from || this.value.till;
    }
  },

  props: {
    value: {
      type: Object,
      required: true
    },
    current: {
      type: Boolean,
      default: false
    }
  },

  filters: {
    moment(date) {
      return moment(date).format('MM/YY');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_typographie';
@import '../../../scss/atoms/_icons';

.study {
  padding-left: 33px;

  .degree-label {
    font-weight: $font-bold;
    color: $color-blacky;
    margin-left: -33px;

    .icon-graduation-cap {
      margin-right: 6px;
    }
  }

  .degree-name {
    color: $color-blue;
    font-weight: $font-bold;
  }

  .degree-period {
    font-style: italic;
    font-weight: $font-regular;
    color: $color-disabled-grey;
  }
  /*
  .university {
    // padding-left: 33px;
  }
  */
}
</style>
