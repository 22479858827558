<template>
  <section id="company-expectation" class="cp-section">
    <div class="company-expectation-inner">
      <CpHeader class="">
        <Translation slot="title" resource_key="joboffer.company-expectation.title"/>
      </CpHeader>

      <div class="row division-title">
        <div class="column education">
          <CompanyDataBlock>
            <Translation slot="data" resource_key="joboffer.necessaryEducation"/>
          </CompanyDataBlock>
        </div>
        <div class="column mobile-hidden">
          <CompanyDataBlock>
            <Translation slot="data" resource_key="joboffer.necessaryPracticalExperience"/>
          </CompanyDataBlock>
        </div>
      </div>

      <div class="row division">
        <div class="column">
          <div class="cell">
            <CompanyDataBlock>
              <template slot="data">
                <span class="fontello-icn-text-wrapper">
                  <span class="fontello icon-graduation-cap"></span>
                  <span>
                    {{ jobOffer.jobOffer.minimalDegreeText }}
                  </span>
                </span>
              </template>
            </CompanyDataBlock>
          </div>
          <div class="cell" v-if="isCollegeGradeImportant()">
            <CompanyDataBlock>
              <template slot="data">
                <span class="fontello-icn-text-wrapper">
                  <span class="fontello icon-graduation-cap"></span>
                  <span>
                    {{ jobOffer.jobOffer.necessaryCollegeGrade }}
                  </span>
                </span>
              </template>
            </CompanyDataBlock>
          </div>
          <div class="cell" v-if="isEnoughMinimalDegree()">
            <CompanyDataBlock>
              <template slot="data">
                <span class="fontello-icn-text-wrapper">
                  <!-- is this condition necessary ?? -->
                  <!--span class="fontello icon-graduation-cap"
                  v-if="jobOffer.jobOffer.specificProgramNecessary !== 'yes'" ></span-->
                  <span class="fontello icon-graduation-cap"></span>
                  <span>
                    {{ jobOffer.jobOffer.desiredStudyProgramsText }}
                  </span>
                </span>
              </template>
            </CompanyDataBlock>
          </div>
          <div class="cell" v-if="isSpecificProfessionNecessary()">
            <CompanyDataBlock>
              <Translation slot="title" resource_key="joboffer.vocationalEducation"/>
              <template slot="data">
                <span class="fontello-icn-text-wrapper">
                  <span class="fontello icon-tools"
                        v-if="jobOffer.jobOffer.specificProfessionNecessary === 'yes'"
                  ></span>
                  <span>
                    {{ jobOffer.jobOffer.desiredProfessionsText }}
                  </span>
                </span>
              </template>
            </CompanyDataBlock>
          </div>

          <div class="cell" v-if="needsOtherQualifications()">
            <CompanyDataBlock>
              <Translation slot="title" resource_key="joboffer.otherQualifications"/>
              <template slot="data">
                <span class="fontello-icn-text-wrapper">
                  <span class="fontello icon-graduation-cap"></span>
                  <span>
                    {{ jobOffer.jobOffer.otherQualifications.join(', ') }}
                  </span>
                </span>
              </template>
            </CompanyDataBlock>
          </div>
        </div>

        <div class="column" v-if="isJobExperienceImportant()">
          <div class="cell division-title desktop-hidden">
            <CompanyDataBlock>
              <Translation slot="data" resource_key="joboffer.necessaryPracticalExperience"/>
            </CompanyDataBlock>
          </div>
          <div class="cell">
            <CompanyDataBlock>
              <Translation slot="title" resource_key="joboffer.duration"/>
              <template slot="data" v-if="isJobExperienceYearsDefined()">
                {{ jobOffer.jobOffer.jobExperienceYears }}
                <Translation resource_key="joboffer.years"/>
              </template>
              <template slot="data" v-else-if="isJobExperienceYearsRangeDefined()">
                {{ jobOffer.jobOffer.jobExperienceYearsRange.join(' - ') }}
                <Translation resource_key="joboffer.years"/>
              </template>
            </CompanyDataBlock>
          </div>
          <div class="cell" v-if="isLeadingExperienceImportant()">
            <CompanyDataBlock>
              <Translation slot="title" resource_key="joboffer.leadingExperience"/>
              <template slot="data">{{ jobOffer.jobOffer.leadingExperienceLevel }}</template>
            </CompanyDataBlock>
          </div>
          <div class="cell" v-if="isSectorsExperienceImportant()">
            <CompanyDataBlock>
              <Translation slot="title" resource_key="joboffer.sectorExperience"/>
              <template slot="data">
                {{ jobOffer.jobOffer.jobExperienceDynaJobsSectors.join(', ') }}
              </template>
            </CompanyDataBlock>
          </div>
        </div>
        <div class="column" v-else>
          <div class="cell">
            <CompanyDataBlock>
              <Translation slot="data" resource_key="joboffer.noExperienceNecessary"/>
            </CompanyDataBlock>
          </div>
        </div>
      </div>

      <LanguageSkillsGraph :values="jobOffer.jobOffer" />

      <ProfessionalKnowledgeTags :values="jobOffer.jobOffer" />

      <div v-if="jobOffer.jobOffer.desiredQualities" class="title-expectation-list">
        <h4>
          <Translation resource_key="joboffer.desiredQualities"/>
        </h4>
        <div class="row" v-if="jobOffer.jobOffer.desiredQualities">
          <div class="joboffer-description free-text"
               v-html="removeStyleAttr(jobOffer.jobOffer.desiredQualities)"
          />
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import CpHeader from '../molecules/CpHeader';
import CompanyDataBlock from '../molecules/CompanyDataBlock';
import Translation from '../atoms/Translation';
import LanguageSkillsGraph from '../organisms/LanguageSkillsGraph';
import ProfessionalKnowledgeTags from '../organisms/ProfessionalKnowledgeTags';
import removeStyleAttr from '../../lib/mixins/htmlUtils';

export default {
  name: 'CompanyExpectation',

  components: {
    CpHeader,
    CompanyDataBlock,
    Translation,
    LanguageSkillsGraph,
    ProfessionalKnowledgeTags
  },

  mixins: [removeStyleAttr],

  props: {
    jobOffer: {
      type: Object,
      required: true
    }
  },

  methods: {
    shouldHaveMinimalDigree() {
      return this.jobOffer.jobOffer.minimalDegree === 'studium_bachelor_level';
    },

    isCollegeGradeImportant() {
      return this.jobOffer.jobOffer.necessaryCollegeGradeImportance === 'important'
        || this.jobOffer.jobOffer.necessaryCollegeGradeImportance === 'veryImportant';
    },

    isEnoughMinimalDegree() {
      return this.jobOffer.jobOffer.minimalDegree === 'studium_bachelor_level'
        || this.jobOffer.jobOffer.minimalDegree === 'studium_master_level'
        || this.jobOffer.jobOffer.minimalDegree >= 4
    },

    isSpecificProfessionNecessary() {
      return this.jobOffer.jobOffer.specificProfessionNecessary === 'yes';
    },

    isJobExperienceImportant() {
      return this.jobOffer.jobOffer.jobExperienceImportance === 'important'
        || this.jobOffer.jobOffer.jobExperienceImportance === 'veryImportant'
    },

    isJobExperienceYearsDefined() {
      return 'jobExperienceYears' in this.jobOffer.jobOffer
        && this.jobOffer.jobOffer.jobExperienceYears.length > 0
        && this.jobOffer.jobOffer.jobExperienceYearsRange.length === 0;
    },

    isJobExperienceYearsRangeDefined() {
      return 'jobExperienceYearsRange' in this.jobOffer.jobOffer
        && this.jobOffer.jobOffer.jobExperienceYearsRange.length > 0;
    },

    isLeadingExperienceImportant() {
      return this.jobOffer.jobOffer.leadingExperienceImportance === 'important'
        || this.jobOffer.jobOffer.leadingExperienceImportance === 'veryImportant'
    },

    isSectorsExperienceImportant() {
      return this.jobOffer.jobOffer.jobExperienceSectorsImportance === 'important'
        || this.jobOffer.jobOffer.jobExperienceSectorsImportance === 'veryImportant'
    },

    needsOtherQualifications() {
      return this.jobOffer.phdNecessary === 'yes'
        || this.jobOffer.jobOffer.llmNecessary === 'yes'
        || this.jobOffer.jobOffer.mbaNecessary === 'yes';
      // || jobOffer.driversLicenseNecessary === "yes"%
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin'; // for /deep/ should be have here
@import '../../scss/atoms/layout'; // for /deep/ should be have here

.company-expectation-inner {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;

  .header /deep/ .title {
    font-size: 30px;
  }

  .language-skills-graph {
    margin-top: 60px;
  }
}

.row {
  display: flex;
  justify-content: center;

  &.division-title {
    margin-top: 30px;

    /deep/ p {
      color: $color-blue !important;
    }
  }

  &.division .column {
    display: flex;
    flex-flow: column;
  }

  .column {
    width: 48%;
    margin-right: 2%;

    &.right {
      height: 70px;

      /deep/ p:before {
        content: '\e817';
        font-family: fontello; // stylelint-disable-line
        color: $color-blue;
        margin-right: 15px;
      }
    }

    .cell.division-title {
      margin-top: 30px;

      /deep/ p {
        color: $color-blue !important;
        &h3 {
          font-size: 400px;
        }
      }
    }

    .fontello-icn-text-wrapper {
      display: flex;
      span {
        display: inline-block;
        &.fontello {
          margin-right: 5px;
        }
      }
    }
  }
}

.title-expectation-list {
  font-size: 17px;
  line-height: 28px;
  h4 {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 28px !important;
  }

  .row {
    justify-content: flex-start;
  }
}
.columns-2-item {
  width: calc((100% - var(--pading-edge) * 2 - 100px) / 2);
}

@include bp-max-large() {
  #employer-advantage {
    margin-top: 0;
  }
  .employer-advantage-inner {
    min-width: auto;
    margin-left: -0;
    .employer-advantage-item:nth-child(2n-1) {
      margin-right: 0;
    }
  }
}

@include bp-max-tablet() {
  .company-expectation-inner {
    padding-left: var(--padding-edge);
    padding-right: var(--padding-edge);

    /deep/ .professional-skill-tags .skills li {
      display: inline-block;
      padding: 11px 15px;
      background-color: #efefef;
      margin: 1px;
      font-size: 14px;
      line-height: 18px;
      color: #3c3c3c;
    }
  }
}

@include bp-max-medium() {
  .company-expectation-inner /deep/ {
    .header h2.title {
      font-size: 24px;
    }

    h4 {
      font-size: 17px;
      line-height: 25px;
    }

    .professional-skill-tags .skills li {
      display: inline-block;
      padding: 11px 15px;
      background-color: #efefef;
      margin: 1px;
      font-size: 14px;
      line-height: 18px;
      color: #3c3c3c;
    }
  }

  .row {
    flex-direction: column;

    .column {
      width: 100%;
    }
  }

  .title-expectation-list {
    h4 {
      font-size: 18px !important;
      line-height: 28px !important;
      font-weight: 700 !important;
    }
  }
}
</style>
