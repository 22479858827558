<template>
  <div :class="{ 'applicant-menu': true, 'mobile-menu-opened': mobileMenuOpened }">
    <div class="under-large-shown">
      <Burger :callback="toggleMenu"/>
    </div>
    <CpLogo />
    <ApplicantMainMenu @linkClicked="toggleMenu" />
    <ApplicantUserMenu />
  </div>
</template>

<script>
import CpLogo from '../../atoms/CpLogo';
import Burger from '../../atoms/Burger';
import ApplicantMainMenu from '../../organisms/Applicant/ApplicantMainMenu'
import ApplicantUserMenu from '../../organisms/Applicant/ApplicantUserMenu'

export default {
  name: 'ApplicantMenu',
  components: {
    CpLogo,
    Burger,
    ApplicantMainMenu,
    ApplicantUserMenu
  },
  data() {
    return {
      mobileMenuOpened: false
    }
  },
  methods: {
    toggleMenu() {
      this.mobileMenuOpened = !this.mobileMenuOpened;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/colors';
@import '../../../scss/atoms/breakpoints.mixin';

.applicant-menu {
  background-color: $color-black;
  display: flex;
  padding: 40px 28px;
  padding-bottom: 27px;

  .cp-logo {
    width: 200px;
  }
}

@include bp-max-large {
  .applicant-menu {
    display: block;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 0;
    max-height: 50px;
    transition: max-height 0.5s;
    overflow: hidden;

    .under-large-shown {
      position: absolute;

      .cp-burger {
        width: 26px;
        height: 17px;
        transition: transform 0.3s;

        &:before {
          top: 4px;
          transition: transform 0.3s;
        }
      }
    }

    > a {
      position: absolute;
      right: 15px;

      /deep/ .cp-logo {
        width: 115px;
      }
    }

    .applicant-main-menu {
      margin-top: 0;
      padding-left: 40px;

      /deep/ li {
        display: none;
        border-right: none;
        padding-top: 0;
        padding-left: 0;
        padding-bottom: 10px;

        &.active {
          display: list-item;

          a {
            color: $color-blue;

            &:after {
              display: none;
            }
          }
        }
      }
    }

    .applicant-user-menu {
      position: relative;
      margin-left: 23px;
      display: none;

      /deep/ button {
        display: none;
        border: none;
        margin: 20px 0;
        line-height: auto;
      }
    }

    &.mobile-menu-opened {
      max-height: 280px;
      transition: max-height 0.5s;

      .cp-burger {
        margin-top: -5px;
        padding-top: 6px;
        border-top: none;
        transform: rotate(45deg);
        overflow: visible;
        transition: transform 0.3s;

        &:before {
          transform: rotate(90deg);
          margin-top: 10px;
          width: 26px;
          transition: transform 0.3s;
        }
      }

      .applicant-main-menu /deep/ li {
        display: list-item;
      }

      .applicant-user-menu {
        display: block;

        /deep/ button {
          display: inline-block;
        }
      }
    }
  }
}

@include bp-max-extra-small {
  .applicant-menu {
    .applicant-main-menu /deep/ li {
      font-size: 15px;
    }

    .applicant-user-menu /deep/ button {
      font-size: 15px;
      margin: 20px 0;
      line-height: auto;
    }
  }
}

</style>
