import { render, staticRenderFns } from "./ServerErrorMessage.vue?vue&type=template&id=a9642aca&scoped=true&"
import script from "./ServerErrorMessage.vue?vue&type=script&lang=js&"
export * from "./ServerErrorMessage.vue?vue&type=script&lang=js&"
import style0 from "./ServerErrorMessage.vue?vue&type=style&index=0&id=a9642aca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9642aca",
  null
  
)

export default component.exports