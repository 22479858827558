<template>
  <button class="cp-burger" @click="openNavigationOverlay"></button>
</template>

<script>
export default {
  name: 'Burger',
  props: {
    callback: Function
  },
  methods: {
    openNavigationOverlay(e) {
      if (this.callback) {
        return this.callback();
      }
      e.preventDefault();
      document.body.classList.add('main-nav-open');
      return false;
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/atoms/burger';
</style>
