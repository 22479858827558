<template>
  <div id="reward-recommendation-header">
    <div class="wrapper">
      <h1>
        <CpLogo size="small"/>
      </h1>
      <div class="container">
        <SourcerAvatar v-if="sourcerObj" :sourcer="sourcerObj" />
        <h2 class="title">
          In nur 4 Schritten zu Ihrer Empfehlungsprovision
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CpLogo from '../atoms/CpLogo';
import SourcerAvatar from '../molecules/SourcerAvatar';

export default {
  name: 'RewardRecommendationHeader',
  components: {
    CpLogo,
    SourcerAvatar
  },
  props: {
    sourcer: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      sourcerId: 0
    }
  },

  computed: {
    ...mapState({
      sourcerObj(state) {
        if (this.sourcer !== null) {
          return this.sourcer;
        }
        if (state) {
          // in case of ApplicantStatus
          if (state.applicant && state.applicant.appointment && state.applicant.appointment.sourcer.id !== 0) {
            return state.applicant.appointment.sourcer;
          }
          // in case of JobSuggestion
          if (state.joboffer && state.joboffer.jobOffer) {
            return state.joboffer.jobOffer.sourcer;
          }
        }
        return null;
      }
    })
  },

  watch: {
    sourcerObj(val) {
      if (val && val.id !== 0 && this.sourcerId !== val.id) {
        this.sourcerId = val.id;
      }
    }
  },

  created() {
    console.log('created');
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_breakpoints.mixin';
@import '../../scss/atoms/_colors';
@import '../../scss/atoms/_typographie';

#reward-recommendation-header {
  display: flex;
  justify-content: center;
  height: 240px;
  background: transparent url(/assets/img/CP_Header_Arbeitnehmer_01.jpg) no-repeat bottom;
  background-size: cover;

  .wrapper {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
  }

  h1 {
    position: absolute;
    top: 20px;
    left: 40px;
  }
  .container{
    position: relative;
    max-width: 1200px;
    padding-top: 70px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    .sourcer-avatar {
      color: $color-blue-light;
    }

    h2.title {
      font-size: 38px;
      color: $color-white;
      display: inline-flex;
      padding-top: 20px;
      margin-left: 25px;
    }
  }
}

@include bp-max($bp-large-with-padding) {

  #reward-recommendation-header .container {
    padding: 70px 40px 0;
    h2.title {
      color: $color-white;
      display: inline-flex;
      align-items: flex-start;
      font-size: 32px;
    }
  }
}

@include bp-max-medium() {

  #reward-recommendation-header .container {
    padding: 70px 30px 0;

    .sourcer-avatar {
      display: none;
    }

    h2.title {
      color: $color-white;
      display: inline-flex;
      align-items: flex-start;
      font-size: 26px;
      margin-left: 10px;
    }
  }
}

</style>
