import user from '../../../api/user';
import types from './types';

const { mail } = user;

export default {
  /**
   * update mail unread count
   * @param  {[type]} commit  given by Vuex Framework
   * @return {[type]}         none. setMailUnread Mutation occures.
   */
  updateMailUnreadCount({ commit }) {
    mail.countUnread().then((result) => {
      commit(types.MUTATIONS.SET_MAIL_UNREAD, { unread: result });
    }, (err) => {
      console.error(err);
    });
  }
};
