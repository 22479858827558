<template>
  <div class="my-account">
    <section :class="{ 'my-account-inner' : true, 'updating' : loading }" v-if="profile">
      <div class="warning" v-if="isFreshAccount">
        Deine Account ist noch nicht aktiviert. Bitte gucke deinen Emailfach, und klicke auf den Aktivierungslink!
      </div>
      <h2>
        <Translation resource_key="applicant.my-account.title" />
      </h2>
      <div :class="{'row' : true, 'name': true, 'mobile' : bpMaxMedium }">
        <div class="first-name">
          <LabelTextField :value="valueOrBlank(bufferedValue.title)"
                          placeholder="z.B. Dr."
                          @change="(v) => valueChanged('title', v)"
          >
            akad. Grad
          </LabelTextField>
          <LabelTextField :value="valueOrBlank(bufferedValue.firstName)"
                          @change="(v) => valueChanged('firstName', v)"
                          :class="{ 'error': errors.firstName}"
          >
            Vorname
          </LabelTextField>
        </div>

        <div class="last-name">
          <LabelTextField :value="valueOrBlank(bufferedValue.lastName)"
                          @change="(v) => valueChanged('lastName', v)"
                          :class="{ 'error': errors.lastName}"
          >
            Familienname
          </LabelTextField>
        </div>
      </div>

      <div class="row">
        <CpSelectBox :value="bufferedValue.infoMailFrequency" :values="infoMailFrequencyValues" @change="(v) => valueChanged('infoMailFrequency', v)">
          <Translation resource_key="applicant.my-account.infomail-frequency" />
        </CpSelectBox>
        <Translation resource_key="applicant.my-account.infomail-frequency.asap" datakey="infoMailFreqValues[0]" />
        <Translation resource_key="applicant.my-account.infomail-frequency.off" datakey="infoMailFreqValues[1]" />
        <Translation resource_key="applicant.my-account.infomail-frequency.weekly" datakey="infoMailFreqValues[2]" />
        <CpSelectBox :value="bufferedValue.status" :values="statusValues" @change="(v) => valueChanged('status', v)">
          <Translation resource_key="applicant.my-account.profile-status" />
        </CpSelectBox>
        <Translation resource_key="applicant.my-account.profile-status.active" datakey="searchStatusValues[0]" />
        <Translation resource_key="applicant.my-account.profile-status.latent" datakey="searchStatusValues[1]" />
        <Translation resource_key="applicant.my-account.profile-status.inactive" datakey="searchStatusValues[2]" />
      </div>

      <div class="row buttons">
        <CpButtonAnchor color="black" :to="{ name: 'PasswordChange' }" :disabled="isFreshAccount">
          <Translation resource_key="applicant.my-account.change-password" />
        </CpButtonAnchor>
        <CpButtonAnchor color="black" :to="{ name: 'RemoveAccount' }" >
          <Translation resource_key="applicant.my-account.delete-account" />
        </CpButtonAnchor>
      </div>
      <div class="row centered">
        <CpButton size="large" :callback="onSubmit" :disabled="!editable">
          <Translation resource_key="applicant.my-account.save" />
        </CpButton>
      </div>
    </section>
    <transition name="modal" :duration="{ leave: 1000 }">
      <div class="loading-wrapper" v-if="loading">
        <CpLoading  />
      </div>
    </transition>
    <router-view></router-view>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import _ from 'lodash';

import Translation from '../../atoms/Translation';
import CpButton from '../../atoms/CpButton/CpButton';
import CpButtonAnchor from '../../atoms/CpButton/CpButtonAnchor';
import CpLoading from '../../atoms/CpLoading';
import CpSelectBox from '../../molecules/CpSelectBox';
import LabelTextField from '../../molecules/LabelTextField';
import formUtils from '../../../lib/mixins/formUtils';
import breakPoints from '../../../lib/mixins/breakPoints';
import types from '../../../store/modules/applicant/types';

const {
  mapState,
  mapMutations,
  mapActions
} = createNamespacedHelpers(types.MODULE_NAME);

export default {
  name: 'MyAccount',

  components: {
    Translation,
    CpButton,
    CpButtonAnchor,
    CpLoading,
    CpSelectBox,
    LabelTextField
  },

  mixins: [formUtils, breakPoints],

  data() {
    return {
      bufferedValue: {},
      loading: true,
      edited: false,
      errors: {
        firstName: false,
        lastName: false
      }
    }
  },

  created() {
    if (!this.profile) {
      this.$store.dispatch('applicant/getProfile').then((profile) => {
        this.bufferedValue = _.cloneDeep(profile);
        this.loading = false;
      });
      return;
    }
    if (Object.keys(this.bufferedValue).length === 0) {
      this.bufferedValue = _.cloneDeep(this.profile);
      this.loading = false;
    }
  },

  computed: {
    ...mapState({
      profile: (state) => state.profile
    }),
    isFreshAccount() {
      return !this.profile.enabled
    },
    editable() {
      return !this.isFreshAccount && this.edited;
    },
    infoMailFrequencyValues() {
      // TODO: will get from locale or API
      return [
        { value: 1, label: 'sofort' },
        { value: 2, label: 'aus' },
        { value: 3, label: 'wöchentlich' }
      ];
    },
    statusValues() {
      // TODO: will get from locale or API
      return [
        { value: 2, label: 'aktiv suchend' },
        { value: 3, label: 'latent suchend' },
        { value: 4, label: 'nicht suchend' }
      ];
    }
  },
  methods: {
    ...mapActions([types.ACTIONS.UPDATE_PROFILE]),
    ...mapMutations([types.MUTATIONS.SET_PROFILE_VALUE]),
    valueChanged(key, value) {
      // do vuex dispatch
      this.bufferedValue[key] = value;
      this.edited = true;
    },
    resetValidationErrors() {
      const self = this;
      Object.keys(this.errors).map((item) => {
        self.errors[item] = false;
        return item;
      });
    },
    validate() {
      this.resetValidationErrors();
      let valid = true;
      if (this.bufferedValue.firstName.length === 0) {
        valid = false;
        this.errors.firstName = true;
      }
      if (this.bufferedValue.lastName.length === 0) {
        valid = false;
        this.errors.lastName = true;
      }
      return valid;
    },
    onSubmit() {
      if (!this.validate()) {
        return false;
      }

      // do vuex dispatch
      this.loading = true;
      this.updateProfile({ profile: this.bufferedValue }).then(() => {
        this.loading = false;
        this.edited = false;
      });
      return false; // always return false, because of preventing of form submission
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../../scss/atoms/colors';
@import '../../../scss/atoms/_breakpoints.mixin';

.my-account {
  section.my-account-inner {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 80px;
    padding-top: 60px;
    background-color: #feffff;

    &.updating {
      opacity: 0.4;
      cursor: not-allowed;
      height: calc(100vh - 80px);
      margin-bottom: 0;
    }

    .warning {
      color: $color-red;
      text-align: center;
      margin-bottom: 60px;
    }

    h2 {
      font-size: 27px;
      margin-bottom: 35px;
    }

    .row {
      display: flex;
      margin-bottom: 20px;

      &.centered {
        align-items: center;
        justify-content: center;

        > .cp-btn {
          width: 33%;
          height: 70px;
          font-size: 17px;
        }
      }

      &:last-of-type {
        width: 100%;
        margin-top: 50px;
      }

      > div {
        width: calc(50% - 10px);

        &:last-of-type {
          margin-left: 20px;
        }

        &.first-name {
          display: flex;

          > .label-textfield {
            width: 100%;

            &:first-of-type {
              max-width: 20%;
            }

            &:last-of-type {
              max-width: 80%;

              /deep/ input[type=text] {
                border-left: 0;
                background-color: $color-lightgrey;
                font-weight: 600;
              }
            }
          }
        }

        /deep/ input[type=text] {
          background-color: $color-lightgrey;
          font-weight: 600;
          &.blank {
            background-color: transparent;
            font-weight: 400;
          }
        }

        &.cp-select-box /deep/ select {
          background-color: $color-lightgrey;
        }
      }

      .label-textfield {
        margin-bottom: 0;

        &.error /deep/ input {
          border: 3px solid $color-red !important;
        }

        /deep/ input {
          height: 50px;
        }
      }

      > .cp-btn {
        width: calc(25% - 10px);
        height: 50px;
        font-size: 14px;
        line-height: 49px;

        &:last-of-type {
          margin-left: 10px;
        }
      }

      > .cp-select-box /deep/ label .slot {
        display: block;
        font-style: italic;
        font-size: 12px;
        line-height: 1.5em;
      }
    }
  }

  .loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 102px);
    cursor: not-allowed;
    background-color: rgba(200, 200, 200, 0.4);
    z-index: 5;
  }
}

@include bp-max-tablet {
  .my-account {
    section.my-account-inner {
      padding-left: var(--padding-edge);
      padding-right: var(--padding-edge);

      .row {
        flex-flow: column;

        &.buttons {
          flex-flow: row;
          justify-content: center;
        }

        > div {
          width: 100%;

          &:first-of-type {
            margin-bottom: 20px;
          }

          &:last-of-type {
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>
