<template>
  <transition name="modal">
    <section class="market-value">
      <div class="container">
        <h2>Market Value</h2>
        <router-link to="/">close</router-link>
      </div>
    </section>
  </transition>
</template>

<script>
export default {
  name: 'MarketValue',

  methods: {
    callback(e) {
      console.log(e);
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../scss/atoms/colors';

.market-value {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $gradient_ci_transparent;
  opacity: 1;
  transition: opacity 1s;
  z-index: 1000;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
  transition: opacity 1s;
}

h2 {
  color: $color-white;
}
</style>
