<template>
  <button class="cp-social-btn" :class="styleClasses" @click="onClick">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'SocialButton',

  props: {
    channel: {
      type: String,
      default: 'xing'
    },
    callback: {
      type: Function
    },
    size: {
      type: String,
      default: 'medium'
    }
  },

  computed: {
    styleClasses() {
      const classes = [];

      if (typeof this.channel === 'string') {
        classes.push(this.channel);
      }

      if (typeof this.size === 'string') {
        classes.push(this.size);
      }

      return classes.join(' ');
    }
  },

  methods: {
    onClick(e) {
      if (typeof this.callback === 'function') {
        this.callback(e);
      }
    }
  }
};
</script>

<style lang="scss">
@import './buttons';
</style>
