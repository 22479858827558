<template>
  <div class="personal-info-display">
    <div v-if="bpMaxMedium" class="column-12">
      <!-- only for mobile -->
      <div class="profile-image">
        <img :src="personalImage">
      </div>
      <div class="name">
        <div class="fullname">
          {{ fullName }}
        </div>
        <ul class="sns">
          <li class="linkedin" v-if="'linkedInLink' in values">
            <a :href="values.linkedInLink" target="_blank" rel="noopener noreferrer">
              <i class="fontello icon-linkedin"></i>
            </a>
          </li>
          <li class="xing" v-if="'xingLink' in values">
            <a :href="values.xingLink" target="_blank" rel="noopener noreferrer">
              <i class="fontello icon-xing"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="column-2" v-if="!bpMaxMedium">
      <img :src="personalImage">
    </div>
    <div :class="(values.telephoneInterview) ? 'column-6' : 'column-10'">
      <div class="fullname" v-if="!bpMaxMedium">
        {{ fullName }}
      </div>
      <div class="email">
        <i class="fontello icon-mail"></i>
        {{ values.owner.email }}
      </div>
      <div class="telephone">
        <i class="fontello icon-phone"></i>
        <span v-html="phone"></span>
      </div>
      <div class="location">
        <i class="fontello icon-location"></i>
        <span v-html="location"></span>
      </div>
      <ul class="sns" v-if="!values.telephoneInterview && !bpMaxMedium" >
        <li class="linkedin" v-if="'linkedInLink' in values">
          <a :href="values.linkedInLink" target="_blank" rel="noopener noreferrer">
            <i class="fontello icon-linkedin"></i>
          </a>
        </li>
        <li class="xing" v-if="'xingLink' in values">
          <a :href="values.xingLink" target="_blank" rel="noopener noreferrer">
            <i class="fontello icon-xing"></i>
          </a>
        </li>
      </ul>
    </div>
    <div class="column-4" v-if="values.telephoneInterview">
      <ul class="sns" v-if="!bpMaxMedium">
        <li class="linkedin" v-if="'linkedInLink' in values">
          <a :href="values.linkedInLink" target="_blank" rel="noopener noreferrer">
            <i class="fontello icon-linkedin"></i>
          </a>
        </li>
        <li class="xing" v-if="'xingLink' in values">
          <a :href="values.xingLink" target="_blank" rel="noopener noreferrer">
            <i class="fontello icon-xing"></i>
          </a>
        </li>
      </ul>
      <div class="available">
        <CompanyTextBase>
          <template slot="title">
            Verfügbarkeit:
          </template>
          <template slot="content">
            {{ values.telephoneInterview.availableFrom }}
          </template>
        </CompanyTextBase>
      </div>
      <div class="joboffer-process">
        <CompanyTextBase>
          <template slot="title">
            in andrem Bewerbungsprozess:
          </template>
          <template slot="content">
            {{ (values.telephoneInterview.inOtherApplicationProcesses) ? 'Ja' : 'Nein' }}
          </template>
        </CompanyTextBase>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyTextBase from '../../molecules/CompanyTextBase';
import breakPoints from '../../../lib/mixins/breakPoints';

export default {
  name: 'PersonalInfoDisplay',
  components: {
    CompanyTextBase
  },
  mixins: [breakPoints],
  props: {
    values: {
      type: Object,
      required: true
    },
    fresh: {
      type: Boolean
    }
  },
  computed: {
    personalImage() {
      // const gender = 1; // TODO: refer profile
      const { gender } = this.values.owner;
      let result = `/assets/img/profile-picture-placeholder${gender}.png`;
      if (this.values.image && 'imagePath' in this.values.image) {
        let path = this.values.image.imagePath;
        if (path.match(/_[a-z]*\./g) != null) {
          path = path.replace(/_[a-z]*\./, '_best.');
        }
        result = `/${path}`;
      }
      return result;
    },
    fullName() {
      return this.values.owner.academicDegree
        ? `${this.values.owner.academicDegree} ${this.values.owner.namefirst} ${this.values.owner.namelast}`
        : `${this.values.owner.namefirst} ${this.values.owner.namelast}`;
    },
    location() {
      return !('zip' in this.values.currentLocation) || !('province' in this.values.currentLocation)
        ? '<i class="fresh">Angabe ergänzen</i>'
        : `${this.values.currentLocation.zip} ${this.values.currentLocation.province}`;
    },
    phone() {
      return (this.values.owner.phone)
        ? `${this.values.owner.phone}`
        : '<i class="fresh">Angabe ergänzen</i>'
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_breakpoints.mixin';

.wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #000000;
}

.personal-info-display {
  display: flex;
  color: $color-white;
  font-size: 16px;
  min-height: 320px;

  i.fresh {
    font-style: italic;
    color: $color-lightgrey;
  }
}

.column-2 {
  width: 25%;
  max-width: 280px;
  max-height: 320px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.column-4 {
  width: 30%;
  margin-top: 32px;
  margin-left: 20px;
}

.column-6 {
  width: 40%;
  margin-top: 34px;
  margin-left: 20px;

  > .location {
    font-style: italic;
    line-height: 24px;
  }
}

.column-10 {
  width: calc(75% - 20px);
  margin-top: 34px;
  margin-left: 20px;
  position: relative;

  > .sns {
    position: absolute;
    right: 0;
    top: 0;
    border-bottom: 0;
  }
}

.column-2,
.column-4,
.column-6,
.column-10 {
  > ul,
  > div {
    border-bottom: 1px solid $color-white;
    padding-top: 11px;
    padding-bottom: 12px;

    &.avaliable,
    &.joboffer-process {
      padding-top: 13px;
      padding-bottom: 14px;
    }

    > i {
      margin-right: 5px;
    }

    /deep/ i.fresh {
      font-style: italic;
      color: #e0e0e0; // FIXME: ask @christina
    }
  }

  .fullname {
    font-size: 27px;
    font-weight: 800;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .telephone {
    .icon-phone:before {
      padding-right: 0;
    }
  }

  .company-text-base {
    margin-top: 4px;
    margin-bottom: 4px;

    /deep/ {
      h3 {
        color: $color-lightgrey;
        font-size: 14px;
        line-height: 1.4em;
        margin: 0;
      }

      .content {
        color: $color-white;
      }
    }
  }
}

ul.sns {
  padding-top: 15px;
  padding-bottom: 15px;

  @include bp-medium() {
    min-height: 63px;
  }

  li {
    display: inline-block;
    font-size: 21px;

    a {
      color: $color-white;
    }
  }
}

.available {
  min-height: 79px;
}

.joboffer-process {
  min-height: 83px;
}

@include bp-max-medium() {
  .personal-info-display {
    width: 100%;
    flex-direction: column;

    .profile-image {
      width: 120px;
      height: 160px;

      img {
        width: 100%;
      }
    }

    .name {
      padding-left: 15px;
      padding-top: 30px;
    }

    .column-2,
    .column-4,
    .column-6,
    .column-10 {
      margin-top: 0;
      width: 100%;
      padding: 5px 30px;

      > ul,
      > div {
        margin-left: 0;
        margin-right: 0;
        // padding-left: var(--padding-edge);
      }

      .email {
        border-top: 1px solid $color-white;
      }
    }

    .column-4 {
      padding: 5px 20px;
      margin-bottom: 45px;
    }

    .column-4,
    .column-6 {
      padding: var(--padding-edge);
      margin-left: 0;
    }

    .column-10 {
      margin-left: 0;
      padding-bottom: 0;
      margin-bottom: 50px;
    }

    .column-12 {
      width: 100%;
      margin-top: 0;
      padding: 0 30px 25px 30px;
      display: flex;
      justify-content: flex-start;
    }

    .available {
      padding-top: 0 !important;
    }

    .fullname {
      font-size: 27px;
      font-weight: 800;
      padding-top: 15px;
    }

    .sns {
      padding-top: 5px;
    }
  }
}
</style>
