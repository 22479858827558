// import StartPage from '../components/pages/StartPage';
import CVUpload from '../components/templates/CVUpload';
import FreeText from '../components/templates/FreeText';
import MarketValue from '../components/templates/MarketValue';
import Registration from '../components/templates/Registration';
import NoLoginAnalyse from '../components/templates/NoLoginAnalyse';
import CompanyProfile from '../components/templates/CompanyProfile';
import JobOffer from '../components/templates/JobOffer';

export default {
  routes: [
    {
      path: '/cv-upload',
      name: 'CVUpload',
      component: CVUpload
    },
    {
      path: '/free-text',
      name: 'FreeText',
      component: FreeText
    },
    {
      path: '/market-value',
      name: 'MarketValue',
      component: MarketValue
    },
    {
      path: '/register/:jobOfferId?',
      name: 'Registration',
      component: Registration
    },
    {
      path: '/analyse',
      name: 'Analyse',
      component: NoLoginAnalyse
    },
    {
      path: '/company/:id',
      name: 'CompanyProfile',
      component: CompanyProfile
    },
    {
      path: '/jobOffer/:id',
      name: 'JobOffer',
      component: JobOffer
    }
  ]
};
