<template>
  <div class="user-login">
    <CpButton class="cpbutton logout" color="dark" v-if="loggedin" :callback="logout">
      Ausloggen
    </CpButton>
    <CpButton class="cpbutton login" v-if="!loggedin">Einloggen / Registrieren</CpButton>
  </div>
</template>

<script>
/**
 * UserLogut is only for Demo Version (until 11.09.2018
 * @type {VueComponent}
 */
import CpButton from '../atoms/CpButton';

export default {
  name: 'UserLogout',
  components: {
    CpButton
  },
  computed: {
    loggedin() {
      return true;
    }
  },
  methods: {
    logout() {
      window.location = `/${this.$careerpeakr.locale}/logout`;
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/atoms/colors';
@import '../../scss/atoms/_breakpoints.mixin';

.cpbutton {
  &.login {
    background: $gradient_ci;
  }
  &.logout {
    background: none;
    background-color: $color-blacky;
    border-left: 1px solid $color-white;
    border-bottom: 1px solid $color-white;
  }
}

@include bp-max-tablet {
  .cpbutton.logout {
    border-right: 1px solid $color-white;
    border-top: 1px solid $color-white;

    .cp-btn-inner{
      line-height: 65px;
    }
  }
}

</style>
