/**
 * global mixins
 * here we will define all Vue instance global mixins.
 * for example now we can refere to this.$careerpeakr.locale
 */
export default {
  computed: {
    $careerpeakr() {
      return { locale: window.translations.locale };
    }
  }
};
