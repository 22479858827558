import axios from 'axios';
import applicant from '../../../api/applicant';
import locations from '../../../api/locations';

import types from './types';
import utils from '../../../lib/utils';

export default {
  /**
   * upload CV File then get result of analyse.
   * as result called 2 mutations (types.MUTATIONS.setCharts, types.MUTATIONS.setJobList)
   * @param  {Object} commit  [description]
   * @param  {Object} payload should have 2 object
   *   - file ... for example: document.getElementById('file').files[0]
   *   - uploadProgressCallback  a callback function, which take progressEvent as an argument
   *   see more in detail: https://github.com/axios/axios/blob/master/examples/upload/index.html
   * @return {Promise}         Promise Object
   */
  [types.ACTIONS.ANALYSE_CV]({ commit }, payload) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      // id is quickhack. later should be removed.
      let userId = 0;
      if (utils.hasProperty(payload, 'id')) {
        userId = payload.id;
      }
      applicant.cv
        .analyse_cv(payload.file, userId, payload.uploadProgressCallback)
        .then((data) => {
          commit(types.MUTATIONS.SET_CHARTS, data.charts);
          commit(types.MUTATIONS.SET_JOBLIST, data.jobList);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  /**
   * post free text then get result of analyse.
   * as result called 2 mutations (types.MUTATIONS.setCharts, types.MUTATIONS.setJobList)
   * @param  {Object} commit  [description]
   * @param  {Object} payload should have 2 object
   *   - text ... freetext from form
   *   - uploadProgressCallback  a callback function, which take progressEvent as an argument
   *   see more in detail: https://github.com/axios/axios/blob/master/examples/upload/index.html
   * @return {Promise}         Promise Object
   */
  [types.ACTIONS.ANALYSE_TEXT]({ commit }, payload) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      applicant.cv
        .analyse_freetext(payload.text, payload.uploadProgressCallback)
        .then((data) => {
          commit(types.MUTATIONS.SET_CHARTS, data.charts);
          commit(types.MUTATIONS.SET_JOBLIST, data.jobList);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  /**
   * get Profile
   * as result called 2 mutations (types.MUTATIONS.setCharts, types.MUTATIONS.setJobList)
   * @param  {Object} commit  [description]
   * @param  {Object} payload should have 1 Integer Vaule
   *   - id : of applicant, without it should work also.
   * @return {Promise}         Promise Object
   */
  [types.ACTIONS.GET_PROFILE]({ commit }, payload) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      if (utils.hasProperty(payload, 'id')) {
        applicant.profile
          .getProfile(payload.id)
          .then((profile) => {
            commit(types.MUTATIONS.SET_PROFILE, { profile });
            resolve(profile);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      } else {
        applicant.profile
          .getProfile()
          .then((profile) => {
            commit(types.MUTATIONS.SET_PROFILE, { profile });
            resolve(profile);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      }
    });
  },

  /**
   * update Profile
   * as result called a mutation (types.MUTATIONS.SET_PROFILE)
   * @param  {Object} commit  [description]
   * @param  {Object} payload should have 1 Integer Vaule
   *   - id : of applicant, without it should work also.
   * @return {Promise}         Promise Object
   */
  [types.ACTIONS.UPDATE_PROFILE]({ commit }, { profile }) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      applicant.profile
        .updateProfile(profile)
        .then((data) => {
          commit(types.MUTATIONS.SET_PROFILE, { profile: data });
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  /**
   * get Profile
   * as result called 2 mutations (types.MUTATIONS.setCharts, types.MUTATIONS.setJobList)
   * @param  {Object} commit  [description]
   * @param  {Object} payload should have 1 Integer Vaule
   *   - id : of applicant, without it should work also.
   * @return {Promise}         Promise Object
   */
  [types.ACTIONS.GET_JOB_LIST]({ commit }, payload) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      applicant.jobList
        .getJobList(payload)
        .then((data) => {
          if (Array.isArray(data)) {
            commit(types.MUTATIONS.SET_JOBLIST, data);
            resolve(data);
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  /**
   * get Refined Profile
   * as result called 2 mutations (types.MUTATIONS.setCharts, types.MUTATIONS.setJobList)
   * @param  {Object} commit  [description]
   * @param  {Object} payload should have 1 Integer Vaule
   *   - id : of applicant, without it should work also.
   * @return {Promise}         Promise Object
   */
  [types.ACTIONS.GET_REFINED_JOB_LIST]({ commit }, payload) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      applicant.refinedJobList
        .getRefinedJobList(payload)
        .then((data) => {
          if (Array.isArray(data)) {
            commit(types.MUTATIONS.SET_JOBLIST, data);
            resolve(data);
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  [types.ACTIONS.GET_JOB_DETAIL]({ commit }, payload) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      applicant.jobDetail
        .getJobDetail(payload)
        .then((data) => {
          commit(types.MUTATIONS.SET_JOB_DETAIL, data);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  /**
   * get Profile
   * as result called 2 mutations (types.MUTATIONS.setCharts, types.MUTATIONS.setJobList)
   * @param  {Object} commit  [description]
   * @param  {Object} payload should have 1 Integer Vaule
   *   - profileId : of applicant, without it should work also.
   *   - jobIds : which user selected, without it should work also.
   *   - optimizationChanceIds : which user selected, without it should work also.
   *     with this values, charts.competenceProfile will be maped into charts.
   *     betterCompetenceProfile.
   * @return {Promise}         Promise Object
   */
  [types.ACTIONS.ANALYSE]({ commit }, payload) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      const profileId = utils.hasProperty(payload, 'profileId') ? payload.profileId : '';
      const jobIds = utils.hasProperty(payload, 'jobIds') ? payload.jobIds : [];
      const optimizationChanceIds = utils.hasProperty(payload, 'optimizationChanceIds') ? payload.optimizationChanceIds : [];

      applicant.analyse
        .analyse(profileId, jobIds, optimizationChanceIds)
        .then((data) => {
          // const data = orig_data.
          if (data.jobList && data.jobList.length) {
            commit(types.MUTATIONS.SET_JOBLIST, data.jobList);
          }

          if (optimizationChanceIds.length) {
            commit(types.MUTATIONS.SET_OPTIMIZED_CHARTS, data);
          } else {
            commit(types.MUTATIONS.SET_CHARTS, data);
          }

          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  [types.ACTIONS.OPTIMIZE]({ commit }, payload) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      const profileId = utils.hasProperty(payload, 'profileId') ? payload.profileId : '';
      const jobIds = utils.hasProperty(payload, 'jobIds') ? payload.jobIds : [];
      const optimizationChanceIds = utils.hasProperty(payload, 'optimizationChanceIds') ? payload.optimizationChanceIds : [];

      applicant.optimise.optimise(profileId, jobIds, optimizationChanceIds)
        .then((data) => {
          // const data = orig_data.
          if (data.jobList && data.jobList.length) {
            commit(types.MUTATIONS.SET_JOBLIST, data.jobList);
          }

          if (optimizationChanceIds.length) {
            commit(types.MUTATIONS.SET_OPTIMIZED_CHARTS, data);
          } else {
            commit(types.MUTATIONS.SET_CHARTS, data);
          }

          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  // location predictions
  /**
   * get all ids marked as trash
   * as result called 1 mutations (types.MUTATIONS.SET_TRASH)
   * @param  {Object} commit  [description]
   * @param  {Object} payload should have 1 Integer Vaule
   *   - id : of applicant, without it should work also.
   * @return {Promise}         Promise Object
   */
  [types.ACTIONS.GET_LOCATION_PREDICTIONS]({ commit }, { input }) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      locations
        .getPredictions(input)
        .then((data) => {
          commit(types.MUTATIONS.SET_LOCATION_PREDICTIONS, data);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  // favorite
  /**
   * get all ids marked as trash
   * as result called 1 mutations (types.MUTATIONS.SET_TRASH)
   * @param  {Object} commit  [description]
   * @param  {Object} payload should have 1 Integer Vaule
   *   - id : of applicant, without it should work also.
   * @return {Promise}         Promise Object
   */
  [types.ACTIONS.GET_ALL_FAVORITE]({ commit }, _payload) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      applicant.favorite
        .getAll()
        .then((data) => {
          commit(types.MUTATIONS.SET_FAVORITE, data);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  /**
   * mark id as trash
   * as result called 1 mutations (types.MUTATIONS.SET_TRASH)
   * @param  {Object} commit  [description]
   * @param  {Object} payload should have 1 Integer Vaule
   *   - id : of job which should be marked as trash
   * @return {Promise}         Promise Object
   */
  [types.ACTIONS.MARK_FAVORITE]({ commit }, payload) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      applicant.favorite
        .mark(payload.id)
        .then((data) => {
          commit(types.MUTATIONS.SET_FAVORITE, data);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  /**
   * unmark id as trash
   * as result called 1 mutations (types.MUTATIONS.SET_TRASH)
   * @param  {Object} commit  [description]
   * @param  {Object} payload should have 1 Integer Vaule
   *   - id : of applicant, without it should work also.
   * @return {Promise}         Promise Object
   */
  [types.ACTIONS.UNMARK_FAVORITE]({ commit }, payload) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      applicant.favorite
        .unmark(payload.id)
        .then((data) => {
          commit(types.MUTATIONS.SET_FAVORITE, data);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  // trash:
  /**
   * get all ids marked as trash
   * as result called 1 mutations (types.MUTATIONS.SET_TRASH)
   * @param  {Object} commit  [description]
   * @param  {Object} payload should have 1 Integer Vaule
   *   - id : of applicant, without it should work also.
   * @return {Promise}         Promise Object
   */
  [types.ACTIONS.GET_ALL_TRASH]({ commit }) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      applicant.trash
        .getAll()
        .then((data) => {
          commit(types.MUTATIONS.SET_TRASH, data);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  /**
   * mark id as trash
   * as result called 1 mutations (types.MUTATIONS.SET_TRASH)
   * @param  {Object} commit  [description]
   * @param  {Object} payload should have 1 Integer Vaule
   *   - id : of job which should be marked as trash
   * @return {Promise}         Promise Object
   */
  [types.ACTIONS.MARK_TRASH]({ commit }, payload) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      applicant.trash
        .mark(payload.id)
        .then((data) => {
          commit(types.MUTATIONS.SET_TRASH, data);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  /**
   * unmark id as trash
   * as result called 1 mutations (types.MUTATIONS.SET_TRASH)
   * @param  {Object} commit  [description]
   * @param  {Object} payload should have 1 Integer Vaule
   *   - id : of applicant, without it should work also.
   * @return {Promise}         Promise Object
   */
  [types.ACTIONS.UNMARK_TRASH]({ commit }, payload) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      applicant.trash
        .unmark(payload.id)
        .then((data) => {
          commit(types.MUTATIONS.SET_TRASH, data);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  [types.ACTIONS.GET_APPLICATION_STATUS_BASE]({ commit }, _payload) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/v2/applicationstatus/base')
        .then((response) => {
          commit(types.MUTATIONS.SET_APPLICATIONSTATUS_BASE, response.data);
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  [types.ACTIONS.GET_APPLICATION_STATUS_MATCHING_JOB_OFFERS]({ commit }, _payload) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/v2/applicationstatus/matchingjoboffers', { params: { ajax: 1 } })
        .then((response) => {
          commit(types.MUTATIONS.SET_APPLICATIONSTATUS_MATCHINGJOBOFFERS, response.data);
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          commit(types.MUTATIONS.SET_APPLICATIONSTATUS_MATCHINGJOBOFFERS, {
            manualmatching: [],
            applicationStageConstants: null,
            user: null
          });
          reject(error);
        });
    });
  },

  [types.ACTIONS.GET_APPLICATION_STATUS_DELETED_MATCHES]({ commit }, _payload) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/v2/applicationstatus/deletedmatches', { params: { ajax: 1 } })
        .then((response) => {
          commit(types.MUTATIONS.SET_APPLICATIONSTATUS_DELETEDMATCHES, response.data);
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          commit(types.MUTATIONS.SET_APPLICATIONSTATUS_DELETEDMATCHES, {
            deletedMatches: [],
            applicationStageConstants: null,
            user: null
          });
          reject(error);
        });
    });
  },

  [types.ACTIONS.GET_APPLICATION_STATUS_JOB_OFFER]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/joboffer/${payload.jobOfferId}/${payload.locale}`)
        .then((response) => {
          const jobOffer = response.data;
          commit(types.MUTATIONS.SET_APPLICATIONSTATUS_JOBOFFER, jobOffer);
          resolve(jobOffer);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  [types.ACTIONS.GET_APPOINTMENT_DATA]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/applicationstatus/appointment/${payload.sourcerId}/${payload.locale}`)
        .then((response) => {
          const { data } = response;
          commit(types.MUTATIONS.SET_APPOINTMENT_DATA, data);
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }
};
