<template>
  <li :class="{'abroad-experience' : true, 'fresh' : isFresh()}">
    <em class="time" v-if="!bpMaxMedium">{{ duration }}</em>
    <div class="point"></div>
    <div class="right">
      <em class="time" v-if="bpMaxMedium">{{ duration }}</em>
      <div class="country">{{ country }}</div>
      <div class="type">{{ type }}</div>
    </div>

  </li>

</template>

<script>
import breakPoints from '../../../lib/mixins/breakPoints';

export default {
  name: 'AbroadExperience',

  mixins: [breakPoints],

  props: {
    value: {
      type: Object,
      required: true
    },
    fresh: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    duration() {
      return this.isFresh() ? '0 Monate' : this.value.duration;
    },
    country() {
      return this.isFresh() ? 'Angabe ergänzen' : this.value.country;
    },
    type() {
      return this.isFresh() ? '' : this.value.type;
    }
  },

  methods: {
    isFresh() {
      return this.fresh
        && this.value.duration === '1 Monat'
        && this.value.country === 'Vereinigte Staaten'
        && this.value.type === 'Praktikum';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_typographie';
@import '../../../scss/atoms/_icons';
@import '../../../scss/atoms/_breakpoints.mixin';

.abroad-experience {
  position: relative;
  line-height: 1.2em;

  @include bp-max-medium() {
    padding-left: 0px;
  }

  &.fresh {
    color: $color-silvergrey;
  }

  .title {
    color: $color-blue-light;
    margin: 0;
  }

  .experience-list {
    position: relative;
    padding-top: 20px;
  }

  .time {
    display: inline-block;
    vertical-align: top;
    padding-right: 27px;
    text-align: right;
    width: 98px;
    font-size: 14px;
    margin-top: 4px;

    @include bp-max-medium(){
      text-align: left;
    }
  }

  .right {
    display: inline-block;
    padding-left: 30px;
    padding-bottom: 1.4em;
    border-left: 1px solid $color-disabled-grey;

    .country {
      margin-top: 4px;
      font-weight: $font-bold;
    }
  }

  .point {
    position: absolute;
    width: 12px;
    height: 12px;
    border: 0 none;
    padding: 2px;
    border-radius: 100%;
    top: 8px;
    left: 99px;
    z-index: 2;
    transform: translateX(-50%);
    background: transparent $gradient-blue;
    transition: all 0.2s;

    @include bp-max-medium(){
      left: 0px;
    }

    &:before {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      background-color: $color-white;
      border-radius: 100%;
    }
  }
}

@include bp-max-medium(){

  ul.abroad-experiences {
    width: 100% !important;
    padding-left: 0px;

    em.time {
      display: block;
      // padding-left: 20px;
      border-left: 1px solid #626262;
    }

    .right {
      padding-left: 0px;

      .country {
        font-size: $fs-medium;
      }

      .type {
        line-height: 14px;
      }
    }
  }
}
</style>
