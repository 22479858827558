<template>
  <div class="application-status-board">
    <div v-if="!jobOffers || !jobOffers.length" class="no-job">
      <slot></slot>
      <div v-if="!jobOffersLoaded" class="no-job-loading"></div>
    </div>
    <template v-else>
      <ApplicationStatus
        v-for="(offer, idx) in jobOffers"
        :key="`status-${idx}`"
        :class="{'disabled' : disabled}"
        :company="offer.company"
        :title="offer.title"
        :place="offer.locations"
        type="Festanstellung"
        :branch="offer.branches"
        :size="offer.employees"
        :level="offer.levels"
        :travel="offer.travel"
        :begin="offer.begin"
        :hours="offer.hours"
        :status="offer.status"
        :companyId="offer.companyId"
        :companylogo="offer.companylogo"
        :jobOfferId="offer.jobOfferId"
        :userId="offer.userId"
        :loading="offer.loading"
        v-on:removed="offerRemoved(idx)"
        v-on:publish-data="publishData(idx)"
      ></ApplicationStatus>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import ApplicationStatus from './ApplicationStatus';
import types from '../../store/modules/applicant/types';

const DeletedMatches = 'deletedMatches';

export default {
  name: 'ApplicationStatusList',

  components: {
    ApplicationStatus
  },

  props: {
    type: String
  },

  data() {
    return {
      dict: [],
      jobOfferLoading: []
    }
  },

  computed: {
    disabled() {
      return DeletedMatches === this.type;
    },
    ...mapState('applicant', {
      jobOffers(state) {
        if (state) {
          const matching = DeletedMatches === this.type
            ? state.applicationstatus.deletedMatches
            : state.applicationstatus.matchingJobOffers;

          if (matching && matching.length) {
            return matching.map((item) => {
              const item2 = { ...item };

              const branches = item2.branches.map((branch) => this.dict[`joboffer.sectors.${branch}`]);
              const levels = item2.levels.map((level) => this.dict[`joboffer.levels.${level}`]);
              const hours = item2.hours.map((magnitude) => this.dict[`joboffer.hours.${magnitude}`]);

              item2.branches = branches.join(', ');
              item2.levels = levels.join('/');
              item2.hours = hours.join(' - ');
              item2.travel = this.dict[`joboffer.trip.${item2.travel}`];

              if (item2.begin === 'asap') {
                item2.begin = this.dict['joboffer.asap'];
              }

              return item2;
            });
          }
          return matching;
        }
        return false;
      },
      jobOffersLoaded(state) {
        if (state) {
          return DeletedMatches === this.type
            ? state.applicationstatus.deletedMatchesLoaded
            : state.applicationstatus.matchingJobOffersLoaded;
        }
        return false;
      }
    })
  },

  methods: {
    updateLoading(idx, status) {
      this.$store.commit(`applicant/${types.MUTATIONS.UPDATE_MATCHINGJOBOFFER_LOADING}`, { idx, status });
    },

    loadJobOffers() {
      const action = DeletedMatches === this.type ? 'getApplicationStatusDeletedMatches' : 'getApplicationStatusMatchingJobOffers';
      return this.$store.dispatch(`applicant/${action}`);
    },

    offerRemoved(idx) {
      /* global Routing */
      if (window.Routing && typeof this.jobOffers[idx] !== 'undefined') {
        this.updateLoading(idx, true);

        const url = Routing.generate('processtracker_notinterested', {
          _locale: this.$careerpeakr.locale,
          jobOfferId: this.jobOffers[idx].jobOfferId,
          applicantId: this.jobOffers[idx].userId
        });

        axios
          .post(url)
          .then((data) => {
            if (data) {
              this.loadJobOffers()
                .catch((_error) => {
                  this.updateLoading(idx, false);
                });
            }
          });
      }
    },

    publishData(idx) {
      if (window.Routing) {
        const url = Routing.generate('processtracker_grantdata', {
          _locale: this.$careerpeakr.locale,
          jobOfferId: this.jobOffers[idx].jobOfferId,
          applicantId: this.jobOffers[idx].userId
        });

        this.updateLoading(idx, true);

        axios
          .post(url)
          .then((_res) => {
            this.loadJobOffers()
              .catch((_error) => {
                // eslint-disable-next-line no-alert
                alert('Fehl geschlagen');
                this.updateLoading(idx, false);
              });
          })
          .catch((_error) => {
            // eslint-disable-next-line no-alert
            alert('Fehl geschlagen');
            this.updateLoading(idx, false);
          });
      }
    }
  },

  created() {
    this.dict = this.$store.getters['translations/dict'];
  }
};
</script>

<style lang="scss">
  @import '../../scss/atoms/colors';

  .no-job {
    position: relative;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: $color-white;
  }

  .no-job-loading {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-white;

    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      display: block;
      width: 50px;
      height: 50px;
      background: transparent url('/assets/img/loader.svg') no-repeat center;
      background-size: contain;
      margin: -25px 0 0 -25px;
      content: '';
      animation: rotation 1.4s linear infinite;
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
