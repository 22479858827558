<template>
  <div class="cp-company-data-block">
    <h3 class="cp-company-data-block-title">
      <slot name="title"></slot>
    </h3>
    <p class="cp-company-data-block-text">
      <slot name="data"></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'CompanyDataBlock'
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';

.cp-company-data-block {
  padding-top: 11px;
  padding-bottom: 11px;
  border-bottom: 1px solid $color-silvergrey;

  h3.cp-company-data-block-title {
    color: $color-darkgrey;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
  }

  p.cp-company-data-block-text {
    color: $color-blacky;
    font-weight: bold;
    line-height: 25px;
    font-size: 17px;
    margin: 0;
  }
}
</style>
