<template>
  <div class="job-suggestion-mobile-filters" :class="{ 'visible': show }">
    <div class="mobile-filter-button" v-if="!show" @click="toggle">
      <i class="fontello icon-search"></i>
      <p>Suche & Filter</p>
      <i class="fontello icon-right-open-big"></i>
    </div>
    <div class="mobile-filter" v-if="show">
      <div class="mobile-filter-inner">
        <div class="cp-modal-close" @click="toggle"></div>
        <h3> Alle Filter </h3>
        <div class="textbox">
          <i class="fontello icon-search"></i>
          <input :tabindex="4"
                 ref="searchText"
                 type="text"
                 name="searchText"
                 :value="searchText"
                 @blur="searchTextInputed"
                 placeholder="Suche verfeinern"/>
        </div>
        <div class="textbox">
          <i class="fontello icon-location"></i>
          <input :tabindex="1"
                 ref="location"
                 type="text"
                 name="location"
                 :value="location"
                 @blur="locationInputed"
                 placeholder="Ort eingeben"/>
          <CpQuestionTooltip
            tooltip="Hier kannst Du Begriffe nennen, die Deiner Jobvorstellung besonders gut entsprechen. Wir suchen dann vor allem nach Jobs, die diese Begriffe enthalten."></CpQuestionTooltip>
        </div>
        <div class="cp-row">
          <CpSelectBox :tabindex="2"
                       :callback="distanceSelected"
                       :values="distanceValues"
                       :value="distance"
                       class="distance aaaa"/>
          <div class="working-time">
            <i class="fontello icon-clock-dark"></i>
            <CpSelectBox :tabindex="3"
                         class="working-time-type"
                         :callback="workingTimeSelected"
                         :values="workingTimeValues"
                         :value="workTimeType"/>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-overlay" v-if="show" @click="toggle"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import translate from '../molecules/Editable';
import CpSelectBox from '../molecules/CpSelectBox';
import CpQuestionTooltip from './CpQuestionTooltip';
import types, { moduleName } from '../../store/modules/applicant/types';

const mutationName = `${moduleName}/${types.MUTATIONS.UPDATE_JOBSUGGESTION_FORM}`;

const workingTimeValues = [
  'Vollzeit', // translate('working-time-type.fulltime'),
  'Teilzeit' // translate('working-time-type.parttime')
];

export default {
  name: 'JobSuggestionMobileFilters',
  components: {
    CpSelectBox,
    CpQuestionTooltip
  },
  data() {
    return {
      distanceValues: ['max. Entfernung', '10 km', '25 km', '50 km', '100 km', '200 km'],
      workingTimeValues,
      show: false
    };
  },
  computed: {
    ...mapState({
      location(state) {
        return state ? state.applicant.jobSuggestionForm.location : '';
      },
      distance(state) {
        return state ? state.applicant.jobSuggestionForm.distance : '';
      },
      workTimeType(state) {
        return state ? state.applicant.jobSuggestionForm.workTimeType : '';
      },
      searchText(state) {
        return state ? state.applicant.jobSuggestionForm.searchText : '';
      }
    })
  },
  methods: {
    distanceSelected(val) {
      console.log('distanceSelected called');
      this.$store.commit(mutationName, { distance: val });
    },
    workingTimeSelected(val) {
      console.log('workingTimeSelected called');
      this.$store.commit(mutationName, { workTimeType: val });
    },
    locationInputed() {
      this.$store.commit(mutationName, { location: this.$refs.location.value });
    },
    searchTextInputed() {
      this.$store.commit(mutationName, { searchText: this.$refs.searchText.value });
    },
    toggle() {
      this.show = !this.show;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';

.job-suggestion-mobile-filters {
  display: block;
  color: $color-disabled-grey;
  font-size: 16px;
  position: absolute;
  width: 100%;
  top: -25px;
  height: 100%;
  max-height: 40px;
  transition: max-height 0.2s, background-color 0.2s;

  &.visible {
    position: fixed;
    z-index: 100;
    top: 42px;
    max-height: calc(100vh - 40px);
    background-color: rgba(0,0,0,0.4);
    transition: max-height 0.2s, background-color 0.2s;
  }

  .mobile-filter-button {
    display: flex;
    padding: 5px;
    background-color: $color-ultralightgrey;

    .icon-search {
      margin-left: 10px;
      margin-right: 5px;
    }

    p {
      margin: 0;
    }

    .icon-right-open-big {
      margin-left: auto;
    }
  }

  .mobile-filter {
    background-color: $color-white;
    padding: 15px;

    .cp-modal-close {
      right: 15px;
      left: auto;
    }

    h3 {
      margin-top: 3px;
      font-weight: 800;
      margin-left: 10px;
    }
  }

  .filter-overlay {
    height: 100%;
    width: 100%;
  }

  input[type='text'] {
    background-color: $color-ultralightgrey;
    border: none;
    width: 80%;

    &::placeholder {
      font-size: 16px;
    }
  }

  .cp-row {
    display: flex;
    flex-flow: row;

    > div {
      width: 50%;
    }
  }

  .working-time {
    display: flex;
    align-items: center;
    background-color: $color-ultralightgrey;

    .icon-clock-dark {
      margin: 10px;
    }
  }

  .fontello :before {
    margin: 0 1em !important;
  }

  .textbox {
    flex-grow: 2;
    background-color: $color-ultralightgrey;
    position: relative;
    padding: 10px;
    margin-bottom: 10px;

    &:last-of-type {
      border-right: none;
    }

    i {
      margin-right: 10px;
    }
  }

  .working-time-type,
  .distance {
    flex-grow: 1;
    position: relative;
    width: 262px;
  }

  .distance {
    margin-right: 2px;
  }

  /deep/ {
    .cp-select-wrapper select {
      height: 53px;
      font-weight: 400;
    }
  }
}

@include bp-medium() {
  .job-suggestion-mobile-filters {
    display: none;
  }
}
</style>

<style lang="css">
  .cp-info {
    position: absolute;
    right: 40px;
    top: 20px;
  }
</style>
