import axios from 'axios';

const nameURL = '/de/geo/name';
const zipURL = '/de/geo/zip';

const getPredictions = (input) => {
  if (input.length < 3) {
    return new Promise((resolve, _reject) => {
      resolve({}); // do nothing
    });
  }

  const url = /^[0-9]{5}/.test(input) ? zipURL : nameURL
  const param = input.includes(',')
    ? input.split(',').find((v) => /^[a-zA-Z]/.test(v)).trim() // use only alphabet string for geo/name
    : input.trim();

  return new Promise((resolve, reject) => {
    console.log(`${url}/${param}`);
    console.log('in api')
    axios
      .get(`${url}/${param}`)
      .then((response) => {
        // handle success
        resolve(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
        reject(error);
      });
  });
};

export default {
  getPredictions
}
