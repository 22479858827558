<template>
  <div class="job-offer-metadata">
    <ul>
      <li class="location">
        {{ locations }}
      </li>
      <li class="job-type">
        <!-- CareerPeakr have Job Offer only for permanentEmployment -->
        <!-- template v-if="jobOffer.permanentEmployment" -->
        <Translation resource_key="joboffer.permanentEmployment" />
        <!-- /template -->
        <!-- template v-else>
          <Translation resource_key="joboffer.freelancer" />
        </template -->
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment';
import Translation from '../atoms/Translation';

export default {
  name: 'JobOfferMetaData',

  components: {
    Translation
  },

  props: {
    jobOffer: {
      type: Object,
      requreid: true
    }
  },

  computed: {
    locations() {
      return this.jobOffer.jobOffer.locations.join(', ');
    }
  },

  created() {
    console.log(this.jobOffer);
  },

  filters: {
    moment(dateObj) {
      moment.locale(this.$careerpeakr.locale);
      return moment(dateObj.date)
        .startOf('hour')
        .fromNow();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';
@import '../../scss/atoms/breakpoints.mixin';

.job-offer-metadata {
  ul li {
    display: inline-block;
    color: $color-dustygrey;
    margin-right: 20px;
    padding-left: 24px;

    &:before {
      margin-left: -24px;
      display: inline-block;
    }

    &.published:before {
      content: '\e810';
      font-family: fontello; // stylelint-disable-line
      margin-right: 10px;
    }

    &.location:before {
      content: '\f031';
      font-family: fontello; // stylelint-disable-line
      margin-right: 10px;
    }

    &.job-type:before {
      content: '\e81c';
      font-family: fontello; // stylelint-disable-line
      margin-right: 10px;
    }
  }
}

@include bp-max-medium() {
  .job-offer-metadata ul li {
    font-size: 16px;
  }
}
</style>
