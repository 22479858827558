import JobSuggestion from '../components/templates/JobSuggestion';
// import CompetenceAnalyse from '../components/templates/CompetenceAnalyse';
// import Optimize from '../components/templates/Optimize';
import JobDetail from '../components/templates/JobDetail';

export default {
  routes: [
    {
      path: '/',
      name: 'CareerBoardJobSuggestion',
      components: {
        'job-suggestion': JobSuggestion
      }
    },
    {
      path: '/competence-analyse',
      name: 'CareerBoardCompetenceAnalyse'
    },
    {
      path: '/optimize',
      name: 'CareerBoardOptimize'
    },
    {
      path: '/job/:id',
      name: 'JobDetail',
      components: {
        'job-suggestion': JobSuggestion,
        modal: JobDetail
      },
      props: {
        'job-suggestion': false,
        modal: true
      }
    }
  ]
};
