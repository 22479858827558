<template>
  <ul :class="{'applicant-main-menu': true, 'non-bp': !has_bp}">
    <router-link tag="li" to="/careerboard" active-class="active" @click.native="linkClicked">
      <a><Editable resource_key="KarriereBoard" /></a>
    </router-link>
    <router-link tag="li"
                 :to="{ name: 'ApplicantProfile', params: { applicantId: userId } }"
                 active-class="active"
                 @click.native="linkClicked"
    >
      <a><Editable resource_key="Bewerberprofil" /></a>
    </router-link>
    <router-link tag="li" to="/status" active-class="active" @click.native="linkClicked">
      <a><Editable resource_key="Bewerbungsstatus" /></a>
    </router-link>

    <li :class="{'non-bp' : !has_bp}">
      <a href="#" v-tooltip="'Coming Soon'">
        <Editable resource_key="Karriereberatung" />
        <span class="coming-soon" v-if="bpMaxTablet">(Coming Soon)</span>
      </a>
    </li>
    <li :class="{'non-bp' : !has_bp}">
      <a href="#" v-tooltip="'Coming Soon'">
        <Editable resource_key="Persönlichkeitsanalyse" />
        <span class="coming-soon" v-if="bpMaxTablet">(Coming Soon)</span>
      </a>
    </li>
    <li :class="{ 'active': isMyAccountActive }">
      <ApplicantSettings @linkClicked="linkClicked" />
    </li>
  </ul>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import VTooltip from 'v-tooltip'
import ApplicantSettings from '../../molecules/ApplicantSettings';
import Editable from '../../molecules/Editable';
import breakPoints from '../../../lib/mixins/breakPoints';

Vue.use(VTooltip)

export default {
  name: 'ApplicantMainMenu',

  components: {
    ApplicantSettings,
    Editable
  },

  mixins: [breakPoints],
  /*
  data() {
    return {
      careerBoardURL: '#',
      statusURL: '#'
    }
  },

  created() {
    if (Routing) {
      this.careerBoardURL = Routing.generate('jobpassion_applicant_pages',
        { _locale: this.$careerpeakr.locale, _page: 'careerboard' }
      );
      this.statusURL = Routing.generate('jobpassion_applicant_pages',
        { _locale: this.$careerpeakr.locale, _page: 'status' }
      );
    }
  },
  */
  computed: {
    ...mapState({
      userId(state) {
        return state.applicant.profile ? state.applicant.profile.id : '';
      },
      has_bp(state) {
        return (state.applicant.profile)
          ? state.applicant.profile.registeredApplicantProfile
          : false;
      }
    }),
    isMyAccountActive() {
      return this.$route.path.includes('settings');
    }
  },

  methods: {
    linkClicked(e) {
      this.$emit('linkClicked', e);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/colors';

.applicant-main-menu {
  margin: 0;
  margin-top: 3px;
  padding-left: 18px;

  .non-bp a {
    color: $color-dustygrey;
  }

  li {
    display: inline-block;
    position: relative;
    border-right: 1px solid $color-white;
    padding: 3px 20px 0 20px;
    font-size: 17px;
    line-height: 1;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }

    &.active a {
      color: $color_ci;

      &:after {
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 22px 16px 0 16px;
        border-color: $color-blacky transparent transparent transparent;
        position: absolute;
        top: 48px;
        left: 50%;
        transform: translate(-50%);
      }
    }

    a {
      position: relative;
      display: inline-block;
      color: $color-white;
      text-decoration: none;

      /deep/ {
        .no-content {
          display: inline-block;
        }

        .coming-soon {
          font-size: 15px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import '../../../scss/atoms/_colors';

.tooltip {
  display: block !important;
  z-index: 10000;
  font-size: 14px;
  line-height: 1.3;
  max-width: 300px;

  .tooltip-inner {
    background: $color-black;
    color: $color-white;
    border-radius: 16px;
    padding: 16px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: $color-black;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: $color-black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

</style>
