<template>
  <div class="login-button-wrapper">
    <div class="login-close-button" ref="close" @click="closeLoginPopup"></div>
    <CpButton id="login-button" :callback="openLoginPopup">Einloggen / Registrieren</CpButton>
    <LoginPopup :closeFunction="closeLoginPopup" :overlayCloseCallback="overlayCloseCallback" :mode="mode" />
  </div>
</template>

<script>
import LoginPopup from './LoginPopup';
import CpButton from '../atoms/CpButton';

export default {
  name: 'LoginButton',

  components: {
    LoginPopup,
    CpButton
  },

  props: {
    overlayCloseCallback: {
      type: Function,
      default: null
    },
    mode: {
      type: String,
      default: ''
    }
  },

  methods: {
    openLoginPopup() {
      this.$el.classList.add('opened');
    },
    closeLoginPopup() {
      this.$el.classList.remove('opened');
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/organisms/login_button';
</style>
