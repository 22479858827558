<template>
  <div class="work-environment-data">
    <div class="row">
      <div class="column" v-if="profile.locations.length">
        <div class="domestic-location">
          <CompanyDataBlock>
            <Translation slot="title" resource_key="company.profile.domestic-location" />
            <template slot="data">
              {{ profile.locations }}
            </template>
          </CompanyDataBlock>
        </div>
      </div>
      <div class="column" v-if="('internationalLocations' in profile) && profile.internationalLocations.length">
        <div class="international-location">
          <CompanyDataBlock>
            <Translation slot="title" resource_key="company.profile.international-location" />
            <template slot="data">
              {{ profile.internationalLocations }}
            </template>
          </CompanyDataBlock>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column" v-if="profile.femaleLeaders">
        <div class="female-leaders">
          <CompanyDataBlock>
            <Translation slot="title" resource_key="company.profile.female-leaders" />
            <template slot="data">
              {{ profile.femaleLeaders }}
            </template>
          </CompanyDataBlock>
        </div>
      </div>
      <div class="column" v-if="profile.employeesNeeded">
        <div class="employees-needed">
          <CompanyDataBlock>
            <Translation slot="title" resource_key="company.profile.employees-needed" />
            <template slot="data">
              {{ profile.employeesNeeded }}
            </template>
          </CompanyDataBlock>
        </div>
      </div>
    </div>
    <div class="row overout" v-if="profile.companyVideo">
      <div class="column over-full-width">
        <youtube player-width="100%" :player-height="(bpMaxMedium)? 202 : 510" :video-id="videoId"></youtube>
      </div>
    </div>
    <div class="row">
      <div class="column" v-if="companyProfile.awards.length > 0">
        <CompanyDataBlock>
          <Translation slot="title" resource_key="company.profile.award" />
          <template slot="data">
            <ul class="awards">
              <li v-for="(award, index) in companyProfile.awards" :key="index">
                <span>{{award.shownName}}</span><br/>
                <img :src="`/${award.imagePath}`" />
              </li>
            </ul>
          </template>
        </CompanyDataBlock>
      </div>
      <div class="column">
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueYouTubeEmbed from 'vue-youtube-embed';
import { createNamespacedHelpers } from 'vuex';
import Translation from '../atoms/Translation';
import CompanyDataBlock from '../molecules/CompanyDataBlock';
import breakPoints from '../../lib/mixins/breakPoints';

Vue.use(VueYouTubeEmbed);

const { mapState } = createNamespacedHelpers('company');

// Mock server data
if (typeof window.Jobpassion === 'undefined') {
  window.Jobpassion = {};
}

export default {
  name: 'WorkEnvironmentData',

  components: {
    CompanyDataBlock,
    Translation
  },

  mixins: [breakPoints],

  props: {
    profile: {
      type: Object,
      requreid: true
    }
  },

  computed: {
    videoId() {
      /* eslint-disable no-useless-escape */
      return this.profile.companyVideo.replace(
        /https?:\/\/(youtu\.be\/|[www.]*youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))([^\?&"'>]+)/,
        '$5'
      );
      /* eslint-enable no-useless-escape */
    },
    ...mapState({ companyProfile: 'profile' })
  },
  methods: {
    formatComanyWebsite() {
      const displayText = this.profile.companyWebsite
        ? this.profile.companyWebsite.replace(/https?:\/\//, '').replace(/\/$/, '')
        : '';
      return `<a href="${this.profile.companyWebsite}">${displayText}</a>`;
    },
    displayAwards() {
      return this.companyProfile.awards;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/_breakpoints.mixin';

.work-environment-data {
  display: flex;
  flex-direction: column;
  max-width: 1014px;
  margin-top: 50px;
  padding-bottom: 40px;
  margin-left: auto;
  margin-right: auto;

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 60px;
    padding-right: 60px;

    &.overout {
      padding: 0;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    &:first-of-type .column {
      border-top: 1px solid #c4c4c4;
    }

    &:last-of-type .column:nth-last-of-type(2) {
      border-top: 1px solid #c4c4c4;
    }
  }

  .column {
    width: 50%;
    max-width: 434px;

    &:first-of-type {
      margin-right: 25px;
    }

    &.over-full-width {
      width: calc(100% + 120px);
      max-width: none;
      margin: 0;
      line-height: 0;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    }

    > div,
    > div > div {
      position: relative;
      height: 100%;
    }
  }

  .awards {
    li {
      display: inline-block;
      margin-right: 15px;
      width: 100px;
      img {
        margin-top: 6px;
        max-width: 100px;
      }
    }
  }
}

@include bp-max-medium() {
  .work-environment-data {
    .row {
      flex-direction: column;
      padding: 0;

      .column {
        width: 100%;
        max-width: none;
      }
    }
  }
}
</style>
