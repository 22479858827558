<template>
  <div :class="{'appointment-list' : true, 'has-error': hasError}">
    <h1 class="appointment-list-title">
      <Translation resource_key="joboffer.appointment.select" />
    </h1>
    <ul class="accordion days" v-if="hasAppointments()">
      <li v-for="(appointments, day) in existingAppointments" :key="day">
        <div :class="{'tab' : true, 'selected' : selected(day) }" @click="toggle(day)">
          {{ day }}
        </div>
        <transition name="slide" tag="div">
          <ul class="hours" :class="{'selected' : selected(day)}">
            <li class="availableAppointmentItem" v-for="(appoint, idx) in appointments" :key="idx">
              <input type="radio" name="selectedAppointment" :id="appoint.id" :value="appoint.id" />
              <label :for="appoint.id">
                {{ formatDateHour(appoint) }}
              </label>
            </li>
          </ul>
        </transition>
      </li>
    </ul>
    <div v-else>
      <p>
        Tut uns leid. Aber derzeit sind leider keine freien Termine verfügbar.
        Bitte kontaktiere uns unter <a href="mailto:kontakt@careerpeakr.de">kontakt@careerpeakr.de</a>.
      </p>
    </div>
  </div>
</template>
<script>
import Translation from '../atoms/Translation';

export default {
  name: 'AppointmentList',

  components: {
    Translation
  },

  props: {
    availableAppointments: {
      type: Array,
      require: true
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selection: 0
    }
  },

  created() {
    [this.selection] = Object.keys(this.existingAppointments);
  },

  computed: {
    existingAppointments() {
      const result = {};
      const appointments = [...this.availableAppointments].sort((appointmentA, appointmentB) => new Date(appointmentA.start) - new Date(appointmentB.start));

      appointments.map((appointment) => {
        const dateStr = this.formatDate(appointment);
        if (!(dateStr in result)) {
          result[dateStr] = [];
        }
        result[dateStr].push(appointment);
        return null;
      });

      if (Object.keys(result).length > 0) {
        [this.selection] = Object.keys(result);
      }
      return result;
    }
  },

  methods: {
    hasAppointments() {
      return Object.entries(this.existingAppointments).length > 0;
    },

    formatDate(appointment) {
      const start = new Date(appointment.start);
      const day = start
        .getDate()
        .toString()
        .padStart(2, '0');
      const month = (start.getMonth() + 1).toString().padStart(2, '0');
      const year = start.getFullYear();
      return `${day}.${month}.${year}`;
    },

    formatDateHour(appointment) {
      const start = new Date(appointment.start);
      const day = start
        .getDate()
        .toString()
        .padStart(2, '0');
      const month = (start.getMonth() + 1).toString().padStart(2, '0');
      const year = start.getFullYear();
      const hours = start.getHours();
      const minutes = `0${start.getMinutes()}`.slice(-2);
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    },

    selected(day) {
      return this.selection === day;
    },

    toggle(day) {
      this.selection = day;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';

.appointment-list {
  &.has-error .appointment-list-title {
    color: $color-red;
  }

  .tab {
    width: 100%;
    font-weight: 800;
    padding: 15px 0;
    border-top: 1px solid $color-silvergrey;
    &:after {
      content: '\e809';
      right: 0;
      position: absolute;
      font-weight: 400;
      font-family: fontello; // stylelint-disable-line
    }

    &.selected:after {
      content: '\e80e';
    }
  }

  .days {
    position: relative;

    > li:last-of-type .hours.selected {
      margin-bottom: 0;
    }
  }

  .hours {
    display: flex;
    flex-direction: column;
    // justify-content: flex-start;
    // align-items: flex-start;
    //flex-wrap: wrap;
    // flex: 1 1 30px;
    // height: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;

    &.selected {
      height: 120px;
      max-height: 120px;
      transition: max-height 0.3s;
      flex-wrap: wrap;
      margin-bottom: 15px;
    }

    .availableAppointmentItem {
      flex: 1 1 30px;
      max-height: 30px;
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  max-height: 120px;
  transition: max-height 0.5s;
  flex-wrap: nowrap;
}

.slide-enter,
.slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  max-height: 0;
  transition: max-height 0.5s;
}

</style>
