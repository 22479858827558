<template>
  <section class="skills">
    <div class="title-wrapper">
      <h2><Translation resource_key="applicant.profile.skills.title" /></h2>
    </div>
    <div class="wrapper">
      <EditableArea :values="values"
                    :form="form"
                    :callbackSave="onSave"
                    :callbackCancel="onCancel"
                    @editing="editStatusChanged"
      >
        <SkillsDisplay slot="display" :values="values" :fresh="fresh" />
        <SkillsEdit slot="edit" :values="bufferedValue" :form="form" :callbackChange="onChange" />
      </EditableArea>
    </div>
  </section>
</template>

<script>
import EditableArea from '../../molecules/EditableArea';
import SkillsDisplay from './SkillsDisplay';
import SkillsEdit from './SkillsEdit';
import Translation from '../../atoms/Translation';

export default {
  name: 'Skills',
  components: {
    EditableArea,
    SkillsDisplay,
    SkillsEdit,
    Translation
  },

  data() {
    return {
      bufferedValue: {},
      editing: false
    };
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    fresh: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      required: true
    },
    callbackOnSave: {
      type: Function,
      required: true
    }
  },

  created() {
    this.setOriginalValue();
  },

  methods: {
    setOriginalValue() {
      // make object from array
      const abroadExperiences = {};
      this.values.abroadExperiences.map((obj, idx) => {
        abroadExperiences[`${idx}`] = obj;
        return obj;
      });

      const languageSkills = {};
      this.values.languageSkills.map((obj, idx) => {
        languageSkills[`${idx}`] = obj;
        return obj;
      });

      const skills = {};
      this.values.skills.map((obj, idx) => {
        skills[`${idx}`] = obj.skillName;
        return obj;
      });

      this.bufferedValue = {
        abroadExperiences,
        languageSkills,
        skills
      };
    },

    onSave() {
      const self = this;
      return new Promise((resolve, _reject) => {
        /* TODO:
          in this method will be replaced with vuex action.
          this implementation is only for storybook
          */

        const filteredValues = self.filterfilledEntries();
        /* global _ */
        const result = _.cloneDeep(self.values);
        result.abroadExperiences = Object.values(filteredValues.abroadExperiences);
        result.languageSkills = Object.values(filteredValues.languageSkills);
        result.skills = Object.values(filteredValues.skills);
        self.callbackOnSave(result).then(() => {
          self.setOriginalValue();
          resolve()
        });
      });
    },

    onCancel() {
      this.setOriginalValue();
    },

    onChange(key, values) {
      this.bufferedValue[key] = values;

      const result = { ...this.values };
      result[key] = values;
      this.$emit('change', result);
    },

    editStatusChanged(editing) {
      this.editing = editing;
    },

    filterfilledEntries() {
      const self = this;
      const languageSkills = {}
      Object.values(this.bufferedValue.languageSkills)
        .sort((a, b) => {
          /* eslint-disable max-len */
          const levelA = self.form.languageSkills.languageLevel.choices.find((c) => c.original === a.languageLevel);
          const levelB = self.form.languageSkills.languageLevel.choices.find((c) => c.original === b.languageLevel);
          /* eslint-enable max-len */
          return levelB.value - levelA.value;
        })
        .map((item, idx) => {
          languageSkills[idx] = item;
          return item;
        });

      const skills = {}
      Object.values(this.bufferedValue.skills)
        .filter((v) => v.length > 0)
        .every((item, idx) => {
          skills[idx] = { skillName: item };
          return true;
        });

      return {
        abroadExperiences: this.bufferedValue.abroadExperiences,
        languageSkills,
        skills
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_breakpoints.mixin';

.skills {
  min-height: 300px;
  position: relative;
  padding: 60px 0;

  .title-wrapper,
  .wrapper {
    max-width: 840px;
    width: 100%;
    margin: 0 auto;

    &.edit {
      max-width: 1200px;
    }

    .editable-area {
      position: static;
    }
  }
}

@include bp-max-medium() {
  .skills {
    padding: 50px 30px;

    .title-wrapper {

      h2 {
        font-size: 24px !important;
        line-height: 34px !important;
        margin-bottom: 30px !important;
      }
    }

    .wrapper {

      ul.abroad-experiences {
        width: 100%;
        padding-left: 10px;
        line-height: 25px;

        h4.title {
          margin-left: -7px;
        }

        h4 {
          line-height: 25px;
        }

        ul {
          li {
            .time {
              padding-left: 20px;
              display: block;
              border-left: 1px solid $color-disabled-grey;
            }

            .point {
              left: 0;
            }
          }
        }
      }
    }
  }
}
</style>
