<template>
  <div class="company-at-a-glance">
    <WorkEnvironmentStickers :profile="profile" />
    <WorkEnvironmentData :profile="profile" />
  </div>
</template>

<script>
import WorkEnvironmentStickers from '../organisms/WorkEnvironmentStickers';
import WorkEnvironmentData from '../organisms/WorkEnvironmentData';
// Mock server data
if (typeof window.Jobpassion === 'undefined') {
  window.Jobpassion = {};
}

export default {
  name: 'CompanyAtAGlance',

  components: {
    WorkEnvironmentStickers,
    WorkEnvironmentData
  },

  props: {
    profile: {
      type: Object,
      requreid: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/_breakpoints.mixin';

.company-at-a-glance {
  background-color: $color-lightgrey;
  padding-left: var(--padding-edge);
  padding-right: var(--padding-edge);
}

@include bp-max-medium() {
  .company-at-a-glance {
    margin-top: 0;
    padding: 0 30px;
  }
}
</style>
