import axios from 'axios';

const URL = '/api/v2/applicant/joblist/detail';

export default {
  getJobDetail(joblistId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL}`, {
          joblistId
        })
        .then((response) => {
          // handle success
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  }
};
