<template>
  <div class="joboffer-status-bar-wrapper">
    <div class="joboffer-status-bar-caption">Status des Jobangebots</div>
    <div v-if="-1 === status" class="joboffer-status-bar">
      <div class="disabled-statusbar"><span>Von mir abgelehnt</span></div>
    </div>
    <div v-if="0 <= status" class="joboffer-status-bar">
      <router-link to="dosier" class="joboffer-status-start-button blue-button" v-if="0 === status"><span>Zum Bewerberprofil</span></router-link>
      <a href="#" class="joboffer-status-start-button" v-if="1 === status" @click="publishData"><span>Meine Daten freigeben</span></a>
      <ArrowLabel v-if="1 < status" v-bind="label1Style">Datenfreigabe erteilt</ArrowLabel>
      <ArrowLabel v-bind="label2Style">Beim Arbeitgeber vorgestellt</ArrowLabel>
      <ArrowLabel v-bind="label3Style">Zum Interview eingeladen</ArrowLabel>
      <ArrowLabel v-bind="label4Style">Arbeitsvertrag erhalten</ArrowLabel>
    </div>
  </div>
</template>

<script>
import ArrowLabel from '../atoms/ArrowLabel';

const backgroundDefault = '#efefef';
const bordercolorDefault = '#C4C4C4';
const numcolorDefault = '#efefef';

export default {
  name: 'JobOfferStatusBar',

  components: {
    ArrowLabel
  },

  props: {
    jobOfferId: Number,
    userId: Number,
    status: {
      type: Number,
      default: 0
    }
  },

  computed: {
    label1Style() {
      return {
        position: 1,
        background: 'linear-gradient(to right, #4dd7fa 0%,#37c3f2 100%)',
        numcolor: '#4dd7fa',
        bordercolor: 'white'
      }
    },
    label2Style() {
      return {
        position: 2,
        background: this.status > 2 ? 'linear-gradient(to right, #36c2f2 0%,#18a0e4 100%)' : backgroundDefault,
        numcolor: this.status > 2 ? '#36c2f2' : numcolorDefault,
        bordercolor: this.status > 2 ? 'white' : bordercolorDefault
      }
    },
    label3Style() {
      return {
        position: 3,
        background: this.status > 3 ? 'linear-gradient(to right, #179ee3 0%,#0080d6 100%)' : backgroundDefault,
        numcolor: this.status > 3 ? '#179ee3' : numcolorDefault,
        bordercolor: this.status > 3 ? 'white' : bordercolorDefault
      }
    },
    label4Style() {
      return {
        position: 4,
        background: this.status > 4 ? 'linear-gradient(to right, #86c81c 0%,#289e1a 100%)' : backgroundDefault,
        numcolor: this.status > 4 ? '#86c81c' : numcolorDefault,
        bordercolor: this.status > 4 ? 'white' : bordercolorDefault,
        class: 'square'
      }
    }
  },

  methods: {
    publishData(e) {
      e.preventDefault();
      this.$emit('publish-data');
    }
  }
};
</script>

<style lang="scss">
  @import '../../scss/atoms/colors';

  .joboffer-status-bar-caption {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 3px;
  }

  .joboffer-status-bar {
    position: relative;
    height: 60px;
    overflow: hidden;

    .arrow-label {
      position: absolute;
      width: calc(25% + 15px);
      top: 0;

      &:first-child {
        left: 0;
        z-index: 4;
      }

      &:nth-child(2) {
        left: calc(25% - 5px);
        z-index: 3;
      }

      &:nth-child(3) {
        left: calc(50% - 10px);
        z-index: 2;
      }

      &:last-child {
        left: calc(75% - 15px);
        z-index: 1;
      }
    }
  }

  .joboffer-status-start-button {
    display: block;
    height: 60px;
    background-color: $color-blacky;
    color: $color-white;
    text-align: center;
    font-size: 16px;
    position: absolute;
    width: calc(25% + 15px);
    top: 0;
    left: 0;
    z-index: 4;
    text-decoration: none;
    font-weight: 700;
    white-space: nowrap;
    line-height: 1.2;

    &.blue-button {
      background-color: $color-blue;
    }
  }

  .disabled-statusbar {
    height: 60px;
    background-color: $color-lightgrey;
    color: $color-silvergrey;
    text-align: center;
  }

  .joboffer-status-start-button,
  .disabled-statusbar {
    span {
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
      max-width: 80%;
    }

    &:before,
    &:after {
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 100%;
      content: '';
    }
  }
</style>
