<template>
  <div class="main-navigation">
    <Burger />
    <NavigationOverlay :mode="mode" />
  </div>
</template>

<script>
import Burger from '../atoms/Burger';
import NavigationOverlay from '../organisms/NavigationOverlay';

export default {
  name: 'MainNavigation',
  components: {
    Burger,
    NavigationOverlay
  },
  props: {
    mode: {
      type: String,
      default: '',
      validator(val) {
        console.log('validator: ', val);
        return val === ''
          || val === 'applicant'
          || val === 'employer'
          || val === 'common'
      }
    }
  }
};
</script>
