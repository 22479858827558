<template>
  <div class="reset-password">
    <form
      :action="`/${$careerpeakr.locale}/user/resetting/send-email`"
      method="POST"
      class="fos_user_resetting_request"
    >
      <div>
        <h2>Ein neues Passwort beantragen!</h2>
        <p><label for="username">Bitte geben Sie Ihren Benutzernamen oder Ihre E-Mail-Adresse ein.</label></p>

        <div class="form-row clearfix">
          <div class="form-col-2">

            <input
              type="email"
              class="cp-default-input narrow"
              id="username"
              name="username"
              :value="username"
              placeholder="Benutzername (E-Mail) eingeben"
              required="required"
            />
          </div>
        </div>
      </div>
      <div>
        <CpButton color="blue" size="large" type="submit" weight="bold">
          Passwort zurücksetzen
        </CpButton>
      </div>
    </form>
  </div>
</template>

<script>
import CpButton from '../../atoms/CpButton/CpButton';

export default {
  name: 'ResetPassword',

  components: {
    CpButton
  },

  data() {
    return {
      password: {
        value: '',
        hasError: false,
        placeholder: 'Passwort eingeben'
      }
    };
  },

  computed: {
    passwordReminderURL() {
      return 'paths' in window.Jobpassion ? window.Jobpassion.paths.passwordReminder : '';
    },
    username() {
      return window.Jobpassion.lastUsername;
    }
  },

  methods: {
    onSubmit() {
      const request = document.createElement('input');
      request.type = 'hidden';
      request.name = '_csrf_token';
      request.value = window.security.csrf_token;
      this.$refs.form.appendChild(request);
      this.$refs.form.submit();
    }
  }
};
</script>

<style lang="scss" scoped>
.form-row.clearfix {
  display: flex;
  justify-content: center;
  margin-top: 26px;
  margin-bottom: 54px;
}

</style>
