<template>
  <div class="job-suggestion-filters">
    <div class="textbox">
      <i class="fontello icon-location"></i>
      <AutoCompleteTextField
        tabindex="1"
        ref="locationPredictions"
        :value="location"
        placeholder="Ort oder PLZ eingeben"
        :values="locationCandidates"
        @inputed="locationCandidatesInputed"
        @change="locationInputed"
        @blur="locationInputed"
      >
      </AutoCompleteTextField>
    </div>
    <CpSelectBox :tabindex="2"
                 :callback="distanceSelected"
                 :values="distanceValues"
                 :value="distance"
                 class="distance aaaa"/>
    <div class="working-time">
      <i class="fontello icon-clock-dark"></i>
      <CpSelectBox :tabindex="3"
                   class="working-time-type"
                   :callback="workingTimeSelected"
                   :values="workingTimeValues"
                   :value="workTimeType"/>
    </div>
    <div class="textbox">
      <i class="fontello icon-search"></i>
      <input tabindex="4"
             ref="searchText"
             type="text"
             name="searchText"
             :value="searchText"
             @blur="searchTextInputed"
             placeholder="Suche verfeinern"/>
    </div>
    <CpQuestionTooltip
      tooltip="Hier kannst Du Begriffe nennen, die Deiner Jobvorstellung besonders gut entsprechen. Wir suchen dann vor allem nach Jobs, die diese Begriffe enthalten."></CpQuestionTooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import translate from '../molecules/Editable';
import AutoCompleteTextField from '../molecules/AutoCompleteTextField';
import CpSelectBox from '../molecules/CpSelectBox';
import CpQuestionTooltip from './CpQuestionTooltip';
import types, { moduleName } from '../../store/modules/applicant/types';

const mutationName = `${moduleName}/${types.MUTATIONS.UPDATE_JOBSUGGESTION_FORM}`;
const actionName = `${moduleName}/${types.ACTIONS.GET_LOCATION_PREDICTIONS}`;

const workingTimeValues = [
  'Vollzeit', // translate('working-time-type.fulltime'),
  'Teilzeit', // translate('working-time-type.parttime')
  'Beides' // translate('working-time-type.both')
];

const workingTimeValueTable = {
  Vollzeit: 'FullTime', // translate('working-time-type.fulltime'),
  Teilzeit: 'PartTime', // translate('working-time-type.parttime')
  Beides: 'NotFiltered' // translate('working-time-type.both')
};

const distanceValues = ['max. Entfernung', '10 km', '25 km', '50 km', '100 km', '200 km'];

const distanceValueTable = {
  'max. Entfernung': undefined,
  '10 km': 10,
  '25 km': 25,
  '50 km': 50,
  '100 km': 100,
  '200 km': 200
};

export default {
  name: 'JobSuggestionFilters',
  components: {
    AutoCompleteTextField,
    CpSelectBox,
    CpQuestionTooltip
  },
  data() {
    return {
      distanceValues,
      workingTimeValues
    };
  },
  computed: {
    ...mapState({
      location(state) {
        return state ? state.applicant.jobSuggestionForm.location.label : '';
      },
      locationCandidates(state) {
        return state ? state.applicant.locationPredictions : [];
      },
      distance(state) {
        return state ? state.applicant.jobSuggestionForm.distance : '';
      },
      workTimeType(state) {
        return state ? state.applicant.jobSuggestionForm.workTimeType : '';
      },
      searchText(state) {
        return state ? state.applicant.jobSuggestionForm.searchText : '';
      }
    })
  },
  methods: {
    distanceSelected(val) {
      this.$store.commit(mutationName, { distance: distanceValueTable[val] });
    },
    workingTimeSelected(val) {
      this.$store.commit(mutationName, { workTimeType: workingTimeValueTable[val] });
    },
    locationInputed(val) {
      const location = this.$store.state.applicant.locationPredictions.find((l) => l.label === val);
      if (location) {
        this.$store.commit(mutationName, { location });
        return;
      }
      console.error('location not found with this label:', val);
    },
    locationCandidatesInputed(val) {
      this.$store.dispatch(actionName, { input: val })
    },
    locationConfirmed() {
      this.$store.commit(mutationName, { location: this.$refs.location.value });
    },
    searchTextInputed() {
      this.$store.commit(mutationName, { searchText: this.$refs.searchText.value });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';

.job-suggestion-filters {
  display: flex;
  color: $color-disabled-grey;
  background-color: $color-ultralightgrey;
  font-size: 16px;
  position: absolute;
  z-index: 100;
  width: 100%;

  .auto-complete-text-field {
    display: inline-block;
    & /deep/ input[type='text'] {
      height: 36px;
      width: auto !important;
    }
  }

  .auto-complete-text-field /deep/ input[type='text'],
  input[type='text'] {
    background-color: $color-ultralightgrey;
    border: none;
    width: 80%;

    &::placeholder {
      font-size: 16px;
    }
  }

  .working-time {
    display: flex;
    align-items: center;

    .icon-clock-dark {
      margin: 10px;
    }
  }

  .fontello :before {
    margin: 0 1em !important;
  }

  .textbox {
    flex-grow: 2;
    padding: 20px;
    border-right: 2px solid $color-white;

    &:last-of-type {
      border-right: none;
    }

    i {
      margin-right: 10px;
    }
  }

  .working-time-type,
  .distance {
    flex-grow: 1;
    border-right: 2px solid $color-white;
    position: relative;
    width: 262px;
  }

  /deep/ {
    .cp-select-wrapper select {
      height: 73px;
    }
  }
}

@include bp-max-medium() {
  .job-suggestion-filters {
    display: none;
  }
}
</style>

<style lang="css">
  .cp-info {
    position: absolute;
    right: 40px;
    top: 20px;
  }
</style>
