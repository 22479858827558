<template>
  <CpButton class="save-button"  :callback="callback" color="dark">
    <span class="fontello icon-edit"> </span>
    Speichern
  </CpButton>
</template>

<script>
import CpButton from './CpButton';

export default {
  name: 'SaveButton',
  components: {
    CpButton
  },
  props: {
    callback: {
      type: Function
    },
    color: {
      type: String,
      default: 'transpalent'
    },
    size: {
      type: String,
      default: 'medium'
    },
    weight: {
      type: String,
      default: 'regular'
    }
  },

  computed: {
    styleClasses() {
      const classes = [];

      if (typeof this.color === 'string') {
        classes.push(this.color);
      }

      if (typeof this.size === 'string') {
        classes.push(this.size);
      }

      if (typeof this.weight === 'string') {
        classes.push(this.weight);
      }

      return classes.join(' ');
    }
  },

  methods: {
    onClick(e) {
      if (typeof this.callback === 'function') {
        const result = this.callback(e);
        if (undefined !== result) {
          return result;
        }
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
@import './buttons';
</style>
