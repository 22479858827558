<template>
  <div :class="{ 'experience-detail' : true, 'fresh' : isFresh() || isBlank() }">
    <div class="experience-item">
      <div class="label column-2"></div>
      <div class="detail experience-name">
        {{ jobExperienceName }}
      </div>
    </div>
    <div class="experience-item">
      <div class="label column-2">Arbeitgeber</div>
      <div class="detail employer-name">
        {{ employerName }}
      </div>
    </div>
    <div class="experience-item">
      <div class="label column-2">Branche</div>
      <div class="detail branch">
        {{ jobExperienceDynaJobsSector }}
      </div>
    </div>
    <div class="experience-item last">
      <div class="label column-2" v-if="value.description">Aufgabe</div>
      <div class="detail etc free-text" v-html="value.description">
      </div>
    </div>
    <div class="divider"></div>
  </div>
</template>

<script>
export default {
  name: 'ExperienceDetail',

  data() {
    return {
      form: {},
      bufferedValue: null
    };
  },

  props: {
    value: {
      type: Object,
      required: true
    },
    current: {
      type: Boolean,
      default: false
    },
    fresh: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    jobExperienceName() {
      return (this.isFresh() || this.isBlank()) ? 'Angaben Ergänzen' : this.value.jobExperienceName;
    },
    employerName() {
      return (this.isFresh() || this.isBlank()) ? 'Angaben Ergänzen' : this.value.employerName;
    },
    jobExperienceDynaJobsSector() {
      return (this.isFresh() || this.isBlank()) ? 'Angaben Ergänzen' : this.value.jobExperienceDynaJobsSector;
    }
  },
  methods: {
    isFresh() {
      return this.fresh
        && !('jobExperienceName' in this.value)
        && !('employerName' in this.value)
        && this.value.jobExperienceDynaJobsSector === 'je_jobExperienceDynaJobsSector.'
    },
    isBlank() {
      return Object.keys(this.value).length === 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_typographie';
@import '../../../scss/atoms/_breakpoints.mixin';

.experience-detail {
  padding: 0 40px 20px;
  position: relative;
  margin-top: -3em;

  &.fresh {
    .experience-item .detail {
      color: $color-silvergrey;
      font-style: italic;
    }
  }

  .divider {
    position: absolute;
    top: 0;
    left: 175px;
    width: 1px;
    height: 100%;
    background-color: $color-disabled-grey;
  }

  .experience-item {
    color: $color-darkgrey;
    display: flex;
    align-items: baseline;

    &.last {
      margin-top: 1em;
    }

    .label {
      display: inline-block;
      width: 13%;
      vertical-align: top;
      text-align: right;
      min-width: 111px;
    }

    .label:nth-child(3) {
      padding-top: 10px;
    }

    .detail {
      display: inline-block;
      max-width: calc(100% - 160px);
    }

    .experience-name,
    .employer-name {
      padding-left: 40px;
      margin-left: 10px;
      color: $color-blue;
      font-weight: $font-bold;
    }

    .experience-name {
      font-size: 21px;
      color: $color-darkgrey;
    }

    .branch {
      padding-left: 40px;
      margin-left: 10px;
    }

    .etc {
      padding-left: 40px;
      margin-left: 10px;

      /deep/ ul li {
        line-height: 1.4em;
      }
    }

    @include bp-max-medium {
      display: flex;
      flex-direction: column;
    }
  }
}

@include bp-max-medium() {
  .experience-detail {
    padding: 0 0 20px 0;

    .experience-item {
      .label {
        display: block !important;
        width: 100%;
        text-align: left;
        padding-left: 40px;
        font-size: 16px;
      }

      .detail {
        margin-left: 0;
        max-width: 500px;
      }
    }

    .divider {
      left: 15px !important;
      background-color: $color-disabled-grey;
    }

    .experience-name,
    .employer-name {
      line-height: 28px;
    }
  }
}
</style>
