<template>
  <div class="competence-line-chart" ref="chart-wrapper">
    <div class="legend-open-toggle" @click="toggleLegend">
      <p>
        {{ legendToggleText }}
        <i :class="{'fontello': true, 'icon-left-open-big' : !legendOpened, 'cp-modal-close': legendOpened }"></i>
      </p>
    </div>
    <div class="cp-line-chart-inner">
      <chart-line :chartData="data" :options="options" ref="chart" v-on:chart:render="onRender">
      </chart-line>
      <div class="cp-line-chart-labels clearfix" :style="labelWrapperStyle">
        <span v-for="(label, i) in labels"
              class="line-chart-label"
              v-bind:style="labelStyle"
              :key='i'>
          {{label}}
        </span>
      </div>
    </div>
    <div :class="{'cp-line-chart-info' : true, 'mobile-legend-opened' : legendOpened }">
      <div class="cp-line-chart-info-inner">
        <h3 class="cp-line-chart-caption">
          <slot name="caption"></slot>
        </h3>
        <p class="cp-line-chart-subline">
          <slot name="subline"></slot>
        </p>
        <div v-bar>
          <ul class="cp-line-chart-legends">
            <li v-for="legend in legends"
                :key="legend.index"
                class="cp-line-chart-legend"
                v-bind:class="{datasetHidden: datasetHidden[legend.index]}"
                v-on:click="updateDataset(legend.index)"
            >
              <span class="cp-line-chart-legend-marker"
                    :style="{backgroundColor: legend.backgroundColor}"
              >
              </span>
              {{legend.label}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * The propery "labels" is a simple array of strings.
 * The datasets property is sets of data. The format is same to the default chartjs data format (see: http://www.chartjs.org/docs/latest/charts/line.html#dataset-properties).
 */

import Vue from 'vue';
import Vuebar from 'vuebar';
import ChartLine from '../molecules/ChartLine';

Vue.use(Vuebar);

export default {
  name: 'ChartLineCompetenceRequest',

  components: {
    ChartLine
  },

  props: {
    labels: {
      type: Array,
      default: () => ['Label1', 'Label2', 'Label3', 'Label4']
    },

    datasets: {
      type: Array,
      default: () => [
        {
          label: 'Default Legend 1',
          data: [10, 20, 30, 20],
          pointBackgroundColor: '#0000ff'
        },
        {
          label: 'Default Legend 2',
          data: [80, 10, 65, 60],
          pointBackgroundColor: '#ff0000',
          hidden: true
        }
      ]
    },

    maxVisibleLines: {
      default: 5
    }
  },

  data() {
    return {
      datasetHidden: [],

      options: {
        aspectRatio: 1,

        legend: {
          display: false
        },

        layout: {
          padding: {
            right: 5
          }
        },

        elements: {
          line: {
            borderWidth: 1,
            borderColor: '#C4C4C4',
            fill: false
          },
          point: {
            radius: 4,
            borderWidth: 0
          }
        },

        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 900,
                fontSize: 14,
                fontColor: '#626262'
              },
              gridLines: {
                drawBorder: false
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                display: false
              },
              gridLines: {
                display: false
              }
            }
          ]
        },

        tooltips: {
          enabled: false
        },

        hover: {
          mode: false
        }
      },

      legends: [],

      isMounted: false,

      legendOpened: false
    };
  },

  computed: {
    legendToggleText() {
      return this.legendOpened ? 'Legende schließen' : 'Legende öffnen';
    },

    labelWrapperStyle() {
      if (!this.isMounted) {
        return {};
      }

      const { _chart: chart } = this.$refs.chart.$data;
      const chartLeft = chart.chartArea.left;
      const xAxisGap = chart.scales['x-axis-0'].getPixelForValue(null, 1) - chartLeft;
      const labelWrapperWidth = xAxisGap * this.labels.length;

      return {
        left: `${chartLeft}px`,
        width: `${labelWrapperWidth}px`
      };
    },

    labelStyle() {
      if (!this.isMounted) {
        return {};
      }

      const { _chart: chart } = this.$refs.chart.$data;
      const xAxisGap = chart.scales['x-axis-0'].getPixelForValue(null, 1) - chart.chartArea.left;

      return {
        width: `${xAxisGap}px`
      };
    },

    data() {
      let max = 10;
      let visibleLines = 0;
      const self = this;

      this.datasets.forEach((data) => {
        if (!data.hidden) {
          visibleLines += 1;
        }

        // Respect max visible lines
        if (visibleLines > self.maxVisibleLines) {
          data.hidden = true; // eslint-disable-line no-param-reassign
        }

        max = Math.max(
          max,
          data.data.reduce((a, b) => Math.max(a, b))
        );
      });

      const maxLen = 10 ** (String(max).length - 1);
      max = maxLen === 0 ? max + 1 : Math.ceil(max / maxLen) * maxLen;

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.options.scales.yAxes[0].ticks.max = max;

      return {
        labels: this.labels,
        datasets: this.datasets
      };
    }
  },

  methods: {
    onRender() {
      /**
       * Set legends
       */
      const { _chart: chart } = this.$refs.chart.$data;
      this.legends = [];
      for (let i = 0; i < chart.data.datasets.length; i += 1) {
        this.legends.push({
          index: chart.legend.legendItems[i].datasetIndex,
          label: chart.data.datasets[i].label,
          backgroundColor: chart.data.datasets[i].pointBackgroundColor
        });
      }

      this.updateDatasetVisibility();
    },

    updateDataset(i) {
      let visibleLines = 0;
      this.data.datasets.forEach((data) => {
        if (!data.hidden) {
          visibleLines += 1;
        }
      });

      const lineHidden = !this.data.datasets[i].hidden;

      // Respect max visible lines
      if (!lineHidden && this.maxVisibleLines <= visibleLines) {
        return;
      }

      this.data.datasets[i].hidden = lineHidden;

      const { _chart: chart } = this.$refs.chart.$data;
      chart.update();

      this.updateDatasetVisibility();
    },

    updateDatasetVisibility() {
      const { _chart: chart } = this.$refs.chart.$data;

      const activeDataList = {};

      for (let i = 0, len = chart.data.datasets.length; i < len; i += 1) {
        activeDataList[i] = !chart.isDatasetVisible(i);
      }

      this.datasetHidden = activeDataList;
    },

    toggleLegend() {
      this.legendOpened = !this.legendOpened;
    }
  },

  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
@import '../../scss/organisms/chart_line_competence_request';
</style>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';
@import '../../scss/atoms/_breakpoints.mixin';
@import '../../scss/atoms/_typographie';

.legend-open-toggle {
  display: none;
}

@include bp-max-medium() {
  .cp-line-chart-inner {
    overflow: hidden;
  }

  .legend-open-toggle {
    display: block;
    text-align: right;
    position: relative;

    &:before {
      content: '';
      display: block;
      padding-top: 40px;
    }

    p {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      z-index: 20;
      font-size: $fs-medium;
      font-weight: $font-black;

      i.fontello {
        display: inline-block;
        position: relative;
        top: 0;
        left: auto;
        right: 20px;
        text-align: right;
        margin-left: 20px;
      }
    }
  }

  .cp-line-chart-info {
    position: absolute;
    height: auto;
    top: -10px;
    overflow: hidden;
    max-width: 0;
    padding: 0;
    z-index: 10;
    box-shadow: none;
    background-color: transparent;

    .cp-line-chart-info-inner {
      transform: translateX(100%);
      transition: transform 0.1s;
      padding-top: 55px;
      box-shadow: 0 0 20px rgba(0,0,0,0.2);
    }

    &.mobile-legend-opened {
      max-width: 250px;
      overflow: visible;

      .cp-line-chart-info-inner {
        background-color: $color-white;
        padding-top: 55px;
        padding-left: 22px;
        padding-right: 22px;
        transform: translateX(0);
        transition: transform 0.1s;
      }
    }
  }
}
</style>
