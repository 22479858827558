<template>
  <div class="cp-select-box">
    <label>
      <span class="slot">
        <slot></slot>
      </span>
    </label>
    <div class="cp-select-wrapper">
      <select
        :required="required"
        v-model="selected"
        @change="onChangeHandler"
        :class="{placeholder: placeholder && '' === selected}"
      >
        <option v-if="placeholder" value="" disabled>{{placeholder}}</option>
        <option
          v-for="(option, idx) in options"
          v-bind:value="option.value"
          :key="idx"
        >
          {{option.label}}
        </option>
      </select>
      <label class="fontello icon-down-open-big"></label>
    </div>
  </div>
</template>

<script>
import { validateCpSelectBoxValues } from '../../lib/property-validators/CpSelectBox_values';

export default {
  name: 'CpSelectBox',

  data() {
    return {
      selected: '',
      options: []
    };
  },

  props: {
    values: {
      type: Array,
      required: true,
      validator: validateCpSelectBoxValues
    },
    value: {
      type: [Number, String]
    },
    placeholder: {
      type: String
    },
    callback: {
      type: Function
    },
    required: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.options = this.convertValuesToOptions(this.values);

    const selectedOption = this.value
      ? this.options.find((option) => this.value === option.value)
      : null;

    if (selectedOption) {
      this.selected = selectedOption.value;
    } else if (!this.placeholder && this.options.length) {
      this.selected = this.options[0].value;
    }
  },

  methods: {
    convertValuesToOptions(values) {
      return values.map((v) => (typeof v === 'object' ? { label: v.label, value: v.value } : { label: v, value: v }));
    },

    onChangeHandler() {
      if (this.callback) {
        this.callback(this.selected);
      }
      this.$emit('change', this.selected);
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/atoms/colors';

.cp-select-box {
  position: relative;
}

.cp-select-wrapper {
  position: relative;

  select {
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 0 none;
    box-shadow: none;
    border-radius: 0;
    -moz-appearance: none; // stylelint-disable-line
    -webkit-appearance: none; // stylelint-disable-line
    padding: 0 10px;
    appearance: none;
    font-family: Lato, sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: $color-blacky;
    background-color: $color-ultralightgrey;
    height: 50px;
    transition: box-shadow 0.4s;

    &.placeholder {
      background-color: $color-white;
      color: $color-dustygrey;
      border: 1px solid $color-dustygrey;
      font-weight: 400;
      font-style: italic;
    }

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 1px $color-blue;
    }

    option {
      font-weight: normal;
    }
  }

  .icon-down-open-big {
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 1;
    color: $color-blacky;
    line-height: 1;
    font-size: 18px;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
</style>
