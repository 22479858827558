<template>
  <div class="range-slider">
    <label>
      <slot></slot>
    </label>
    <div class="range-slider-container" ref="rangeSliderContainer">
      <span class="range-label">{{minRangeLabel}}</span>
      <span v-if="midLabel" class="range-label midRange">{{midLabel}}</span>
      <span class="range-label maxRange">{{maxRangeLabel}}</span>
      <div class="range-slider-container-inner">
        <input type="range" class="slider"
               :min="minRange.value"
               :max="maxRange.value"
               :step="step"
               v-model="myValue"
               @change="onChange"
        />
        <span class="slider-bg"></span>
        <span :style="thumbPosition" ref="myValue" class="myValue">
          <span class="myValue--label">{{myLabel}}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RangeSlider',

  data() {
    return {
      myValue: 50,
      myData: { label: '50%', value: 50 },
      maxLeft: 0
    };
  },

  props: {
    values: {
      type: Array,
      validator(arr) {
        return arr.every((v) => (typeof v === 'object') && 'label' in v && 'value' in v);
      }
    },

    value: {
      type: Object,
      default() {
        return { label: '50%', value: 50 };
      }
    },

    max: {
      type: Number
    },

    min: {
      type: Number
    },

    maxLabel: {
      type: String
    },

    minLabel: {
      type: String
    },

    midLabel: {
      type: String
    },

    step: {
      type: Number,
      default: 1
    }
  },

  created() {
    const values = Object.values(this.values);
    let currentValue = values[0];

    for (let i = 0; i < values.length; i += 1) {
      if (this.value.value === values[i].value) {
        currentValue = values[i];
        break;
      }
    }

    this.myValue = currentValue.value;
    this.myData = currentValue;
  },

  mounted() {
    /* window.addEventListener("resize", this.calcMaxLeft.bind(this));
      Vue.nextTick(() => {
        this.calcMaxLeft();
      }); */
    this.calcMaxLeft();
  },

  destroyed() {
    window.removeEventListener('resize', this.calcMaxLeft.bind(this));
  },

  computed: {
    myLabel() {
      const currentValue = parseInt(this.myValue, 10);
      const valueObj = Object.values(this.values).find((v) => v.value === currentValue);
      return valueObj ? valueObj.label : '';
    },

    thumbPosition() {
      return {
        left: `${this.maxLeft
          * ((parseInt(this.myValue, 10) - this.minRange.value)
            / (this.maxRange.value - this.minRange.value))}px`
      };
    },

    minRange() {
      /**
       * Use prop min as default min value.
       * If min is not set or not found in the values,
       * use minimal value of values as fallback
       */
      const values = Object.values(this.values);
      let min = false;

      if (typeof this.min === 'number') {
        min = values.find((item) => this.min === item.value);
      }

      if (!min) {
        min = values.reduce((v1, v2) => (v1.value > v2.value ? v2 : v1));
      }

      return min;
    },

    minRangeLabel() {
      if (typeof this.minLabel === 'string') {
        return this.minLabel;
      }
      return this.minRange.label;
    },

    maxRange() {
      /**
       * Use prop max as default max value.
       * If max is not set or not found in the values,
       * use maximal value of values as fallback
       */
      const values = Object.values(this.values);
      let max = false;

      if (typeof this.max === 'number') {
        max = values.find((item) => this.max === item.value);
      }

      if (!max) {
        max = values.reduce((v1, v2) => (v1.value < v2.value ? v2 : v1));
      }

      return max;
    },

    maxRangeLabel() {
      if (typeof this.maxLabel === 'string') {
        return this.maxLabel;
      }
      return this.maxRange.label;
    }
  },
  methods: {
    calcMaxLeft() {
      const labelEl = this.$refs.myValue;
      const labelMax = labelEl.cloneNode(true);

      labelMax.classList.add('clone');
      labelMax.querySelector('.myValue--label').innerText = this.maxRange.label;

      this.$refs.rangeSliderContainer.appendChild(labelMax);
      this.maxLeft
        = this.$refs.rangeSliderContainer.querySelector('.slider').offsetWidth - labelMax.offsetWidth;

      this.$refs.rangeSliderContainer.removeChild(this.$refs.rangeSliderContainer.querySelector('.clone'));
    },
    onChange() {
      this.$emit('change', this.myValue);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';

.range-slider {
  position: relative;

  /deep/ label {
    font-style: italic;
    color: $color-dustygrey;
  }
}

.range-slider-container {
  width: 100%;
  height: 50px;
  position: relative;
  color: $color-dustygrey;
  background-color: $color-lightgrey;
  padding: 4px 10px 0;
  font-size: 12px;
  line-height: 1.4;
}

.range-slider-container-inner {
  position: relative;
}

.slider {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  height: 20px;
  outline: none;
  margin: 3px 0 0;
  opacity: 0;

  &:hover + .slider-bg {
    opacity: 1;
  }
}

.slider-bg {
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
  width: 100%;
  height: 8px;
  background: $gradient-blue-horizontal-reverse;
  opacity: 0.7;
  -webkit-transition: 0.2s; // stylelint-disable-line
  transition: opacity 0.2s; // stylelint-disable-line
  backface-visibility: hidden;
}

.myValue {
  position: absolute;
  pointer-events: none;
  border: 1px solid $color-blacky;
  text-align: center;
  background-color: $color-blacky;
  color: $color-ultralightgrey;
  white-space: nowrap;
  min-width: 50px;
  padding: 0 6px;
  height: 18px;
  top: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:after {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 4px 0 4px;
    border-color: $color-blacky transparent transparent transparent;
    position: absolute;
    top: 16px;
    left: calc(50% - 5px);
    content: '';
  }
}

input[type='range']::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none; // stylelint-disable-line
  -moz-appearance: none; // stylelint-disable-line
  height: 30px;
  width: 50px;
  background: transparent;
  cursor: grab;
}

.range-label {
  font-style: italic;
  font-size: 11px;

  /deep/ &.maxRange {
    float: right;
  }

  &.midRange {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
