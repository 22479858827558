<template>
  <div class="language-skills-graph" :class="{'applicant-skill' : asApplicantSkill }">
    <h4>
      <Translation resource_key="joboffer.language" />
    </h4>
    <div class="skill-graph necessary" v-if="necessaryLang.length > 0">
      <h5 v-if="!asApplicantSkill">
        <Translation resource_key="joboffer.necessary-skills" />
      </h5>
      <ul class="skills">
        <li v-for="(lang, idx) in necessaryLang" :key="idx">
          <span class="level" :class="lang.languageLevel">
            {{ lang.language }}
          </span>
        </li>
      </ul>
      <ul class="skill-levels">
        <li v-for="(skill, idx) in skillLevels" :key="idx">
          <Translation :datakey="`languageLevel${idx}`" :resource_key="`joboffer.languageLevel.${skill}`" />
          <span v-html="languageLevel(idx)" />
        </li>
      </ul>
    </div>
    <div class="skill-graph desired" v-if="desiredLang.length > 0">
      <h5 v-if="!asApplicantSkill">
        <Translation resource_key="joboffer.desired-skills" />
      </h5>
      <ul class="skills">
        <li v-for="(lang, idx) in desiredLang" :key="idx">
          <span class="level" :class="lang.languageLevel">
            {{ lang.language }}
          </span>
        </li>
      </ul>
      <ul class="skill-levels">
        <li v-for="(skill, idx) in skillLevels" :key="idx">
          <Translation :datakey="`languageLevel${idx}`" :resource_key="`joboffer.languageLevel.${skill}`" />
          <span v-html="languageLevel(idx)" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Translation from '../atoms/Translation';

export default {
  name: 'LanguageSkillsGraph',

  components: {
    Translation
  },

  props: {
    values: {
      type: Object,
      required: true
    },
    asApplicantSkill: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      languageLevel0: '',
      languageLevel1: '',
      languageLevel2: '',
      languageLevel3: ''
    };
  },

  computed: {
    skillLevels() {
      return ['basic', 'fluent', 'businessFluent', 'nativeSpeaker'];
    },

    necessaryLang() {
      const self = this;
      const result = [...this.values.languageImportances];
      return result.filter((sa) => self.isNecessary(sa));
    },

    desiredLang() {
      const self = this;
      const result = [...this.values.languageImportances];
      return result.filter((sa) => !self.isNecessary(sa));
    }
  },

  methods: {
    isNecessary(skill) {
      return skill.languageImportance === 'necessary';
    },
    languageLevel(idx) {
      return this[`languageLevel${idx}`];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';
@import '../../scss/atoms/breakpoints.mixin';

.language-skills-graph {
  margin-bottom: 105px;
  // display: inline-block;

  &.applicant-skill .skill-graph.desired {
    margin-top: 23px;
  }

  h4 {
    color: $color-blue;
  }

  h5 {
    color: #626262;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
  }

  .skill-graph {
    position: relative;

    &.desired {
      margin-top: 60px;
    }

    .skills li {
      background-color: $color-lightgrey;
      margin: 1px;
      font-size: 17px;
      color: $color-darkgrey;

      span {
        display: inline-block;
        color: $color_white;
        background: $gradient_ci_horizontal_reverse;
        width: 25%;
        padding: 7px 10px;

        &.fluent {
          width: 50%;
        }

        &.businessFluent {
          width: 75%;
        }

        &.nativeSpeaker {
          width: 100%;
        }
      }
    }

    .skill-levels {
      position: absolute;
      top: 23px;
      right: 0;
      left: 0;
      bottom: -20px;
      vertical-align: bottom;

      li {
        display: inline-block;
        position: relative;
        width: 25%;
        height: 100%;
        vertical-align: bottom;
        text-align: right;
        border-right: 1px solid $color-silvergrey;
        padding-right: 10px;

        span {
          font-size: 16px;
          color: $color-dustygrey;
          position: absolute;
          bottom: -20px;
          right: 0;
          line-height: 1em;
        }
      }
    }
  }
}

@include bp-max-medium() {
  .language-skills-graph .skill-graph .skill-levels li span {
    font-size: 12px;
    line-height: 12px;
    max-width: 107px;
    word-break: break-word;
    padding-left: 4px;
  }
}
</style>
