import axios from 'axios';

const URL = '/api/v2/applicant/joblist/refine';

export default {
  getRefinedJobList(profileId, joblistIds, lat, long, distance, employmentType, keyword) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL}`, {
          profileId,
          joblistIds,
          lat,
          long,
          distance,
          employmentType,
          keyword
        })
        .then((response) => {
          // handle success
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  }
};
