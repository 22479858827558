import _ from 'lodash';
import moment from 'moment';

const handleFileEntries = (entity) => {
  const result = _.cloneDeep(entity);
  if (!('image' in entity)
    || (!('attached' in entity.image) && entity.extension !== 'pdf' && !('file' in entity))) {
    delete result.image;
  } else {
    // removed not allowed fields
    delete result.image.type;
    delete result.image.id;
    delete result.image.name;
    delete result.image.attached;
    delete result.image.imagePath;
  }

  // cv
  if (!('cv' in entity)
    || !entity.cv
    // || (!('attached' in entity.cv) && (!('furtherDocuments' in entity) || entity.furtherDocuments.every(fd => !('attached' in fd))))
    || (!('attached' in entity.cv) && (!('extension' in entity.cv)))) {
    delete result.cv;
  } else {
    // removed not allowed fields
    // cv is needed, if furtherDocuments must be updated.
    delete result.cv.type;
    delete result.cv.id;
    delete result.cv.attached;
  }

  // furtherDocuments
  if ('furtherDocuments' in entity) {
    if (entity.furtherDocuments.length === 0 || entity.furtherDocuments.every((fd) => !('attached' in fd))) {
      delete result.furtherDocuments;
    } else {
      result.furtherDocuments = entity.furtherDocuments.map((fd) => {
        const newFd = { ...fd };
        // removed not allowed fields
        delete newFd.type;
        delete newFd.id;
        delete newFd.attached;
        if (!('extension' in newFd)) {
          newFd.extension = 'pdf'; // as default value
        }
        return newFd;
      });
    }
  }
  return result;
};

const convertValueFromTable = (original, key, table) => {
  const keyVal = Object.entries(table).find(([_key, val]) => (typeof val === 'string' ? original[key] === val : original[key] === val.label));

  if (keyVal) {
    return typeof keyVal[1] === 'string' ? keyVal[0] : keyVal[1].value;
  }
  return null;
};

const isDdMmYyyyFormat = (str) => str.indexOf('.') === 2 && str.lastIndexOf('.') === 5 && str.length === 10;

const convertDateFormat = (value) => {
  let m = null;
  if (value instanceof Date) {
    // iso or someother Date object
    m = moment(value);
  } else if (value === 'now') {
    m = moment();
  } else if (typeof value === 'string' && value.length === 5) {
    // String which has format MM/YY into 01.MM.YYYY
    m = moment(value, 'MM/YY');
  } else if (isDdMmYyyyFormat(value)) {
    m = moment(value, 'DD.MM.YYYY');
  } else if (!value) {
    // null or undefined
    m = moment();
  } else {
    // other string format like UTC Time String YYYY-MM-DDT00:00:00+00:00
    // console.warn('unexpected value', value);
    m = moment(value);
  }
  return m.format('DD.MM.YYYY');
};

const sortDateOrderByMomentFormat = (dateA, dateB) => {
  const a = moment(dateA, 'DD.MM.YYYY');
  const b = moment(dateB, 'DD.MM.YYYY');
  return a.isBefore(b) ? [dateA, dateB] : [dateB, dateA];
};

const compareByFromDate = (eva, evb) => convertDateFormat(evb.from).valueOf() - convertDateFormat(eva.from).valueOf();

const handleTelephoneInterview = (entity) => {
  const result = { ...entity };
  delete result.oneColumnId;
  return result;
};

const convertCurrentLocation = (location, _form) => {
  const result = { ...location };
  if (!('country' in result)) {
    result.country = 'DE';
  }
  return result;
};

const convertAbroadExperiences = (experiences, form) => {
  const result = [];
  experiences.map((experience) => {
    const exp = { ...experience };

    const countryVal = convertValueFromTable(experience, 'country', form.abroadExperiences.country.choices);
    if (countryVal) {
      exp.country = countryVal;
    }

    const durationVal = convertValueFromTable(experience, 'duration', form.abroadExperiences.duration.choices);
    if (durationVal) {
      exp.duration = durationVal;
    }

    const typeVal = convertValueFromTable(experience, 'type', form.abroadExperiences.type.choices);
    if (typeVal) {
      exp.type = typeVal;
    }

    result.push(exp);
    return experience;
  });

  return result;
};

const convertLanguageSkills = (languages, form) => {
  const result = [];
  languages.map((language) => {
    const lang = { ...language };
    // assign key of durations
    const languageArray = [...Object.values(form.languageSkills.language.preferred_choices), ...Object.values(form.languageSkills.language.choices)];
    const languageObj = languageArray.find((val) => language.language === val.label);
    if (languageObj) {
      lang.language = languageObj.value;
    }

    const languageLevelTable = form.languageSkills.languageLevel.choices;
    const level = Object.values(languageLevelTable)
      .find((val) => language.languageLevel === val.original);
    if (level) {
      lang.languageLevel = level.value;
    }
    result.push(lang);
    return language;
  });

  return result;
};

const convertJobExperiences = (experiences, form) => {
  const result = [];
  experiences.map((experience) => {
    if (!('jobExperienceName' in experience) || experience.jobExperienceName.length === 0) {
      // ignore if no jobExperienceName
      return experience;
    }

    const exp = { ...experience };
    const [from, till] = sortDateOrderByMomentFormat(convertDateFormat(experience.from), convertDateFormat(experience.till));
    exp.from = from;
    exp.till = till;

    if (experience.currentJob !== 'yes') {
      delete exp.currentJob;
    }

    const sectorVal = convertValueFromTable(experience, 'jobExperienceDynaJobsSector', form.jobExperiences.jobExperienceDynaJobsSector.choices);
    if (sectorVal) {
      exp.jobExperienceDynaJobsSector = sectorVal;
    } else {
      delete exp.jobExperienceDynaJobsSector;
    }

    const experienceLevelVal = convertValueFromTable(experience, 'jobExperienceLevel', form.jobExperiences.jobExperienceLevel.choices);
    if (experienceLevelVal) {
      exp.jobExperienceLevel = experienceLevelVal;
    } else {
      delete exp.jobExperienceLevel;
    }

    delete exp.duration;
    result.push(exp);
    return experience;
  });

  return result.sort(compareByFromDate);
};

const convertStudyPrograms = (studies, form) => {
  const result = [];
  studies.map((study) => {
    const std = { ...study };

    const [from, till] = sortDateOrderByMomentFormat(convertDateFormat(study.from), convertDateFormat(study.till));
    std.from = from;
    std.till = till;

    const degreeVal = convertValueFromTable(study, 'degree', form.studyPrograms.degree.choices);
    if (degreeVal) {
      std.degree = degreeVal;
    }

    const gradeVal = convertValueFromTable(study, 'grade', form.studyPrograms.grade.choices);
    if (gradeVal) {
      std.grade = gradeVal;
    }

    result.push(std);
    return study;
  });

  return result.sort(compareByFromDate);
};

const convertProfessions = (professions, _form) => {
  const result = [];
  professions.map((profession) => {
    const prf = { ...profession };

    const [from, till] = sortDateOrderByMomentFormat(convertDateFormat(profession.from), convertDateFormat(profession.till));
    prf.from = from;
    prf.till = till;

    if (profession.mastercraftsman !== 'yes') {
      delete prf.mastercraftsman;
    }

    result.push(prf);
    return profession;
  });

  return result.sort(compareByFromDate);
};

const convertHighestDegree = (entity, form) => convertValueFromTable(entity, 'highestDegree', form.highestDegree.choices);

const convertHighestDegreeGrade = (entity, form) => convertValueFromTable(entity, 'highestDegreeGrade', form.highestDegreeGrade.choices);

const convertFurtherQualifications = (qualifications, form) => {
  const result = [];
  qualifications.map((qualification) => {
    const qualific = { ...qualification };
    const qualVal = convertValueFromTable(qualification, 'qualification', form.furtherQualifications.qualification.choices);
    if (qualVal) {
      qualific.qualification = qualVal;
    }
    result.push(qualific);
    return qualification;
  });

  return result;
};

const convertForUpdate = (entity, form) => {
  const result = handleFileEntries(entity);
  result.currentLocation = convertCurrentLocation(entity.currentLocation, form);
  result.abroadExperiences = convertAbroadExperiences(entity.abroadExperiences, form);
  result.jobExperiences = convertJobExperiences(entity.jobExperiences, form);
  result.languageSkills = convertLanguageSkills(entity.languageSkills, form);
  result.studyPrograms = convertStudyPrograms(entity.studyPrograms, form);

  result.highestDegree = convertHighestDegree(result, form);
  if (typeof result.highestDegree === 'undefined') {
    delete result.highestDegree;
  }

  result.highestDegreeGrade = convertHighestDegreeGrade(result, form);
  if (typeof result.highestDegreeGrade === 'undefined') {
    delete result.highestDegreeGrade;
  }
  result.professions = convertProfessions(entity.professions, form);

  result.furtherQualifications = convertFurtherQualifications(entity.furtherQualifications, form);

  if ('telephoneInterview' in result) {
    result.telephoneInterview = handleTelephoneInterview(entity.telephoneInterview);
  }
  return result;
};

// for test
export { convertDateFormat };
export default {
  convertForUpdate
};
