<template>
  <ModalBase :additional_classes="{'outer' : 'analyse-modal'}">
    <div class="analyse-modal-inner">
      <header class="analyse-modal-header">
        <div class="analyse-modal-header-image desktop-hidden"></div>
        <div class="analyse-modal-header-content">
          <h1 class="cp-header-title">
            Deine
            <span class="text-blue">Jobaussichten</span>
          </h1>
          <h3 class="header-subtitle">
            Wir haben Deine beruflichen Fähigkeiten mit den Anforderungen
            aus aktuellen Stellenanzeigen verglichen.
          </h3>
          <p class="header-paragraph">
            Erfahre nun, wie hoch Dein derzeitiger Marktwert ist und welche Jobangebote
            am besten zu Deinen Fähigkeiten passen. Hier sind mit Sicherheit auch Stellen
            dabei, an die Du bisher nicht gedacht hast.
          </p>
        </div>
      </header>

      <div class="analyse-modal-section bg-light-grey">
        <div class="analyse-modal-section-inner">
          <div class="cp-row">
            <CpHeader>
              <template slot="title">Dein persönlicher Marktwert</template>
              <template slot="taglines">
                Der Marktwert zeigt Dir an, wie gut Du die Anforderungen des Arbeitsmarktes
                erfüllst. <br/>
                Je höher Dein persönlicher Marktwert, desto besser sind Deine Jobaussichten.
              </template>
            </CpHeader>
          </div>
          <div class="cp-row">
            <div class="analyse-modal-doughnut">
              <chart-doughnut-market-value
                :values="charts.doughnutValues"
                :background_colors="doughnutBackgroundColors"
                :chartWidth="doughnutWidth"
              />
            </div>
            <div class="analyse-modal-radar">
              <chart-radar-competence
                :labels="charts.radarLabels"
                :datasets="charts.radarDatasets"
              />
            </div>
          </div>
          <div class="cp-row align-center">
            <cp-button-anchor
              to="/register"
              size="large"
              color="dark"
              weight="bold"
            >Jetzt registrieren
            </cp-button-anchor>
          </div>
        </div>
      </div>

      <div class="analyse-modal-section">
        <div class="analyse-modal-section-inner">
          <div class="cp-row">
            <CpHeader>
              <template slot="title">Deine passenden Jobangebote</template>
              <template slot="taglines">
                Hier findest Du eine erste Auswahl an aktuellen Stellenanzeigen, die besonders gut
                zu Deinen Fähigkeiten passen. Nach Deiner Registrierung bei CareerPeakr zeigen wir
                Dir weitere passende Jobs – natürlich mit Informationen zur Stelle und zum
                Arbeitgeber.
              </template>
            </CpHeader>
          </div>
          <div class="cp-row">
            <job-listing :jobs="jobList" :anonymous="true"></job-listing>
          </div>
        </div>
      </div>

      <footer class="analyse-modal-section align-center">
        <div class="analyse-modal-section-inner">
          <div class="cp-row">
            <p class="analyse-modal-footer-text">
              Jetzt kostenlos registrieren und Jobangebote finden,
              die wirklich zu Dir passen und Dich zufrieden machen.
            </p>
          </div>
          <div class="cp-row">
            <cp-button-anchor
              to="/register"
              size="large"
              color="dark"
              weight="bold"
            >Jetzt registrieren
            </cp-button-anchor>
          </div>
        </div>
      </footer>
    </div>
  </ModalBase>
</template>

<script>
import { mapState } from 'vuex';
import ModalBase from '../molecules/ModalBase';
import CpHeader from '../molecules/CpHeader';
import { CpButtonAnchor } from '../atoms/CpButton';
import JobListing from '../organisms/JobListing';
import ChartRadarCompetence from '../organisms/ChartRadarCompetence';
import ChartDoughnutMarketValue from '../organisms/ChartDoughnutMarketValue';

export default {
  name: 'NoLoginAnalyse',

  components: {
    ModalBase,
    JobListing,
    CpButtonAnchor,
    CpHeader,
    ChartRadarCompetence,
    ChartDoughnutMarketValue
  },

  data() {
    return {
      doughnutBackgroundColors: [['#00C6F8', '#0098FF']],
      doughnutWidth: '140px'
    };
  },

  computed: {
    ...mapState({
      charts(state) {
        if (!state.applicant.charts) {
          return null;
        }

        const { charts } = state.applicant;

        const data = {
          doughnutValues: [charts.marketValue],
          radarLabels: [],
          radarDatasets: [
            {
              data: [],
              borderColor: '#0098FF',
              backgroundColor: ['#B6ECFA', '#0098FF']
            }
          ]
        };

        for (
          let i = 0, len = charts.competenceProfile.points.length;
          i < len;
          i += 1
        ) {
          data.radarLabels.push(charts.competenceProfile.points[i].categoryText);
          data.radarDatasets[0].data.push(charts.competenceProfile.points[i].weight);
        }

        return data;
      },

      jobList(state) {
        if (!state.applicant.jobList) {
          return null;
        }

        return state.applicant.jobList;
      }
    })
  },

  created() {
    window.addEventListener('resize', this.setDoughnutWidth.bind(this));
    this.setDoughnutWidth();
  },

  mounted() {
    // if the analyse data in state is empty, close the modal
    if (!this.charts || !this.jobList) {
      this.$router.push('/');
    }
  },

  methods: {
    setDoughnutWidth() {
      if (window.innerWidth < 580) {
        this.doughnutWidth = '90px';
      } else {
        this.doughnutWidth = '140px';
      }
    }
  }
};
</script>

<style lang="scss">
  @import '../../scss/organisms/header_headline';
  @import '../../scss/templates/no_login_analyse';

  @media screen and (max-width: 1023px) {
    .analyse-modal-doughnut {
      position: relative;
      left: auto;
      top: auto;
      width: auto;
      height: auto;
    }

    .analyse-modal-radar {
      margin-left: 0;
    }

    .analyse-modal-section.bg-light-grey {
      padding-left: 20px;
      padding-right: 20px;
    }

    .cp-row {
      + .cp-row {
        margin-top: 24px;
      }
    }
  }
</style>
