<template>
  <div class="cp-header-headline">
    <h1 class="cp-header-title">
      <Translation resource_key="commonlanding_header.your_digital" /><br>
      <span class="text-blue"><Translation resource_key="commonlanding_header.career_coach" /></span>
    </h1>
    <p class="header-paragraph">
      <Translation resource_key="commonlanding_header.find_jobs" /><br>
      <span class="text-bold break-words"><Translation resource_key="commonlanding_header.for_your_working_feature" /></span>
    </p>
  </div>
</template>

<script>
import Translation from '../atoms/Translation';

export default {
  name: 'HeaderHeadline',

  components: {
    Translation
  }
};
</script>

<style lang="scss">
@import '../../scss/organisms/header_headline';
</style>
