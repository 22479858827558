<template>
  <section class="company-overview">
    <div class="company-metadata">
      <div class="column">
        <h2 class="mobile-hidden">
          <Translation resource_key="joboffer.company.company" />
        </h2>
        <div class="name">
          <h3>
            <router-link :to="{name: 'CompanyProfile', params: {id: profile.id}}">
              {{ profile.address.name }}
            </router-link>
          </h3>
          <p>
          </p>
        </div>
        <div class="employees">
          <CompanyDataBlock>
            <Translation slot="title" resource_key="joboffer.company.employees.title" />
            <template slot="data">
              {{ profile.employeesNumber }}
              <Translation slot="title" resource_key="joboffer.company.employees" />
            </template>
          </CompanyDataBlock>
        </div>
        <div class="branch">
          <CompanyDataBlock>
            <Translation slot="title" resource_key="company.profile.branch" />
            <ul slot="data">
              <li v-for="(sector, index) in profile.dynaJobsJobSectors" :key="index">
                {{ sector }}
              </li>
            </ul>
          </CompanyDataBlock>
        </div>
        <div class="socalimedia" v-if="hasSocialMedia()">
          <CompanyDataBlock>
            <Translation slot="title" resource_key="company.profile.socialmedia"/>
            <CompanySocialMediaLinks :company="profile" slot="data" />
          </CompanyDataBlock>
        </div>
      </div>
      <div class="column logo">
        <img :src="logo.imagePath" alt="">
      </div>
      <h2 class="desktop-hidden">
        <Translation resource_key="joboffer.company.company" />
      </h2>
    </div>
    <CompanyJumboImage :image="image" v-if="image" />
  </section>
</template>

<script>
import Translation from '../atoms/Translation'
import CompanyDataBlock from '../molecules/CompanyDataBlock';
import CompanySocialMediaLinks from '../molecules/CompanySocialMediaLinks';
import CompanyJumboImage from '../molecules/CompanyJumboImage';

// Mock server data
if (typeof window.Jobpassion === 'undefined') {
  window.Jobpassion = {};
}

export default {
  name: 'CompanyOverview',

  components: {
    CompanySocialMediaLinks,
    CompanyDataBlock,
    CompanyJumboImage,
    Translation
  },

  props: {
    profile: {
      type: Object,
      requreid: true
    },
    logo: {
      type: Object,
      required: true
    },
    image: {
      type: Object,
      required: false
    }
  },
  created() {
    console.log(this.profile);
  },

  methods: {
    formatComanyWebsite() {
      const displayText = (this.profile.companyWebsite)
        ? this.profile.companyWebsite
          .replace(/https?:\/\//, '')
          .replace(/\/$/, '')
        : '';
      return `<a href="${this.profile.companyWebsite}" target="_blank" rel="noopener">${displayText}</a>`;
    },
    hasSocialMedia() {
      return this.profile.facebookLink
        || this.profile.googlePLusLink
        || this.profile.xingLink
        || this.profile.linkedInLink
        || this.profile.twitterLink
        || this.profile.youtubeLink;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/_breakpoints.mixin';

.company-overview {
  background-color: $color-ultralightgrey;
  padding-top: 10px;
  padding-bottom: 40px;
}

.company-metadata {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 780px;
  margin-top: 40px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;

  .column {
    width: 45%;

    &.logo {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 380px;

      img {
        width: 100%;
      }
    }

    h2, .mobile-hidden {
      font-size: 30px !important;
      line-height: 40px;
      font-weight: 900 !important;
    }
    .name {
      h3 {
        margin: 0;
        font-size: 17px;
        line-height: 25px;

        a {
          display: block;
          color: $color-blue;
          font-weight: 400;
          border-bottom: 1px solid $color-silvergrey;
          padding: 1em 0;
          margin: 0;
          text-decoration: none;
        }
      }

      h1,
      p {
        margin: 0;
      }

      h1 {
        color: $color-blue;
        font-weight: 400;
        font-size: 17px;
        line-height: 25px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 15px;
      }
    }

    .employees .cp-company-data-block {
      padding-top: 0;
    }

    .branch /deep/ ul li {
      display: inline;
      // margin-left: 0.2em;

      &:after {
        content: ', ';
        margin-left: -3px;
        margin-right: 3px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child:after {
        content: '';
        margin: 0;
      }
    }

    .company-website {
      padding-top: 11px;
      padding-bottom: 11px;

      li {
        &:after {
          display: inline-block;
          content: '\E813';
          font-family: fontello;  // stylelint-disable-line
          font-size: 12px;
          position: absolute;
          margin-left: 10px;
          transform: rotate(-45deg);
        }

        /deep/ a {
          color: $color-blacky;
          text-decoration: none;
          font-size: 17px;
          font-weight: bold;
        }
      }
    }
  }
}

.company-jumbo-image {
  max-height: 510px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;

  /deep/ img {
    box-shadow: 0 0 30px 0 rgba(52, 53, 53, 0.2);
    height: auto;
    width: 100%;
  }
}

@include bp-max-tablet() {
  .company-overview {
    padding-left: var(--padding-edge);
    padding-right: var(--padding-edge);
  }
}

@include bp-max-medium() {
  .company-overview {
    padding-top: 1px;
  }
  .company-jumbo-image {
    max-height: 230px;
    margin-left: auto;
    margin-right: auto;

    /deep/ img {
      box-shadow: 0 0 30px 0 rgba(52, 53, 53, 0.2);
      height: auto;
      width: 100%;
    }
  }

  .company-metadata {
    flex-direction: column-reverse;

    .column {
      width: 100%;

      .name {
        border-top: 1px solid $color-silvergrey;
        h3 a {
          font-size: 18px;
        }
      }

      &.logo {
        margin-top: 30px;
        margin-bottom: 30px;
        padding-left: 40px;
        padding-right: 40px;
        max-width: 200px;

        img {
          width: 100%;
        }
      }
    }
  }
}

</style>
