<template>
  <div :class="{ 'my-job-selection': true, 'boxshadow-basic': true, 'hidden': !visibleOnMobile, 'transitioned': transitioned }">
    <div :class="{ 'my-job-selection-overlay': true, 'hidden': !visibleOnMobile, 'transitioned': transitioned }"
         v-if="bpMaxTablet" @click="onClose">
    </div>
    <div :class="{ 'my-job-selection-inner': true, 'hidden': !visibleOnMobile, 'transitioned': transitioned }">
      <h3 @click="toggleOnMobile">
        <Editable resource_key="my_job_selection" />
        <i :class="{'cp-modal-close' : true, 'hidden' : !visibleOnMobile }" v-if="bpMaxTablet"></i>
      </h3>
      <StickyInfoBox color="black">
        <template slot="StickyInfoText"> Je weniger Stellen Du Dir aussuchst, desto genauer können wir sagen, wie gut Du zur Stelle passt. </template>
      </StickyInfoBox>
      <ul>
        <transition-group name="disappear">
          <li is="JobListItem" class="job-list-item" v-for="job in jobs" :job="job" :key="job.id" :like="showLike"></li>
        </transition-group>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import JobListItem from '../molecules/JobListItem';
import Editable from '../molecules/Editable';
import types from '../../store/modules/applicant/types';
import StickyInfoBox from '../molecules/StickyInfoBox';
import breakPoints from '../../lib/mixins/breakPoints';

export default {
  name: 'MyJobSelection',

  components: {
    JobListItem,
    Editable,
    StickyInfoBox
  },

  mixins: [breakPoints],

  data() {
    return {
      showLike: false,
      visibleOnMobile: false,
      transitioned: true
    };
  },

  computed: {
    ...mapState({
      jobs(state) {
        // console.log(state.applicant.jobList.jobList);
        // return Object.keys(state.applicant.jobList.jobList).filter((i) => { return i.selected });
        return state.applicant.jobList.filter((j) => state.applicant.selected.includes(j.id) && !state.applicant.trash.includes(j.id));
      },
      hasApplicantProfile(state) {
        return state.applicant.profile && state.applicant.profile.registeredApplicantProfile;
      }
    })
  },

  watch: {
    $route(to, _from) {
      this.showLike = to.path.includes('/optimize');
    }
  },

  methods: {
    marked(id) {
      return this.$store.state.applicant.favorite
        ? this.$store.state.applicant.favorite.includes(id)
        : false;
    },

    toggleMark(id) {
      if (this.marked(id)) {
        this.$store.dispatch('applicant/unmarkFavorite', { id });
      } else {
        this.$store.dispatch('applicant/markFavorite', { id });
      }
    },

    toggleSelected(id) {
      this.$store.commit(`applicant/${types.MUTATIONS.TOGGLE_SELECTED}`, { id });
    },

    selected(id) {
      return this.$store.state.applicant.selected
        ? this.$store.state.applicant.selected.includes(id)
        : false;
    },

    isNew(timestamp) {
      if (this.$store.state.applicant.profile) {
        const lastLogin = new Date(this.$store.state.applicant.profile.lastLogin);
        const publishedAt = new Date(timestamp);
        return lastLogin.getTime() < publishedAt.getTime();
      }
      return false;
    },

    onClose(e) {
      if (this.bpMaxTablet) {
        e.preventDefault();
        this.transitioned = false;
        this.visibleOnMobile = false;
        const self = this;
        this.$nextTick(() => {
          setTimeout(() => {
            self.transitioned = true;
          }, 400);
        });
      }
      return false;
    },

    toggleOnMobile() {
      if (this.bpMaxTablet) {
        this.transitioned = false;
        this.visibleOnMobile = !this.visibleOnMobile;
        const self = this;
        setTimeout(() => {
          self.transitioned = true;
        }, 400);
      }
    }

  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/_breakpoints.mixin';
@import '../../scss/organisms/job_listing';

.my-job-selection {
  position: absolute;
  right: 0;
  top: 190px;
  bottom: 0;
  width: 100%;
  max-width: 375px;
  background-color: $color-white;
  overflow: hidden;

  h3 {
    margin-left: 18px;
  }

  > ul {
    padding-left: 0;
    overflow: scroll;
    height: calc(100% - 30px);
  }

  .cp-modal-close {
    display: none;
  }
}

.disappear-leave-active {
  opacity: 0;
  transition: opacity 1s;
}

/deep/ {
  .job-list-item .cp-small-textbox-title {
    font-size: 17px;
  }

  .sticky-info-box {
    bottom: 0px;
  }
}

@include bp-max-tablet() {
  .my-job-selection {
    max-height: calc(100vh - 210px);
    height: 100vh;
    max-width: 100%;
    top: 168px;
    transition: max-height 2s, top 2s;

    &.hidden {
      max-height: calc(100vh - 615px);
      top: calc(100vh - 95px);
      transition: max-height 2s, top 2s;

      .sticky-info-box {
        display: none;
      }
    }

    .my-job-selection-overlay {
      position: fixed;
      display: block;
      width: 100%;
      height: 167px;
      top: 0;
      background-color: rgba(0,0,0,0.4);
      opacity: 1;
      transition: opacity 0.4s;
      transition-delay: 0.2s;
      content: ' ';

      &.hidden {
        opacity: 0;
        transition: opacity 0.4s;

        &.transitioned {
          display: none;
        }
      }
    }

    .job-list-item.selected /deep/ input[type='checkbox'] {
      top: 10px;
      left: 0;
    }

    .cp-modal-close {
      display: block;
      left: auto;
      width: 12px;
      height: 12px;

      &:before {
        transition: left 0.4s;
      }
      &:after {
        transition: right 0.4s;
      }

      &.hidden {
        &:before {
          left: -6px;
          transition: left 0.4s;
        }
        &:after {
          right: -6px;
          transition: right 0.4s;
        }
      }
    }

    h3 {
      margin-top: 13px;
      margin-bottom: 13px;
      font-size: 18px;
      font-weight: 400;
    }
  }
}
</style>

<style lang="css">
.my-job-selection .job-list-item.selected {
  padding-top: 7px;
  padding-left: 12px;
}

.my-job-selection .job-list-item.selected h3 {
  padding-top: 1px;
}

.my-job-selection .job-list-item.selected input[type=checkbox] {
  top: 10px;
  left: 4px;
}

.my-job-selection .job-list-item.selected .icons .icon-trash-empty {
  display: none;
}

.my-job-selection .job-list-item.selected .icons .icon-heart-empty,
.my-job-selection .job-list-item.selected .icons .icon-heart {
  float: right;
  margin-right: 15px;
}

</style>
