<script>
export default {
  name: 'ListingTab',

  data() {
    return {
      tabs: [],
      activeTabIndex: 0
    }
  },

  created() {
    this.tabs = this.$slots.default.filter((item) => item.componentOptions && item.componentOptions.tag === 'ListingTabChild');
  },

  methods: {
    onTabClick(e) {
      e.preventDefault();
      this.activeTabIndex = parseInt(e.currentTarget.dataset.index, 10);
    }
  },

  render(h) {
    return h(
      'div',
      {
        class: 'listing-tab-wrapper'
      },
      [
        h(
          'ul',
          {
            class: 'listing-tab-header'
          },
          this.tabs.map((tab, idx) => h(
            'li',
            {
              class: [
                'listing-tab-header-item',
                {
                  active: this.activeTabIndex === idx
                }
              ],
              attrs: {
                'data-index': idx
              },
              on: {
                click: this.onTabClick
              }
            },
            [tab.componentOptions.propsData.title]
          ))
        ),
        h(
          'div',
          {
            class: 'listing-tab-inner'
          },
          this.tabs.map((tab, idx) => h(
            'div',
            {
              class: [
                'listing-tab-inner-item',
                {
                  active: this.activeTabIndex === idx
                }
              ],
              attrs: {
                'data-index': idx
              }
            },
            [tab]
          ))
        )
      ]
    );
  }
}
</script>

<style lang="scss">
@import '../../scss/atoms/_colors';

.listing-tab-wrapper {
  position: relative;
}

.listing-tab-header {
  display: table;
  max-width: 100%;
}

.listing-tab-header-item {
  position: relative;
  display: table-cell;
  padding: 24px 40px;
  background: #efefef;
  cursor: pointer;

  &.active {
    z-index: 2;
    background-color: $color-white;
    box-shadow: 0 -10px 20px -12px rgba(0,0,0,0.1),
      20px 0 20px -22px rgba(0,0,0,0.1),
      -20px 0 20px -22px rgba(0,0,0,0.1);
    font-weight: 700;
  }

  @media screen and (max-width: 1023px) {
    text-align: center;
    padding: 18px;
    font-size: 14px;
    line-height: 1.28;
  }
}

.listing-tab-inner {
  position: relative;
}

.listing-tab-inner-item {
  position: relative;
  width: 100%;
  display: none;
  background-color: $color-white;

  &.active {
    display: block;
  }
}
</style>
