import axios from 'axios';

const URL = '/api/v2/user/mail/unread'
// const URL = 'http://careerpeakr.test/api/v2/dict'

export default {
  /**
   * get number of unread
   * URI[ POST ] /ap1/v2/applicant/analyse
   * @return {Number} number of unread mails
   */
  countUnread() {
    return new Promise((resolve, reject) => {
      axios.get(`${URL}`).then((response) => {
        // handle success
        resolve(response.data);
      })
        .catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  }
};
