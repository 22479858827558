<template>
  <section class="result-page-container">
    <div class="result-page-wrapper">
      <img class="result-page-header-img" :src="headerImage" alt="" />
      <div class="result-page-inner">
        <div class="result-page-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ResultContainer',
  props: {
    headerImage: {
      required: false
    }
  },
  components: {
  }
};
</script>

<style lang="scss">
  .result-page-container {
    padding: 100px 0;
  }

  .result-page-wrapper {
    position: relative;
    max-width: 996px;
    margin: 0 auto;
  }

  .result-page-header-img {
    display: block;
    width: 100%;
    height: auto;
  }

  .result-page-inner {
    position: relative;
    padding: 60px 20px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
  }

  .result-page-content {
    position: relative;
    margin: 0 auto;
    max-width: 792px;
    text-align: center;

    > *:first-child {
      margin-top: 0;
    }

    h2, h3, p {
      margin: 8px 0 0;
    }

    h2 {
      font-size: 36px;
      font-weight: 900;
    }

    h3 {
      font-size: 21px;
      font-weight: 700;
    }

    p {
      font-size: 17px;
    }

    footer {
      margin-top: 44px;
    }
  }
</style>
