<template>
  <div class="documents-display">
    <ul>
      <li v-for="(val,idx) in values" :key="idx" class="doc">
        <CpButton class="cp-btn blue medium regular">
          <i class="fontello icon-doc-text"></i>
          <template v-if="val.type==='cv'">
            <a :href="`/${$careerpeakr.locale}/applicantcv/${applicantProfileId}`" target="_blank" class="cp-btn-inner">
              Lebenslauf herunterladen
            </a>
          </template>
          <template v-else>
            <!-- TODO: check which path -->
            <a :href="`/${$careerpeakr.locale}/applicantdoc/${applicantId}/${val.id}`" target="_blank" class="cp-btn-inner">
              {{ val.shownName }} herunterladen
            </a>
          </template>
        </CpButton>
      </li>
    </ul>
    <div v-if="empty">
      <p class="fresh" v-if="fresh">
        Bevor Du Deinen CV hochlädst, nenne uns zunächst Deine aktuelle Tätigkeit
        im Bereich Berufs- und Praxiserfahrung.
      </p>
      <p class="fresh" v-else>
        <i class="fresh">Dokumente auswählen</i>
      </p>
    </div>
  </div>
</template>

<script>
import CpButton from '../../atoms/CpButton';

export default {
  name: 'DocumentsDisplay',

  components: {
    CpButton
  },

  props: {
    values: {
      type: Object,
      required: true
    },
    applicantId: {
      type: Number,
      required: true
    },
    applicantProfileId: {
      type: Number
    },
    fresh: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    empty() {
      return Object.values(this.values).length === 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';

.documents-display {
  padding-bottom: 50px;

  .fresh {
    font-style: italic;
    color: $color-dustygrey;
    font-size: 17px;
  }
}
.cp-btn {
  margin-bottom: 2px;
}

.doc {
  display: inline-block;
  margin-right: 10px;
  line-height: 60px;
  font-weight: 700;

  &:last-of-type {
    margin-right: 0;
  }

  /deep/ {
    a {
      text-decoration: none;
      color: $color-white;
    }
  }
}
</style>
