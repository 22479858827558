<template>
  <section :class="{'appointment-form': true, loading: loading}">
    <div class="wrapper">
      <div class="center">
        <h1>
          <Translation resource_key="joboffer.appointment.form.title" />
        </h1>
        <p>
          <Translation resource_key="joboffer.appointment.form.text" />
        </p>
      </div>
      <form action="#" method="post" @submit.prevent="onSubmit">
        <AppointmentList :availableAppointments="sourcerObj.availableAppointments"
                         :selectAppointmentCallback="onSelectAppointment"
                         :hasError="appointmentListError"
        />
        <FulfillApplicantProfile :withHeader="false" v-if="!hasAppointment" />
        <AppointmentContactForm :sourcer="sourcerObj"
                                :applicant="applicant"
                                :errors="contactFormErrors"
                                v-else
        />
      </form>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex';
import axios from 'axios';
import Cookie from 'js-cookie';
import Translation from '../atoms/Translation';
import AppointmentList from '../organisms/AppointmentList';
import AppointmentContactForm from '../organisms/AppointmentContactForm';
import FulfillApplicantProfile from '../organisms/FulfillApplicantProfile';
import scrollIt from '../../lib/animatingScroll';

export default {
  name: 'AppointmentForm',
  components: {
    Translation,
    AppointmentList,
    AppointmentContactForm,
    FulfillApplicantProfile
  },

  props: {
    jobOffer: {
      type: Object,
      default: null
    },
    sourcer: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      appointment: null,
      loading: false,
      contactFormErrors: [],
      appointmentListError: false
    };
  },

  computed: {
    ...mapState('applicant', {
      sourcerObj(state) {
        if (this.sourcer !== null) {
          return this.sourcer;
        }

        if (state) {
          return state.appointment.sourcer;
        }
        return null;
      },
      applicant: (state) => state.applicationstatus.user
    }),
    hasAppointment() {
      return (this.sourcerObj) ? this.sourcerObj.availableAppointments.length > 0 : false;
    }
  },

  mounted() {
    scrollIt(document.body);
  },

  methods: {
    onSelectAppointment(appointment) {
      this.appointment = appointment;
    },

    savePayloadInCookie(payload) {
      /* eslint-disable camelcase */
      const {
        selectedAppointment: appointment_selected_id,
        applicantFirstName: appointment_applicant_name_first,
        applicantLastName: appointment_applicant_name_last,
        tel: appointment_applicant_tel,
        email: appointment_applicant_email
      } = payload;

      const cookiePayload = {
        appointment_selected_id,
        appointment_applicant_name_first,
        appointment_applicant_name_last,
        appointment_applicant_tel,
        appointment_applicant_email
      }
      /* eslint-enable camelcase */

      Cookie.set(
        'appointmentBeforeRegistration',
        JSON.stringify(cookiePayload)
      );
    },

    onSubmit(e) { // eslint-disable-line consistent-return
      e.preventDefault();

      if (this.loading) {
        return false;
      }

      this.loading = true;
      const formData = new FormData(e.target);

      this.appointmentListError = false;
      this.contactFormErrors = [];
      const { processTrackerId = null } = this.$route.params;
      let { jobOfferId = null } = this.$route.params;

      if (jobOfferId === null && document.location.href.indexOf('jobsuggestion') !== -1) {
        jobOfferId = document.location.href.match(/jobsuggestion\/[0-9]*/)[0].match(/\/[0-9]*/)[0].replace('/', '');
      }

      const payload = {
        processTrackerId,
        jobOfferId,
        selectedAppointment: formData.get('selectedAppointment'),
        applicantFirstName: formData.get('namefirst'),
        applicantLastName: formData.get('namelast'),
        tel: formData.get('tel'),
        email: formData.get('email'),
        message: formData.get('message')
      }

      axios
        .post('/api/v2/applicationstatus/appointment', payload)
        .then((res) => {
          const { data } = res;

          if (data.success) {
            // this part does not work because there are still no account
            // this.$store.dispatch('applicant/getApplicationStatusMatchingJobOffers');
            this.savePayloadInCookie(payload);
            this.$router.push({ name: 'AppointmentDone' });
          } else if (typeof data.errors === 'object') {
            Object.keys(data.errors).map((key) => {
              if (key === 'selectedAppointment') {
                this.appointmentListError = true;
              } else {
                this.contactFormErrors.push(key);
              }
              return key;
            });
          }
        })
        .catch((_error) => {
          // eslint-disable-next-line no-alert
          alert('Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut');
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';

.appointment-form {
  position: relative;
  width: 100%;
  max-width: 980px;
  margin: 50px auto;
  padding-top: 50px;
  padding-bottom: 55px;
  padding-left: var(--padding-edge);
  padding-right: var(--padding-edge);
  background: $color-white;
  font-size: 17px;

  &.loading {
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.75);
      content: '';
      z-index: 3;
    }

    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      display: block;
      width: 50px;
      height: 50px;
      background: transparent url('/assets/img/loader.svg') no-repeat center;
      background-size: contain;
      margin: -25px 0 0 -25px;
      content: '';
      animation: rotation 1.4s linear infinite;
      z-index: 4;
    }
  }

  .wrapper {
    max-width: 780px;
    margin: 0 auto;
  }

  .center {
    text-align: center;
    max-width: 792px;
    margin: 20px auto;

    &:first-child {
      margin-bottom: 50px;

      h1 {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .left {
    text-align: left;
    max-width: 792px;
    margin: 40px auto;
  }

  h1,
  h2,
  h3,
  em {
    margin: 0;
    font-weight: 800;
  }

  h1,
  h2 {
    font-size: 38px;
  }

  h3 {
    font-size: 21px;
  }

  em {
    font-style: normal;
  }

  a {
    text-decoration: none;
    color: $color-black;
  }
}

</style>
