<template>
  <div class="professional-skill-tags">
    <h4>
      <Translation resource_key="joboffer.professional-knowledge" />
    </h4>
    <div class="necessary-skills" v-if="necessarySkills.length > 0">
      <h5 v-if="!asApplicantSkill">
        <Translation resource_key="joboffer.necessary-skills" />
      </h5>
      <ul class=" skills">
        <li v-for="(skill, idx) in necessarySkills" :key="idx" :class="{ 'necessary': isNecessary(skill) }">
          {{ skill.skillName }}
        </li>
      </ul>
    </div>
    <div class="desired-skills" v-if="desiredSkills.length > 0">
      <h5 v-if="!asApplicantSkill">
        <Translation resource_key="joboffer.desired-skills" />
      </h5>
      <ul class="skills">
        <li v-for="(skill, idx) in desiredSkills" :key="idx" :class="{ 'necessary': isNecessary(skill) }">
          {{ skill.skillName }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Translation from '../atoms/Translation';

export default {
  name: 'ProfessionalKnowledgeTags',

  components: {
    Translation
  },

  props: {
    values: {
      type: Object,
      required: true
    },
    asApplicantSkill: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    necessarySkills() {
      const self = this;
      const result = [...this.values.desiredSkills];
      return result.filter((sa) => self.isNecessary(sa));
    },
    desiredSkills() {
      const self = this;
      const result = [...this.values.desiredSkills];
      return result.filter((sa) => !self.isNecessary(sa));
    }
  },

  methods: {
    isNecessary(skill) {
      return skill.importance === 'necessary';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';
@import '../../scss/atoms/_typographie';

.professional-skill-tags {
  margin-bottom: 60px;

  h4 {
    color: $color-blue;
  }

  h5 {
    color: #626262;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
  }

  .desired-skills {
    margin-top: 23px;
  }

  .skills {
    li {
      display: inline-block;
      padding: 5px 15px;
      background-color: $color-lightgrey;
      margin: 1px;
      font-size: $font-bold;
      color: $color-blacky;
    }
  }
}
</style>
