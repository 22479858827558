<template>
  <div class="further-skills-edit">
    <MultipleElementForm
      :value="values"
      :component="FurtherSkillField"
      :callbackAdd="onAdd"
      :callbackRemove="onRemove"
      @change="onChange"
    />
  </div>
</template>

<script>
import FurtherSkillField from '../../organisms/ApplicantProfile/FurtherSkillField';
import MultipleElementForm from '../../molecules/MultipleElementForm';
import { RemoveElementButton } from '../../atoms/CpButton';

export default {
  name: 'FurtherSkillsEdit',

  components: {
    MultipleElementForm,
    FurtherSkillField, // eslint-disable-line vue/no-unused-components
    RemoveElementButton // eslint-disable-line vue/no-unused-components
  },

  data() {
    return {
      FurtherSkillField
    };
  },

  props: {
    values: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    callbackChange: {
      type: Function,
      required: true
    }
  },

  methods: {
    onAdd() {
      let maxIndex = -1;
      const keys = Object.keys(this.values)
      if (keys.length !== 0) {
        maxIndex = Object.keys(this.values)
          .map((i) => Math.trunc(i)) // convert string to int
          .reduce((max, n) => (n > max ? n : max));
      }
      this.callbackChange({ ...this.values, [maxIndex + 1]: '' });
    },
    onRemove(idx) {
      const newVal = { ...this.values };
      delete newVal[idx];
      this.callbackChange(newVal);
    },
    onChange({ index, value }) {
      const newVal = { ...this.values };
      newVal[index] = value;
      this.callbackChange(newVal);
    }
  }
};
</script>

<style lang="scss" scoped>

.further-skills-edit {
  padding-bottom: 20px;

  /deep/ .add-element-button {
    display: block;
  }
}
</style>
