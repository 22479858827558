<template>
  <div class="company-jumbo-image">
    <img :src="`/${image.imagePath}`" />
  </div>
</template>

<script>
// Mock server data
if (typeof window.Jobpassion === 'undefined') {
  window.Jobpassion = {};
}

export default {
  name: 'CompanyJumboImage',
  props: {
    image: {
      type: Object,
      requreid: true
    }
  }
};
</script>

<style lang="scss" scoped>
.company-jumbo-image {
  overflow: hidden;
  max-height: 540px;

  img {
    width: 100%;
  }
}
</style>
