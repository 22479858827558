import Vue from 'vue';

import types from './types';
import utils, { formatApplicantStatusMatchings } from '../../../lib/utils';

export default {
  /**
   * setCharts
   * @type {function}
   * @param state given automatically by framework
   * @param charts updated charts object
   */
  [types.MUTATIONS.SET_CHARTS](state, charts) {
    // state.charts = { ...state.charts, ...charts };
    Vue.set(state, 'charts', charts);
  },

  /**
   * setOptimizedCharts
   * @type {function}
   * @param state given automatically by framework
   * @param charts updated charts object
   */
  [types.MUTATIONS.SET_OPTIMIZED_CHARTS](state, charts) {
    Vue.set(state, 'optimizedCharts', charts);
  },

  /**
   * setJobList
   * @type {function}
   * @param state given automatically by framework
   * @param jobList updated JobList Array
   */
  [types.MUTATIONS.SET_JOBLIST](state, jobList) {
    Vue.set(state, 'jobList', jobList);
  },

  /**
   * setJobListDetail
   * @type {function}
   * @param state given automatically by framework
   * @param job
   */
  [types.MUTATIONS.SET_JOB_DETAIL](state, job) {
    Vue.set(state, 'jobListDetail', job);
  },

  /**
   * setProfile
   * @type {function}
   * @param state given automatically by framework
   * @param profile updated profile object
   */
  [types.MUTATIONS.SET_PROFILE](state, { profile }) {
    Vue.set(state, 'profile', profile);
  },

  /**
   * setProfileValue
   * @type {function}
   * @param state given automatically by framework
   * @param profile updated profile object
   */
  [types.MUTATIONS.SET_PROFILE_VALUE](state, { key, value }) {
    if (state.profile) {
      Vue.set(state.profile, key, value);
    }
  },

  /**
   * setLocationPredictions
   * @type {function}
   * @param state given automatically by framework
   * @param profile updated profile object
   */
  [types.MUTATIONS.SET_LOCATION_PREDICTIONS](state, data) {
    if (!('locationPredictions' in state)) {
      Vue.set(state, 'locationPredictions', []);
    }
    const nv = Object.keys(data).map((k) => {
      const result = data[k];
      result.label = `${result.cityName}, ${result.zipcode}`;
      return result;
    });
    // state.locationPredictions = nv;
    // Vue.set(state, 'locationPredictions', nv);
    state.locationPredictions.splice(0, nv.length, ...nv);
  },

  /**
   * toggleSelected add / remove value from Array in state
   * @type {function}
   * @param state given automatically by framework
   * @param selected updated profile object
   */
  [types.MUTATIONS.TOGGLE_SELECTED](state, selected) {
    const index = state.selected.indexOf(selected.id);
    if (index >= 0) {
      state.selected.splice(index, 1);
    } else {
      state.selected.push(selected.id);
    }
  },

  /**
   * setFavorite
   * @type {function}
   * @param state given automatically by framework
   * @param profile updated profile object
   */
  [types.MUTATIONS.SET_FAVORITE](state, favorite) {
    state.favorite = favorite;
  },

  /**
   * setTrash
   * @type {function}
   * @param state given automatically by framework
   * @param profile updated profile object
   */
  [types.MUTATIONS.SET_TRASH](state, trash) {
    state.trash = trash;
  },
  /**
   * setTrash
   * @type {function}
   * @param state given automatically by framework
   * @param profile updated profile object
   */
  [types.MUTATIONS.UPDATE_JOBSUGGESTION_FORM](state, formValues) {
    if (utils.hasProperty(formValues, 'location')) {
      state.jobSuggestionForm.location = formValues.location;
    }
    if (utils.hasProperty(formValues, 'distance')) {
      state.jobSuggestionForm.distance = formValues.distance;
    }
    if (utils.hasProperty(formValues, 'workTimeType')) {
      state.jobSuggestionForm.workTimeType = formValues.workTimeType;
    }
    if (utils.hasProperty(formValues, 'searchText')) {
      state.jobSuggestionForm.searchText = formValues.searchText;
    }
  },

  [types.MUTATIONS.SET_APPLICATIONSTATUS_BASE](state, data) {
    Vue.set(state.applicationstatus, 'user', data.user);
    const sourcer = Object.assign(state.applicationstatus.sourcer, data.sourcer);
    Vue.set(state.applicationstatus, 'sourcer', sourcer);
  },

  [types.MUTATIONS.SET_APPOINTMENT_DATA](state, data) {
    Vue.set(state.appointment, 'sourcer', data.sourcer);
  },

  [types.MUTATIONS.SET_APPLICATIONSTATUS_MATCHINGJOBOFFERS](state, { manualmatching, applicationStageConstants, user }) {
    let result = [];
    if (manualmatching.length) {
      // remove rejected matches
      // FIXME: param should be not assigned
      const notArchivedMatching = manualmatching.filter((item) => applicationStageConstants.ARCHIVED > item.applicationStage);
      result = formatApplicantStatusMatchings(notArchivedMatching, applicationStageConstants, user);
    }

    Vue.set(state.applicationstatus, 'matchingJobOffers', result);
    Vue.set(state.applicationstatus, 'matchingJobOffersLoaded', true);
  },

  [types.MUTATIONS.SET_APPLICATIONSTATUS_DELETEDMATCHES](state, { deletedMatches, applicationStageConstants, user }) {
    const result = !deletedMatches.length ? [] : formatApplicantStatusMatchings(deletedMatches, applicationStageConstants, user);

    Vue.set(state.applicationstatus, 'deletedMatches', result);
    Vue.set(state.applicationstatus, 'deletedMatchesLoaded', true);
  },

  [types.MUTATIONS.UPDATE_MATCHINGJOBOFFER_LOADING](state, { idx, status }) {
    if (state.applicationstatus.matchingJobOffers[idx]) {
      Vue.set(state.applicationstatus.matchingJobOffers[idx], 'loading', status);
    }
  }
};
