<template>
  <CpButton class="add-element-button" :callback="callback" >
    <span class="plus-circle"></span>
    Neues hinzufügen
  </CpButton>
</template>

<script>
import CpButton from './CpButton';

export default {
  name: 'AddElementButton',
  components: {
    CpButton
  },
  props: {
    callback: {
      type: Function
    },
    color: {
      type: String,
      default: 'blue'
    },
    size: {
      type: String,
      default: 'medium'
    },
    weight: {
      type: String,
      default: 'regular'
    }
  },

  computed: {
    styleClasses() {
      const classes = [];

      if (typeof this.color === 'string') {
        classes.push(this.color);
      }

      if (typeof this.size === 'string') {
        classes.push(this.size);
      }

      if (typeof this.weight === 'string') {
        classes.push(this.weight);
      }

      return classes.join(' ');
    }
  },

  methods: {
    onClick(e) {
      if (typeof this.callback === 'function') {
        const result = this.callback(e);
        if (undefined !== result) {
          return result;
        }
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import './buttons';
@import '../../../scss/atoms/_breakpoints.mixin';

.add-element-button {
  border-radius: 25px;
  width: 290px;
  height: 50px;
}

.plus-circle {
  display: inline-block;
  border: 1px solid $color-white;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  margin-top: -15px;
  line-height: 14px;
  margin-right: 4px;

  &:before {
    content: '+';
  }
}

@include bp-max-medium {
  .add-element-button.cp-btn.medium  {
    line-height: 1;
  }
}

@include bp-max-extra-small {
  .add-element-button {
    width: 260px;
  }
}
</style>
