import axios from 'axios';

const URL = '/api/v2/applicant/joblist/trash'

export default {

  getAll() {
    return new Promise((resolve, reject) => {
      axios.get(`${URL}`)
        .then((response) => {
          // handle success
          resolve(response.data);
        })
        .catch((error) => {
        // handle error
          console.error(error);
          reject(error);
        })
    });
  },

  mark(id) {
    return new Promise((resolve, reject) => {
      axios.put(`${URL}`, { jobId: id })
        .then((response) => {
          // handle success
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  },

  unmark(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${URL}`, { data: { jobId: id } })
        .then((response) => {
          // handle success
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  }
};
