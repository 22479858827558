<template>
  <transition name="fade" :duration="{ leave: 1000 }">
    <div v-if="visible" class="butter-bar boxshadow-basic" :class="styleClasses">
      <slot></slot>
      <button class="close" @click="close"></button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ButterBar',

  props: {
    messageType: {
      type: String,
      default: 'warning'
    },
    closeCallback: {
      type: Function
    }
  },

  data() {
    return {
      visible: true
    };
  },

  computed: {
    styleClasses() {
      const classes = [];

      if (typeof this.messageType === 'string') {
        classes.push(this.messageType);
      }
      return classes.join(' ');
    }
  },

  methods: {
    close() {
      this.visible = false;
      if (this.closeCallback) {
        this.closeCallback();
      }
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/atoms/_colors';

@keyframes rotate {
  0% {
    background-position: 0%;
  }
  50% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}

.butter-bar {
  position: fixed;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 50px;
  line-height: 52px;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  animation: rotate linear 2s;
  animation-iteration-count: infinite;
  color: $color-white;

  &.warning {
    background: $gradient_ci_begin $gradient_ci_horizontal_btn;
    backface-visibility: hidden;
    background-size: 200% 100%;
    transition: background-position 0.3s;

    &:hover:not([disabled]) {
      background-position: right center;
    }
  }

  .close {
    display: block;
    position: absolute;
    right: 14px;
    top: 18px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    overflow: hidden;
    text-indent: -999px;

    &:before,
    &:after {
      display: block;
      width: 1px;
      height: 21px;
      position: absolute;
      background: $color-blacky;
      content: '';
      top: 0;
    }

    &:before {
      left: 0;
      transform: rotate(-45deg);
      transform-origin: right top;
    }

    &:after {
      right: 0;
      transform: rotate(45deg);
      transform-origin: left top;
    }
  }
}
// transition
.fade-enter,
.fade-enter-to,
.fade-leave-active {
  .butter-bar {
    opacity: 0;
    transition: opacity 1s;
  }
}
</style>
