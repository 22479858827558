import Vue from 'vue';
import axios from 'axios';

import api from '../../api/joboffer';

// const moduleName = 'application';
const types = {
  STATE: {
    JOBOFFER: 'jobOffer'
  },
  ACTIONS: {
    LOAD_JOBOFFER: 'loadJobOffer',
    LOAD_SOURCER: 'loadSourcer'
  },
  MUTATIONS: {
    SET_JOBOFFER: 'setJobOffer',
    SET_SOURCER: 'setSourcer'
  }
};

// initial state
const state = {
  [types.STATE.JOBOFFER]: {}
};

const getters = {};

const mutations = {
  /**
   * set company profile into vue store
   * @type {function}
   * @param state given automatically by framework
   * @param payload including profile object
   */
  [types.MUTATIONS.SET_JOBOFFER](vuexState, payload) {
    Vue.set(vuexState, types.STATE.JOBOFFER, payload.jobOffer);
  }
};

const actions = {
  [types.ACTIONS.LOAD_JOBOFFER]({ commit, state: vuexState }, payload) {
    return new Promise((resolve, reject) => {
      if (
        vuexState.jobOffer
        && 'jobOffer' in vuexState.jobOffer
        && vuexState.jobOffer.jobOffer.id === payload.jobOfferId
      ) {
        return resolve(vuexState.jobOffer);
      }
      commit(types.MUTATIONS.SET_JOBOFFER, { jobOffer: {} });
      api
        .loadJobOffer(payload.jobOfferId, payload.locale)
        .then((jobOffer) => {
          commit(types.MUTATIONS.SET_JOBOFFER, { jobOffer });
          return resolve(jobOffer);
        })
        .catch((err) => reject(err));
      return true; // TODO: check what should be written by using arrow function inside of Promise
    });
  },

  [types.ACTIONS.LOAD_SOURCER]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/applicationstatus/appointment/${payload.sourcerId}/${payload.locale}`)
        .then((response) => {
          // handle success
          const sourcer = response.data;
          commit(types.MUTATIONS.SET_SOURCER, { sourcer });
          resolve(sourcer);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  types,
  state,
  getters,
  actions,
  mutations
};
