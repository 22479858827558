import Vue from 'vue';
import types from './types';

export default {
  /**
   * set applicant dosier entity
   * @type {function}
   * @param    {Object} state given automatically by framework
   * @param    {Object} payload
   * @property {Object} dosier applicant dosier object from API
   */
  [types.MUTATIONS.SET_DOSIER_ENTITY](state, { dosier }) {
    Vue.set(state, types.STATE.DOSIER_ENTITY, dosier.entity);
  },

  /**
   * set applicant dosier form
   * @type {function}
   * @param    {Object} state given automatically by framework
   * @param    {Object} payload
   * @property {Object} dosier applicant dosier object from API
   */
  [types.MUTATIONS.SET_DOSIER_FORMS](state, { dosier }) {
    Vue.set(state, types.STATE.DOSIER_FORMS, dosier.form);
  },

  /**
   * set applicant dosier form
   * @type {function}
   * @param    {Object} state given automatically by framework
   * @param    {Object} payload
   * @property {Object} dosier applicant dosier object from API
   */
  [types.MUTATIONS.SET_DOSIER_FRESH](state, { dosier }) {
    Vue.set(state, types.STATE.DOSIER_FRESH, dosier.fresh);
  }
};
