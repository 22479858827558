import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import applicant from './modules/applicant';
import dosier from './modules/dosier';
import user from './modules/user';
import translations from './modules/translations';
import application from './modules/application';
import company from './modules/company';
import joboffer from './modules/joboffer';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    translations,
    application,
    applicant,
    dosier,
    company,
    joboffer,
    user
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
});
