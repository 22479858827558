import CarrerBoardRoutes from './applicant/careerboard';
import StatusRoutes from './applicant/status';
import MyAccountRoutes from './applicant/myaccount';
import CareerBoard from '../components/templates/Applicant/CareerBoard';
import ApplicantProfile from '../components/templates/ApplicantProfile/ApplicantProfile';
import Status from '../components/templates/Applicant/Status';
import MyAccount from '../components/templates/Applicant/MyAccount';

export default {
  mode: 'history',
  base: `${window.translations.locale}/applicant/app`,
  routes: [
    {
      path: '/',
      redirect: '/careerboard'
    },
    {
      path: '/careerboard',
      component: CareerBoard,
      children: CarrerBoardRoutes
    },
    {
      path: '/dosier/',
      name: 'ApplicantProfile',
      component: ApplicantProfile
    },
    {
      path: '/status',
      name: 'StatusHome',
      component: Status,
      children: StatusRoutes
    },
    {
      path: '/settings',
      name: 'MyAccount',
      component: MyAccount,
      children: MyAccountRoutes
    }
  ]
};
