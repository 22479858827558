<template>
  <div class="animated-label-passwordfield">
    <label>
      <span :class="{'slot' : true, 'blank' : blank, 'required': required}" >
        <slot></slot>
      </span>
      <Password
        :id="name"
        :name="name"
        :type="type"
        :class="{ 'blank' : blank }"
        :placeholder="placeholder"
        :secureLength="secureLength"
        :toggle="toggle"
        :required="required"
        v-model="bufferedValue"
        @score="(s) => $emit('score', s)"
        @feedback="(f) => $emit('feedback', f)"
        @blur="onBlur"
        @input="updateValue"
        @focus="onFocus"
      />
    </label>
  </div>
</template>

<script>
import Password from 'vue-password-strength-meter';

export default {
  name: 'AnimatedLabelPasswordField',
  components: {
    Password
  },
  props: {
    name: {
      type: String
    },
    type: {
      type: String
    },
    placeholder: {
      type: String
    },
    secureLength: {
      type: Number
    },
    toggle: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    validate: {
      type: Function
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      focused: false,
      bufferedValue: this.value
    }
  },
  computed: {
    blank() {
      return !this.focused && this.bufferedValue.length === 0;
    }
  },
  methods: {
    updateValue(e) {
      this.$emit('input', e);
      this.$emit('change', e);
      if (this.validate) {
        const valid = this.validate(e);
        if (!valid) {
          return false;
        }
      }
      return true;
    },
    onFocus() {
      this.focused = true;
      this.$emit('focus');
    },
    onBlur() {
      this.focused = false;
      this.$emit('blur');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';

$color-placeholder: $color-silvergrey;

.animated-label-passwordfield {
  margin-bottom: 10px;
  position: relative;

  &.no-indicator {
    .Password {
      background-color: transparent !important;

      /deep/ {
        .Password__badge {
          display: none;
        }
        .Password__strength-meter {
          display: none;
        }
        .Password__field {
          height: 50px;
          border: none !important;
        }
      }
    }
  }

  &.error /deep/ {
    input[type='email'],
    input[type='tel'],
    input[type='url'],
    input[type='number'],
    input[type='password'],
    input[type='text'] {
      border: 3px solid $color-red;

      &.blank {
        border: 3px solid $color-red;
      }
    }
  }

  label {
    position: relative;
    display: block;
  }

  span.slot {
    position: absolute;
    display: block;
    font-style: italic;
    font-size: 12px;
    line-height: 1.5em;
    z-index: 1;
    transition: top 0.5s, left  0.5s, font-size 0.5s, color 0.5s;
    top: 1px;
    left: 4px;

    &.blank {
      font-size: 17px;
      top: 15px;
      left: 15px;
      color: $color-dustygrey;
      transition: top 0.5s, left  0.5s, font-size 0.5s, color 0.5s;
    }

    &.required:after {
      content: '*';
    }
  }

  input[type='email'],
  input[type='tel'],
  input[type='url'],
  input[type='number'],
  input[type='password'],
  input[type='text'] {
    top: 18px;
    display: block;
    background-color: $color-silvergrey;
    border: none;
    padding-left: 15px;
    line-height: 50px;
    font-size: 17px;
    width: 100%;
    transition: background-color 0.5s;

    &.blank {
      font-weight: 400 !important;
      font-style: italic;
      background-color: transparent !important;
      border: 1px solid #aaaaaa;
      transition: background-color 0.5s;

      &:-ms-input-placeholder {
        color: $color-placeholder;
      }

      &::-ms-input-placeholder {
        color: $color-placeholder;
      }

      &::-webkit-input-placeholder {
        color: $color-placeholder;
      }

      &::placeholder {
        color: $color-placeholder;
      }

      &:after {
        content: '';
        height: 54px;
        position: relative;
      }
    }
  }
  /*
  i {
    display: flex;
    position: absolute;
    // top: 1.4em;
    z-index: 1;
    left: 5px;
    top: 50%;
    padding-top: 18px;
    transform: translateY(-50%);
  }

  i ~ input[type='email'],
  i ~ input[type='tel'],
  i ~ input[type='url'],
  i ~ input[type='number'],
  i ~ input[type='password'],
  i ~ input[type='text'] {
    padding-left: 40px;
  }
  */

  .Password {
    max-width: none;
    background-color: $color-silvergrey;

    &.blank {
      background-color: transparent !important;
    }

    /deep/ {
      .Password__strength-meter {
        margin-top: 2px;
        margin-bottom: 8px;
      }

      .Password__strength-meter--fill[data-score='3'] {
        background: $color-deepgreen !important;
      }
    }
  }
}
</style>
