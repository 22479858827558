<template>
  <CpButton class="cancel-button" :callback="callback" color="transparent">
    <span class="close-x"></span>
    Abbrechen
  </CpButton>
</template>

<script>
import CpButton from './CpButton';

export default {
  name: 'CancelButton',
  components: {
    CpButton
  },
  props: {
    callback: {
      type: Function
    },
    color: {
      type: String,
      default: 'transpalent'
    },
    size: {
      type: String,
      default: 'medium'
    },
    weight: {
      type: String,
      default: 'regular'
    }
  },

  computed: {
    styleClasses() {
      const classes = [];

      if (typeof this.color === 'string') {
        classes.push(this.color);
      }

      if (typeof this.size === 'string') {
        classes.push(this.size);
      }

      if (typeof this.weight === 'string') {
        classes.push(this.weight);
      }

      return classes.join(' ');
    }
  },

  methods: {
    onClick(e) {
      if (typeof this.callback === 'function') {
        const result = this.callback(e);
        if (undefined !== result) {
          return result;
        }
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import './buttons';

.close-x {
  display: inline-block;
  position: relative;
  left: 0;
  top: 3px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  overflow: hidden;
  text-indent: -999px;
  margin-right: 4px;

  &:before {
    display: block;
    width: 1px;
    height: 21px;
    position: absolute;
    background: #3c3c3c;
    transform: rotate(-45deg);
    transform-origin: left top;
    content: '';
    top: 0;
    left: 0;
  }

  &:after {
    display: block;
    width: 1px;
    height: 21px;
    position: absolute;
    background: #3c3c3c;
    transform: rotate(45deg);
    transform-origin: left top;
    content: '';
    top: 0;
    right: 0;
  }
}
</style>
