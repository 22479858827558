import EmployerContact from '../components/templates/EmployerContact';

export default {
  routes: [
    {
      path: '/contact',
      name: 'EmployerContact',
      component: EmployerContact
    }
  ]
};
