<template>
  <div class="wizard-progress-status">
    <ul class="wizard-progress-status-index clearfix">
      <li ref="0" :class="{'active': current == 0, 'passed': current > 0}">
        <Editable resource_key="wizard-progress.status1" />
        <div class="point"></div>
      </li>
      <li ref="1" :class="{'active': current == 1, 'passed': current > 1}">
        <Editable resource_key="wizard-progress.status2" />
        <div class="point"></div>
      </li>
      <li ref="2" :class="{'active': current == 2}">
        <Editable resource_key="wizard-progress.status3" />
        <div class="point"></div>
      </li>
    </ul>
    <div class="progress-bar">
      <transition name="expanded">
        <span ref="active_line" class="active-line" :style="{width: (27.99 + (21.99 * this.current)) + '%'}"></span>
      </transition>
    </div>
  </div>
</template>

<script>
// import { ResizeObserver } from 'vue-resize';
import Editable from '../molecules/Editable';

export default {
  name: 'WizardProgressStatus',
  components: {
    // ResizeObserver,
    Editable
  },
  props: {
    current: {
      type: Number,
      required: true
    }
  },
  computed: {
    active_line_style() {
      if (this.$refs) {
        return {
          width: '200px'
        };
      }
      return this.active_menu_item();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/_breakpoints.mixin';

.wizard-progress-status-index {
  display: block;
  margin: 0 auto;
  width: 66%;
}

.wizard-progress-status-index li {
  display: block;
  float: left;
  width: 33.33%;
  text-align: center;
  font-size: 14px;
  position: relative;
  color: $color-dustygrey;
  padding: 40px 0 15px;
  line-height: 1;

  &.active {
    font-size: 15px;
    font-weight: 900;
    padding-top: 32px;

    .point {
      background: transparent $gradient-blue;
      width: 30px;
      height: 30px;
      padding: 9px;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
      top: 54px;
    }
  }

  &.passed {
    .point {
      background-color: $color_ci;
    }
  }

  &.passed,
  &.active {
    color: $color_ci;
  }

  .point {
    position: absolute;
    width: 10px;
    height: 10px;
    border: 0 none;
    padding: 2px;
    border-radius: 100%;
    top: 64px;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    background-color: $color-dustygrey;
    transition: all 0.2s;

    &:before {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      background-color: $color-white;
      border-radius: 100%;
    }
  }
}

.progress-bar {
  width: 100%;
  border-top: 1px dashed $color-dustygrey;
}

.active-line {
  position: absolute;
  display: block;
  height: 3px;
  background: $gradient-blue-horizontal;
  margin-top: -2px;
  z-index: 1;
  transition: width 0.5s;
}

.moved-enter {
  transition: left 0.5s;
}

.expanded-enter {
  transition: width 0.5s;
}

@include bp-max-medium() {
  .wizard-progress-status-index {
    height: 69px;

    li:not(.active) > div:not(.point) {
      display: none;
    }

    li:nth-child(2) > div /deep/ span {
      margin-left: -20px;
    }

    li:nth-child(3) > div /deep/ span {
      margin-left: -40px;
    }
  }
}

@include bp-max-smaller() {
  .wizard-progress-status-index {
    width: 55%;

    li:nth-child(3) > div /deep/ span {
      margin-left: -80px;
    }
  }
}
</style>
