<template>
  <div id="login-popup">
    <div class="mobile-login-close desktop-hidden" ref="closer">
      <button @click="closeLoginOverlay" class="close-btn"></button>
    </div>

    <div class="login-popup-inner">
      <div class="login-popup-main">
        <h2 class="login-popup-title">Willkommen zurück!</h2>
        <h3 class="login-popup-title">Login mit E-Mail-Adresse</h3>
        <div class="login-form-wrapper">
          <form
            ref="form"
            class="login-form"
            :action="`/${$careerpeakr.locale}/login_check`"
            method="post"
            @submit.prevent="onSubmit"
          >
            <div class="form-row">
              <input
                name="_username"
                type="text"
                value
                placeholder="E-Mail eingeben"
                class="cp-default-input narrow"
              >
            </div>
            <div class="form-row">
              <!-- not to show the * mark i changed require="false"-->
              <AnimatedLabelPasswordField
                name="_password"
                type="password"
                :class="{ 'error': password.hasError }"
                :secureLength="8"
                :toggle="true"
                :required="false"
                v-model="password.value"
                :placeholder="password.placeholder"/>
            </div>
            <div class="form-row">
              <CpButton
                color="blue"
                size="medium-large full"
                weight="bold"
                type="submit"
              >Jetzt einloggen</CpButton>
            </div>
          </form>
        </div>
      </div>
      <div class="login-popup-row" v-if="isApplicantLanding">
        <h3 class="login-popup-title">Login mit sozialem Netzwerk</h3>
        <ul class="login-popup-footer-buttons">
          <li class="login-popup-footer-button-item">
            <SocialButton channel="xing" size="medium" :callback="loginXing">Mit Xing einloggen</SocialButton>
          </li>
          <li class="login-popup-footer-button-item">
            <SocialButton channel="linkedin" size="medium" :callback="loginLinkedin">Mit LinkedIn einloggen</SocialButton>
          </li>
        </ul>
      </div>
      <div class="login-popup-footer">
        <h3>Noch keinen Account?</h3>
        <span v-if="isCommonLanding">
          <p>
            <a
              :href="`/${$careerpeakr.locale}/forcandidates#/cv-upload`"
              class="simple-arrow-link"
            >
              <span class="login-popup-span">Für Kandidaten </span>
            </a>
          </p>
          <p>
            <router-link
              to="/contact"
              class="simple-arrow-link"
              v-on:click.native="closeCallback"
            >
              Für Arbeitgeber
            </router-link>
          </p>

        </span>
        <p v-if="isApplicantLanding">
          <router-link
            to="/cv-upload"
            class="simple-arrow-link"
            v-on:click.native="closeCallback"
          >
            <span class="login-popup-span">Jetzt kostenlos registrieren </span>
          </router-link>
        </p>
        <p v-if="isEmployerLanding">
          <router-link
            to="/contact"
            class="simple-arrow-link"
            v-on:click.native="closeCallback"
          >
            Jetzt Kontakt aufnehmen
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CpButton, { SocialButton } from '../atoms/CpButton';
import AnimatedLabelPasswordField from '../molecules/AnimatedLabelPasswordField'

export default {
  name: 'LoginPopup',
  data() {
    return {
      password: {
        value: '',
        hasError: false,
        placeholder: 'Passwort eingeben'
      }
    }
  },
  components: {
    CpButton,
    SocialButton,
    AnimatedLabelPasswordField
  },

  props: {
    closeFunction: {
      type: Function
    },
    /** callback for mobile menu overlay */
    overlayCloseCallback: {
      type: Function,
      default: null
    },
    mode: {
      type: String,
      default: '',
      validator(val) {
        return val === ''
          || val === 'applicant'
          || val === 'employer'
          || val === 'common'
      }
    }
  },

  computed: {
    isApplicantLanding() {
      return this.mode === 'applicant';
    },
    isEmployerLanding() {
      // FIXME; quick hack... consider also for storybook.
      return this.mode === 'employer';
      // return window.location.href.match(/foremployers/g) != null;
    },
    isCommonLanding() {
      // FIXME; quick hack... consider also for storybook.
      return this.mode === 'common';
    }
  },

  methods: {
    onSubmit() {
      const request = document.createElement('input');
      request.type = 'hidden';
      request.name = '_csrf_token';
      request.value = window.security.csrf_token;
      this.$refs.form.appendChild(request);
      this.$refs.form.submit();
    },

    closeCallback(e) {
      if (typeof this.closeFunction === 'function') {
        if (this.overlayCloseCallback) {
          this.overlayCloseCallback(e);
        }
        this.closeFunction(e);
      }
    },

    closeLoginOverlay(e) {
      if (typeof this.closeFunction === 'function') {
        this.closeFunction(e);
      }
      if (this.overlayCloseCallback) {
        this.overlayCloseCallback(e);
      }
    },

    loginXing(e) {
      e.preventDefault();
      window.location.href = `/${this.$careerpeakr.locale}/xinghybridlogin`;
      return false;
    },

    loginLinkedin(e) {
      e.preventDefault();
      window.location.href = `/${this.$careerpeakr.locale}/linkedinhybridlogin`;
      return false;
    }
  }

};
</script>

<style lang="scss">
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';

#login-popup {
  display: block;
  position: relative;
  padding: 46px;
  width: 431px;
  background-color: $color-white;

  .simple-arrow-link {
    font-weight: bold;
    color: $color-blacky;
  }

  .animated-label-passwordfield {
    .Password {
      background-color: transparent !important;
    }
    .Password__badge {
      display: none;
    }
    .Password__strength-meter {
      display: none;
    }
    .Password__field {
      height: 50px;
    }
  }
}

.login-popup-title {
  padding: 0 0 14px;
  margin: 0;
  font-weight: 900;
  line-height: 1;
}

.login-popup-footer {
  font-size: 17px;
  line-height: 1.58;
  margin: 36px 0 0;

  a {
    text-decoration: none;
  }

  h3 {
    margin-bottom: 14px;
    font-weight: 900;
  }

  p {
    margin: 0;
  }

  > p > a,
  .login-popup-span {
    font-weight: bold;
  }
}

.login-popup-row {
  margin-top: 36px;
}

.login-popup-footer-buttons {
  padding: 0;
  margin: 0;
  list-style: none;

  .login-popup-footer-button-item {
    text-align: center;

    /* from here
     * text-content rendered wrong icons and distance
     */
    padding-left: 0;

    &:before {
      content: '';
      margin-left: 0;
      margin-right: 0;
    }
    /* until here */
  }
}

.login-popup-footer-button-item + .login-popup-footer-button-item {
  margin-top: 1px;
}

@include bp-max($bp-medium-small) {
  .login-button-wrapper #login-popup {
    position: fixed;
    width: 100%;
    padding: var(--padding-edge);
  }

  .login-popup-title{
    text-align: center;
    line-height: 25px;
  }
}

.mobile-login-close {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  z-index: 1;

  .close-btn {
    display: block;
    position: absolute;
    left: 20px;
    top: 20px;
    cursor: pointer;
    width: 32px;
    height: 32px;

    &:before,
    &:after {
      display: block;
      width: 3px;
      height: 41px;
      position: absolute;
      background: $color-blacky;
      content: '';
      top: 0;
    }

    &:before {
      left: 0;
      transform: rotate(-45deg);
      transform-origin: right top;
    }

    &:after {
      right: 0;
      transform: rotate(45deg);
      transform-origin: left top;
    }
  }
}

@include bp-medium() {
  .desktop-hidden {
    display: none;
  }
}

@include bp-max-medium() {
  .login-popup-inner {
    padding-top: 50px;

    .login-popup-row {
      text-align: left;

      .login-popup-span {
        border-bottom: 1px solid #d8d8d8;

        &:last-of-type {
          font-weight: bold;
        }
      }
    }
  }

  #login-popup {
    .mobile-login-close {
      .close-btn {
        width: 16px;
        height: 16px;

        &:before, &:after {
          height: 18px;
        }
      }
    }
  }
}
</style>
