<template>
  <div class="listing-tab-child">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ListingTabChild',
  props: {
    title: String
  }
}
</script>
