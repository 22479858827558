export default {
  profile: null,
  jobList: [],
  jobListDetail: [],
  selected: [],
  locationPredictions: [],
  favorite: [],
  trash: [],
  jobSuggestionForm: {
    location: '',
    distance: '',
    workTimeType: '',
    searchText: ''
  },
  charts: {
    competenceProfile: { points: [] },
    marketValue: 0,
    futureOverview: [],
    jobPerformance: [],
    cvPerformance: [],
    demandCompetence: [],
    optimizationChance: [],
    profileID: ''
  },
  optimizedCharts: {
    competenceProfile: { points: [] },
    marketValue: 0,
    futureOverview: [],
    jobPerformance: [],
    cvPerformance: [],
    demandCompetence: [],
    optimizationChance: [],
    profileID: ''
  },
  applicationstatus: {
    user: {
      name: '',
      email: '',
      tel: ''
    },
    sourcer: {
      id: 0,
      name: '',
      email: '',
      phone: '',
      image: ''
    },
    matchingJobOffers: false,
    matchingJobOffersLoaded: false,
    deletedMatches: false,
    deletedMatchesLoaded: false
  },
  // jobOffer: {},
  appointment: {
    sourcer: {
      id: 0,
      name: '',
      email: '',
      phone: '',
      image: '',
      availableAppointments: []
    }
  }
};
