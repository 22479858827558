import Vue from 'vue';

import api from '../../api/company';

// const moduleName = 'application';
const types = {
  ACTIONS: {
    LOAD_PROFILE: 'loadProfile'
  },
  MUTATIONS: {
    SET_PROFILE: 'setProfile'
  }
};

// initial state
// shape: [{ id, quantity }]
const state = {
  profile: null
};

const getters = {};

const mutations = {
  /**
   * set company profile into vue store
   * @type {function}
   * @param state given automatically by framework
   * @param payload including profile object
   */
  [types.MUTATIONS.SET_PROFILE](vuexState, payload) {
    Vue.set(vuexState, 'profile', payload.profile);
  }
};

const actions = {
  [types.ACTIONS.LOAD_PROFILE]({ commit, state: vuexState }, payload) {
    return new Promise((resolve, reject) => {
      if (vuexState.profile && vuexState.profile.companyProfileId === payload.companyId) {
        return resolve(vuexState.profile);
      }
      commit(types.MUTATIONS.SET_PROFILE, { profile: null });
      api
        .loadProfile(payload.companyId, payload.locale)
        .then((profile) => {
          commit(types.MUTATIONS.SET_PROFILE, { profile });
          resolve(profile);
          return profile;
        })
        .catch((err) => {
          reject(err);
          return false;
        });
      return true;
    });
  }
};

export default {
  namespaced: true,
  types,
  state,
  getters,
  actions,
  mutations
};
