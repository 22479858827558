<template>
  <div class="server-error-toast-message">
    <ButterBar :closeCallback="closeCallback" messageType="">
      <img src="/assets/img/icon-server-error.svg" />
      <p>
        <b>
          Ein Serverfehler ist aufgetreten.
        </b><br/>
        Bitte versuchen Sie es später noch einmal.
      </p>
    </ButterBar>

  </div>
</template>

<script>
import ButterBar from './ButterBar';

export default {
  name: 'ServerErrorMessage',
  components: {
    ButterBar
  },

  data() {
    return {
      visible: true
    };
  },

  props: {
    closeCallback: {
      type: Function
    }
  },

  computed: {
    styleClasses() {
      const classes = [];

      if (typeof this.messageType === 'string') {
        classes.push(this.messageType);
      }
      return classes.join(' ');
    }
  },

  methods: {
    close() {
      this.visible = false;
      if (this.closeCallback) {
        this.closeCallback();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';

.server-error-toast-message {
  /deep/ .butter-bar {
    display: flex;
    background-color: $color-white;

    img,
    p {
      display: inline-block;
    }

    p {
      color: $color-blacky;
      line-height: 1.46em;
      margin-left: 20px;
      padding-left: 20px;
      border-left: 1px solid $color-blacky;
    }
  }
}
</style>
