<template>
  <div :class="{'applicant-profile' : true, 'fresh': isFresh}" key="1">
    <transition-group name="fade" tag="div">
      <div v-if="ready" key="c1">
        <SocialMediaImport v-if="isFresh"/>
        <div v-if="isAdministrator">
          <a :href="backURL">&laquo; zurück zum {{ backPage }}</a>
        </div>
        <div class="header-wrapper">
          <h2 class="between-header">{{ pageTitle }}</h2>
        </div>
        <div class="applicant-profile-inner boxshadow-basic" v-if="!blank">
          <PersonalInfo :fresh="isFresh" :values="entity" :form="forms" :callbackOnSave="onSave" @change="onChange" />
          <FurtherSkills :values="entity" :form="forms" :callbackOnSave="onSave" @change="onChange" v-if="hasTelephoneInterview" />
          <Experiences :fresh="isFresh" :values="entity" :form="forms" :callbackOnSave="onSave" @change="onChange" />
          <Educations :fresh="isFresh" :values="entity" :form="forms" :callbackOnSave="onSave" @change="onChange" />
          <Skills :fresh="isFresh" :values="entity" :form="forms" :callbackOnSave="onSave" @change="onChange" />
          <OnboardingInfo :values="entity" :form="forms" :callbackOnSave="onSave" @change="onChange" v-if="hasTelephoneInterview" />
          <Documents :fresh="isFresh" :values="entity" :form="forms" :callbackOnSave="onSave" @change="onChange" />
          <!-- AdditionalInfo :values="entity" :form="forms / -->
        </div>
      </div>
      <div v-if="!ready" class="loading" key="c2">
        <CpLoading />
      </div>
    </transition-group>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import CpLoading from '../../atoms/CpLoading';
import PersonalInfo from './PersonalInfo';
import SocialMediaImport from './SocialMediaImport';
import FurtherSkills from './FurtherSkills';
import Experiences from './Experiences';
import Educations from './Educations';
import Skills from './Skills';
import Documents from './Documents';
import OnboardingInfo from './OnboardingInfo';
// import AdditionalInfo from './AdditionalInfo'
import ApplicantProfileStore from '../../../store/modules/dosier';
import converter from '../../../lib/converter/dosier'

const { mapState: mapDosierState, mapActions: mapDosierActions } = createNamespacedHelpers('dosier');
const { mapState: mapApplicantState } = createNamespacedHelpers('applicant');
const { types } = ApplicantProfileStore;

export default {
  name: 'ApplicantProfile',

  components: {
    CpLoading,
    PersonalInfo,
    SocialMediaImport,
    FurtherSkills,
    Experiences,
    Educations,
    Skills,
    Documents,
    OnboardingInfo
    // AdditionalInfo
  },

  props: {
    applicantId: {
      type: Number
    }
  },

  data() {
    return {
      id: ''
    }
  },

  computed: {
    ...mapApplicantState({
      userId(state) {
        return state.profile ? state.profile.id : '';
      }
    }),

    ...mapDosierState([
      types.STATE.DOSIER_ENTITY,
      types.STATE.DOSIER_FORMS,
      types.STATE.DOSIER_FRESH
    ]),

    ready() {
      return (this.entity !== null);
    },
    blank() {
      return (this.entity !== null && Object.keys(this.entity).length === 0);
    },
    isFresh() {
      return (!this.entity) || (this.fresh);
    },
    hasTelephoneInterview() {
      return (this.ready) && 'telephoneInterview' in this.entity;
    },
    pageTitle() {
      if (this.isAdministrator
        && 'application' in window
        && 'jobOfferName' in window.application
        && window.application.jobOfferName.length > 0) {
        return `Bewerber für Stelle: ${window.application.jobOfferName}`;
      }
      return (this.isAdministrator) ? '' : 'So sehen Arbeitgeber Dein Profil nach Deiner Datenfreigabe';
    },
    isAdministrator() {
      return ('application' in window && 'role' in window.application);
    },
    backPage() {
      if ('application' in window && 'role' in window.application) {
        return (window.application.role === 'EMPLOYER')
          ? 'Sourcingstatus'
          : 'Prozess-Übersicht';
      }
      return 'Dashboard';
    },
    backURL() {
      if ('application' in window && 'role' in window.application) {
        return (window.application.role === 'EMPLOYER')
          ? `/${this.$careerpeakr.locale}/joboffer/status`
          : 'javascript:history.back()'; // eslint-disable-line
      }
      return `/${this.$careerpeakr.locale}/dashoboard`
    }
  },

  created() {
    if (this.userId === '' && (!('application' in window) || !('role' in window.application))) {
      this.$store.dispatch('applicant/getProfile');
    }
  },

  mounted() {
    const self = this;
    const id = this.applicantId || this.userId;
    if (id !== '') {
      this.id = id;
      this.loadDosier({ id }).then(() => {
        self.$emit('loaded', true);
      });
    }
  },

  watch: {
    userId(newId, oldId) {
      const self = this;
      if (oldId === '' && newId !== '') {
        this.id = newId;
        this.loadDosier({ id: newId }).then(() => {
          self.$emit('loaded', true);
        });
      }
    }
  },

  methods: {
    ...mapDosierActions({
      loadDosier: types.ACTIONS.LOAD_DOSIER,
      updateDosier: types.ACTIONS.UPDATE_DOSIER
    }),
    onSave(value) {
      this.$emit('save', value);
      return this.updateDosier({
        id: this.id,
        entity: converter.convertForUpdate(value, this.forms)
      });
    },
    onChange(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/colors';
@import '../../../scss/atoms/_breakpoints.mixin';

.applicant-profile {
  position: relative;
  margin: 0 auto;
  //max-width: 1440px;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  /deep/ h2 {
    font-weight: 800;
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 28px;
  }

  .header-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden; // quick hack to show margins

    .between-header {
      font-weight: 400;
      margin-top: 65px;
      margin-bottom: 40px;

      @include bp-max-medium() {
        font-size: 15px;
        padding: 0 30px;
        margin-bottom: 20px;
        margin-top: 50px;
      }
    }
  }

  .loading {
    width: 100vw;
    height: 100vh;
  }
}

.applicant-profile-inner {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  // margin-top: 80px;
  margin-bottom: 80px;
  background-color: #feffff;

  // .fresh & {
  //   margin-top: 0;
  // }
}

.fade-leave-active {
  opacity: 0;
  transition: opacity 1s;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1s;
}

</style>
