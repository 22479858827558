<template>
  <section class="onboarding-info">
    <div class="wrapper">
      <h2><Translation resource_key="applicant.profile.onboading.title" /></h2>
      <EditableArea :values="values" :form="form" :callbackSave="onSave" :callbackCancel="onCancel" >
        <OnboardingInfoDisplay slot="display" :values="values" />
        <OnboardingInfoEdit slot="edit" :values="bufferedValue" :form="form" :callbackChange="onChange" />
      </EditableArea>
    </div>
  </section>
</template>

<script>
import _ from 'lodash';
import Translation from '../../atoms/Translation';
import EditableArea from '../../molecules/EditableArea';
import OnboardingInfoDisplay from './OnboardingInfoDisplay';
import OnboardingInfoEdit from './OnboardingInfoEdit';

export default {
  name: 'OnboardingInfo',
  components: {
    Translation,
    EditableArea,
    OnboardingInfoDisplay,
    OnboardingInfoEdit
  },
  data() {
    return {
      bufferedValue: ''
    };
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    callbackOnSave: {
      type: Function,
      required: true
    }
  },

  created() {
    this.setOriginalValue();
  },

  methods: {
    onSave() {
      const self = this;
      return new Promise((resolve, _reject) => {
        const result = _.cloneDeep(self.values);
        result.telephoneInterview = self.bufferedValue;
        self.callbackOnSave(result).then(() => {
          self.setOriginalValue();
          resolve()
        });
      });
    },
    onCancel() {
      this.setOriginalValue();
    },
    onChange(values) {
      this.bufferedValue = values;
      // this.$emit('change', values);
    },
    setOriginalValue() {
      // make object from array
      this.bufferedValue = { ...this.values.telephoneInterview };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_breakpoints.mixin';

.onboarding-info {
  background-color: $color-blue-ultralight;
  position: relative;
  padding: 60px 0;

  .wrapper {
    max-width: 840px;
    width: 100%;
    margin: 0 auto;

    .editable-area {
      position: static;
    }
  }
}

@include bp-max-medium() {
  .onboarding-info {
    padding: 40px 15px;
    word-wrap: break-word;

    .wrapper {
      ul {
        li {
          &:first-child {
            border-top: 1px solid #807676;
          }
        }
      }
    }
  }
}
</style>
