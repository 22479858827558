<template>
  <div class="cp-footer-nav">
    <h3 class="cp-footer-logo">
      <CpLogo />
    </h3>
    <FooterMenu />
  </div>
</template>

<script>
import CpLogo from '../atoms/CpLogo';
import FooterMenu from '../molecules/FooterMenu';

export default {
  name: 'FooterNav',

  components: {
    CpLogo,
    FooterMenu
  }
};
</script>

<style lang="scss">
@import '../../scss/atoms/_colors';
@import '../../scss/organisms/footer_nav';
</style>
