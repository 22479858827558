<template>
  <div class="job-offer-menu">
    <ul>
      <!-- replace with button and define callback for action in this component -->
      <li class="heart" v-if="isCareerboad()">
        <span class="sr-only">
          heart
        </span>
      </li>
      <!-- <li class="print mobile-hidden" @click="print()">
        <span class="sr-only" >
          print
        </span>
      </li> -->
      <li class="trash" v-if="isCareerboad()">
        <span class="sr-only">
          trash
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'JobOfferMenu',

  props: {
    jobOffer: {
      type: Object,
      requreid: true
    }
  },

  methods: {
    isCareerboad() {
      return false;
    },
    print() {
      console.log('print called');
      window.print();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';

.job-offer-menu {
  position: absolute;
  right: 60px;

  ul {
    overflow: hidden;
    li {
      display: inline-block;
      color: $color-dustygrey;
      cursor: pointer;

      &.heart:before {
        content: '\e801';
        font-family: fontello; // stylelint-disable-line
        &.active {
          content: '\e803';
        }
      }

      &.print:before {
        content: '\e81d';
        font-family: fontello; // stylelint-disable-line
      }

      &.trash:before {
        content: '\e804';
        font-family: fontello; // stylelint-disable-line
      }
      .sr-only {
        display: inline-block;
        text-indent: -9999px;
        width: 10px;
      }
    }
  }
}
</style>
