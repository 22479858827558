<template>
  <div v-bind:class="{
    'editable' : editable,
    'edited' : edited,
    'no-content' : blank
  }">
    <span
      class='resource-key'
      ref="resource_key"
      v-if="!editing && blank"
      @click="toggleEditable()">
      {{ resource_key }}
    </span>
    <transition name="fade">
      <span
        v-if="!editing"
        ref="label"
        @click="toggleEditable()"
        v-html="content">
      </span>
    </transition>
    <textarea
      v-if="editing"
      ref="textarea"
      :rows="rows"
      @keyup.shift.enter="toggleEditable"
      @blur="toggleEditable"
      v-model="content">
    </textarea>
    <i class="hint" v-if="editing">push shift + enter or unfocus when editing is finished</i>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import translations from '../../../store/modules/translations';

const { types } = translations;
export default {
  name: 'Editable',
  props: {
    resource_key: {
      type: String
    }
  },
  data() {
    return {
      // originalbufferedContent: ,
      bufferedContent: null,
      originalbufferedContent: null,
      // originalbufferedContent: null,
      editing: false,
      edited: false,
      editable: false
    };
  },
  created() {
    const dict = this.$store.getters['translations/dict'];
    if (dict) {
      this.originalbufferedContent = dict[this.resource_key];
    }
    this.bufferedContent = this.originalbufferedContent;
    this.editable = false; // FIXME depends on user permisson
  },
  computed: {
    rows() {
      if (this.value) {
        const num = this.value.split('\n').length;
        return num > 4 ? num : 4;
      }
      return 4;
    },
    blank() {
      return !(this.content) || this.content.length === 0;
    },
    ...mapState({
      content(state) {
        // console.log(state);
        // debugger;
        if (state.translations.dict) {
          if (this.edited) {
            // this.content = state.translations.dict[this.resource_key];
            return this.bufferedContent;
          }
          // console.log('content update');
          this.$forceUpdate();
          this.bufferedContent = state.translations.dict[this.resource_key];
          return state.translations.dict[this.resource_key];
        }
        return null;
      }
    })
  },
  methods: {
    toggleEditable(_event) {
      if (this.editable) {
        this.editing = !this.editing;
        this.toggleContent();
      }
    },
    toggleContent() {
      if (this.editing) {
        this.bufferedContent = this.blank ? '' : this.content.replace(/<br\/>/g, '\n');
        const displayedDom = this.blank ? this.$refs.resource_key : this.$refs.label;
        const width = displayedDom.offsetWidth;
        const height = displayedDom.offsetHeight;
        this.$nextTick(() => {
          this.updateTextAreaStyle(width, height);
        });
      } else {
        this.bufferedContent = this.content.trim().replace(/\n/g, '<br/>');
        if (this.bufferedContent.length > 0
            && this.bufferedContent !== this.originalbufferedContent) {
          this.edited = true;
          const payload = {};
          payload[this.resource_key] = this.bufferedContent;
          this.$store.commit(`translations/${types.MUTATIONS.stockUpdates}`, payload);
        }
      }
    },
    updateTextAreaStyle(width, height) {
      if (this.$refs.textarea) {
        this.$refs.textarea.style.width = `${width}px`;
        this.$refs.textarea.style.height = `${height}px`;
        this.$refs.textarea.focus();
      } else {
        // can it happens?
        this.$nextTick(this.updateTextAreaStyle(width, height));
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../../scss/atoms/colors';

.edited:before {
  content: '*';
  font-size: 10px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  z-index: -1;
}
.editable {
  position: relative;
}
.hint {
  display: block;
  font-style: italic;
  font-size: 10px;
  color: $color-dustygrey;
}
.editable.no-content {
  border: 2px solid $color-marker-orange;
}
.editable .resource-key {
  color: $color-marker-orange;
  font-style: italic;
  font-size: 0.8em;
}
</style>
