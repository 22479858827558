<template>
  <div class="month-year-select-box">
    <label>
      <slot></slot>
    </label>
    <div class="month-year-select-box-inner">
      <CpSelectBox class="month"
                   :values="months"
                   :value="month"
                   @change="(v) => valueChanged('month', v)"
      />
      <CpSelectBox class="year"
                   :values="years"
                   :value="year"
                   @change="(v) => valueChanged('year', v)"
      />
    </div>
  </div>
</template>

<script>
import { validateCpSelectBoxValues } from '../../lib/property-validators/CpSelectBox_values';
import CpSelectBox from './CpSelectBox';

export default {
  name: 'MonthYearSelectBox',
  components: {
    CpSelectBox
  },
  data() {
    return {
      months: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
      ],
      bufferedValue: null
    };
  },
  props: {
    givenYears: {
      type: Array,
      validator: validateCpSelectBoxValues
    },
    value: {
      type: Date
    },
    placeholder: {
      type: Object,
      validate(value) {
        return typeof value === 'undefined' || 'month' in value || 'year' in value;
      }
    },
    callback: {
      type: Function
    }
  },

  created() {
    this.bufferedValue = this.value;
  },

  computed: {
    years() {
      return this.givenYears
        ? this.givenYears
        : this.range(2000, 2030).map((y) => y.toString()).reverse();
    },
    month() {
      return this.value ? this.months[this.bufferedValue.getMonth()] : null;
    },
    year() {
      // console.log(this.value);
      // if (this.value) {
      //  console.log(this.value.getFullYear());
      // }
      return this.value
        ? this.bufferedValue.getFullYear().toString()
        : new Date().getFullYear().toString();
    },
    monthPlaceholder() {
      return (this.placeholder && 'month' in this.placeholder)
        ? this.placeholder.month : undefined;
    },
    yearPlaceholder() {
      return (this.placeholder && 'month' in this.placeholder)
        ? this.placeholder.month : undefined;
    },
    valueAsDate() {
      return new Date(this.year, this.bufferedValue.getMonth(), 1);
    }
  },

  methods: {
    range(start, end) {
      /* eslint-disable-next-line no-mixed-operators */
      return Array.from(Array(end - start + 1).keys()).map((i) => i + start);
    },
    valueChanged(type, value) {
      if (type === 'month') {
        this.bufferedValue = new Date(this.year, this.months.indexOf(value), 1);
      } else if (type === 'year') {
        this.bufferedValue = new Date(value, this.bufferedValue.getMonth(), 1);
      }
      if (this.callback) {
        this.callback(this.valueAsDate);
      }
      this.$emit('change', this.valueAsDate);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';

.month-year-select-box {
  label {
    font-size: 14px;
    color: $color-dustygrey;
  }
}

.month-year-select-box-inner {
  display: flex;
  justify-content: space-between;

  .month {
    flex: 0 0 calc(66% - 2px);
  }

  .year {
    flex: 0 0 34%;
  }
}
</style>
