<template>
  <div ref="careerboardPage" class="competence-analyse careerboard-page">
    <!-- Competence Profile -->
    <div id="competence-profile-section" class="competence-analyse-section">
      <div class="competence-analyse-section-inner">
        <div class="cp-row">
          <CpHeader>
            <template slot="title">Dein persönlicher Marktwert</template>
            <template slot="taglines">
              Der Marktwert zeigt Dir an, wie gut Du die Anforderungen der von Dir
              ausgewählten Stellen erfüllst. Je höher Dein Marktwert, desto besser
              erfüllst Du die Anforderungen und desto besser sind Deine Bewerbungschancen.
            </template>
          </CpHeader>
        </div>
        <div class="cp-row">
          <div class="competence-analyse-radar">
            <chart-radar-competence :labels="radar.labels" :datasets="radar.datasets"/>
          </div>
        </div>
        <div class="competence-analyse-doughnut">
          <chart-doughnut-market-value
            :values="doughnut.values"
            :background_colors="doughnut.backgroundColors"
            chartWidth="140px"
          />
        </div>
      </div>
    </div>

    <!-- Future Prospect -->
    <div id="future-prospect-section" class="competence-analyse-section" style="display: none;">
      <div class="competence-analyse-section-inner">
        <div class="cp-row">
          <CpHeader>
            <template slot="title">Deine beruflichen Zukunftsaussichten</template>
            <template slot="taglines">
              Hier siehst Du, wie stark Deine Fähigkeiten heute und in Zukunft gesucht werden.
            </template>
          </CpHeader>
        </div>
        <ul class="cp-row clearfix">
          <li class="columns-3-item" v-for="(prospect, idx) in futureProspects" :key="idx">
            <chart-future-prospect :prospects="prospect.datasets">
              {{prospect.caption}}
            </chart-future-prospect>
          </li>
        </ul>
      </div>
    </div>

    <!-- Competence Demand -->
    <div id="competence-demand-section" class="competence-analyse-section" style="display: none;">
      <div class="competence-analyse-section-inner">
        <div class="cp-row">
          <CpHeader>
            <template slot="title">Nachfrage nach Deinen Kompetenzen</template>
            <template slot="taglines">
              Hier siehst Du, wie stark Deine Fähigkeiten und Erfahrungen
              in der Vergangenheit am Arbeitsmarkt gesucht wurden.
            </template>
          </CpHeader>
        </div>
        <div class="cp-row">
          <chart-line-competence-request :labels="line.labels" :datasets="line.datasets">
            <template slot="caption">
              Entwicklung der gesuchten Fähigkeiten und Erfahrungen
            </template>
            <template slot="subline">
              max. 5 Merkmale auswählen
            </template>
          </chart-line-competence-request>
        </div>
      </div>
    </div>

    <!-- Performance -->
    <div id="performance-section" class="competence-analyse-section" style="display: none;">
      <div class="competence-analyse-section-inner">
        <div class="cp-row">
          <CpHeader>
            <template slot="title">Deine Über-/ Unter- Performance</template>
            <template slot="taglines">
              Diese Grafik zeigt Dir, welche Anforderungen Du bezüglich der von Dir
              ausgewählten Stelle/n über- oder untererfüllst.
            </template>
          </CpHeader>
        </div>
        <div class="cp-row">
          <chart-bar-performance :categories="bar.categories" :datasets="bar.datasets"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { scrollToTop } from '../../lib/mixins';
import CpHeader from '../molecules/CpHeader';
import ChartRadarCompetence from '../organisms/ChartRadarCompetence';
import ChartDoughnutMarketValue from '../organisms/ChartDoughnutMarketValue';
import ChartLineCompetenceRequest from '../organisms/ChartLineCompetenceRequest';
import ChartBarPerformance from '../organisms/ChartBarPerformance';
import ChartFutureProspect from '../organisms/ChartFutureProspect';

export default {
  name: 'CompetenceAnalyse',

  components: {
    CpHeader,
    ChartRadarCompetence,
    ChartDoughnutMarketValue,
    ChartLineCompetenceRequest,
    ChartBarPerformance,
    ChartFutureProspect
  },

  mixins: [
    scrollToTop
  ],

  data() {
    return {
      lineChartColors: [
        '#5dff77',
        '#52bfff',
        '#4122ff',
        '#ff4af8',
        '#ffcb3e',
        '#ff3e7a',
        '#c2c2c2',
        '#999999',
        '#ef92b7',
        '#e4065e',
        '#b50ef1',
        '#000fff',
        '#4900bf'
      ]
    };
  },

  computed: mapState({
    doughnut(state) {
      return {
        values: [state.applicant.charts.marketValue],
        backgroundColors: [['#00C6F8', '#0098FF']]
      };
    },

    radar(state) {
      const data = {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: '#0098FF',
            backgroundColor: ['#B6ECFA', '#0098FF']
          }
        ]
      };

      state.applicant.charts.competenceProfile.points.forEach((point) => {
        data.labels.push(point.categoryText);
        data.datasets[0].data.push(point.weight);
      });

      return data;
    },

    line(state) {
      const data = {
        labels: [],
        datasets: []
      };

      state.applicant.charts.demandCompetence.forEach((line, i) => {
        if (i === 0) {
          Object.keys(line.values).map((key) => {
            data.labels.push(key);
            return key;
          });
        }
        const valuesArray = [];

        Object.values(line.values).map((value) => {
          valuesArray.push(value);
          return value;
        })

        data.datasets.push({
          label: line.name,
          data: valuesArray,
          pointBackgroundColor: this.lineChartColors[i]
        });
      });
      return data;
    },

    bar(state) {
      const barColors = [
        ['#999999', '#c2c2c2'],
        ['#e4065e', '#ef92b7'],
        ['#4900bf', '#b50ef1'],
        ['#0098ff', '#00c6f8'],
        ['#42ff5a', '#5ff8e9'],
        ['#fffd5d', '#f8cba0']
      ];

      const data = {
        categories: [],
        datasets: []
      };

      const categoryBuff = [];

      state.applicant.charts.jobPerformance.forEach((item) => {
        if (categoryBuff.indexOf(item.catalogId) === -1) {
          categoryBuff.push(item.catalogId);

          const barColor = barColors[data.categories.length];
          data.categories.push({
            label: item.catalogText,
            color: barColor
          });
        }

        const { catalogId } = item;
        // eslint-disable-next-line max-len
        const cvPerformance = state.applicant.charts.cvPerformance.find((elm) => elm.catalogId === catalogId);

        const value = (typeof cvPerformance === 'undefined') ? -(item.weight) : cvPerformance.weight - item.weight;

        data.datasets.push({
          value,
          categoryIndex: categoryBuff.indexOf(item.catalogId),
          label: item.categoryText
        });
      });

      return data;
    },

    futureProspects(state) {
      const data = [];

      state.applicant.charts.futureOverview.forEach((item) => {
        data.push({
          caption: item.name,
          datasets: [
            {
              value: item.values.today,
              label: 'Heute'
            },
            {
              value: item.values.tomorrow,
              label: 'Morgen'
            }
          ]
        });
      });

      return data;
    }
  }),
  created() {}
};
</script>

<style lang="scss">
@import '../../scss/templates/competence_analyse';

.competence-analyse .taglines {
  line-height: 28px;
}
</style>

<style lang="scss" scoped>
@import '../../scss/atoms/_breakpoints.mixin';

@include bp-max-medium() {
  .competence-analyse-section {
    padding-top: 45px;
    padding-bottom: 55px;
  }

  .competence-analyse .taglines p {
    font-size: 14px;
  }

  #competence-profile-section {
    padding-left: 30px;
    padding-right: 30px;

    .competence-analyse-section-inner {
      display: flex;
      flex-flow: column;

      > .cp-row:nth-child(1) {
        order: 1;

        .header {
          margin-left: 0;
        }
      }

      > .cp-row:nth-child(2) {
        order: 3;

        .competence-analyse-radar {
          margin-left: 0;
        }
      }

      .competence-analyse-doughnut {
        position: relative;
        top: auto;
        left: auto;
        margin-top: 20px;
        order: 2;

        .market-value-doughnut-chart {
          display: flex;
          align-items: center;

          /deep/ {
            .cp-doughnut-chart-inner {
              max-width: 120px;
            }

            .cp-doughnut-chart-text-wrapper {
              min-width: 210px;
              padding-left: 15px;
            }
          }
        }
      }
    }
  }

  #future-prospect-section {
    padding-left: 30px;
    padding-right: 30px;

    .competence-analyse-section-inner > .cp-row.clearfix {
      display: flex;
      flex-flow: column;

      .columns-3-item {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;

        &:first-of-type {
          margin-top: 0;
        }

        .future-prospect /deep/ {
          .future-prospect-inner {
            display: flex;
            justify-content: space-evenly;
          }
        }
      }
    }
  }

  #competence-demand-section {
    .cp-row:first-of-type {
      padding-left: 30px;
      padding-right: 30px;
    }

    .competence-line-chart /deep/ {
      .cp-line-chart-inner {
        margin-right: 30px;
      }
    }
  }

  #performance-section {
    .cp-row:first-of-type {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
</style>
