<template>
  <div class="further-skill-field">
    <i class="fontello icon-check"></i>
    <RemovableTextField
      :value="value"
      :placeholder="placeholder"
      :callbackRemove="callbackRemove"
      @change="onChange"
    />
  </div>
</template>

<script>
import RemovableTextField from '../../molecules/RemovableTextField';

export default {
  name: 'FurtherSkillField',

  components: { RemovableTextField },

  props: {
    value: {
      type: String,
      required: true
    },
    placeholder: {
      type: String
    },
    callbackRemove: {
      type: Function,
      required: true
    }
  },

  methods: {
    onChange(val) {
      this.$emit('change', val);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_breakpoints.mixin';

.further-skill-field {
  width: 100%;
  position: relative;

  @include bp-max-medium(){
    padding: 0;
    width: 100%;
    display: flex;

    .removable-textfield {
      display: inline-block;
      width: 100%;
      margin-bottom: 8px;

      /deep/ input[type='text'] {
        width: calc(95% - 35px) !important;
        border: none;
        padding-left: 38px;
      }
    }

  }

  .icon-check {
    color: $color-blue;
    position: absolute;
    left: 5px;
    top: 10px;
    z-index: 10;
  }

  .removable-textfield {
    display: inline-block;
    width: 100%;
    margin-bottom: 8px;

    /deep/ input[type='text'] {
      width: calc(100% - 35px);
      border: none;
      padding-left: 38px;
    }
  }
}
</style>
