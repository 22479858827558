<template>
  <div class="cp-small-textbox">
    <h4 class="cp-small-textbox-caption">
      <slot name="caption"></slot>
    </h4>
    <h3 class="cp-small-textbox-title">
      <slot name="title"></slot>
    </h3>
    <p class="cp-small-textbox-text">
      <slot name="text"></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'SmallTextbox'
};
</script>

<style lang="scss">
@import '../../scss/molecules/small_textbox';
</style>
