<template>
  <section class="answer-form">
    <SourcerAvatar v-if="sourcerObj" :sourcer="sourcerObj" />
    <div class="form-text">
      <h1>
        <Translation resource_key="joboffer.welcome.titel" />
      </h1>
      <p>
        <Translation resource_key="joboffer.welcome.text" />
      </p>
      <form action="" @submit.prevent="onClick">
        <ul>
          <li>
            <input type="radio" id="interest-yes" value="yes" v-model="hasInterest">
            <label for="interest-yes" :class="{'selected': hasInterest === 'yes'}">
              <Translation resource_key="joboffer.has-interest-yes" />
            </label>
          </li>
          <li>
            <input type="radio" id="interest-no" value="no" v-model="hasInterest">
            <label for="interest-no" :class="{'selected': hasInterest === 'no'}">
              <Translation resource_key="joboffer.has-interest-no" />
            </label>
          </li>
        </ul>
        <transition-group name="fade">
          <template v-if="hasInterest === 'no'">
            <h3 key="2">
              <Translation resource_key="joboffer.why-no-interest-title" />
            </h3>
            <ul key="3">
              <li v-for="(reason, idx) in reasons" :key="idx">
                <input type="radio"
                       :id="`reason-${idx}`"
                       :value="idx"
                       v-model="selectedReason">
                <label :for="`reason-${idx}`" :class="{'selected': selectedReason === idx}">
                  <Translation :resource_key="`joboffer.reason-${idx}`" />
                </label>
              </li>
            </ul>
          </template>
          <CpButton
            color="blue"
            size="large"
            weight="bold"
            key="4"
            :disabled="!hasAppointments()"
          >
            <Translation resource_key="joboffer.has-interest-next" />
          </CpButton>
        </transition-group>
      </form>
    </div>
  </section>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import applicantStore from '../../store/modules/applicant';
import CpButton from '../atoms/CpButton';
import Translation from '../atoms/Translation';
import SourcerAvatar from '../molecules/SourcerAvatar';

const { mapActions } = createNamespacedHelpers('applicant');
const { types } = applicantStore;

export default {
  name: 'AnswerForm',
  components: {
    CpButton,
    Translation,
    SourcerAvatar
  },
  props: {
    sourcer: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      hasInterest: 'yes',
      selectedReason: 0,
      sourcerId: 0
    }
  },
  computed: {
    ...mapState({
      sourcerObj(state) {
        if (this.sourcer !== null) {
          return this.sourcer;
        }

        if (state) {
          // in case of ApplicantStatus
          if (state.applicant && state.applicant.appointment && state.applicant.appointment.sourcer.id !== 0) {
            return state.applicant.appointment.sourcer;
          }
          // in case of JobSuggestion
          if (state.joboffer && state.joboffer.jobOffer) {
            return state.joboffer.jobOffer.sourcer;
          }
        }

        return null;
      },
      jobOffer(state) {
        return (state && state.joboffer && state.joboffer.jobOffer) ? state.joboffer.jobOffer : null;
      }
    }),
    reasons() {
      return Array(6).fill().map((_, i) => i * i);
    }
  },
  watch: {
    sourcerObj(val) {
      if (val && val.id !== 0 && this.sourcerId !== val.id) {
        this.sourcerId = val.id;
        this.getAppointmentData({ sourcerId: val.id, locale: this.$careerpeakr.locale });
      }
    }
  },
  methods: {
    hasAppointments() {
      return this.sourcerObj;
    },
    onClick(e) {
      e.preventDefault();
      if (this.hasInterest === 'yes') {
        this.$router.push({
          name: 'AppointmentForm',
          params: {
            // processTrackerId: this.jobOffer.processTrackerId,
            sourcerId: this.sourcerObj.id,
            jobOfferId: this.jobOffer.id
          }
        });
      } else {
        this.$router.push('reward');
      }
      return false;
    },
    ...mapActions([types.ACTIONS.GET_APPOINTMENT_DATA])
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';
@import '../../scss/atoms/_breakpoints.mixin';

.answer-form {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  font-size: 17px;
  color: $color-white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .form-text {
    max-width: 745px;

    .cp-btn {
      width: 171px;
      height: 60px;
      line-height: 59px;
    }

    form {
      margin-bottom: 1.6em;
    }

    label.selected {
      font-weight: 700;
    }

    ul {
      margin-top: 1.2em;
      margin-bottom: 1.4em;
    }
  }

  h1,
  h2,
  h3,
  em {
    margin: 0;
    font-weight: 800;
  }

  h1,
  h2 {
    font-size: 38px;
  }

  h3 {
    font-size: 21px;
  }

  em {
    font-style: normal;
  }

  a {
    text-decoration: none;
    color: $color-white;
    border-bottom: 1px solid $color-white;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@include bp-max-tablet {
  .answer-form {
    flex-direction: column;

    .sourcer-avatar {
      display: flex;
      flex-direction: row;
      width: 100%;

      /deep/ {
        svg {
          width: 86px;
        }
        .sourcer-name {
          padding-top: 30px;
        }
      }
    }

    form ul li {
      display: flex;
      align-items: baseline;
    }
  }
}
</style>
