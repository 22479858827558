<template>
  <div class="column-2 experience-time" :class="{ 'current' : current }">
    <span :class="{'time' : true, 'current-job' :currentJob }">{{ from }} - {{ till }}</span>
    <div class="point"></div>
    <div class="mobile-border"></div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ExperienceTime',

  data() {
    return {
      form: {},
      bufferedValue: null
    };
  },

  props: {
    value: {
      type: Object,
      required: true
    },
    current: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    currentJob() {
      return this.value.currentJob === 'yes';
    },
    from() {
      return this.moment(this.value.from);
    },
    till() {
      return (this.value.till === 'now' || this.currentJob) ? 'jetzt' : this.moment(this.value.till);
    }
  },

  methods: {
    moment(date) {
      return moment(date).format('MM/YY');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_typographie';
@import '../../../scss/atoms/_breakpoints.mixin';

.experience-time {
  // border-right: 1px solid $color-darkgrey;
  padding: 0 3px 20px 0;
  position: relative;
  display: inline-block;
  width: 155px;
  text-align: right;

  &.current {
    .point {
      background: transparent $gradient-blue;
      width: 20px;
      height: 20px;
      padding: 6px;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
      top: 4px;
      right: -41px;
    }
  }

  span {
    display: inline-block;
    font-size: 17px;
    font-weight: $font-regular;
  }

  .time {
    font-style: italic;
    font-size: 14px;
    line-height: 22px;
    color: $color-blacky;
  }

  .current-job {
    color: $color-blacky;
    font-weight: $font-bold;
  }

  .point {
    position: absolute;
    width: 15px;
    height: 15px;
    border: 0 none;
    padding: 3px;
    border-radius: 100%;
    top: 6px;
    right: -36px;
    z-index: 2;
    transform: translateX(-50%);
    background: transparent $gradient-blue;
    transition: all 0.2s;

    &:before {
      display: block;
      content: '';
      width: 98%;
      height: 98%;
      background-color: $color-white;
      border-radius: 100%;
    }
  }

  .mobile-border {
    display: none;
  }
}

@include bp-max-medium(){
  .experience-time {
    display: block;
    width: 400px;
    top: -30px;
    text-align: left;
    padding-left: 40px;
    border-right: 0;

    .point {
      left: 15px;
    }

    .mobile-border {
      width: 1px;
      height: 35px;
      background-color: #626262;
      position: absolute;
      left: 15px;
      top: 15px;
      display: block;
    }
  }
}
</style>
