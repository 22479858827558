/* eslint no-extend-native: ["error", { "exceptions": ["Array"] }] */
Array.prototype.flush = () => {
  while (this.pop() != null);
};

/**
 * check if object has property
 * @arg obj instance to check
 * @arg prop name of proerty
 */
const hasProperty = (obj, prop) => {
  if (typeof obj === 'undefined' || obj === null) {
    return false;
  }
  const proto = obj.__proto__ || obj.constructor.prototype; // eslint-disable-line no-proto
  return prop in obj && (!(prop in proto) || proto[prop] !== obj[prop]);
};

const mergeDeep = (target, source) => {
  const isObject = (obj) => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  const result = { ...target };

  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      result[key] = targetValue.concat(sourceValue);
    } else if (sourceValue instanceof Date) {
      result[key] = sourceValue;
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      result[key] = mergeDeep({ ...targetValue }, sourceValue);
    } else {
      result[key] = sourceValue;
    }
  });

  return result;
};

const buildObjectFromPath = (path, value) => {
  const result = {};
  const keys = path.split('.').reverse();
  result[keys.shift()] = value;
  return keys.reduce((obj, key) => {
    const ret = {};
    ret[key] = obj;
    return ret;
  }, result);
};

const range = (from, to) => Array.from(Array((to - from) + 1).keys()).map((i) => i + from);

const viewPort = () => {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  const width = w.innerWidth || e.clientWidth || g.clientWidth;
  const height = w.innerHeight || e.clientHeight || g.clientHeight;

  return {
    width,
    height,
    mobile: width < 768, //  === bp-max-medium
    desktop: width >= 768 //  === bp-medium
  };
};

const formatDateHour = (appointment) => {
  const start = new Date(appointment.start);
  const day = start.getDate();
  const month = start.getMonth() + 1;
  const year = start.getFullYear();
  const hours = start.getHours();
  const minutes = `0${start.getMinutes()}`.slice(-2);
  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

const formatApplicantStatusMatchings = (data, statusConstants, user) => data.map((item) => {
  let status = -1;
  if (statusConstants.ARCHIVED <= item.applicationStage) {
    status = -1;
  } else if (statusConstants.OFFERED_CONTRACT <= item.applicationStage) {
    status = 5;
  } else if (statusConstants.INVITED <= item.applicationStage) {
    status = 4;
  } else if (statusConstants.PRESENTED <= item.applicationStage) {
    status = 3;
  } else if (statusConstants.PROFILE_CREATED <= item.applicationStage) {
    if (item.applicationStageFlags.GRANTED_DATA) {
      status = 2;
    } else {
      status = 1;
    }
  } else if (item.applicationStage) {
    status = 0;
  }

  return {
    jobOfferId: item.jobOfferId,
    userId: user,
    title: item.jobOffer.name,
    branches: item.companyProfile.dynaJobsJobSectors,
    employees: item.companyProfile.employeesNumber,
    locations: item.jobOffer.locations.join(', '),
    levels: item.jobOffer.jobLevel,
    begin: item.jobOffer.startDate,
    hours: item.jobOffer.timeMagnitude,
    travel: item.jobOffer.businessTripsMagnitude,
    company: item.companyProfile.address.name,
    companyId: item.companyProfile.id,
    companylogo: `/${item.companyProfile.logo.imagePath.replace('_preview.', '_best.')}`,
    status,
    loading: false,
    processTrackerId: 'processTrackerId' in item ? item.processTrackerId : null,
    sourcerId: 'sourcerId' in item ? item.sourcerId : null,
    appointment: 'appointment' in item ? item.appointment : null
  };
});

export default {
  hasProperty,
  mergeDeep,
  buildObjectFromPath,
  range
};
export { viewPort, formatApplicantStatusMatchings, formatDateHour };
