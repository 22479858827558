<template>
  <div class="wrong-password">
    <h2>Falsches Passwort!</h2>
    <p>Bitte geben Sie erneut Ihre Zugangsdaten ein.</p>
    <div class="login-form-wrapper">
      <form
        ref="form"
        class="login-form"
        :action="`/${$careerpeakr.locale}/login_check`"
        method="post"
        @submit.prevent="onSubmit"
      >
        <div class="form-row clearfix">
          <div class="form-col-2">
            <input
              name="_username"
              type="text"
              v-model="username"
              placeholder="Benutzername (E-Mail) eingeben"
              class="cp-default-input narrow"
            >
          </div>
          <div class="form-col-2">
            <AnimatedLabelPasswordField
              name="_password"
              type="password"
              class="cp-default-input narrow no-indicator"
              :toggle="true"
              :required="false"
              v-model="password.value"
              :placeholder="password.placeholder"
            />
          </div>
        </div>
        <div class="form-footer">
          <CpButton
            color="blue"
            size="large"
            weight="bold"
            type="submit"
          >Anmelden</CpButton>
          <p class="form-note">
            <router-link to="reset">Passwort vergessen?</router-link>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CpButton from '../../atoms/CpButton/CpButton';
import AnimatedLabelPasswordField from '../../molecules/AnimatedLabelPasswordField'

export default {
  name: 'WrongPassword',

  components: {
    AnimatedLabelPasswordField,
    CpButton
  },

  data() {
    return {
      password: {
        value: '',
        hasError: false,
        placeholder: 'Passwort eingeben'
      },
      username: ''
    }
  },

  mounted() {
    this.username = (window.Jobpassion && window.Jobpassion.lastUsername) ? window.Jobpassion.lastUsername : '';
  },

  computed: {
    passwordReminderURL() {
      return window.Jobpassion.paths.passwordReminder;
    }
  },

  methods: {
    onSubmit() {
      const request = document.createElement('input');
      request.type = 'hidden';
      request.name = '_csrf_token';
      request.value = window.security.csrf_token;
      this.$refs.form.appendChild(request);
      this.$refs.form.submit();
    }
  }

}
</script>

<style lang="scss" scoped>
.login-form-wrapper {
  margin-top: 26px;
}

.form-note a {
  color: #9b9b9b;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.animated-label-passwordfield {
  padding: 0;
}
</style>
