<template>
  <div class="reward-recommendation-links">
    <h1>
      <Translation resource_key="joboffer.reward.recommendation.titel" />
    </h1>
    <p>
      <Translation resource_key="joboffer.reward.recommendation.text" />
    </p>
    <p class="reward-page">
      <router-link tag="a" to="reward-recommendation">
        <Translation resource_key="joboffer.reward.recommendation.text.more" />
      </router-link>
    </p>
    <h2>
      <Translation resource_key="joboffer.reward.recommendation.choice.next" />
    </h2>

    <ul>
      <li>
        <CpButtonAnchor
          href="/#/register"
          color="blue"
          size="large"
          weight="bold"
          key="4"
          :callback="onFurtherClicked"
        >
          <Translation resource_key="joboffer.reward.recommendation.choice.further" />
        </CpButtonAnchor>
      </li>
      <li>
        <CpButtonAnchor
          href="/"
          color="blue"
          size="large"
          weight="bold"
          key="4"
          :callback="onByeClicked"
        >
          <Translation resource_key="joboffer.reward.recommendation.choice.bye" />
        </CpButtonAnchor>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import CpButtonAnchor from '../atoms/CpButton/CpButtonAnchor'
import Translation from '../atoms/Translation'
import scrollIt from '../../lib/animatingScroll';

export default {
  name: 'RewardRecommendationLinks',
  components: {
    CpButtonAnchor,
    Translation
  },

  data() {
    return {
      hasInterest: 'yes',
      selectedReason: 0
    }
  },

  computed: {
    reasons() {
      return Array(6).fill().map((_, i) => i * i);
    },
    jobOfferId() {
      return (this.jobOffer) ? this.jobOffer.jobOfferId : 0;
    },
    rewardPageUrl() {
      const applicantId = (this.applicant && this.applicant.profile) ? this.applicant.profile.id : 0;
      return `/${this.$careerpeakr.locale}/reward/${this.jobOfferId}/${applicantId}`;
    },
    ...mapState({
      applicant(state) {
        return (state && state.applicant && state.applicant.id !== 0) ? state.applicant : null;
      },
      jobOffer(state) {
        return (state && state.joboffer && state.joboffer.jobOffer) ? state.joboffer.jobOffer : null;
      }
    })
  },

  mounted() {
    scrollIt(document.body);
  },

  methods: {
    onFurtherClicked() {
      if (this.applicant) {
        window.location.href = `/${this.$careerpeakr.locale}/applicant/app/dosier`;
        return false;
      }
      window.location.href = '/#/register';
      return false;
    },
    onByeClicked() {
      window.location.href = '/';
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';
@import '../../scss/atoms/_typographie';
@import '../../scss/atoms/_breakpoints.mixin';

.reward-recommendation-links {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  font-size: 17px;
  color: $color-white;

  .reward-page {
    a {
      font-weight: $font-bold;
      border-bottom: 1px solid $color-white;
    }

    .cp-btn {
      width: 171px;
      height: 60px;
      line-height: 59px;
    }

    label.selected {
      font-weight: 700;
    }

  }

  ul {
    display: flex;
    flex-flow: row;
    margin-top: 1.2em;
    margin-bottom: 3.0em;
    // margin-bottom: 1.6em;

    li {
      flex: 1;

      &:first-of-type {
        margin-right: 10px;
      }
    }
  }

  h1,
  h2,
  h3,
  em {
    margin: 0;
    font-weight: 800;
  }

  h1,
  h2 {
    font-size: 38px;
  }

  h2 {
    margin-bottom: 25px;
  }

  h3 {
    font-size: 21px;
  }

  em {
    font-style: normal;
  }

  a {
    text-decoration: none;
    color: $color-white;
  }

  .cp-btn.large {
    max-width: 770px;
    margin-bottom: 5px;
  }
}

@include bp-max-medium {
  .reward-recommendation-form {
    ul {
      flex-flow: column;

      li:first-of-type {
        margin-right: 0;
      }

      .cp-btn {
        line-height: 24px;
        padding: 24px 17px;
        font-size: 17px;
      }
    }
  }
}
</style>
