<template>
  <div class="yes-no-switch">
    <label>
      <slot></slot>
    </label>
    <div class="switch-checkbox-container">
      <input type="checkbox"  :name="name" :id="id"  class="" :value="value" :checked="value" />
      <span :class="{'switch': true, 'checked' : value}"  @click="toggle">
        <template v-if="value">Ja</template>
        <template v-if="!value">Nein</template>
      </span>
      <span class="yes" @click="toggle">Ja</span>
      <span class="no" @click="toggle">Nein</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YesNoSwitch',

  props: {
    name: {
      type: String
    },
    id: {
      type: String
    },
    value: {
      type: Boolean,
      require: true
    }
  },

  methods: {
    toggle() {
      this.$emit('change', !this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';

input[type='checkbox'] {
  display: none;
}

.yes-no-switch {
  label {
    font-size: 15px;
    font-style: italic;
  }
}

.switch-checkbox-container {
  position: relative;
  border: 1px $color-disabled-grey solid;
  padding: 4px 0;
  border-radius: 25px;
  font-size: 15px;
  width: 245px;
  user-select: none;
}

.switch-checkbox-container input:checked ~ label {
  color: $color-white;
  background: #4d7f9d;
}

.switch-checkbox-container span {
  display: inline-block;
  width: 119px;
  text-align: center;

  &.switch {
    position: absolute;
    top: 0;
    right: 0;
    height: inherit;
    border: solid transparent 1px;
    z-index: 2;
    border-radius: 25px;
    color: $color-white;
    background: #3c3c3c;
    padding: 3px 15px;
    cursor: pointer;
    transition: right 0.1s, opacity 0.1s;

    &.checked {
      right: 125px;
      transition: right 0.1s, opacity 0.1s;
    }
  }

  &.yes {
    font-style: italic;
  }

  &.no {
    padding: 0 11px;
    z-index: 1;
    opacity: 1;
    font-style: italic;
  }
}

.switch-checkbox-container input:checked ~ label .yes {
  opacity: 1;
}

.switch-checkbox-container input:checked ~ label .switch {
  left: auto;
  right: 0;
}

.switch-checkbox-container input:checked ~ label .no {
  opacity: 0;
}
</style>
