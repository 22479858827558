import axios from 'axios';

const URL = '/api/v2/company';
// const URL = 'http://careerpeakr.test/api/v2/dict'

export default {
  // id is quickhack. later should be removed.
  loadProfile(companyId, locale) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL}/${companyId}/${locale}`)
        .then((response) => {
          // handle success
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  }
};
