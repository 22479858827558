<template>
  <ModalBase :additional_classes="{'outer' : 'contact-modal'}">
    <div class="contanct-modal-inner">
      <h2 class="modal-title">
        <Editable slot="title" resource_key="contact.title" />
      </h2>
      <p class="modal-subline">
        <Editable slot="taglines" resource_key="contact.taglines" />
      </p>
      <form ref="form" method="POST" :action="`/${$careerpeakr.locale}/tempheadhunterregistration/create`">
        <div class="contanct-form-inner">
          <div class="contanct-form-main">
            <div class="form-row clearfix">
              <input
                name="fos_user_registration_form[namefirst]"
                type="text"
                value
                placeholder="Vorname eingeben*"
                class="cp-default-input"
              >
            </div>
            <div class="form-row">
              <input
                name="fos_user_registration_form[namelast]"
                type="text"
                value
                placeholder="Nachname eingeben*"
                class="cp-default-input"
              >
            </div>
            <div class="form-row">
              <input
                name="fos_user_registration_form[email]"
                type="email"
                value
                placeholder="E-Mail eingeben*"
                class="cp-default-input"
              >
            </div>
            <div class="form-row">
              <input
                name="fos_user_registration_form[companyName]"
                type="text"
                value
                placeholder="Name der Organisation eingeben"
                class="cp-default-input"
              >
            </div>
            <div class="form-row">
              <input
                name="fos_user_registration_form[phone]"
                type="text"
                value
                placeholder="Telefonnummer eingeben"
                class="cp-default-input"
              >
            </div>
            <div class="form-row">
              <textarea
                id="jobpassion_basebundle_tempregistration_remark"
                name="fos_user_registration_form[remark]"
                placeholder="Bemerkung"
                class="cp-default-textarea"
              ></textarea>
            </div>
            <div class="form-row">
              <input
                ref="agreeToTerms"
                type="checkbox"
                class="cp-checkbox"
                name="fos_user_registration_form[agreedToTerms]"
                id="privacy-check"
                v-model="privacy_checked"
              >
              <label for="privacy-check">
                Der Schutz Ihrer Daten ist uns wichtig.
                Mit der Kontaktaufnahme akzeptieren Sie unsere
                <a href="/de/privacy" target="_blank" class="">
                  CareerPeakr-Datenschutzerklärung
                </a>
                und bestätigen, diese zur Kenntnis genommen zu haben. *
              </label>
            </div>
            <div class="form-footer">
              <p class="form-note">
                <sup>*</sup>Pflichtfelder
              </p>
              <CpButton
                :callback="sendClicked"
                color="blue"
                size="large"
                weight="bold"
                :disabled="!privacy_checked"
                type="submit"
              >Jetzt Kontakt abschicken</CpButton>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ModalBase>
</template>

<script>
import CpButton from '../atoms/CpButton';
import ModalBase from '../molecules/ModalBase';
import Editable from '../molecules/Editable';
// import RegisterToken from "../../lib/registerToken";

export default {
  name: 'EmployerContact',

  components: {
    CpButton,
    ModalBase,
    Editable
  },

  data() {
    return {
      privacy_checked: false,
      resigter_token: ''
    };
  },

  methods: {
    sendClicked() {
      /* update on 03.12.2018 because of unifing with version christian's
       * name of csrf token parameter was changed.
      const param_token = document.createElement("input");
      param_token.type = "hidden";
      param_token.name = "fos_user_registration_form[_token]";
      param_token.value = this.resigter_token; // window.security.csrf_token;
      this.$refs.form.appendChild(param_token);
      */
      const paramToken = document.createElement('input');
      paramToken.type = 'hidden';
      paramToken.name = '_csrf_token';
      paramToken.value = this.resigter_token; // window.security.csrf_token;
      this.$refs.form.appendChild(paramToken);

      // overwrite value for framework
      this.$refs.agreeToTerms.value = 1;

      this.$refs.form.submit();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';

/deep/ .contact-modal {
  .modal-inner {
    max-width: 720px;
  }

  .modal-title {
    font-size: 32px;
    max-width: 520px;
  }

  .modal-subline {
    max-width: 560px;
  }
}

.contanct-modal-inner {
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  max-width: 680px;
  margin: 0 auto;
  padding: 60px 0 66px;

  form {
    width: 480px;
  }
}

.contanct-form-inner {
  position: relative;
  padding: 0;
  max-width: 480px;
}

.contanct-form-main {
  position: relative;
  margin-top: 46px;
}

.cp-default-textarea {
  display: block;
  width: 100%;
  font-size: 17px;
  height: 240px;
  line-height: 1.46em;
  padding: 18px;
  border: 1px solid #c4c4c4;
  background-color: $color-white;
}

.cp-checkbox + label {
  text-align: left;
  margin-left: -28px;
}

@include bp-max-medium() {
  .contanct-modal-inner form {
    width: 100%;

    .contanct-form-inner {
      margin: 0 auto;
    }
  }

  .cp-checkbox + label {
    margin-left: 0;
  }
}

</style>
