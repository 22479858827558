<template>
  <div id="reward-recommendation-form">
    <div class="wrapper">
      <RewardRecommendationHeader/>
      <RewardRecommendationProcess/>
      <RewardRecommendationContactForm :jobOfferId="jobOfferId" />
      <MainFooter/>
    </div>
  </div>
</template>

<script>
import RewardRecommendationHeader from './RewardRecommendationHeader';
import RewardRecommendationProcess from './RewardRecommendationProcess';
import RewardRecommendationContactForm from './RewardRecommendationContactForm';
import MainFooter from './MainFooter';

export default {
  name: 'RewardRecommendationForm',
  components: {
    RewardRecommendationHeader,
    RewardRecommendationProcess,
    RewardRecommendationContactForm,
    MainFooter
  },

  data() {
    return {
      hello: 'world'
    }
  },

  computed: {
    jobOfferId() {
      // refer URL directly
      return Math.trunc(window.document.location.href.replace(/#.*$/, '').replace(/^.*\//, ''));
    }
  },

  created() {
    console.log('created: ', this.jobOfferId);
  }
};
</script>

<style lang="scss" scoped>
#reward-recommendation-form {
  display: block;
  .wrapper {
    display: flex;
    flex-direction: column;
  }
}

</style>
