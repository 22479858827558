<template>
  <div class="applicant-settings" v-if="loadedUserProfile">
    <!-- :href="`/${$careerpeakr.locale}/user/settings/edit/${userId}`">
    <router-link tag="li" to="/status" active-class="active" @click.native="linkClicked">

      <span class="fontello icon-cog"></span>
    </!-->
    <router-link tag="a" to="/settings" active-class="active" @click.native="linkClicked" >
      <span class="fontello icon-cog"></span>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ApplicantSettings',
  computed: {
    ...mapState({
      userId(state) {
        return state.applicant.profile ? state.applicant.profile.id : '';
      },
      loadedUserProfile(state) {
        return state.applicant.profile !== null;
      }
    })
  },
  methods: {
    linkClicked(e) {
      this.$emit('linkClicked', e);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';

.icon-cog:before {
  color: $color-silvergrey;

  .active & {
    color: $color-blue;
  }
}
</style>
