<template>
  <div class="welcome-joboffer">
    <div class="wrapper">
      <h1>
        <CpLogo size="small" />
      </h1>
      <router-view name="top"></router-view>
      <div class="mobile-background"> </div>
    </div>
    <div class="content-wrapper">
      <h2>
        Ihr Jobangebot
      </h2>
      <div class="boxshadow-basic">
        <JobOfferContent />
      </div>
    </div>
    <div class="wrapper">
      <router-view name="bottom"></router-view>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import CpLogo from '../atoms/CpLogo';
import JobOfferContent from './JobOfferContent';

const { mapState } = createNamespacedHelpers('application');

// Mock server data
if (typeof window.Jobpassion === 'undefined') {
  window.Jobpassion = {};
}

export default {
  name: 'WelcomeJobOffer',

  components: {
    CpLogo,
    JobOfferContent
  },

  computed: {
    ...mapState([
      'modalOpened',
      'scrolltop'
    ]),
    modalOpenStyle() {
      return (this.modalOpened)
        ? { position: 'relative', top: `-${this.scrolltop}px` }
        : '';
    }
  }

};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_breakpoints.mixin';

.wrapper {
  padding-top: 80px;
  padding-bottom: 60px;
  padding-left: var(--padding-edge);
  padding-right: var(--padding-edge);
  background: transparent url(/assets/img/CP_Header_Arbeitnehmer_01.jpg) no-repeat top right;
  background-size: cover;

}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 90px;

  h2 {
    margin-top: 55px;
    margin-bottom: 35px;
  }
}

.welcome-joboffer {
  h1 {
    position: absolute;
    top: 20px;
    left: 40px;
  }
}

.mobile-background {
  display: none;
}

.modal-opened {
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

@include bp-max-tablet {
  .welcome-joboffer .wrapper {
    position: relative;
    background-color: rgba(0,0,0,0.4);
    background-image: none;

    .mobile-background {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      content: ' ';
      background: transparent url(/assets/img/CP_Header_Arbeitnehmer_01.jpg) no-repeat top right;
      background-size: cover;
    }
  }

  h2 {
    margin-left: var(--padding-edge);
  }
}

</style>
