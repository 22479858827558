import axios from 'axios';
import objectToFormData from '../../lib/objectToFormData';

const URL_BASE = '/api/v2/applicant';
const URL = `${URL_BASE}/profile`;
const URL_CHANGE_PASSWORD = 'user/change-password';

export default {
  /**
   * get user Profile
   * URI[ POST ] /ap1/v2/applicant/analyse
   * @return {Promise<number>} number of unread mails
   */
  getProfile(id = 0) {
    return new Promise((resolve, reject) => {
      const url = id ? `${URL_BASE}/${id}/profile` : URL;
      axios
        .get(url)
        .then((response) => {
          // handle success
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  },

  updateProfile(profile) {
    return new Promise((resolve, reject) => {
      const url = `${URL_BASE}/profile`; // this API allows only changing by your self at this moment.
      axios
        .post(url, profile)
        .then((response) => {
          // handle success
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  },

  changePassword(locale = 'de', passwords) {
    return new Promise((resolve, reject) => {
      const url = `/${locale}/${URL_CHANGE_PASSWORD}`;
      const options = {
        indices: true,
        nullsAsUndefineds: false
      };
      const formData = objectToFormData(passwords, options, new FormData(), 'fos_user_change_password_form');

      axios
        .post(url, formData, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
        .then((response) => {
          // handle success
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  }
};
