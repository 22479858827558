// original is https://github.com/therealparmesh/object-to-formdata#readme
// because of import Failure I added just here.
// FIXME: try to import with npm install or yarn add.

const isUndefined = (value) => value === undefined;

const isNull = (value) => value === null;

const isObject = (value) => value === Object(value);

const isArray = (value) => Array.isArray(value);

const isDate = (value) => value instanceof Date;

const isBlob = (value) => value
  && typeof value.size === 'number'
  && typeof value.type === 'string'
  && typeof value.slice === 'function';

const isFile = (value) => isBlob(value)
  && typeof value.name === 'string'
  && (typeof value.lastModifiedDate === 'object' || typeof value.lastModified === 'number');

const objectToFormData = (obj, cfg_, fd_, pre) => {
  const cfg = cfg_ || {};
  cfg.indices = isUndefined(cfg.indices) ? false : cfg.indices;
  cfg.nullsAsUndefineds = isUndefined(cfg.nullsAsUndefineds) ? false : cfg.nullsAsUndefineds;
  const fd = fd_ || new FormData();

  if (isUndefined(obj)) {
    return fd;
  } if (isNull(obj)) {
    if (!cfg.nullsAsUndefineds) {
      fd.append(pre, '');
    }
  } else if (isArray(obj)) {
    if (!obj.length) {
      const key = `${pre}[]`;

      fd.append(key, '');
    } else {
      obj.forEach((value, index) => {
        const key = `${pre}[${cfg.indices ? index : ''}]`;

        objectToFormData(value, cfg, fd, key);
      });
    }
  } else if (isDate(obj)) {
    fd.append(pre, obj.toISOString());
  } else if (isObject(obj) && !isFile(obj) && !isBlob(obj)) {
    Object.keys(obj).forEach((prop) => {
      const value = obj[prop];

      if (isArray(value)) {
        while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
          // FIXME: check if it works correctly. it must be tested with original code...
          prop = prop.substring(0, prop.length - 2); // eslint-disable-line no-param-reassign
        }
      }

      const key = pre ? `${pre}[${prop}]` : prop;

      objectToFormData(value, cfg, fd, key);
    });
  } else {
    fd.append(pre, obj);
  }

  return fd;
};

export default objectToFormData;
