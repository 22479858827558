// getters
import state from './state';

export default {
  getProfile() {
    return state.profile;
  },
  getFavorite() {
    return state.favorite;
  },
  getTrash() {
    return state.trash;
  },
  getLocation(label) {
    return state.locationPredictions.find((location) => location.label === label);
  }
};
