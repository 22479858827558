<template>
  <div class="skills-display">
    <LanguageSkillsGraph :values="languageSkills" :asApplicantSkill="true" />
    <h4 v-if="bpMaxMedium">
      <Translation resource_key="applicant.profile.abroad.experiences" />
    </h4>
    <div class="abroad-experiences">
      <h4 v-if="!bpMaxMedium">
        <Translation resource_key="applicant.profile.abroad.experiences" />
      </h4>
      <ul>
        <AbroadExperience v-for="(exp, index) in experiences"
                          :value="exp"
                          :key="index"
                          :fresh="fresh"
        />
      </ul>
    </div>
    <ProfessionalKnowledgeTags :values="knowledges" :asApplicantSkill="true" />
    <div v-if="values.skills.length === 0">
      <p class="fresh" v-if="fresh"><i class="fresh">Angabe ergänzen</i></p>
    </div>
  </div>
</template>

<script>
import Translation from '../../atoms/Translation';
import LanguageSkillsGraph from '../../organisms/LanguageSkillsGraph';
import ProfessionalKnowledgeTags from '../../organisms/ProfessionalKnowledgeTags';
import AbroadExperience from '../../organisms/ApplicantProfile/AbroadExperience';
import breakPoints from '../../../lib/mixins/breakPoints';

export default {
  name: 'SkillsDisplay',
  components: {
    Translation,
    LanguageSkillsGraph,
    ProfessionalKnowledgeTags,
    AbroadExperience
  },
  mixins: [breakPoints],

  props: {
    values: {
      type: Object,
      required: true
    },
    fresh: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    experiences() {
      return this.values.abroadExperiences;
    },
    languageSkills() {
      return { languageImportances: this.values.languageSkills };
    },
    knowledges() {
      return {
        desiredSkills: this.values.skills
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_typographie';
@import '../../../scss/atoms/_icons';
@import '../../../scss/atoms/_breakpoints.mixin';

.wrapper {
  max-width: 840px;
  width: 100%;
  margin: 0 auto;
}

.skills-display {
  .language-skills-graph {
    width: 50%;
    display: inline-block;

    /deep/ .skill-graph .skill-levels {
      top: 0;

      li span {
        font-size: 14px;
      }
    }

    /deep/ h4 {
      font-weight: 400;
      line-height: 25px;
    }
  }

  h4 {
    color: $color-blue;
    margin: 0;
    font-weight: 400;
    font-size: 17px;
  }

  /deep/ h4 {
    font-size: 17px;
    color: $color-blue;
    margin: 0;
    font-weight: 400;
  }

  .abroad-experiences {
    width: 40%;
    vertical-align: top;
    padding-left: 35px;
    font-size: 17px;
    float: right;
    position: relative;

    > ul {
      margin-top: 10px;
    }

    .abroad-experience:first-child {
      padding-top: 10px;
      //  overflow: hidden;

      &.fresh {
        /deep/ {
          .time {
            margin-top: 0;
          }
        }
      }

      /deep/ {
        .time,
        .country {
          margin-top: -10px;
        }
      }
    }
  }
  p.fresh {
    margin-top: -40px;

    i.fresh {
      display: inline-block;
      padding: 10px 15px;
      background-color: #efefef;
      margin: 1px;
      font-size: 17px;
      font-style: italic;
      color: $color-dustygrey;
    }
  }
}

@include bp-max-medium() {
  .skills-display {
    width: 100%;
    flex-direction: column;

    .language-skills-graph {
      width: 100%;
    }

    .abroad-experiences {
      width: 100%;
      margin-bottom: 40px;
      padding: 0 !important;
    }

    .additional-info {
      ul.abroad-experiences {
        width: 100%;
      }
    }

    /deep/ .professional-skill-tags {
      .skills {
        li {
          padding: 11px 15px;
          font-size: 14px;
          line-height: 18px;
          color: $color-blacky;
        }
      }
    }
  }
}
</style>
