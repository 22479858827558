<template>
  <a href="/">
    <img src="/assets/img/cp-logo-black.svg" alt="CareerPeakr" class="cp-logo" />
  </a>
</template>

<script>
export default {
  name: 'CpLogoBlack'
};
</script>

<style lang="scss" scoped>
.cp-logo {
  display: block;

  &.small {
    width: 140px;
  }
}
</style>
