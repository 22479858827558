<template>
  <div class="onboarding-info-edit">
    <div class="row">
      <LabelTextField :value="valueOrBlank(stripHtml(values.availability))" @change="(v) => valueChanged('availability', v)">
        <Translation resource_key="applicant.profile.onboading.availability" />
      </LabelTextField>
    </div>
    <div class="row">
      <LabelTextField :value="valueOrBlank(values.periodNotice)" @change="(v) => valueChanged('periodNotice', v)">
        <Translation resource_key="applicant.profile.onboading.period.of.notice" />
      </LabelTextField>
    </div>
    <div class="row">
      <RichTextArea :value="valueOrBlank(values.mobility)" @change="(v) => valueChanged('mobility', v)">
        <Translation resource_key="applicant.profile.onboading.mobility" />
      </RichTextArea>
    </div>
    <div class="row">
      <RichTextArea :value="valueOrBlank(values.generalImpression)" @change="(v) => valueChanged('generalImpression', v)">
        <Translation resource_key="applicant.profile.onboading.motivation.of.change" />
      </RichTextArea>
    </div>
    <div class="row">
      <LabelTextField :value="valueOrBlank(stripHtml(values.salaryExpectations))" @change="(v) => valueChanged('salaryExpectations', v)">
        <Translation resource_key="applicant.profile.onboading.salary.expectations" />
      </LabelTextField>
    </div>
    <div class="row">
      <LabelTextField :value="valueOrBlank(values.interviewAppointment)" @change="(v) => valueChanged('interviewAppointment', v)">
        <Translation resource_key="applicant.profile.onboading.interview.appointment" />
      </LabelTextField>
    </div>
    <div class="row">
      <RichTextArea :value="valueOrBlank(values.privateNotes)" @change="(v) => valueChanged('privateNotes', v)">
        <Translation resource_key="applicant.profile.onboading.private.notes" />
      </RichTextArea>
    </div>
  </div>
</template>

<script>
import Translation from '../../atoms/Translation';
import LabelTextField from '../../molecules/LabelTextField';
import RichTextArea from '../../molecules/RichTextArea';
import formUtils from '../../../lib/mixins/formUtils';
import htmlUtils from '../../../lib/mixins/htmlUtils';
import util from '../../../lib/utils';

export default {
  name: 'OnboardingInfoEdit',
  components: {
    Translation,
    LabelTextField,
    RichTextArea
  },
  mixins: [formUtils, htmlUtils],
  props: {
    values: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    callbackChange: {
      type: Function,
      required: true
    }
  },
  methods: {
    valueChanged(path, value) {
      const newVal = { ...this.values };
      const inputedValue = util.buildObjectFromPath(path, value);
      this.callbackChange(util.mergeDeep(newVal, inputedValue));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';

.onboarding-info-edit {
  margin-bottom: 30px;

  .label-textfield {
    margin-bottom: 0;

    /deep/ {
      label > span {
        font-size: 14px;
        line-height: inherit;
        color: $color-dustygrey;
      }

      input[type='text'] {
        background-color: $color-white;
        font-size: 15px;
      }
    }
  }

  .rich-text-area {
    /deep/ {
      label {
        font-weight: 400;
      }

      .ck-content {
        font-size: 15px;
        padding-left: 15px;

        .ck-placeholder:before {
          color: $color-dustygrey;
        }
      }
    }
  }
}
</style>
