import Vue from 'vue';

// const moduleName = 'application';
const types = {
  MUTATIONS: {
    OPEN_MODAL: 'openModal',
    CLOSE_MODAL: 'closeModal'
  }
};

// initial state
// shape: [{ id, quantity }]
const state = {
  scrolltop: 0,
  modalOpened: false
};

const mutations = {
  /**
   * openModal
   * @type {function}
   * @param state given automatically by framework
   * @param dict updated dictionary object
   */
  [types.MUTATIONS.OPEN_MODAL](vuexState, payload) {
    Vue.set(vuexState, 'scrolltop', payload.scrolltop);
    Vue.set(vuexState, 'modalOpened', true);
  },

  /**
   * closeModal
   * @type {function}
   * @param state given automatically by framework
   * @param entry update value,
   */
  [types.MUTATIONS.CLOSE_MODAL](vuexState) {
    Vue.set(vuexState, 'scrolltop', 0);
    Vue.set(vuexState, 'modalOpened', false);
  }
};

const getters = {};
const actions = {};

export default {
  namespaced: true,
  types,
  state,
  getters,
  actions,
  mutations
};
