<template>
  <ModalBase :additional_classes="{'outer' : 'registration-modal'}">
    <div class="registration-modal-inner">
      <h2 class="modal-title">Jetzt Jobs finden, <br/> die wirklich zu Dir passen!</h2>
      <form ref="form" method="POST" :action="`/${locale}/register/applicant`">
        <input
          type="hidden"
          id="fos_user_registration_form_locale"
          name="fos_user_registration_form[locale]"
          :value="locale"
        >
        <input
          type="hidden"
          id="fos_user_registration_form_usernameEqualsEmail"
          name="fos_user_registration_form[usernameEqualsEmail]"
          value="1"
        >
        <input
          v-if="jobOfferId"
          type="hidden"
          id="slected_jobOfferId"
          name="slected_jobOfferId"
          :value="jobOfferId"
        >
        <div class="registration-form-inner">
          <input
            ref="agreeToTerms"
            type="checkbox"
            class="cp-checkbox"
            name="fos_user_registration_form[agreedToTerms]"
            id="privacy-check"
            v-model="privacy_checked"
          >
          <label for="privacy-check">
            Der Schutz Deiner Daten ist uns wichtig. Mit der Registrierung akzeptierst Du unsere
            <a
              href="/de/privacy"
              target="_blank"
              class="simple-arrow-link"
            >CareerPeakr-Datenschutzerklärung</a> sowie die
            <a href="/de/termsconditions" target="_blank" class="simple-arrow-link">
              Nutzungsbedingungen
            </a>
            und bestätigst, diese zur Kenntnis genommen zu haben. *
          </label>
          <div class="registration-form-main">
            <h3 class="registration-form-caption">Mit sozialem Netzwerk registrieren</h3>
            <ul class="registration-social-registrations clearfix">
              <li class="registration-social-registrations-item form-col-2">
                <SocialButton channel="xing" size="large" :callback="registerWithXing">
                  Mit Xing registrieren
                </SocialButton>
              </li>
              <li class="registration-social-registrations-item form-col-2">
                <SocialButton channel="linkedin" size="large" :callback="registerWithLinkedin">
                  Mit LinkedIn registrieren
                </SocialButton>
              </li>
            </ul>
            <p class="registration-form-separator">Oder</p>
            <h3 class="registration-form-caption">Mit E-Mail-Adresse registrieren</h3>
            <div class="form-row">
              <input
                ref="salutation-m"
                type="radio"
                :class="{'cp-radio' : true, 'error': form.gender.hasError}"
                name="fos_user_registration_form[gender]"
                value="1"
                id="registration-salutation-m"
                :disabled="!privacy_checked"
                v-model="form.gender.value"
                @blur="validateGender()"
              >
              <label for="registration-salutation-m">männlich</label>
              <input
                ref="salutation-f"
                type="radio"
                :class="{'cp-radio' : true, 'error': form.gender.hasError}"
                name="fos_user_registration_form[gender]"
                value="2"
                id="registration-salutation-f"
                :disabled="!privacy_checked"
                v-model="form.gender.value"
                @blur="validateGender()"
              >
              <label for="registration-salutation-f">weiblich</label>
              <input
                ref="salutation-n"
                type="radio"
                :class="{'cp-radio' : true, 'error': form.gender.hasError}"
                name="fos_user_registration_form[gender]"
                value="3"
                id="registration-salutation-n"
                :disabled="!privacy_checked"
                v-model="form.gender.value"
                @blur="validateGender()"
              >
              <label for="registration-salutation-n">keine Angabe</label>
            </div>
            <div class="form-row clearfix">
              <div class="form-col-2">
                <input
                  name="fos_user_registration_form[namefirst]"
                  type="text"
                  value
                  placeholder="Vorname eingeben*"
                  :class="{'cp-default-input' : true, 'error': form.firstName.hasError}"
                  :disabled="!privacy_checked"
                  v-model="form.firstName.value"
                  @blur="validateRequiredField(form.firstName)"
                >
              </div>
              <div class="form-col-2">
                <input
                  name="fos_user_registration_form[namelast]"
                  type="text"
                  value
                  placeholder="Nachname eingeben*"
                  :class="{'cp-default-input' : true, 'error': form.lastName.hasError}"
                  :disabled="!privacy_checked"
                  v-model="form.lastName.value"
                  @blur="validateRequiredField(form.lastName)">
              </div>
            </div>
            <div class="form-row">
              <input
                name="fos_user_registration_form[email]"
                type="email"
                value
                placeholder="E-Mail eingeben*"
                :class="{'cp-default-input' : true, 'error': form.email.hasError}"
                :disabled="!privacy_checked"
                v-model="form.email.value"
                @blur="validateEmail(form.email)"
              >
            </div>
            <div class="form-row clearfix">
              <div class="form-col-2 password-form">
                <Password
                  name="fos_user_registration_form[plainPassword][first]"
                  :class="{'Password__field' : true, 'error': form.password.hasError}"
                  type="password"
                  :disabled="!privacy_checked"
                  placeholder="Passwort eingeben*"
                  :secureLength="8"
                  :toggle="true"
                  v-model="form.password.value"
                  @score="(s) => scoreCatched('password', s)"
                  @input="(v) => updateFormValue('password', v)"
                  @feedback="showFeedback"
                />
              </div>
              <div class="form-col-2 password-form" >
                <Password
                  name="fos_user_registration_form[plainPassword][second]"
                  type="password"
                  value
                  placeholder="Passwort bestätigen*"
                  :class="{'Password__field' : true, 'error': form.password.hasError}"
                  :disabled="!privacy_checked"
                  :secureLength="8"
                  :toggle="true"
                  v-model="form.passwordRepeat.value"
                  @score="(s) => scoreCatched('passwordRepeat', s)"
                  @input="(v) => updateFormValue('passwordRepeat', v)"
                />
              </div>
            </div>
            <div class="form-footer">
              <ul class="error-messages" v-if="hasError()" >
                <li class="message" v-for="(error, index) in errors" :key="index">
                  {{ error.message }}
                </li>
              </ul>
              <p class="form-note">
                <sup>*</sup>Pflichtfelder
              </p>
              <CpButton
                :callback="registerClicked"
                color="blue"
                size="large"
                weight="bold"
                :disabled="!privacy_checked"
                type="submit"
              >Jetzt Registrieren</CpButton>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ModalBase>
</template>

<script>
import Cookie from 'js-cookie';
import Password from 'vue-password-strength-meter';
import CpButton, { SocialButton } from '../../atoms/CpButton'
import ModalBase from '../../molecules/ModalBase';
// import * as api from '../../api/user/';
// import RegisterToken from "../../lib/registerToken";

export default {
  name: 'Registration',

  components: {
    CpButton,
    SocialButton,
    ModalBase,
    Password
  },

  data() {
    return {
      privacy_checked: false,
      form: {
        gender: { value: null, hasError: false },
        firstName: { value: '', hasError: false },
        lastName: { value: '', hasError: false },
        email: { value: '', hasError: false },
        password: {
          name: 'fos_user_registration_form[plainPassword][second]', value: '', hasError: false, score: 0
        },
        passwordRepeat: {
          name: 'fos_user_registration_form[plainPassword][second]', value: '', hasError: false, score: 0
        }
      },
      errorMessages: {
        genderNotselected: {
          shown: false,
          message: 'Bitte Geschlecht auswählen.'
        },
        notFullfilled: {
          shown: false,
          message: 'Bitte Pflichtfelder ausfüllen.'
        },
        notCorrectEmail: {
          shown: false,
          message: 'Bitte Email Adresse mit dem richitigen Format eingeben.'
        },
        emailInUse: {
          shown: false,
          message: 'Diese E-Mail-Adresse wird bereits verwendet.'
        },
        passwordsNotSame: {
          shown: false,
          message: 'Beide Passwort-Felder müssen identisch sein.'
        },
        passwordsNumber: {
          shown: false,
          message: 'Passwort-Felder müssen identisch sein.'
        },
        feedBacks: {
          shown: false,
          message: ''
        },
        passwordScores: {
          shown: false,
          message: 'Passwort ist zu schwach.'
        }
      },
      jobOfferId: 0
    };
  },
  created() {
    if (this.$route.params.jobOfferId) {
      this.jobOfferId = this.$route.params.jobOfferId;
    }

    if (this.hasAppointmentData()) {
      this.assignAppointmentData()
    }
    /*
    RegisterToken.get(
      result => {
        console.log(result);
        this.resigter_token = result;
      },
      error => {
        console.log(error);
      }
    ); */
  },

  computed: {
    errors() {
      const result = Object.values(this.errorMessages).filter((emsg) => emsg.shown);
      result.map((err) => console.log(err.message));
      return result;
    },
    locale() {
      return (this.$careerpeakr && this.$careerpeakr.locale)
        ? this.$careerpeakr.locale : 'de';
    }
  },

  methods: {
    hasError() {
      const errors = Object.values(this.errorMessages).filter((emsg) => emsg.shown);
      return (errors) ? errors.length > 0 : false;
    },

    toggleOffErrorOnFields() {
      /*
      this.errorMessages.forEach((_emsg, key, _map) => {
        this.errorMessage[key].shown = false;
      });
      */
      /*
      for (const emsg of Object.entries(this.errorMessages)) {
        this.$set(this.errorMessages[emsg[0]], 'shown', false);
      }
      for (const field of Object.entries(this.form)) {
        field[1].hasError = false;
      }
      */
      Object.keys(this.errorMessages).map((key) => {
        this.$set(this.errorMessages[key], 'shown', false);
        return key;
      });

      Object.keys(this.form).map((key) => {
        this.$set(this.form[key], 'hasError', false);
        return key;
      });
    },

    /**
     * both name fields should be filled.
     */
    validateGender() {
      const valid = (this.form.gender.value !== null);
      this.$set(this.form.gender, 'hasError', !valid);
      this.$set(this.errorMessages.genderNotselected, 'shown', !valid);
      return valid;
    },

    /**
     * both name fields should be filled.
     */
    validateRequiredField(fieldObject) {
      const valid = (fieldObject.value !== '');
      this.$set(fieldObject, 'hasError', !valid);
      this.$set(this.errorMessages.notFullfilled, 'shown', !valid);
      return valid;
    },

    /**
     *  queue Email Check API...
     */
    validateEmail(emailObject) {
      // debugger;
      // api.checkEmail().then((valid) => {
      //   if (valid) {
      //     this.errorMessages.emailInUse.shown = true;
      //   }
      // });
      const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let valid = mailFormat.test(emailObject.value);
      this.$set(emailObject, 'hasError', !valid);
      this.$set(this.errorMessages.notCorrectEmail, 'shown', !valid);
      /* eslint-disable no-bitwise */
      valid &= this.validateRequiredField(emailObject);
      /* eslint-enable no-bitwise  */
      return valid;
    },

    /**
     *  check if both password fields filled with same password
     */
    validateSamePasswords() {
      const valid = this.form.password.value === this.form.passwordRepeat.value;
      this.$set(this.form.password, 'hasError', !valid);
      this.$set(this.form.passwordRepeat, 'hasError', !valid);
      this.$set(this.errorMessages.passwordsNotSame, 'shown', !valid);
      return valid
    },

    validatePassword(passwordObj) {
      let valid = this.validateRequiredField(passwordObj);
      /* eslint-disable no-bitwise  */
      valid &= passwordObj.score >= 3
      /* eslint-enable no-bitwise  */
      this.$set(passwordObj, 'hasError', !valid);
      this.$set(this.errorMessages.passwordScores, 'shown', !valid);
      /* eslint-disable no-bitwise  */
      if (this.form.passwordRepeat.value.length > 0) {
        valid &= this.validateSamePasswords();
      }
      /* eslint-enable no-bitwise  */
      return valid;
    },

    validate() {
      this.toggleOffErrorOnFields();
      /* eslint-disable no-bitwise  */
      // must be filled or checked
      let valid = this.validateGender();
      valid &= this.validateRequiredField(this.form.firstName);
      valid &= this.validateRequiredField(this.form.lastName);
      valid &= this.validateEmail(this.form.email);
      valid &= this.validatePassword(this.form.password);
      valid &= this.validatePassword(this.form.passwordRepeat);
      if (this.form.password.value.length > 0
          || this.form.passwordRepeat.value.length > 0) {
        valid &= this.validateSamePasswords();
      }
      /* eslint-enable no-bitwise  */
      return valid;
    },

    registerClicked(e) {
      /* update on 03.12.2018 because of unifing with version christian's
       * name of csrf token parameter was changed.
      const param_token = document.createElement("input");
      param_token.type = "hidden";
      param_token.name = "fos_user_registration_form[_token]";
      param_token.value = this.resigter_token; // window.security.csrf_token;
      this.$refs.form.appendChild(param_token);
      */
      if (!this.validate()) {
        e.preventDefault();
        return false;
      }
      const paramsToken = document.createElement('input');
      paramsToken.type = 'hidden';
      paramsToken.name = '_csrf_token';
      paramsToken.value = this.resigter_token; // window.security.csrf_token;
      this.$refs.form.appendChild(paramsToken);

      // overwrite value for framework
      this.$refs.agreeToTerms.value = 1;

      this.$refs.form.submit();
      return false;
    },

    registerWithXing(e) {
      e.preventDefault();
      console.log('jobOfferId: ', this.jobOfferId);

      if (this.jobOfferId) {
        window.location.href = `/${this.locale}/xingregistration/${this.jobOfferId}`;
      } else {
        window.location.href = `/${this.locale}/xingregistration`;
      }
      return false;
    },

    registerWithLinkedin(e) {
      e.preventDefault();
      if (this.jobOfferId) {
        window.location.href = `/${this.locale}/linkedinregistration/${this.jobOfferId}`;
      } else {
        window.location.href = `/${this.locale}/linkedinregistration`;
      }
      return false;
    },

    showFeedback({ suggestions, warning }) {
      console.log('warning', warning);
      console.log('suggestions', suggestions);
      // remove all feedbacks, if suggestions are updated.
      const self = this;
      Object.keys(this.errorMessages).filter((key) => key.indexOf('feedBacks') !== -1).map((key) => {
        delete self.errorMessages[key];
        return key;
      });

      suggestions.map((suggestion, index) => {
        //   console.log(suggestion)
        // switch (suggestion) {
        //   case 'Add another word or two. Uncommon words are better.':
        //     suggestion.replace('yes i chenged the text');
        //     break;
        //   case 'No need for symbols, digits, or uppercase letters':
        //     suggestion.replace('yes i chenged the text secondaly');
        //     break;
        //   default:
        //     suggestion.replace('default');
        // }
        // console.log('after switch', suggestion)
        self.$set(self.errorMessages, `feedBacks${index}`, {});
        self.$set(self.errorMessages[`feedBacks${index}`], 'message', suggestion);
        self.$set(self.errorMessages[`feedBacks${index}`], 'shown', true);
        return suggestion;
      });
    },

    scoreCatched(path, score) {
      console.log(`score: ${path}`, score);
      this.form[path].score = score;
      if (path !== 'passwordRepeat') {
        this.validatePassword(this.form[path], path === 'passwordRepeat')
      }
    },

    updateFormValue(key, val) {
      this.form[key].value = val;
      if (key !== 'passwordRepeat') {
        this.validatePassword(this.form[key]);
      }
    },

    hasAppointmentData() {
      const appointment = Cookie.get('appointmentBeforeRegistration');
      return (typeof appointment) !== 'undefined';
    },

    assignAppointmentData() {
      const appointment = JSON.parse(Cookie.get('appointmentBeforeRegistration'));

      /* eslint-disable camelcase */
      const {
        appointment_applicant_name_first,
        appointment_applicant_name_last,
        appointment_applicant_email
      } = appointment;

      this.form.firstName.value = appointment_applicant_name_first;
      this.form.lastName.value = appointment_applicant_name_last;
      this.form.email.value = appointment_applicant_email;
      /* eslint-enable camelcase */
    }
  }
}

</script>

<style lang="scss">
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_breakpoints.mixin';
@import './registration_modal';

.Password__field {
  background-color: transparent !important;
  font-size: 17px !important;
  border: 1px solid #c4c4c4 !important;
  position: relative;
  display: block;
  height: 60px;
  // width:365px !important;

  &.error input[type=password] {
    background-color: $color-error-filed !important;
  }

  &.Password {
    border: 1px solid #ffffff !important;
    padding: 0 !important;
  }
}

.password-form {
  height: 85px;
}

.Password__field,
.error {
  all: none !important;
}

.Password__strength-meter {
  margin: -7px auto 20px;
}

.Password__strength-meter--fill[data-score='3'] {
  background: $color-deepgreen !important;
}

@include bp-max-medium {
  .Password__field {
    max-width: 640px !important;
    margin-bottom: -8px;
  }
}

</style>
