<template>
  <div class="why-good-in-hand">
    <h3><Translation resource_key="company.profile.cp_descriptionBenefits" /></h3>
    <div class="free-text" v-html="removeStyleAttr(profile.descriptionBenefits)"></div>
  </div>
</template>

<script>
import Translation from '../atoms/Translation';
import removeStyleAttr from '../../lib/mixins/htmlUtils';

// Mock server data
if (typeof window.Jobpassion === 'undefined') {
  window.Jobpassion = {};
}

export default {
  name: 'WhyGoodInHand',
  components: {
    Translation
  },

  mixins: [removeStyleAttr],

  props: {
    profile: {
      type: Object,
      requreid: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin'; // for /deep/ should be have here
@import '../../scss/atoms/layout'; // for /deep/ should be have here

.why-good-in-hand {
  color: $color-darkgrey;
  font-size: 17px;
  line-height: 28px;
  max-width: 894px;
  margin-top: 50px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  // padding-left: var(--padding-edge);
  // padding-right: var(--padding-edge);

  h3 {
    color: $color-blacky;
    font-size: 30px;
    line-height: 49px;
  }
  /deep/ {
    h4 {
      color: $color-blacky;
      font-size: 21px;
    }

    strong {
      color: $color-blacky;
      font-weight: 900;
    }

    p {
      font-weight: normal;
    }

    ul {
      li {
        padding-left: 40px;
        line-height: 41px;

        &:before {
          content: '\e813';
          color: $color-blue;
          font-family: fontello;  // stylelint-disable-line
          margin-left: -40px;
          margin-right: calc(40px - 1em);
        }
      }
    }
  }
}

@include bp-max-medium() {
  .why-good-in-hand {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@include bp-max-small() {
  .why-good-in-hand h3 {
    font-size: 25px;
    line-height: 36px;
  }
  .free-text {
    font-size: 17px;
    line-height: 28px;
  }
}

@include bp-max-extra-small() {
  .why-good-in-hand h3 {
    font-size: 19px;
    line-height: 28px;

    p {
      font-size: 14px;
      line-height: 21px;
    }
  }
}
</style>
