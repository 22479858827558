export const moduleName = 'applicant';

// types
export default {
  MODULE_NAME: 'applicant',
  MUTATIONS: {
    SET_CHARTS: 'setCharts',
    SET_JOBLIST: 'setJobList',
    SET_JOB_DETAIL: 'setJobDetails',
    SET_PROFILE: 'setProfile',
    SET_PROFILE_VALUE: 'setProfileValue',
    TOGGLE_SELECTED: 'toggleSelected',
    SET_LOCATION_PREDICTIONS: 'setLocationPredictions',
    SET_FAVORITE: 'setFavorite',
    SET_TRASH: 'setTrash',
    UPDATE_JOBSUGGESTION_FORM: 'updateJobSuggestionForm',
    SET_OPTIMIZED_CHARTS: 'setOptimizedCharts',
    SET_APPLICATIONSTATUS_BASE: 'setApplicationStatusBase',
    SET_APPLICATIONSTATUS_MATCHINGJOBOFFERS: 'setApplicationStatusMatchingJobOffers',
    SET_APPLICATIONSTATUS_DELETEDMATCHES: 'setApplicationStatusDeletedMatches',
    UPDATE_MATCHINGJOBOFFER_LOADING: 'updateMatchingJobOffersLoading',
    SET_APPOINTMENT_DATA: 'setAppointmentData'
  },

  ACTIONS: {
    ANALYSE_CV: 'analyseCV',
    ANALYSE_TEXT: 'analyseText',
    ANALYSE: 'analyse',
    OPTIMIZE: 'optimize',
    GET_PROFILE: 'getProfile',
    UPDATE_PROFILE: 'updateProfile',
    GET_JOB_LIST: 'getJobList',
    GET_JOB_DETAIL: 'getJobDetail',
    GET_REFINED_JOB_LIST: 'getRefinedJobList',
    GET_LOCATION_PREDICTIONS: 'getLocationPredictions',
    GET_ALL_FAVORITE: 'getAllFavorite',
    MARK_FAVORITE: 'markFavorite',
    UNMARK_FAVORITE: 'unmarkFavorite',
    GET_ALL_TRASH: 'getAllTrash',
    MARK_TRASH: 'markTrash',
    UNMARK_TRASH: 'unmarkTrash',
    GET_APPLICATION_STATUS_BASE: 'getApplicationStatusBase',
    GET_APPLICATION_STATUS_MATCHING_JOB_OFFERS: 'getApplicationStatusMatchingJobOffers',
    GET_APPLICATION_STATUS_DELETED_MATCHES: 'getApplicationStatusDeletedMatches',
    GET_APPLICATION_STATUS_JOB_OFFER: 'getApplicationStatusJobOffer',
    GET_APPOINTMENT_DATA: 'getAppointmentData'
  },

  STATUS: {
    PROFILE: 'profile'
  }
};
