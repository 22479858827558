<template>
  <span v-if="isNew(timestamp)" class="caption-sticker">NEU</span>
</template>

<script>
export default {
  name: 'NewSticker',
  props: {
    timestamp: {
      type: String,
      default: '2018-07-21T13:15:00-01:00'
    },
    displayLogic: {
      type: Function,
      default: null
    }
  },
  methods: {
    isNew(timestamp) {
      return this.displayLogic
        ? this.displayLogic()
        : Date.now() - 2592000000 < new Date(timestamp).getTime();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';

.caption-sticker {
  display: inline-block;
  background-color: $color-red;
  color: $color-white;
  font-weight: 400;
  font-style: italic;
  font-size: 13px;
  transform: skew(-10deg, 0);
  padding: 4px 5px;
  position: relative;
  width: 2.7em;
  line-height: 1em;
  margin-left: 10px;
}
</style>
