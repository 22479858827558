<template>
  <div class="job-suggestion">
    <div class="job-suggestion-inner boxshadow-basic">
      <JobSuggestionFilters v-if="bpMedium" />
      <JobSuggestionMobileFilters v-if="bpMaxMedium" />
      <h2>
        <Editable resource_key="your_job_suggestion"/>
      </h2>
      <JobSuggestionTabs :state='state' :callback="listUpdate"/>
      <JobSuggestionList :filter='filter' :selectedTab="selectedTab" :loadingStatus="loading"/>
      <JobSuggestionFooter :loadingCallback="listReloading" />
    </div>
    <router-view name="modal"></router-view>
  </div>
</template>

<script>
// import { mapState } from 'vuex';

import JobSuggestionFilters from '../organisms/JobSuggestionFilters';
import JobSuggestionMobileFilters from '../organisms/JobSuggestionMobileFilters';
import JobSuggestionTabs from '../organisms/JobSuggestionTabs';
import JobSuggestionList from '../organisms/JobSuggestionList';
import JobSuggestionFooter from '../organisms/JobSuggestionFooter';
import Editable from '../molecules/Editable';
import breakPoints from '../../lib/mixins/breakPoints';

// import {jobList} from '../../api/applicant/__mocks__/__dummy__/joblist'

export default {
  name: 'JobSuggestion',
  components: {
    Editable,
    JobSuggestionFilters,
    JobSuggestionMobileFilters,
    JobSuggestionTabs,
    JobSuggestionList,
    JobSuggestionFooter
  },
  mixins: [breakPoints],
  data() {
    return {
      state: {
        all: true,
        new: false,
        favorite: false,
        trash: false
      },
      selectedTab: 'all',
      loading: false
    }
  },
  created() {
    console.log('created called');
  },
  destroyed() {
    console.log('destroyed called');
  },
  methods: {
    /* FIXME consider where this logich should be written. hier should not be */
    filter(jobList) {
      const { favorite, trash } = this.$store.state.applicant;

      if (this.state.all) {
        console.log(trash);
        this.selectedTab = 'all';
        return (trash) ? jobList.filter((i) => !trash.includes(i.id)) : jobList;
      } if (this.state.new) {
        this.selectedTab = 'new';
        if (trash) {
          return jobList.filter((i) => !trash.includes(i.id) && this.isNew(i.created_at));
        }
        return jobList.filter((i) => this.isNew(i.created_at));
      } if (this.state.favorite) {
        this.selectedTab = 'favorite';
        if (trash) {
          return (favorite)
            ? jobList.filter((i) => favorite.includes(i.id) && !trash.includes(i.id))
            : [];
        }
        return (favorite) ? jobList.filter((i) => favorite.includes(i.id)) : [];
      } if (this.state.trash) {
        this.selectedTab = 'trash';
        return (trash) ? jobList.filter((i) => trash.includes(i.id)) : [];
      }

      console.error('all state is disabled or something wrong. state:');
      this.selectedTab = 'all';

      this.$nextTick(() => {
        console.log('filter nextTick called');
        this.$forceUpdate();
      });
      // return default
      return jobList;
    },

    listUpdate() {
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },

    listReloading(status) {
      console.log(`listReloading called status: ${(status) ? 'true' : 'false'}`);
      this.loading = status;
    },

    isNew(timestamp) {
      if (this.$store.state.applicant.profile) {
        const lastLogin = new Date(this.$store.state.applicant.profile.lastLogin);
        const publishedAt = new Date(timestamp);
        return lastLogin.getTime() < publishedAt.getTime();
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';

.job-suggestion {
  position: fixed;
  top: 255px;
  left: 0;
  bottom: 0;
  width: 100vw;
  z-index: 100;
}

.job-suggestion-inner {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  background-color: $color-white;
}

h2 {
  margin-left: 16px;
  padding-top: 90px;
  font-size: 23px;
  font-weight: 900;
  padding-left: 22px;
}

@include bp-13inch-large() {
  .job-suggestion-inner {
    max-width: unset;
    margin-left: 120px;
    margin-right: 120px;
  }
}

@include bp-extra-large() {
  .job-suggestion-inner {
    max-width: 1960px;
  }
}

@include bp(#{1960px + 280px}) {
  .job-suggestion-inner {
    margin: 0 auto;
  }
}

@include bp-max-medium() {
  .job-suggestion {
    top: 233px;
    z-index: 5;

    .job-suggestion-inner {
      position: static;
      box-shadow: none;

      h2 {
        padding-top: 13px;
        padding-left: 0;
        font-size: 21px;
      }
    }
  }
}
</style>
