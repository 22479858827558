<template>
  <component :is="type" :to="(disabled)? '#' : to" :class="className" >
    <span class="cp-btn-inner"><slot></slot></span>
  </component>
</template>

<script>
import CpButton from './CpButton';

export default {
  name: 'CpButtonAnchor',

  extends: CpButton,

  props: {
    to: {
      type: [String, Object]
    }
  },

  computed: {
    type() {
      if (typeof this.to === 'string') {
        return 'router-link';
      } if (typeof (this.to) === 'object' && 'name' in this.to) {
        return 'router-link';
      }

      return 'a';
    },
    className() {
      let result = (this.disabled) ? 'disabled ' : '';
      result += (`${this.color} `);
      result += (`${this.size} `);
      result += (`${this.weight} `);
      result += 'cp-btn'
      return result;
    }
  }
};
</script>

<style lang="scss">
@import './buttons';
</style>
