import WelcomeJobOffer from '../components/templates/WelcomeJobOffer';
import AppointmentForm from '../components/templates/AppointmentForm';
import ThankYouAppointment from '../components/templates/ThankYouAppointment';
import AnswerForm from '../components/templates/AnswerForm';
import RewardRecommendationLinks from '../components/templates/RewardRecommendationLinks';
import RewardRecommendationForm from '../components/templates/RewardRecommendationForm';
import CompanyProfile from '../components/templates/CompanyProfile';

export default {
  routes: [
    {
      path: '/',
      name: 'WelcomeJobOffer',
      component: WelcomeJobOffer,
      children: [
        {
          path: '',
          components: {
            top: AnswerForm,
            bottom: AnswerForm
          }
        },
        {
          path: 'reward',
          name: 'RewardRecommendationLinks',
          components: {
            top: RewardRecommendationLinks,
            bottom: RewardRecommendationLinks
          }
        }
      ]
    },
    {
      path: '/company/:id',
      name: 'CompanyProfile',
      component: CompanyProfile
      /*
      props: {
        closeTo: { name: 'WelcomeJobOffer' }
      }
      */
    },
    {
      path: '/appointment',
      name: 'AppointmentForm',
      component: AppointmentForm
    },
    {
      path: '/thankyou',
      name: 'AppointmentDone',
      component: ThankYouAppointment
    },
    {
      path: '/reward-recommendation',
      name: 'RewardRecommendationForm',
      component: RewardRecommendationForm
    }
  ]
};
