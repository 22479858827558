import types from './types';

export default {
  /**
   * setMailUnread
   * @type {function}
   * @param state given automatically by framework
   * @param count updates count
   */
  [types.MUTATIONS.SET_MAIL_UNREAD](state, payload) {
    state.unread = payload.unread;
  }
}
