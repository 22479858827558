import { render, staticRenderFns } from "./EducationsDisplay.vue?vue&type=template&id=573fca75&scoped=true&"
import script from "./EducationsDisplay.vue?vue&type=script&lang=js&"
export * from "./EducationsDisplay.vue?vue&type=script&lang=js&"
import style0 from "./EducationsDisplay.vue?vue&type=style&index=0&id=573fca75&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "573fca75",
  null
  
)

export default component.exports