<template>
  <div class="personal-info-edit">
    <div class="column-2">
      <ImageForm :value="personalImage" @change="(v) => valueChanged('image', v)" :disabled="fresh" />
    </div>
    <div class="column-9">
      <div class="row">
        <LabelTextField slot="additional-before"
                        :value="valueOrBlank(values.owner.academicDegree)"
                        placeholder="z.B. Dr."
                        @change="(v) => valueChanged('owner.academicDegree', v)"
                        v-if="bpMaxMedium"
        >
          akad. Grad
        </LabelTextField>
        <LabelTextField :value="valueOrBlank(values.owner.namefirst)"
                        @change="(v) => valueChanged('owner.namefirst', v)" v-if="bpMaxMedium"
        >
          Vorname
        </LabelTextField>
        <div class="first-name" v-if="!bpMaxMedium">
          <LabelTextField :value="valueOrBlank(values.owner.academicDegree)"
                          placeholder="z.B. Dr."
                          @change="(v) => valueChanged('owner.academicDegree', v)"
          >
            akad. Grad
          </LabelTextField>
          <LabelTextField :value="valueOrBlank(values.owner.namefirst)"
                          @change="(v) => valueChanged('owner.namefirst', v)" v-if="!bpMaxMedium"
          >
            Vorname
          </LabelTextField>
        </div>
        <div class="last-name">
          <LabelTextField :value="valueOrBlank(values.owner.namelast)"
                          @change="(v) => valueChanged('owner.namelast', v)"
          >
            Familienname
          </LabelTextField>
        </div>
      </div>
      <div class="row">
        <div class="linkedin">
          <LabelTextField icon="linkedin" :value="valueOrBlank(values.linkedInLink)" placeholder="https://www.linkedin.com/in..." @change="(v) => valueChanged('linkedInLink', v)" @blur="validateLinkedInUrl" :valid="isValid('linkedIn')">
            LinkedIn
          </LabelTextField>
        </div>
        <div class="xing">
          <LabelTextField icon="xing" :value="valueOrBlank(values.xingLink)" placeholder="https://www.xing.com/profile..." @change="(v) => valueChanged('xingLink', v)" @blur="validateXingUrl" :valid="isValid('xing')">
            Xing
          </LabelTextField>
        </div>
      </div>
      <div class="row">
        <div class="zip">
          <LabelTextField icon="mail"
                          inputType="email"
                          :value="valueOrBlank(values.owner.email)"
                          @change="(v) => valueChanged('owner.email', v)"
          >
            E-Mail
          </LabelTextField>
        </div>
        <div class="telephone">
          <LabelTextField icon="phone"
                          :value="valueOrBlank(values.owner.phone)"
                          @change="(v) => valueChanged('owner.phone', v)"
          >
            Telefon
          </LabelTextField>
        </div>
      </div>
      <div class="row">
        <div class="zip">
          <LabelTextField :value="valueOrBlank(values.currentLocation.zip)"
                          @change="(v) => valueChanged('currentLocation.zip', v)"
          >
            Plz
          </LabelTextField>
        </div>
        <div class="province">
          <LabelTextField :value="valueOrBlank(values.currentLocation.province)"
                          @change="(v) => valueChanged('currentLocation.province', v)"
          >
            Ort
          </LabelTextField>
        </div>
      </div>
      <div class="row" v-if="values.telephoneInterview">
        <div class="available-from">
          <LabelTextField :value="valueOrBlank(values.telephoneInterview.availableFrom)"
                          @change="(v) => valueChanged('telephoneInterview.availableFrom', v)"
          >
            Verfügbarkeit
          </LabelTextField>
        </div>
        <div class="in-other-process" v-if="!bpMaxMedium">
          <YesNoSwitch
            :value="values.telephoneInterview.inOtherApplicationProcesses"
            @change="(v) => valueChanged('telephoneInterview.inOtherApplicationProcesses', v)"
          >
            In anderem Bewerbungsprozess:
          </YesNoSwitch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageForm from '../../molecules/ImageForm';
import LabelTextField from '../../molecules/LabelTextField';
import YesNoSwitch from '../../molecules/YesNoSwitch';
import util from '../../../lib/utils';
import formUtils from '../../../lib/mixins/formUtils';
import breakPoints from '../../../lib/mixins/breakPoints';

export default {
  name: 'PersonalInfoEdit',
  components: {
    ImageForm,
    LabelTextField,
    YesNoSwitch
  },
  mixins: [formUtils, breakPoints],

  data() {
    return {
      invalid: []
    };
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    fresh: {
      type: Boolean,
      defalut: false
    },
    form: {
      type: Object,
      required: true
    },
    callbackChange: {
      type: Function,
      required: true
    }
  },

  computed: {
    personalImage() {
      // const gender = 1; // TODO: refer profile
      const { gender } = this.values.owner;
      let result = `/assets/img/profile-picture-placeholder${gender}.png`;
      if (this.values.image && 'imagePath' in this.values.image) {
        let path = this.values.image.imagePath;
        if (path.match(/_[a-z]*\./g) != null) {
          path = path.replace(/_[a-z]*\./, '_best.');
        }
        result = `/${path}`;
      }
      return result;
    }
  },

  methods: {
    valueChanged(path, value) {
      let newVal = { ...this.values };
      const inputedValue = util.buildObjectFromPath(path, value);
      newVal = util.mergeDeep(newVal, inputedValue);
      this.callbackChange(newVal);
    },
    validateXingUrl() {
      const valid = (this.values.xingLink.indexOf('https://www.xing.com/profile') !== -1);
      this.setValid('xing', valid);
    },
    validateLinkedInUrl() {
      const valid = (this.values.xingLink.indexOf('https://www.linkedin.com/in') !== -1);
      this.setValid('linkedIn', valid);
    },
    setValidation(key, valid) {
      if (!valid) {
        this.invalid.push = key;
      } else {
        this.invalid = this.invalid.filter((item) => item !== 'xing')
      }
    },
    isValid(key) {
      return !(key in this.invalid);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_breakpoints.mixin';

$color-placeholder: rgba(255, 255, 255, 0.6);

.column-2,
.column-9 {
  display: inline-block;
  vertical-align: top;
}

.column-9 {
  width: 65%;
  margin-top: 40px;
  margin-bottom: 60px;

  .row {
    margin-left: 20px;
    display: flex;

    > div {
      display: inline-block;
      width: calc(50% - 20px);

      &:last-of-type {
        padding-left: 20px;
      }

      &.first-name {
        display: flex;

        .label-textfield {
          display: inline-block;

          &:first-of-type {
            width: 80px;
            // float: left;
            // width: 20%;

            /deep/ input {
              background-color: transparent;
              border: 1px solid rgba(255, 255, 255, 0.4);

              &.blank {
                font-size: 15px;

                &:-ms-input-placeholder {
                  color: $color-placeholder;
                }

                &::-ms-input-placeholder {
                  color: $color-placeholder;
                }

                &::-webkit-input-placeholder {
                  color: $color-placeholder;
                }

                &::placeholder {
                  color: $color-placeholder;
                }
              }
            }
          }

          &:last-of-type {
            width: calc(100% - 80px);
          }
        }
      }

      &.in-other-process {
        .yes-no-switch {
          display: block;
          margin-top: -15px;

          /deep/ {
            label {
              display: block;
              padding-top: 10px;
            }
          }
        }
      }

      /deep/ {
        .yes-no-switch label {
          font-size: 12px;
        }

        label {
          color: $color-white;

          span.slot {
            font-size: 12px;
          }
        }

        input[type='email'],
        input[type='number'],
        input[type='text'] {
          width: 100%;
          color: $color-white;
          background-color: rgba(255, 255, 255, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.05);

          &.blank {
            background-color: transparent;
            border: 1px solid rgba(255, 255, 255, 0.4);
            font-size: 15px;

            &:-ms-input-placeholder {
              color: $color-placeholder;
            }

            &::-ms-input-placeholder {
              color: $color-placeholder;
            }

            &::-webkit-input-placeholder {
              color: $color-placeholder;
            }

            &::placeholder {
              color: $color-placeholder;
            }
          }
        }
      }
    }
  }
}

@include bp-max-medium() {
  .personal-info-edit {
    width: 100%;
    padding: 0 30px;

    .column-9 {
      width: 100%;

      .row {
        margin-left: 0px;
        display: flex;
        flex-direction: column;

        > div {
          display: inline-block;
          width: calc(100%);

          &:last-of-type {
            padding-left: 0;
          }
        }
      }
    }
  }
}
</style>
