<template>
  <div ref="careerboardPage" class="competence-analyse careerboard-page">
    <!-- Competence Profile -->
    <div id="optimization-possibility-section" class="competence-analyse-section">
      <div class="competence-optimization-section-inner">
        <div class="cp-row">
          <CpHeader>
            <template slot="title">Deine Optimierungsmöglichkeiten</template>
            <template slot="taglines" >
              Hier siehst Du Möglichkeiten, wie Du Deine Bewerbungschancen in Bezug
              auf die von Dir ausgewählten Stellen erhöhen kannst.
            </template>
          </CpHeader>
        </div>
        <div class="cp-row">
          <div class="competence-optimization-radar">
            <chart-radar-competence :labels="radar.labels" :datasets="radar.datasets"/>
          </div>
        </div>
        <div class="competence-optimization-doughnut">
          <chart-doughnut-market-value :values="doughnut.values"
                                       :background_colors="doughnut.backgroundColors"
                                       chartWidth="140px"
          />
          <div class="cp-check-list-wrapper">
            <h4 class="check-list-caption">CV verbessern
              <!-- eslint-disable-next-line max-len -->
              <cp-question-tooltip tooltip="Unser System schlägt Dir Fähigkeiten und Erfahrungen vor, mit denen Du Deine Bewerbungschancen erhöhen kannst. Sofern Du über diese Fähigkeiten oder Erfahrungen verfügst, solltest Du sie bei Deiner Bewerbung ergänzen oder noch ausführlicher beschreiben. Durch Anklicken der Vorschläge siehst Du, wie stark Deine Bewerbungs-chancen hierdurch steigen können."
              ></cp-question-tooltip>
            </h4>
            <ul class="cp-check-list">
              <li v-for="(chance, i) in chances" class="cp-check-list-item" :key='i'>
                <input type="checkbox" class="cp-checkbox"
                       :id="'cv-optimize-check-' + i"
                       ref="cvOptimizeCheck"
                       :data-id="chance.competenceId"
                       @change="toggleCheckListItem"
                />
                <label :for="'cv-optimize-check-' + i">{{chance.name}}</label>
              </li>

            </ul>
          </div>
          <div class="competence-optimization-doughnut-footer">
            <router-link tag="p"
                         :to="{ name: 'ApplicantProfile', params: { applicantId: userId } }"
                         active-class="active"
            >
              Jetzt Bewerberprofil aktualisieren!
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div id="competence-optimization-footer-section" class="competence-analyse-section">
      <p>
        Bald wirst Du hier sehen, mit welchen Weiterbildungen Du Deine Jobchancen verbessern kannst.
      </p>
    </div>
  </div>
</template>

<script>
// framework
import { mapState } from 'vuex';

// own library
import { scrollToTop } from '../../lib/mixins';

// components
import CpHeader from '../molecules/CpHeader';
import ChartRadarCompetence from '../organisms/ChartRadarCompetence';
import ChartDoughnutMarketValue from '../organisms/ChartDoughnutMarketValue';
import CpQuestionTooltip from '../organisms/CpQuestionTooltip';

// vuex store
// import types, { moduleName } from '../../store/modules/applicant/types';

// const actionName = `${moduleName}/${types.ACTIONS.ANALYSE}`;

export default {
  name: 'Optimize',

  components: {
    CpHeader,
    ChartRadarCompetence,
    ChartDoughnutMarketValue,
    CpQuestionTooltip
  },

  mixins: [
    scrollToTop
  ],

  data() {
    return {
      doughnutBackgroundColors: [['#efefef', '#cccccc'], ['#189719', '#95CF1D']]
    };
  },

  computed: mapState({
    userId(state) {
      return state.applicant.profile ? state.applicant.profile.id : '';
    },

    doughnut(state) {
      return {
        values: [
          state.applicant.charts.marketValue,
          Math.max(
            0,
            state.applicant.optimizedCharts.marketValue - state.applicant.charts.marketValue
          )
        ],
        backgroundColors: [
          ['#efefef', '#cccccc'],
          ['#189719', '#95CF1D']
        ]
      };
    },

    radar(state) {
      const data = {
        labels: [],
        datasets: [{
          data: [],
          borderColor: '#189719',
          backgroundColor: ['rgba(149,207,29,0.7)', 'rgba(24,151,25,0.7)']
        }, {
          data: [],
          borderColor: '#999999',
          backgroundColor: '#999999',
          pointHitRadius: 0,
          pointRadius: 0
        }]
      };

      if (state.applicant.optimizedCharts.competenceProfile.points.length) {
        state.applicant.optimizedCharts.competenceProfile.points.forEach((point) => {
          data.datasets[0].data.push(point.weight);
        });
      } else {
        state.applicant.charts.competenceProfile.points.forEach((point) => {
          data.datasets[0].data.push(point.weight);
        });
      }

      state.applicant.charts.competenceProfile.points.forEach((point) => {
        data.labels.push(point.categoryText);
        data.datasets[1].data.push(point.weight);
      });

      return data;
    },

    chances(state) {
      return state.applicant.charts.optimizationChance;
    }
  }),

  /*
  created() {
    const radar_gradient2 = document
      .createElement('canvas')
      .getContext('2d')
      .createLinearGradient(150, 0, 250, 0);
    radar_gradient2.addColorStop(0, 'rgba(149,207,29,0.7)');
    radar_gradient2.addColorStop(1, 'rgba(24,151,25,0.7)');
    this.radarBackgroundColor2 = radar_gradient2;

    const doughnut_gradient = document
      .createElement('canvas')
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 200);
    doughnut_gradient.addColorStop(0, '#efefef');
    doughnut_gradient.addColorStop(1, '#cccccc');
    this.doughnutBackgroundColors[0] = doughnut_gradient;

    const doughnut_gradient2 = document
      .createElement('canvas')
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 200);
    doughnut_gradient2.addColorStop(0, '#95CF1D');
    doughnut_gradient2.addColorStop(1, '#189719');
    this.doughnutBackgroundColors[1] = doughnut_gradient2;
  },
  */

  methods: {
    toggleCheckListItem() {
      const optimizationChanceIds = [];
      const jobIds = this.$store.state.applicant.selected;
      const { profileId } = this.$store.state.applicant.profile;

      this.$refs.cvOptimizeCheck.forEach((item) => {
        if (item.checked) {
          optimizationChanceIds.push(item.getAttribute('data-id'));
        }
      });

      if (optimizationChanceIds.length) {
        this.$store.dispatch('applicant/optimize', { profileId, jobIds, optimizationChanceIds });
      } else {
        this.$store.commit('applicant/setOptimizedCharts', {
          competenceProfile: { points: [] },
          marketValue: 0,
          futureOverview: [],
          jobPerformance: [],
          cvPerformance: [],
          demandCompetence: [],
          optimizationChance: [],
          profileID: ''
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/organisms/check_list';
@import '../../scss/templates/competence_analyse';
@import '../../scss/atoms/_breakpoints.mixin';

/deep/ .cp-info {
  margin-top: -17px;
}

@include bp-max-medium() {
  #optimization-possibility-section {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;

    .competence-optimization-section-inner {
      flex-flow: column;
      display: flex;

      > .cp-row:nth-child(1) {
        order: 1;

        .header {
          margin-left: 0;
        }
      }

      > .cp-row:nth-child(2) {
        order: 3;

        .competence-optimization-radar {
          margin-left: 0;
        }
      }

      > .competence-optimization-doughnut {
        order: 2;
        position: relative;
        width: 100%;
        box-shadow: none;

        h4.check-list-caption {
          border: none;
        }

        .competence-optimization-doughnut-footer {
          display: none;
        }
      }
    }
  }
}

</style>
