<template>
  <section class="thank-you-appointment">
    <div class="center">
      <h2>
        <Translation resource_key="joboffer.thank-you-appointment-title" />
      </h2>
      <Translation datakey="sentEmailText" resource_key="joboffer.we-sent-appointment-email" />
      <p v-html="sentEmailText"></p>
      <p>
        <em>
          <Translation resource_key="joboffer.looking-forward-appointment" />
        </em>
      </p>
    </div>
    <FulfillApplicantProfile />
  </section>
</template>

<script>
import Translation from '../atoms/Translation'
import FulfillApplicantProfile from '../organisms/FulfillApplicantProfile'
import scrollIt from '../../lib/animatingScroll';

export default {
  name: 'ThankYouAppointment',

  components: {
    Translation,
    FulfillApplicantProfile
  },

  data() {
    return {
      sentEmailText: ''
    }
  },

  mounted() {
    scrollIt(document.body);
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';

.thank-you-appointment {
  width: 100%;
  max-width: 980px;
  margin: 50px auto;
  padding-top: 50px;
  padding-bottom: 55px;
  padding-left: var(--padding-edge);
  padding-right: var(--padding-edge);
  background: $color-white;
  font-size: 17px;

  .center {
    text-align: center;
    max-width: 792px;
    margin: 20px auto;
  }

  .left {
    text-align: left;
    max-width: 792px;
    margin: 40px auto;
  }

  h2,
  h3,
  em {
    margin: 0;
    font-weight: 800;
  }

  h2 {
    font-size: 38px;
  }

  h3 {
    font-size: 21px;
  }

  em {
    font-style: normal;
  }
  a {
    text-decoration: none;
    color: $color-black;
  }
}
</style>
