<template>
  <section class="documents">
    <diV class="wrapper">
      <h2><Translation resource_key="applicant.profile.documents.title"/></h2>
      <EditableArea :values="values" :form="form" :callbackSave="onSave" :callbackCancel="onCancel" :disabled="fresh">
        <DocumentsDisplay slot="display" :fresh="fresh" :values="documents" :applicantId="applicantId" :applicantProfileId="applicantProfileId" />
        <DocumentsEdit slot="edit" :values="bufferedValue" :form="form" :callbackChange="onChange"/>
      </EditableArea>
    </diV>
  </section>
</template>

<script>
import EditableArea from '../../molecules/EditableArea';
import DocumentsDisplay from './DocumentsDisplay';
import DocumentsEdit from './DocumentsEdit';
import Translation from '../../atoms/Translation';

export default {
  name: 'Documents',
  components: {
    EditableArea,
    DocumentsDisplay,
    DocumentsEdit,
    Translation
  },
  data() {
    return {
      bufferedValue: null
    };
  },

  props: {
    values: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    fresh: {
      type: Boolean,
      default: false
    },
    callbackOnSave: {
      type: Function,
      required: true
    }
  },

  computed: {
    documents() {
      const result = {};
      if ('cv' in this.values && this.values.cv) {
        result['0'] = { type: 'cv', ...this.values.cv };
      }
      if (('furtherDocuments' in this.values) && this.values.furtherDocuments.length) {
        this.values.furtherDocuments.map((v, idx) => {
          result[`${idx + 1}`] = { type: 'furtherDocument', ...v }
          return v;
        });
      }
      return result;
    },
    applicantId() {
      return this.values.owner.id;
    },
    applicantProfileId() {
      return this.values.id;
    }
  },

  created() {
    this.setOriginalValue();
  },

  methods: {
    setOriginalValue() {
      this.bufferedValue = this.documents;
    },

    /**
     * here we will compose from inputed values with original JSON data,
     * then emit to upper component. upper component will recieve and call vuex store.
     */
    onSave() {
      const self = this;
      return new Promise((resolve, _reject) => {
        // revert into original format as Array
        const cv = Object.values(self.bufferedValue).find((v) => v.type === 'cv' && ('path' in v || 'file' in v));
        const furtherDocuments = Object.values(self.bufferedValue)
          .filter((v) => v.type === 'furtherDocument' && ('extension' in v || ('path' in v && v.path.length > 0) || 'file' in v));
        /* global _ */
        const result = _.cloneDeep(self.values);
        if (cv) {
          result.cv = cv;
          if (!('file' in result.cv)) {
            // append blank input file field
            ([result.cv.file] = document.getElementById('cv-upload-file[0]').files);
            result.cv.extension = 'pdf'; // as default value
          }
        } else {
          // it should be occured never
          delete result.cv;
        }

        if (furtherDocuments) {
          result.furtherDocuments = furtherDocuments;
        } else {
          delete result.furtherDocuments;
        }

        self.callbackOnSave(result).then(() => {
          self.setOriginalValue();
          resolve()
        });
      });
    },

    onCancel() {
      this.setOriginalValue();
    },

    onChange(values) {
      this.bufferedValue = values;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_breakpoints.mixin';

.documents {
  background-color: $color-ultralightgrey;
  padding: 60px 0;
  position: relative;

  .wrapper {
    max-width: 840px;
    width: 100%;
    margin: 0 auto;

    .editable-area {
      position: static;
    }
  }
}

@include bp-max-medium {
  .documents {
    padding: 50px 30px;

    .wrapper {
      word-wrap: break-word;

      h2 {
        font-size: 24px !important;
        line-height: 34px !important;
        margin-bottom: 30px !important;
      }
    }
  }
}

</style>
