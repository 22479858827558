<template>
  <a href="/">
    <img :width="width" :height="height" src="/assets/img/cp-logo.svg" alt="CareerPeakr" :class="`cp-logo ${size}`" />
  </a>
</template>

<script>
export default {
  name: 'CpLogo',
  props: {
    size: {
      type: String,
      default: ''
    }
  },
  computed: {
    width() {
      // add size logic here
      return this.size === 'small' ? '140' : '257';
    },
    height() {
      return this.size === 'small' ? '20' : '35';
    }
  }
};
</script>

<style lang="scss">
.cp-logo {
  display: block;

  &.small {
    width: 140px;
  }
}
</style>
