<template>
  <div class="contact-card">
    <div class="contact-card-inner">
      <div class="contact-card-avatar" :style="'background-image: url(' + avatar + ');'"></div>
      <div class="contact-card-text">
        <h4 class="contact-card-name">{{ name }}</h4>
        <p class="contact-card-email"><a :href="'mailto:' + email">{{ email }}</a></p>
        <p v-if="tel !== ''" class="contact-card-tel">{{ tel }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactCard',
  props: {
    avatar: String,
    name: String,
    email: String,
    tel: String
  }
}
</script>

<style lang="scss" scoped>
  .contact-card {
    position: relative;
    font-size: 17px;
    padding-left: 80px;
    min-height: calc(79px + 0.4em);
  }

  .contact-card-avatar {
    position: absolute;
    left: 0;
    top: 0.4em;
    width: 69px;
    height: 78px;
    background: transparent no-repeat center;
    background-size: cover;

    &:after {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent url('/assets/img/contact-avatart-bubble.svg') no-repeat center;
      background-size: cover;
      content: '';
    }
  }

  .contact-card-text {
    > * {
      margin: 0;
    }

    a {
      color: #3c3c3c;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .contact-card-name {
    color: #00c6f8;
    font-weight: 400;
  }

  .contact-card-email:before,
  .contact-card-tel:before {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.4em;
    background: transparent url('/assets/img/icon-email.svg') no-repeat center;
    background-size: contain;
    content: '';
    vertical-align: middle;
  }

  .contact-card-tel:before {
    background-image: url('/assets/img/icon-tel.svg');
  }
</style>
