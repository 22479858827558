<template>
  <div class="greeting-card">
    <ContactCard :avatar="sourceravatar"
                 :name="sourcername"
                 :email="sourceremail"
                 :tel="sourcerphone"
    />
    <AppointmentCard :username="username"
                     :jobOffer="jobOffer"
                     :availableAppointments="availableAppointments"
    />
    <div class="greeting-card-messenger">
      <h4 class="greeting-messenger-title">Möchten Sie uns eine Nachricht schreiben?</h4>
      <p class="greeting-messenger-error" v-if="formError">
        Bitte versuchen Sie später erneug.
      </p>
      <form v-if="!formSent" v-on:submit.prevent="onSubmit"
            :class="{'contact-form': true, 'ajax-form': true, 'loading': formLoading}" method="post"
            :action="contactaction"
            novalidate>
        <div class="form-row">
          <textarea :class="{'contactRequestApplicant_mailtext': true, 'error': textempty}"
                    name="contactRequestApplicant[mailtext]" placeholder="Ihre Nachricht">
          </textarea>
        </div>
        <div class="form-row">
          <CpButton color="blue" size="large full" type="submit" weight="bold">
            Nachricht senden
          </CpButton>
        </div>
      </form>
      <p class="greeting-messenger-response" v-if="formSent">
        Vielen Dank, wir werden uns schnellstmöglich bei Ihnen melden.
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';

import AppointmentCard from '../organisms/Applicant/AppointmentCard';
import ContactCard from '../organisms/ContactCard';
import CpButton from '../atoms/CpButton/CpButton';

export default {
  name: 'GreetingCard',

  components: {
    ContactCard,
    AppointmentCard,
    CpButton
  },

  props: {
    sourcerId: Number,
    sourceravatar: String,
    sourcername: String,
    sourceremail: String,
    sourcerphone: String,
    username: String,
    contactaction: String,
    appointmenturl: String
  },

  data() {
    return {
      textempty: false,
      formSent: false,
      formError: false,
      formLoading: false,
      availableAppointments: []
    }
  },

  methods: {
    onSubmit(e) {
      const form = e.currentTarget;
      const textarea = form.querySelector('.contactRequestApplicant_mailtext');
      const text = textarea.value;

      if (text.length) {
        this.formLoading = true;

        axios.post(form.getAttribute('action'), new FormData(form))
          .then((_result) => {
            this.formLoading = false;
            this.formSent = true;
          })
          .catch((_error) => {
            this.formLoading = false;
            this.formError = true;
          });

        this.textempty = false;
      } else {
        textarea.focus();
        this.textempty = true;
      }
    }
  },

  watch: {
    jobOffer(val) {
      if (val) {
        axios.get(`/api/v2/applicationstatus/appointment/${val.sourcerId}/${this.$careerpeakr.locale}`)
          .then((response) => {
            this.availableAppointments = response.data.sourcer.availableAppointments;
          });
      }
    }
  },

  computed: mapState('applicant', {
    /**
     * refer always only first matched jobOffer for contact
     */
    jobOffer: (state) => {
      if (state.applicationstatus.matchingJobOffers
          && state.applicationstatus.matchingJobOffers.length) {
        return state.applicationstatus.matchingJobOffers[0];
      }

      return false;
    }
  })
};
</script>

<style lang="scss">
@import '../../scss/atoms/_colors';

.greeting-card-text {
  margin-top: 20px;
  border-top: 1px solid #c4c4c4;

  p {
    font-size: 17px;
  }
}

.greeting-card-title {
  font-weight: 900;
}

.greeting-card-messenger {
  font-size: 17px;

  textarea {
    display: block;
    width: 100%;
    height: 200px;
    border: 1px solid #c4c4c4;
    resize: none;
    font-size: 17px;
    padding: 14px 14px 0;

    &.error {
      background-color: #f8e0e0;
    }
  }
}

.contact-form {
  display: block;
  position: relative;

  &.loading {
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $color-white;
      content: '';
    }

    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      display: block;
      width: 50px;
      height: 50px;
      background: transparent url('/assets/img/loader.svg') no-repeat center;
      background-size: contain;
      margin: -25px 0 0 -25px;
      content: '';
      animation: rotation 1.4s linear infinite;
    }
  }
}

.greeting-messenger-title {
  margin: 30px 0 14px;
  font-size: 17px;
  font-weight: 900;
}

.greeting-messenger-error {
  color: $color-red;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
