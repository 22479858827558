<template>
  <transition name="modal" :duration="{ leave: 1000 }">
    <div class="company-profile">
      <ModalBase :closeTo="closeTo" displayCloseButton="true" :waitingMessage="false">
        <div class="company-profile-page" v-if="profile">
          <CompanyProfileHeader :profile="profile" />
          <CompanyAtAGlance :profile="profile.profile" />
          <CompanyDescription :profile="profile.profile" />
        </div>
      </ModalBase>
    </div>
  </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import ModalBase from '../molecules/ModalBase';
import CompanyProfileHeader from './CompanyProfileHeader';
import CompanyAtAGlance from './CompanyAtAGlance';
import CompanyDescription from './CompanyDescription';
import CompanyStore from '../../store/modules/company';

const { mapState, mapActions } = createNamespacedHelpers('company');
const { types } = CompanyStore;

export default {
  name: 'CompanyProfile',
  components: {
    ModalBase,
    CompanyProfileHeader,
    CompanyAtAGlance,
    CompanyDescription
  },

  props: {
    closeTo: {
      type: [String, Object],
      default: '/'
    }
  },

  data() {
    return {
      job: null
    }
  },

  computed: {
    ...mapState([
      'profile'
    ])
  },

  created() {
    console.log(this.$route.params.id);
    this.loadCompanyProfile({
      companyId: this.$route.params.id,
      locale: this.$careerpeakr.locale
    });
  },

  methods: {
    ...mapActions({
      loadCompanyProfile: types.ACTIONS.LOAD_PROFILE
    })
  }
}
</script>

<style lang="scss">
@import '../../scss/atoms/colors';
@import '../../scss/atoms/_breakpoints.mixin';

.company-profile .modal-inner {
  max-width: 1200px;
  padding: 0;
}

.company-profile .modal-inner .container {
  padding: 0;
}

.company-profile-page {
  max-width: 1200px;
  background-color: $color-white;
  margin: 0 auto;
}

@include bp-max-medium() {
  .company-profile {
    .modal-outer {
      padding: 0;

      &:before {
        display: none;
      }
    }

    .modal-inner {
      width: 100%;
      height: 100%;

      .container {
        max-height: 100%;
      }

      .cp-modal-close {
        left: auto;
        top: 10px;
      }
    }
  }
}

</style>
