<template>
  <div class="company-text-base">
    <h3><slot name="title"></slot></h3>
    <div class="content free-text">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
// Mock server data
if (typeof window.Jobpassion === 'undefined') {
  window.Jobpassion = {};
}

export default {
  name: 'CompanyTextBase',

  props: {
    profile: {
      type: Object,
      requreid: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';

.company-text-base {
  color: $color-darkgrey;
  font-size: 17px;
  line-height: 28px;

  h3 {
    color: $color-blacky;
    font-size: 30px;
    line-height: 49px;
  }

  .content /deep/ {
    h4 {
      color: $color-blacky;
      font-size: 21px;
    }

    strong {
      color: $color-blacky;
      font-weight: 900;
    }

    p {
      font-weight: normal;
    }

    ul {
      > li {
        padding-left: 40px;
        line-height: 41px;

        &:before {
          content: '\e813';
          color: $color-blue;
          font-family: fontello; // stylelint-disable-line
          margin-left: -40px;
          margin-right: calc(40px - 1em);
        }

        > ul {
          > li {
            &:before {
              font-family: Lato; // stylelint-disable-line
              content: '\2022';
            }
          }
        }
      }
    }
  }
}
</style>
