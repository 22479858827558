<template>
  <div class="multiple-element-form">
    <component
      v-for="(item, index) in value"
      :is="component"
      :value="item"
      :index="parseInt(index)"
      :form="form"
      :key="index"
      :callbackRemove="onRemove(index)"
      @change="onChange($event, index)"
    />
    <AddElementButton :callback="onAdd" />
  </div>
</template>

<script>
import { AddElementButton } from '../atoms/CpButton';

export default {
  name: 'MultipleElementForm',
  components: {
    AddElementButton
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      default: null
    },
    component: {
      type: Object,
      required: true
    },
    callbackAdd: {
      type: Function,
      required: true
    },
    callbackRemove: {
      type: Function,
      required: true
    }
  },
  /*
  data() {
    return {
      displaying: true,
      editing: false,
      loading: false
    };
  },
  */
  methods: {
    onAdd() {
      this.callbackAdd();
    },
    onChange(value, index) {
      this.$emit('change', { index, value });
    },
    onRemove(index) {
      const self = this;
      return () => {
        self.callbackRemove(index);
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_breakpoints.mixin';

.multiple-element-form {
  min-height: 120px;
  position: relative;
  // text-align: center;

  /deep/ {
    .add-element-button {
      margin: 0 auto;
      margin-top: 20px;
    }
  }

  @include bp-max-medium() {
    padding: 0;
    min-height: auto;

    .add-element-button {
      padding: 16px 8px;
    }
  }
}
</style>
