<template>
  <CpButton color="dark" class="remove-element-button" :size="size" :callback="onClick" >
    <span class="circle">—</span>
    <template v-if="size != 'small'">Entfernen</template>
  </CpButton>
</template>

<script>
import CpButton from './CpButton';

export default {
  name: 'RemoveElementButton',
  components: {
    CpButton
  },
  props: {
    callback: {
      type: Function
    },
    color: {
      type: String,
      default: 'dark'
    },
    size: {
      type: String,
      default: 'long'
    },
    weight: {
      type: String,
      default: 'regular'
    }
  },

  computed: {
    styleClasses() {
      const classes = [];

      if (typeof this.color === 'string') {
        classes.push(this.color);
      }

      if (typeof this.size === 'string') {
        classes.push(this.size);
      }

      if (typeof this.weight === 'string') {
        classes.push(this.weight);
      }

      return classes.join(' ');
    }
  },

  methods: {
    onClick(e) {
      if (typeof this.callback === 'function') {
        const result = this.callback(e);
        if (undefined !== result) {
          return result;
        }
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
@import './buttons';

.remove-element-button.cp-btn {
  border-radius: 20px;
  width: 110px;
  height: 30px;
  font-size: 14px;
  padding: 5px 0;
  background: auto;
  background-color: $color-dustygrey !important;
  &.small {
    width: 30px;
    padding-left: 1px;
  }
}

.remove-element-button.cp-btn.medium {
  line-height: 25px;
}
span.circle {
  width: 16px;
  height: 16px;
  border: 1px solid $color-white;
  border-radius: 15px;
  display: inline-block;
  line-height: 13px;
  margin-right: 2px;
}
</style>
