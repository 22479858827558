<template>
  <div class="company-concept">
    <h3><Translation resource_key="company.profile.cp_descriptionCompany" /></h3>
    <div class="free-text" v-html="removeStyleAttr(profile.descriptionCompany)"></div>
  </div>
</template>

<script>
import Translation from '../atoms/Translation';
import removeStyleAttr from '../../lib/mixins/htmlUtils';

// Mock server data
if (typeof window.Jobpassion === 'undefined') {
  window.Jobpassion = {};
}

export default {
  name: 'CompanyConcept',
  components: {
    Translation
  },

  mixins: [removeStyleAttr],

  props: {
    profile: {
      type: Object,
      requreid: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';
@import '../../scss/atoms/layout';

.company-concept {
  color: $color-darkgrey;
  font-size: 17px;
  line-height: 28px;
  max-width: 894px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  // padding-left: var(--padding-edge);
  // padding-right: var(--padding-edge);

  h3 {
    color: $color-blacky;
    font-size: 30px;
    line-height: 49px;
    margin: 0;
  }

  h4 {
    color: $color-blacky;
    font-size: 21px;
  }

  strong {
    color: $color-blacky;
    font-weight: 900;
  }

  p {
    font-weight: normal;
  }
}

@include bp-max-medium() {
  .company-concept {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@include bp-max-small() {
  .company-concept h3 {
    font-size: 25px;
    line-height: 36px;
  }
}

@include bp-max-extra-small() {
  .company-concept h3 {
    font-size: 19px;
    line-height: 28px;

    p {
      font-size: 14px;
      line-height: 21px;
    }
  }
}
</style>
