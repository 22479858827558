<template>
  <section class="personal-info">
    <EditableArea :values="values" :form="form" :callbackSave="onSave" :callbackCancel="onCancel" >
      <PersonalInfoDisplay slot="display" :values="values" :fresh="fresh" />
      <PersonalInfoEdit slot="edit"
                        :values="bufferedValue"
                        :fresh="fresh"
                        :form="form"
                        :callbackChange="onChange"
      />
    </EditableArea>
  </section>
</template>

<script>
import EditableArea from '../../molecules/EditableArea';
import PersonalInfoDisplay from './PersonalInfoDisplay';
import PersonalInfoEdit from './PersonalInfoEdit';
// import dosier from './dosier.json';

export default {
  name: 'PersonalInfo',
  components: {
    EditableArea,
    PersonalInfoDisplay,
    PersonalInfoEdit
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    callbackOnSave: {
      type: Function,
      required: true
    },
    fresh: {
      type: Boolean
    }
  },
  data() {
    return {
      bufferedValue: {}
    };
  },
  created() {
    this.setOriginalValue();
  },
  methods: {
    onSave() {
      const self = this;
      return new Promise((resolve, _reject) => {
        // revert into original format as Array
        const { image } = self.bufferedValue;

        /* global _ */
        const result = _.cloneDeep(self.bufferedValue);
        if (image && 'extension' in image) {
          result.image = image;
        } else {
          delete result.image;
        }

        self.callbackOnSave(result).then(() => {
          self.setOriginalValue();
          resolve()
        });
      });
    },
    onCancel() {
      this.setOriginalValue();
    },
    onChange(values) {
      this.bufferedValue = values;
      // this.$emit('change', values);
    },
    setOriginalValue() {
      // make object from array
      this.bufferedValue = { ...this.values };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_breakpoints.mixin';

.personal-info {
  max-width: 1200px;
  margin: 0 auto;
  background: $gradient-blue-horizontal-big;

  /deep/ .icon-edit:before {
    color: $color-white;
  }

  @include bp-max-medium() {
    word-wrap: break-word;
  }
}
</style>
