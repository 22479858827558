<template>
  <transition name="modal" :duration="{ leave: 1000 }">
    <div class="appointment">
      <ModalBase :closeTo="{name: 'StatusHome'}" displayCloseButton="true" :waitingMessage="false">
        <div class="cp-outer" :class="{'dialog-only' : dialogOnly}">
          <router-view></router-view>
        </div>
      </ModalBase>
    </div>
  </transition>
</template>

<script>
import ModalBase from '../../../molecules/ModalBase';

// Mock server data
if (typeof window.Jobpassion === 'undefined') {
  window.Jobpassion = {};
}

export default {
  name: 'Appointment',

  components: {
    ModalBase
  },

  watch: {
    $route(to, _from) {
      console.log(to);
      console.log(to.fullPath.indexOf('appointment') !== -1
          || to.fullPath.indexOf('thankyou') !== -1);
      this.dialogOnly = to.fullPath.indexOf('appointment') !== -1
          || to.fullPath.indexOf('thankyou') !== -1;
    }
  },

  data() {
    return {
      dialogOnly: false
    };
  },

  created() {
    this.$store.dispatch('applicant/getAppointmentData', { sourcerId: this.$route.params.sourcerId, locale: this.$careerpeakr.locale });
  }
};
</script>

<style lang="scss">
  @import '../../../../scss/atoms/_breakpoints.mixin';

  .appointment {
    .modal-inner {
      max-width: 980px;
      padding: 0;

      .container {
        padding: 0;

        .appointment-form,
        .thank-you-appointment {
          margin: 0;
        }
      }

      .content-wrapper {
        max-width: none;
        margin: 0 30px;
      }
    }

    @include bp-max-medium() {
      .modal-outer {
        padding: 0;

        &:before {
          display: none;
        }
      }

      .modal-inner {
        width: 100%;
        height: 100%;

        .container {
          max-height: 100%;
        }

        .cp-modal-close {
          left: auto;
          top: 10px;
        }
      }
    }
  }

  .cp-outer {
    &.dialog-only {
      //background: transparent url(/assets/img/CP_Header_Arbeitnehmer_01.jpg) no-repeat top right;
      background-size: cover;
      padding-top: 80px;
      padding-bottom: 60px;
    }
  }

  .modal-opened {
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }
</style>
