<template>
  <div v-if="visible" class="sticky-info-box cp-btn" :class="styleClasses">
    <div class="sticky-info-inner">
      <slot name="StickyInfoText"></slot>
    </div>
    <button class="close" @click="close"></button>
  </div>
</template>

<script>
export default {
  name: 'StickyInfoBox',

  props: {
    color: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {
      visible: true
    };
  },

  computed: {
    styleClasses() {
      const classes = [];

      if (typeof this.color === 'string') {
        classes.push(this.color);
      }

      return classes.join(' ');
    }
  },

  methods: {
    close() {
      this.visible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../scss/atoms/_colors';
@import '../../scss/atoms/_breakpoints.mixin';
// @import '../atoms/CpButton/_buttons';

.sticky-info-box {
  position: absolute;
  z-index: 999;
  display: block;
  text-align: center;
  font-size: 17px;
  line-height: 24px;
  color: $color-white;
  padding: 28px 12px;
  width: 100%;
  max-width: 400px;
}

.close {
  display: block;
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
  width: 10px;
  height: 9px;
  overflow: hidden;
  text-indent: -999px;

  &:before,
  &:after {
    display: block;
    width: 1px;
    height: 21px;
    position: absolute;
    background: $color-white;
    content: '';
    top: 0;
  }

  &:before {
    left: 0;
    transform: rotate(-45deg);
    transform-origin: right top;
  }

  &:after {
    right: 0;
    transform: rotate(45deg);
    transform-origin: left top;
  }
}

@include bp-max-medium() {
  .sticky-info-box {
    max-width: 100%;

    .sticky-info-inner {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}
</style>
