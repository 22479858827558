import Vue from 'vue';
import VueScreenSize from 'vue-screen-size';

Vue.use(VueScreenSize);

const breakPoints = {
  bpExtraSmall: 320,
  bpSmall: 480,
  bpMediumSmall: 580, // added by ym@henkelhiedl.,
  bpMedium: 768,
  bpTablet: 1024,
  bpLarge: 1200,
  bpLargeWithPadding: 1235,
  bp13inchLarge: 1440,
  bpExtraLarge: 1600,
  bp15inchLarge: 1680,
  bpSuperLarge: 2000
};

export default {
  computed: {
    bpMaxExtraSmall() {
      return this.$vssWidth <= breakPoints.extraSmall;
    },
    bpExtraSmall() {
      return this.$vssWidth > breakPoints.extraSmall;
    },
    bpMaxSmall() {
      return this.$vssWidth <= breakPoints.small;
    },
    bpSmall() {
      return this.$vssWidth > breakPoints.small;
    },
    bpMaxMediumSmall() {
      return this.$vssWidth <= breakPoints.bpMediumSmall;
    },
    bpMediumSmall() {
      return this.$vssWidth > breakPoints.bpMediumSmall;
    },
    bpMaxMedium() {
      return this.$vssWidth <= breakPoints.bpMedium;
    },
    bpMedium() {
      return this.$vssWidth > breakPoints.bpMedium;
    },
    bpMaxTablet() {
      return this.$vssWidth <= breakPoints.bpTablet;
    },
    bpTablet() {
      return this.$vssWidth > breakPoints.bpTablet;
    },
    bpMaxLarge() {
      return this.$vssWidth <= breakPoints.bpLarge;
    },
    bpLarge() {
      return this.$vssWidth > breakPoints.bpLarge;
    },
    bpMaxLargeWithPadding() {
      return this.$vssWidth <= breakPoints.bpLargeWithPadding;
    },
    bpLargeWithPadding() {
      return this.$vssWidth > breakPoints.bpLargeWithPadding;
    },
    bpMax13inchLarge() {
      return this.$vssWidth <= breakPoints.bp13inchLarge;
    },
    bp13inchLarge() {
      return this.$vssWidth > breakPoints.bp13inchLarge;
    },
    bpMaxExtraLarge() {
      return this.$vssWidth <= breakPoints.bpExtraLarge;
    },
    bpExtraLarge() {
      return this.$vssWidth > breakPoints.bpExtraLarge;
    },
    bpMax15inchLarge() {
      return this.$vssWidth <= breakPoints.bp15inchLarge;
    },
    bp15inchLarge() {
      return this.$vssWidth > breakPoints.bp15inchLarge;
    },
    bpMaxSuperLarge() {
      return this.$vssWidth <= breakPoints.bpSuperLarge;
    },
    bpSuperLarge() {
      return this.$vssWidth > breakPoints.bpSuperLarge;
    }
  }
};
