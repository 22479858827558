<template>
  <div class="file-upload-container">
    <span class="title">Angezeigter Name</span>
    <p v-if="errors.length">
      <b>Bitte korrigieren sie die folgenden Fehler:</b>
      <ul>
        <li v-for="(error, idx) in errors" :key="idx">{{ error }}</li>
      </ul>
    </p>
    <div class="file-upload">
      <input type="text"
             class="custom-file-name"
             name="file_name"
             :value="fileName"
             :placeholder="placeholder"
             @change="onNameChange"
             :disabled="value.type === 'cv'"
      />
      <div class="file-upload-form">
        <div class="file-name" :class="{ selected: cvSelected }">{{uploadedCV}}</div>
        <input
          type="file"
          :name="`document[${index}]`"
          :id="`cv-upload-file[${index}]`"
          @change="onFileChange"
          ref="cv"
          :accept="acceptableFileTypes"
          value=""
        />
        <label :for="`cv-upload-file[${index}]`" class="cp-btn">
          Datei {{ verb }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileForm',
  props: {
    value: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      requreid: true
    }
  },

  data() {
    return {
      placeholder: 'Bitte geben Sie den Dateinamen ein',
      uploadedCV: 'Keine Datei ausgewählt',
      cvSelected: false,
      uploading: false,
      hasError: false,
      errors: []
    };
  },
  computed: {
    acceptableFileTypes() {
      return [
        'text/rtf',
        'application/rtf',
        'text/richtext',
        'application/pdf',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ].join();
    },
    fileName() {
      if (this.value.type === 'cv') {
        return 'Lebenslauf';
      }
      if (!('shownName' in this.value)) {
        return null;
      }
      return this.value.shownName;
    },
    verb() {
      return ('path' in this.value) ? 'ändern' : 'wählen';
    }
  },
  created() {
    if ('path' in this.value) {
      this.uploadedCV = '...';
    }
  },
  methods: {
    checkFileName(e) {
      this.error = [];

      if (!this.file_name) {
        this.error.push('File name required');
      }

      e.preventDefault();
    },
    onNameChange(e) {
      const newValue = { ...this.value };
      newValue.shownName = e.target.value;
      this.$emit('change', newValue);
    },
    onFileChange(e) {
      if (e.target.files.length) {
        this.cvSelected = true;
        this.uploadedCV = e.target.files[0].name
          .split('.')
          .slice(0, -1)
          .join('.');
        const file = e.target.files[0];
        const newValue = { ...this.value };
        newValue.file = file;
        const extension = e.target.files[0].name.split('.').pop();
        newValue.extension = (extension.lenght === 0) ? 'pdf' : extension; // pdf as default value
        newValue.attached = true;
        this.$emit('change', newValue);
      } else {
        this.cvSelected = false;
        this.uploadedCV = 'Keine Datei ausgewählt';
        const file = null;
        const newValue = { ...this.value, file };
        this.$emit('change', newValue);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/typographie';
@import '../../scss/atoms/breakpoints.mixin';

input[type='file'] {
  display: none;
}

@include bp-max-medium() {
  .file-upload {
    .file-name {
      font-size: 17px;
    }

    input[type='file'] {
      width: 50%;
    }
  }
}

.file-upload-container {
  .title {
    font-size: $fs-medium;
    color: $color-dustygrey;
    font-style: italic;
    font-weight: 400;
    display: block;
    text-align: left;
  }

  .file-upload {
    margin-top: 5px;

    .custom-file-name {
      border: 1px solid $color-lightgrey;
      padding: 8px 10px;
      font-size: $fs-regular;
      max-width: 790px;
      font-weight: $font-bold;
      background-color: $color-lightgrey;
      margin-top: 0;
      margin-bottom: 25px;
      width: 100%;
      display: block;
    }

    .file-upload-form {
      max-width: 790px;
      margin-bottom: 25px;

      .file-name {
        border: 1px solid $color-lightgrey;
        background-color: $color-lightgrey;
        display: inline-block;
        width: 80%;
        padding: 8px 10px;
        text-align: left;

        @include bp-max-small() {
          width: 68%;
        }
      }

      .cp-btn {
        border: 1px solid #cccccc;
        padding: 16px 20px;
        float: right;
        color: #ffffff;
        background-color: $color-dustygrey;
        cursor: pointer;
        font-size: $fs-medium;
        width: 20%;
        text-align: center;
        font-weight: $font-bold;

        @include bp-max-small() {
          padding: 16px 9px;
          width: 32%;
        }
      }

    }
  }
}

</style>
