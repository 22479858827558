<template>
  <div class="company-metadata">
    <div class="column">
      <div class="name">
        <h1>
          {{ profile.address.name }}
        </h1>
        <p>
          {{ profile.slogan}}
        </p>
      </div>
      <div class="address">
        <CompanyDataBlock>
          <template slot="data">
            <span v-if="profile.address.line1">
              {{ profile.address.line1 }} <br/>
            </span>
            <span v-if="profile.address.line2">
              {{ profile.address.line2 }} <br/>
            </span>
            <span v-if="profile.address.line3">
              {{ profile.address.line3 }} <br/>
            </span>
            <span v-if="profile.address.city">
              {{ profile.address.city }} <br/>
            </span>
            <span v-if="profile.address.country">
              {{ profile.address.country }} <br/>
            </span>
          </template>
        </CompanyDataBlock>
      </div>
      <div class="branch">
        <CompanyDataBlock>
          <Translation slot="title" resource_key="company.profile.branch" />
          <ul slot="data">
            <li v-for="(sector, index) in profile.dynaJobsJobSectors" :key="index">
              {{ sector }}
            </li>
          </ul>
        </CompanyDataBlock>
      </div>
      <div class="socalimedia" v-if="hasSocialMedia()">
        <CompanyDataBlock>
          <Translation slot="title" resource_key="company.profile.socialmedia"/>
          <CompanySocialMediaLinks :company="profile" slot="data" />
        </CompanyDataBlock>
      </div>
      <div class="company-website">
        <ul>
          <li v-html="formatComanyWebsite()">
          </li>
          <li>
            <a :href="profile.careerWebsite" target="_blank" rel="noopener">
              <Translation resource_key="company.profile.careerWebsite" />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="column logo">
      <img :src="`/${logo.imagePath}`" alt="">
    </div>
  </div>
</template>

<script>
import Translation from '../atoms/Translation';
import CompanyDataBlock from '../molecules/CompanyDataBlock';
import CompanySocialMediaLinks from '../molecules/CompanySocialMediaLinks';

// Mock server data
if (typeof window.Jobpassion === 'undefined') {
  window.Jobpassion = {};
}

export default {
  name: 'CompanyMetaData',

  components: {
    CompanySocialMediaLinks,
    CompanyDataBlock,
    Translation
  },

  props: {
    profile: {
      type: Object,
      requreid: true
    },
    logo: {
      type: Object,
      required: true
    }
  },
  created() {
    console.log(this.profile);
  },

  methods: {
    formatComanyWebsite() {
      const displayText = this.profile.companyWebsite
        ? this.profile.companyWebsite.replace(/https?:\/\//, '').replace(/\/$/, '')
        : '';
      return `<a href="${
        this.profile.companyWebsite
      }" target="_blank" rel="noopener">${displayText}</a>`;
    },
    hasSocialMedia() {
      return (
        this.profile.facebookLink
        || this.profile.googlePLusLink
        || this.profile.kununuLink
        || this.profile.xingLink
        || this.profile.linkedInLink
        || this.profile.twitterLink
        || this.profile.youtubeLink
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/_breakpoints.mixin';

.company-metadata {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 894px;
  margin-top: 50px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--padding-edge);
  padding-right: var(--padding-edge);

  .column {
    width: 45%;

    &.logo {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        max-width: 380px;
      }
    }

    .name {
      padding-top: 11px;
      border-top: 1px solid $color-silvergrey;
      border-bottom: 1px solid $color-silvergrey;

      h1,
      p {
        margin: 0;
      }

      h1 {
        color: $color-blue;
        font-weight: 400;
        font-size: 17px;
        line-height: 28px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 15px;
      }
    }

    .branch /deep/ ul li {
      display: inline-block;

      &:after {
        content: ', ';
        margin-left: -3px;
        margin-right: 3px;
      }

      &:last-child:after {
        content: '';
        margin: 0;
      }
    }

    .company-website {
      padding-top: 11px;
      padding-bottom: 11px;

      li {
        transform: translate3d(0, 0, 0); // because of bug of scrolling

        &:after {
          display: inline-block;
          content: '\E813';
          font-family: fontello; // stylelint-disable-line
          font-size: 12px;
          position: absolute;
          margin-left: 10px;
          transform: rotate(-45deg);
        }

        /deep/ a {
          color: $color-blacky;
          text-decoration: none;
          font-size: 17px;
          font-weight: bold;
        }
      }
    }
  }
}

@include bp-max-medium() {
  .company-metadata {
    flex-direction: column-reverse;
    padding: 0 30px;
    margin-bottom: 30px;

    .column {
      width: 100%;

      &.logo {
        margin-bottom: 30px;
        padding-left: 40px;
        padding-right: 40px;

        img {
          max-width: 200px;
        }
      }

      h1 {
        font-size: 18px;
      }

      .company-website li /deep/ a {
        font-size: 14px;
      }
    }
  }
}
</style>
