<template>
  <transition name="modal" :duration="{ leave: 1000 }">
    <div class="remove-account">
      <ModalBase closeTo="/settings" displayCloseButton="true" :waitingMessage="false">
        <div :class="{ 'remove-account-inner' : true, 'disabled': disabled }">
          <h2>
            <Translation resource_key="applicant.myaccount.remove.account.header" />
          </h2>
          <p>
            <Translation resource_key="applicant.myaccount.remove.account.text" />
          </p>
          <div class="buttons">
            <CpButton size="large" :callback="onYesClicked" :disabled="disabled">
              <Translation resource_key="applicant.myaccount.remove.account.yes" />
            </CpButton>
            <CpButtonAnchor size="large" :to="{ name: 'MyAccount' }" :disabled="disabled">
              <Translation resource_key="applicant.myaccount.remove.account.no" />
            </CpButtonAnchor>
          </div>
        </div>
      </ModalBase>
    </div>
  </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import axios from 'axios';
import CpButton from '../../../atoms/CpButton'
import CpButtonAnchor from '../../../atoms/CpButton/CpButtonAnchor'
import Translation from '../../../atoms/Translation'
import ModalBase from '../../../molecules/ModalBase';
import types from '../../../../store/modules/applicant/types';

const {
  mapState
} = createNamespacedHelpers(types.MODULE_NAME);

export default {
  name: 'RemoveAccount',
  components: {
    CpButton,
    CpButtonAnchor,
    Translation,
    ModalBase
  },
  data() {
    return {
      disabled: false
    }
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile
    })
  },
  methods: {
    onYesClicked() {
      // TODO: check which URL should be called
      this.disabled = true;
      axios.post(`/${this.$careerpeakr.locale}/applicantdelete/${this.profile.id}`).then(() => {
        // FIXME: QUICKHACK
        /* error occured, when we call direct '/' after delete account ...
         * [2020-01-14 15:59:03] request.CRITICAL: Uncaught PHP Exception InvalidArgumentException:
         * "You cannot refresh a user from the EntityUserProvider that does not contain an identifier.
         * The user object has to be serialized with its own identifier mapped by Doctrine."
         * at /var/www/careerpeakr/vendor/symfony/symfony/src/Symfony/Bridge/Doctrine/Security/User/EntityUserProvider.php line 86
         * {"exception":"[object] (InvalidArgumentException(code: 0): You cannot refresh a user from the EntityUserProvider that does not contain an identifier.
         * The user object has to be serialized with its own identifier mapped by Doctrine.
         * at /var/www/careerpeakr/vendor/symfony/symfony/src/Symfony/Bridge/Doctrine/Security/User/EntityUserProvider.php:86)"} []
         */
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
      });
      return false;
    },
    onNoClicked() {
      // TODO: check which URL should be called
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.remove-account /deep/ .modal-inner {
  max-width: 700px;
  margin: 0 auto;
  padding: 30px;
  text-align: center;

  .remove-account-inner {
    max-width: 445px;
    margin: 0 auto;

    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    h2 {
      font-size: 38px;
      font-weight: 800;
      line-height: 1.2em;
      text-align: center;
    }

    p {
      font-size: 17px;
      line-height: 1.4em;
    }

    .buttons {
      margin-top: 35px;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;

      .cp-btn {
        margin-left: 10px;
        width: 140px;
      }
    }

  }
}
</style>
