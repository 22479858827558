<template>
  <footer class="joboffer-footer align-center">
    <div class="joboffer-footer-inner">
      <div class="cp-row">
        <Translation resource_key="joboffer.footer.text" datakey="text"/>
        <p class="joboffer-footer-text" v-html="text" />
      </div>
      <div class="cp-row">
        <CpButtonAnchor :to="'/register/' + jobOffer.jobOfferId"
                        size="large" color="blue" weight="bold"
        >
          <Translation resource_key="joboffer.footer.button" />
        </CpButtonAnchor>
      </div>
    </div>
  </footer>
</template>

<script>
import { CpButtonAnchor } from '../atoms/CpButton';
import Translation from '../atoms/Translation';

export default {
  name: 'JobOfferFooter',
  components: {
    CpButtonAnchor,
    Translation
  },

  props: {
    jobOffer: {
      type: Object,
      requreid: true
    }
  },

  data() {
    return {
      text: ''
    }
  }

}
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors.scss';
@import '../../scss/atoms/breakpoints.mixin'; // for /deep/ should be have here

footer.joboffer-footer {
  background-color: $color-ultralightgrey;
  padding: 30px 0 60px;

  .joboffer-footer-inner {
    max-width: 894px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--padding-edge);
    padding-right: var(--padding-edge);

    .joboffer-footer-text {
      font-size: 38px;
      font-weight: 900;
      line-height: 1.26;
    }
  }

  .button {
    padding-bottom: 120px;
  }
}

@include bp-max-medium() {
  .joboffer-footer-text {
    font-size: 28px !important;
    font-weight: 900 !important;
    line-height: 38px !important;
  }

  .joboffer-footer .button {
    padding-bottom: 50px !important;
  }

  .cp-row {
    margin-top: 30px;
  }
}
</style>
