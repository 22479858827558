<template>
  <div :class="{'market-value-doughnut-chart': true, 'small-chart': 130 > parseInt(chartWidth, 10)}"
       ref="chart-wrapper"
  >
    <div class="cp-doughnut-chart-inner" :style="{width: chartWidth}">
      <chart-doughnut :chartData="data" :options="options" ref="chart" :plugins="chartPlugins"/>
      <div class="doughnut-inner-text">
        <div class="doughnut-value"><b>{{valueTotal}}</b>%</div>
      </div>
    </div>
    <div class="cp-doughnut-chart-text-wrapper">
      <h3 class="cp-doughnut-chart-caption">
        Dein Marktwert
      </h3>
      <p class="cp-doughnut-chart-text">Deine Bewerbungschancen</p>
      <div v-if="2 < values.length" class="cp-doughnut-chart-note">
        <p class="cp-doughnut-chart-text">{{values[0]}}% ohne Verbesserung</p>
        <p class="cp-doughnut-chart-text text-green">{{values[0] + values[1]}}% mit Verbesserung</p>
      </div>
    </div>
  </div>
</template>

<script>
import ChartDoughnut from '../molecules/ChartDoughnut';

export default {
  name: 'ChartDoughnutMarketValue',

  components: {
    ChartDoughnut
  },

  props: {
    values: {
      type: Array,
      default() {
        return [];
      }
    },
    background_colors: {
      type: Array
    },
    chartWidth: {
      type: String,
      default: '100%'
    }
  },

  data() {
    return {
      data: {},
      isMounted: false,
      options: {
        aspectRatio: 1,
        legend: {
          display: false
        },
        cutoutPercentage: 60,
        tooltips: {
          enabled: false
        },
        hover: {
          mode: false
        }
      },
      valueTotal: 0,
      chartPlugins: [
        {
          id: 'ArrowsRing',
          ctx: null,
          radius: 0,
          innerRadius: 0,
          outerRadius: 0,
          data: [],
          center: 0,
          datasetUpdated: false,

          afterDatasetsUpdate(chart) {
            if (!chart.config.data.datasets.length) {
              return;
            }

            const self = this;

            this.data = [];
            chart.config.data.datasets[0].data.forEach((val) => {
              self.data.push(val);
            });

            this.ctx = chart.canvas.getContext('2d');
            this.innerRadius = chart.innerRadius;
            this.outerRadius = chart.outerRadius;
            this.radius = (chart.innerRadius + chart.outerRadius) / 2;
            this.center = chart.width / 2;
          },

          afterDatasetsDraw(chart) {
            const meta = chart.getDatasetMeta(0);

            if (!this.data.length) {
              return;
            }

            const self = this;
            this.ctx.lineJoin = 'round';
            this.ctx.lineCap = 'round';

            meta.data.forEach((item, i) => {
              // eslint-disable-next-line no-underscore-dangle
              if (item._model.backgroundColor === 'rgba(0,0,0,0)') {
                return;
              }

              if (self.data[i] === 0) {
                return;
              }

              // eslint-disable-next-line no-underscore-dangle
              const currentRadian = item._view.endAngle;
              const currentRadianPlus = currentRadian + 0.2;
              const currentX = (self.radius * Math.cos(currentRadianPlus)) + this.center;
              const currentY = (self.radius * Math.sin(currentRadianPlus)) + this.center;
              const innerCurrentX = (self.innerRadius * Math.cos(currentRadian)) + this.center;
              const innerCurrentY = (self.innerRadius * Math.sin(currentRadian)) + this.center;
              const outerCurrentX = (self.outerRadius * Math.cos(currentRadian)) + this.center;
              const outerCurrentY = (self.outerRadius * Math.sin(currentRadian)) + this.center;

              // eslint-disable-next-line no-underscore-dangle
              self.ctx.fillStyle = item._model.backgroundColor;
              // eslint-disable-next-line no-underscore-dangle
              self.ctx.strokeStyle = item._model.backgroundColor;
              self.ctx.lineWidth = 1;
              self.ctx.beginPath();
              self.ctx.moveTo(innerCurrentX, innerCurrentY);
              self.ctx.lineTo(outerCurrentX, outerCurrentY);
              self.ctx.stroke();
              self.ctx.lineTo(currentX, currentY);
              self.ctx.closePath();
              self.ctx.fill();

              // eslint-disable-next-line no-underscore-dangle
              self.ctx.strokeStyle = item._view.borderColor;
              self.ctx.lineWidth = 2;
              self.ctx.beginPath();
              self.ctx.moveTo(innerCurrentX, innerCurrentY);
              self.ctx.lineTo(currentX, currentY);
              self.ctx.lineTo(outerCurrentX, outerCurrentY);
              self.ctx.stroke();
            });

            const firstRadian = (-90 * Math.PI) / 180;
            const firstRadianPlus = firstRadian + 0.2;
            const firstX = (self.radius * Math.cos(firstRadianPlus)) + this.center;
            const firstY = (self.radius * Math.sin(firstRadianPlus)) + this.center;
            const innerFirstX = (self.innerRadius * Math.cos(firstRadian)) + this.center;
            const innerFirstY = (self.innerRadius * Math.sin(firstRadian)) + this.center;
            const outerFirstX = (self.outerRadius * Math.cos(firstRadian)) + this.center;
            const outerFirstY = (self.outerRadius * Math.sin(firstRadian)) + this.center;

            this.ctx.globalCompositeOperation = 'destination-out';
            this.ctx.beginPath();
            this.ctx.moveTo(innerFirstX, innerFirstY);
            this.ctx.lineTo(outerFirstX, outerFirstY);
            this.ctx.lineTo(firstX, firstY);
            this.ctx.closePath();
            this.ctx.fill();
            this.ctx.globalCompositeOperation = 'source-over';
          }
        }
      ]
    };
  },

  watch: {
    values() {
      this.fillData();
    },
    chartWidth() {
      this.resizeChart();
    }
  },

  methods: {
    resizeChart() {
      this.$refs.chart.reRender();
    },

    fillData() {
      let valueTotal = 0;

      for (let i = 0; i < this.values.length; i += 1) {
        if (!this.values[i]) {
          this.values[i] = 0;
        }

        valueTotal += this.values[i];
      }

      if (valueTotal < 100) {
        this.values.push(100 - valueTotal);
        this.background_colors.push('rgba(0,0,0,0)');
      }

      this.valueTotal = valueTotal;

      const backgrounds = [];
      let datasetValTotal = 0;

      this.background_colors.forEach((item, i) => {
        const currentVal = datasetValTotal;
        datasetValTotal += this.values[i];

        backgrounds.push(this.getChartBackground(item, currentVal, datasetValTotal));
      });

      this.data = {
        datasets: [
          {
            data: this.values,
            backgroundColor: backgrounds,
            borderWidth: 0
          }
        ]
      };
    },

    getChartBackground(colors, valueStart, valueStop) {
      let stopValue = valueStop;
      if (typeof colors === 'object' && colors.length >= 2) {
        // eslint-disable-next-line no-underscore-dangle
        const chart = this.$refs.chart.$data._chart;

        /**
         * Calc y position of begin and end of the value
         */

        // if value start less than 50 and goes over 50,
        // set end balue to 50, as the value will be at the bottom of the chart
        if (valueStart < 50 && valueStop > 50) {
          stopValue = 50;
        }

        // calc radians
        const startRadian = (((360 * (valueStart / 100)) - 90) * Math.PI) / 180;
        const stopRadian = (((360 * (stopValue / 100)) - 90) * Math.PI) / 180;

        // calc y positions
        const colorStart = (chart.width / 2) + ((chart.width / 2) * Math.sin(startRadian));
        const colorStop = (chart.width / 2) + ((chart.width / 2) * Math.sin(stopRadian));

        // generate gradient
        const bgColor = document
          .createElement('canvas')
          .getContext('2d')
          .createLinearGradient(0, colorStart, 0, colorStop);
        bgColor.addColorStop(0, colors[0]);
        bgColor.addColorStop(0.5, colors[1]);

        return bgColor;
      }

      return colors;
    }
  },

  mounted() {
    this.isMounted = true;
    this.fillData();
  }
};
</script>

<style lang="scss">
  @import '../../scss/organisms/chart_doughnut_market_value';

  @media screen and (max-width: 1023px) {
    .market-value-doughnut-chart {
      text-align: left;

      .cp-doughnut-chart-inner {
        margin: 0;
        display: inline-block;
        vertical-align: middle;
      }

      .cp-doughnut-chart-text-wrapper {
        display: inline-block;
        vertical-align: middle;
        padding-left: 16px;
      }
    }
  }

  @media screen and (max-width: 579px) {
    .market-value-doughnut-chart {
      display: flex;
      align-items: center;

      .cp-doughnut-chart-inner {
        max-width: 120px;
      }

      .cp-doughnut-chart-text-wrapper {
        padding-left: 5px;
        min-width: 210px;

        .cp-doughnut-chart-caption {
          font-size: 17px;
          margin: 0;
        }

        .cp-doughnut-chart-text {
          font-size: 14px;
        }
      }
    }
  }
</style>
