<template>
  <div class='small-image-textbox' :class="align">
    <div class="small-image-textbox-image" :style="bgStyle"></div>
    <SmallTextbox>
      <template slot="caption"><slot name="caption"></slot></template>
      <template slot="title"><slot name="title"></slot></template>
      <template slot="text"><slot name="text"></slot></template>
    </SmallTextbox>
  </div>
</template>

<script>
import SmallTextbox from '../molecules/SmallTextbox';

export default {
  name: 'SmallImageTextbox',

  props: {
    bgimage: {
      type: String,
      requreied: true
    },
    align: {
      type: String,
      default: ''
    }
  },

  components: {
    SmallTextbox
  },

  computed: {
    bgStyle() {
      const styles = [];

      if (typeof this.bgimage === 'string') {
        styles.push(`background-image: url(${this.bgimage});`);
      }

      return styles.join(' ');
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/atoms/breakpoints.mixin';

.small-image-textbox {
  &.center {
    text-align: center;
  }

  .cp-small-textbox {
    margin: -70px 30px 0;
  }
}

.small-image-textbox-image {
  display: block;
  width: 100%;
  height: auto;
  padding-top: 64.93%;
  background: transparent no-repeat center;
  background-size: cover;
}

@include bp-max-medium() {

  #cp-personal-advices {
    .small-image-textbox {
      .cp-small-textbox {
        margin: -70px 40px 0;
        padding: 20px 25px 40px;
      }
    }
  }
}</style>
