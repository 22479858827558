<template>
  <section class="social-media-import boxshadow-basic">
    <div class="wrapper">
      <h2>
        <Translation resource_key="applicant.profile.socialmedia.import.title" />
      </h2>
      <Translation datakey="text1" resource_key="applicant.profile.socialmedia.import.text1" />
      <Translation datakey="text2" resource_key="applicant.profile.socialmedia.import.text2" />
      <p v-html="text1"></p>
      <p v-html="text2"></p>
      <p>
        <strong>
          <Translation resource_key="applicant.profile.socialmedia.import.buttons.title" />
        </strong>
      </p>
      <ul class="login-popup-footer-buttons btns">
        <li class="login-popup-footer-button-item">
          <SocialButton channel="xing" size="medium" :callback="exportXing">
            Xing Daten importieren
          </SocialButton>
        </li>
        <!-- Jan said that LinkedIn does not give back data rightly (Email on 07.11.2019) -->
        <!--li class="login-popup-footer-button-item">
          <SocialButton channel="linkedin" size="medium" :callback="exportLinkedin">
            LinkedIn Daten exportieren
          </SocialButton>
        </!--li -->
      </ul>
    </div>
  </section>
</template>

<script>
import Translation from '../../atoms/Translation';
import { SocialButton } from '../../atoms/CpButton';

export default {
  name: 'SocialMediaImport',

  components: {
    Translation,
    SocialButton
  },

  data() {
    return {
      text1: '',
      text2: ''
    };
  },

  methods: {
    exportXing() {
      window.location.href = `/${this.$careerpeakr.locale}/importxingprofile`;
      return false;
    },
    exportLinkedin() {
      window.location.href = `/${this.$careerpeakr.locale}/linkedingetownprofile`;
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';

.social-media-import {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #feffff;

  .wrapper {
    padding-top: 40px;
    padding-bottom: 70px;
    padding-left: var(--padding-edge);
    padding-right: var(--padding-edge);
    max-width: 955px;
    margin: 0 auto;
  }

  h2 {
    font-size: 26px;
    font-weight: 900;
  }

  p {
    font-size: 17px;
    color: $color-darkgrey;

    &:last-of-type {
      margin-top: 40px;
    }
  }

  .btns {
    li {
      display: inline;

      button {
        height: 52px;
        line-height: 52px;
      }
    }
  }
}
</style>
