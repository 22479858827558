<template>
  <section class="educations">
    <div class="wrapper">
      <h2><Translation resource_key="applicant.profile.study.programs.title"/></h2>
      <EditableArea :values="values" :form="form" :callbackSave="onSave" :callbackCancel="onCancel" >
        <EducationsDisplay slot="display" :values="values" :form="form" :fresh="fresh" />
        <EducationsEdit slot="edit" :values="bufferedValue" :form="form" :callbackChange="onChange" />
      </EditableArea>
    </div>
  </section>
</template>

<script>
import EditableArea from '../../molecules/EditableArea';
import EducationsDisplay from './EducationsDisplay';
import EducationsEdit from './EducationsEdit';
import Translation from '../../atoms/Translation';
import dateOrderUtils from '../../../lib/mixins/dateOrderUtils';

export default {
  name: 'Educations',

  components: {
    EditableArea,
    EducationsDisplay,
    EducationsEdit,
    Translation
  },

  mixins: [dateOrderUtils],

  data() {
    return {
      bufferedValue: {}
    };
  },

  props: {
    values: {
      type: Object,
      required: true
    },
    fresh: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      required: true
    },
    callbackOnSave: {
      type: Function,
      required: true
    }
  },

  created() {
    this.setOriginalValue();
  },

  methods: {
    setOriginalValue() {
      // make object from array
      const { highestDegree = 4 } = this.values;
      const studyPrograms = {};
      this.values.studyPrograms.map((obj, idx) => {
        studyPrograms[`${idx}`] = obj;
        return obj;
      });

      const professions = {};
      this.values.professions.map((obj, idx) => {
        professions[`${idx}`] = obj;
        return obj;
      });

      const furtherQualifications = {};
      this.values.furtherQualifications.map((obj, idx) => {
        furtherQualifications[`${idx}`] = obj;
        return obj;
      });

      this.bufferedValue = {
        highestDegree,
        studyPrograms,
        professions,
        furtherQualifications
      };
    },

    onSave() {
      const self = this;
      return new Promise((resolve, _reject) => {
        /* TODO:
          in this method will be replaced with vuex action.
          this implementation is only for storybook
          */

        const filteredValues = self.filterfilledEntries();
        /* global _ */
        const result = _.cloneDeep(self.values);
        result.studyPrograms = Object.values(filteredValues.studyPrograms);
        result.professions = Object.values(filteredValues.professions);
        result.furtherQualifications = Object.values(filteredValues.furtherQualifications);
        result.highestDegree = filteredValues.highestDegree;
        result.highestDegreeGrade = filteredValues.highestDegreeGrade;
        self.callbackOnSave(result).then(() => {
          self.setOriginalValue();
          resolve()
        });
      });
    },

    onCancel() {
      this.setOriginalValue();
    },

    onChange(key, values) {
      this.bufferedValue[key] = values;

      const result = { ...this.values };
      result[key] = values;
      this.$emit('change', result);
    },

    editStatusChanged(editing) {
      this.editing = editing;
    },

    filterfilledEntries() {
      const studyPrograms = {}
      Object.values(this.bufferedValue.studyPrograms)
        .filter((v) => v.nameText.length)
        .map((itm) => {
          const item = { ...itm };
          // from should be earlier than till
          [item.from, item.till] = this.sortDateOrderByMomentFormat(itm.from, itm.till);
          return item;
        }).sort(this.compareWithFromDate)
        .map((item, idx) => {
          studyPrograms[idx] = item;
          return item;
        });

      const furtherQualifications = {}
      Object.values(this.bufferedValue.furtherQualifications)
        .filter((v) => v.qualification.length && v.comment.length)
        .map((item, idx) => {
          furtherQualifications[idx] = item;
          return item;
        });

      const professions = {}
      Object.values(this.bufferedValue.professions)
        .filter((v) => v.nameText.length && v.employer.length)
        .map((itm) => {
          const item = { ...itm };
          // from should be earlier than till
          [item.from, item.till] = this.sortDateOrderByMomentFormat(itm.from, itm.till);
          return item;
        }).sort(this.compareWithFromDate)
        .map((item, idx) => {
          professions[idx] = item;
          return item;
        });

      const { highestDegree, highestDegreeGrade } = this.bufferedValue;
      return {
        studyPrograms,
        professions,
        furtherQualifications,
        highestDegree,
        highestDegreeGrade
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_breakpoints.mixin';

.educations {
  background-color: $color-ultralightgrey;
  position: relative;
  padding: 60px 0;

  .wrapper {
    max-width: 840px;
    width: 100%;
    margin: 0 auto;

    .editable-area {
      position: static;
    }
  }
}

@include bp-max-medium(){
  .educations {
    padding: 50px 0;

    .wrapper {
      word-wrap: break-word;
      padding: 0 30px;

      ul.educations {
        width: 100%;
        border-bottom: 1px solid #c4c4c4;
        display: block;
        padding: 0;
      }

      ul.further-qualification {
        display: block;
        padding-top: 40px;
      }

      h2 {
        margin: 0 0 30px 0 !important;
      }
    }
  }
}
</style>
