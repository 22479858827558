<template>
  <div class="graduation-form">
    <div class="column">
      <div class="row">
        <!-- autocomplete box-->
        <CpSelectBox :value="valueOrBlank(value)" :values="grades" @change="valueChanged">
          <Translation resource_key="applicant.profile.education.grade" />
        </CpSelectBox>
      </div>
    </div>
  </div>
</template>

<script>
import Translation from '../../atoms/Translation';
import CpSelectBox from '../../molecules/CpSelectBox';
import formUtils from '../../../lib/mixins/formUtils';
import htmlUtils from '../../../lib/mixins/htmlUtils';

export default {
  name: 'GraduationForm',
  components: {
    Translation,
    CpSelectBox
  },

  mixins: [formUtils, htmlUtils],

  props: {
    value: {
      type: String,
      required: true
    },
    form: {
      type: Object,
      requreid: true
    }
  },

  computed: {
    grade() {
      const choice = this.findValueByLabelFromChoices(this.value, this.grades);
      return (choice) ? choice.value : '';
    },
    grades() {
      return Object.values(this.form.highestDegreeGrade.choices);
    }
  },

  methods: {
    onRemove() {
      this.callbackRemove(this.value.value);
    },
    valueChanged(value) {
      // let newVal = { ...this.value };
      // const inputedValue = util.buildObjectFromPath(path, value);
      // newVal = util.mergeDeep(newVal, inputedValue);
      this.$emit('change', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';

.graduation-form {
  margin-bottom: 40px;

  .detail {
    width: calc(100% - 385px);
  }

  .cp-select-box {
    margin-bottom: 20px;

    /deep/ label span {
      font-size: 14px;
      line-height: inherit;
      color: #9b9b9b;
    }
  }

  .remove-element-button {
    display: block;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
