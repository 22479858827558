import axios from 'axios';

const URL = '/api/v2/applicant/optimise';

export default {
  optimise(profileId, joblistIds, optimizationChangeIds) {
    console.log(joblistIds);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL}`, {
          profileId,
          joblistIds,
          optimizationChangeIds
        }).then((response) => {
        // handle success
          resolve(response.data);
        }).catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  }
};
