<script>
import { mapState } from 'vuex';

export default {
  name: 'Translation',

  props: ['resource_key', 'datakey', 'hidden'],

  computed: mapState({
    translations: (state) => state.translations,

    translation() {
      if (this.translations.dict) {
        const result = this.translations.dict[this.resource_key];
        if (typeof this.datakey === 'undefined' && typeof this.hidden === 'undefined') {
          return typeof result !== 'undefined' ? result : this.resource_key;
        }
        // const cascade = this.datakey.split('.');
        this.$parent[this.datakey] = typeof result !== 'undefined' ? result : this.resource_key;
        this.$emit('translated', { key: this.resource_key, value: (typeof result !== 'undefined') ? result : this.resource_key });
        return '';
      }
      return this.resource_key;
    }
  }),

  render() {
    return this._v(this.translation); // eslint-disable-line no-underscore-dangle
  }
};
</script>
