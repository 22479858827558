import axios from 'axios';

const URL = '/api/v2/dict'
// const URL = 'http://careerpeakr.test/api/v2/dict'

export default {
  getAll(
    domain = window.translations.domain,
    locale = window.translations.locale,
    callback,
    errorCb
  ) {
    console.log(domain, locale);
    axios.get(`${URL}/${domain}/${locale}`)
      .then((response) => {
        // handle success
        callback(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
        errorCb(error);
      });
  },

  update(domain, locale, dict, callback, errorCb) {
    axios.put(`${URL}/${domain}/${locale}`, dict)
      .then((response) => {
        // handle success
        callback(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
        errorCb(error);
      });
  }
};
