<template>
  <div class="careerboard" v-bind:class="$route.name">
    <section class="careerboard-left-bar-wrapper">
      <div class="careerboard-inner">
        <div class="careerboard-left-bar">
          <UserInfo/>
          <my-job-selection></my-job-selection>
        </div>
      </div>
    </section>

    <section class="careerboard-pagination-wrapper">
      <div class="careerboard-inner">
        <div class="careerboard-pagination">
          <WizardProgressBar :callback='pageChanged'/>
        </div>
      </div>
    </section>

    <div class="careerboard-inner">
      <section class="careerboard-main">
        <div class="careerboard-page-container">
          <competence-analyse ref="competenceAnalyse" v-bind:style="{transform: competenceAnalyseStyle}"></competence-analyse>
          <optimize ref="optimize" v-bind:style="{transform: optimizeStyle}"></optimize>
        </div>
      </section>
    </div>

    <transition name="slide-bottom">
      <router-view name="job-suggestion"></router-view>
    </transition>

    <transition name="popup">
      <router-view name="modal"></router-view>
    </transition>
  </div>
</template>

<script>
import UserInfo from '../../organisms/UserInfo';
import WizardProgressBar from '../WizardProgressBar';
import MyJobSelection from '../../organisms/MyJobSelection'
import CompetenceAnalyse from '../CompetenceAnalyse';
import Optimize from '../Optimize';

export default {
  name: 'CareerBoard',
  components: {
    UserInfo,
    WizardProgressBar,
    MyJobSelection,
    CompetenceAnalyse,
    Optimize
  },

  data() {
    return {
      current: 0,
      transitionName: 'slide-left',
      myPath: '',
      competenceAnalyseStyle: 'translateX(100%)',
      optimizeStyle: 'translateX(100%)'
    }
  },

  watch: {
    $route(to, _from) {
      switch (to.name) {
        case 'CareerBoardCompetenceAnalyse':
          this.competenceAnalyseStyle = 'translateX(0)';
          this.optimizeStyle = 'translateX(100%)';
          this.$refs.competenceAnalyse.scrollToTop();
          break;
        case 'CareerBoardOptimize':
          this.competenceAnalyseStyle = 'translateX(-100%)';
          this.optimizeStyle = 'translateX(0)';
          this.$refs.optimize.scrollToTop();
          break;
        default:
          this.competenceAnalyseStyle = 'translateX(100%)';
          this.optimizeStyle = 'translateX(100%)';
      }
    }
  },
  created() {
    this.$store.dispatch('applicant/getProfile');
    this.$store.dispatch('applicant/getJobList');
    this.$store.dispatch('applicant/analyse');
    this.$store.dispatch('applicant/getAllTrash');
    this.$store.dispatch('applicant/getAllFavorite');

    if (!this.$store.state.applicant.selected.length) {
      if (this.$route.name === 'CareerBoardCompetenceAnalyse' || this.$route.name === 'CareerBoardOptimize') {
        this.$router.push({ name: 'CareerBoardJobSuggestion' });
      }
    }
  },
  methods: {
    pageChanged(direction) {
      this.transitionName = (direction > 0) ? 'slide-right' : 'slide-left';
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../../scss/atoms/colors';
@import '../../../scss/atoms/breakpoints.mixin';
/// Layout wrappers
.careerboard-inner {
  position: relative;
  margin: 0 auto;
  //max-width: 1440px;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.careerboard-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 3;
}

.careerboard-left-bar-wrapper {
  position: fixed;
  left: 0;
  top: 100px;
  width: 100vw;
  z-index: 2;
}

.careerboard-pagination-wrapper {
  position: fixed;
  left: 0;
  top: 100px;
  width: 100vw;
  z-index: 1;
}

.careerboard-main {
  position: relative;
  margin: 196px 0 0 29%;
}

/// Left Bar
.careerboard-left-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: calc(100vh - 100px);
  width: 41%;
  transition: width 0.3s;
}

.CareerBoardCompetenceAnalyse .careerboard-left-bar,
.CareerBoardOptimize .careerboard-left-bar {
  width: 29%;

  .my-job-selection {
    right: 0;
  }
}

.careerboard-left-bar .my-job-selection {
  right: 100vw;
  transition: right 0.3s;
}

/// Pagination Bar
.careerboard-pagination {
  position: absolute;
  left: 41%;
  right: 0;
  top: 0;
  transition: left 0.3s;
}

.CareerBoardCompetenceAnalyse .careerboard-pagination,
.CareerBoardOptimize .careerboard-pagination {
  left: 29%;
}

/// Page
.careerboard-page-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 196px);
  overflow: hidden;
}

.careerboard-page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  transition: transform 0.5s;
}

/// Transition slide-bottom
.slide-bottom-enter-active,
.slide-bottom-leave-active {
  transition: transform 0.5s;
}

.slide-bottom-enter,
.slide-bottom-leave-to {
  transform: translateY(100vh);
}

/// for big monitor
@include bp-extra-large() {
  .careerboard-left-bar {
    max-width: 656px;
  }

  .CareerBoardCompetenceAnalyse .careerboard-left-bar,
  .CareerBoardOptimize .careerboard-left-bar {
    max-width: 464px;
  }

  .careerboard-pagination {
    left: auto;
    width: calc(100vw - 656px);
  }

  .CareerBoardCompetenceAnalyse .careerboard-pagination,
  .CareerBoardOptimize .careerboard-pagination {
    left: auto;
    width: calc(100vw - 464px);
  }

  .CareerBoardCompetenceAnalyse .careerboard-main,
  .CareerBoardOptimize .careerboard-main {
    margin-top: 196px;
    float: right;
    width: calc(100vw - 464px);
  }
}

@include bp-max-large() {
  .careerboard-left-bar-wrapper {
    top: 42px;

    .careerboard-left-bar {
      height: calc(100vh - 42px);
    }
  }
  .careerboard-pagination-wrapper {
    top: 42px;
  }
}

@include bp-max-tablet() {
  .careerboard-left-bar-wrapper {
    .careerboard-left-bar {
      width: 100%;
      height: 70px;

      .user-info {
        height: 70px;

        &:before {
          display: none;
        }

        /deep/ {
          .user-picture {
            width: 70px;
          }
          .user-names {
            left: 70px;
            padding-top: 0;

            h2 {
              border-bottom: 0;
              padding-bottom: 0;
            }
          }
        }
      }

      .my-job-selection {
        transition: max-height 0.4s, top 0.4s;

        &.hidden {
          transition: max-height 0.4s, top 0.4s;
        }
      }
    }
  }

  .careerboard-pagination-wrapper {
    .careerboard-pagination {
      top: 50px;
      left: 0;
    }
  }

  .CareerBoardCompetenceAnalyse .careerboard-main,
  .CareerBoardOptimize .careerboard-main {
    margin-left: 0;
  }
}

@include bp-max-extra-small() {
  .careerboard-left-bar-wrapper {
    top: 40px;
  }
}
</style>
