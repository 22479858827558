<template>
  <div class="performance-bar-chart" ref="chart-wrapper">
    <div class="bar-chart-legends" v-bind:style="{marginRight: chartMarginRight + 'px'}">
      <div v-for="(legend, idx) in chartLegends" class="bar-chart-legend" :key="idx">
        <span class="bar-chart-lagend-color" :style="legend.style"></span>
        {{legend.label}}
      </div>
    </div>
    <div class="cp-bar-chart-inner">
      <chart-horizontal-bar
        :chartData="data"
        :options="options"
        ref="chart"
        v-bind:style="{height: chartHeight + 'px'}"
        v-on:chart:render="adjusteBackground"
        v-on:chart:update="adjusteBackground"
        v-on:resize="adjusteBackground"
      ></chart-horizontal-bar>
      <div class="bar-chart-labels" v-bind:style="{right: chartMarginRight + 'px'}">
        <div v-for="(label, i) in data.labels"
             class="bar-chart-label" ref="label"
             v-bind:style="{height: barThickness + 'px', lineHeight: barThickness + 'px'}"
             :key="i"
        >
          {{label}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartHorizontalBar from '../molecules/ChartHorizontalBar';

export default {
  name: 'ChartBarPerformance',

  components: {
    ChartHorizontalBar
  },

  props: {
    categories: {
      type: Array,
      default() {
        return [];
      }
    },

    datasets: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      data: {
        labels: [],
        datasets: []
      },

      barThickness: 30,
      isMounted: false
    };
  },

  computed: {
    options() {
      let max = 10;
      this.datasets.forEach((item, _i) => {
        // Set Chart Max Value
        max = Math.max(max, item.value);
      });

      // Calc Chart Max Value
      const maxLen = 10 ** (String(max).length - 1);
      max = Math.ceil(max * 1.25);
      max = maxLen === 0 ? max + 1 : Math.ceil(max / maxLen) * maxLen;

      return {
        legend: {
          display: false
        },

        maintainAspectRatio: false,

        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontSize: 14,
                fontColor: '#626262',
                max
              },
              gridLines: {
                display: false
              }
            }
          ],

          yAxes: [
            {
              barPercentage: 1,
              categoryPercentage: 1,
              ticks: {
                display: false
              }
            }
          ]
        },

        tooltips: {
          enabled: false
        },

        hover: {
          mode: false
        },

        onResize: this.adjusteBackground
      };
    },

    chartHeight() {
      if (!this.isMounted) {
        return 0;
      }

      const { _chart: chart } = this.$refs.chart.$data;
      return (this.barThickness * this.data.labels.length) + chart.scales['y-axis-0'].margins.bottom;
    },

    chartMarginRight() {
      if (!this.isMounted) {
        return 0;
      }
      // eslint-disable-next-line no-underscore-dangle
      return this.$refs.chart.$data._chart.scales['x-axis-0'].margins.right;
    },

    chartLegends() {
      const legends = [];

      this.categories.forEach((item, _i) => {
        const style
          = typeof item.color === 'object'
            ? {
              background: `linear-gradient(to right, ${item.color[0]} 0%, ${item.color[1]} 100%)`
            }
            : { backgroundColor: item.color };

        legends.push({
          label: item.label,
          style
        });
      });

      return legends;
    }
  },

  watch: {
    datasets() {
      this.fillData();
    }
  },

  methods: {
    fillData() {
      const labels = [];
      const dataset = {
        data: [],
        backgroundColor: [],
        fill: false,
        borderWidth: 0,
        label: 'data'
      };

      const self = this;
      const { _chart: chart } = this.$refs.chart.$data;
      let max = 10;

      this.datasets.forEach((item, _i) => {
        // Generate Gradient Backgrounds
        const { color } = self.categories[item.categoryIndex];
        let bgColor;

        if (typeof color === 'object') {
          bgColor = document
            .createElement('canvas')
            .getContext('2d')
            .createLinearGradient(0, 0, chart.width, 0);
          bgColor.addColorStop(0, color[1]);
          bgColor.addColorStop(0.5, color[0]);
          bgColor.addColorStop(1, color[1]);
        } else {
          bgColor = color;
        }

        labels.push(item.label);
        dataset.data.push(item.value);
        dataset.backgroundColor.push(bgColor);

        // Set Chart Max Value
        max = Math.max(max, item.value);
      });

      // Calc Chart Max Value
      const maxLen = 10 ** (String(max).length - 1);
      max = Math.ceil(max * 1.25);
      max = maxLen === 0 ? max + 1 : Math.ceil(max / maxLen) * maxLen;
      this.options.scales.xAxes[0].ticks.max = max;

      this.labels = labels;

      this.data = {
        labels,
        datasets: [dataset]
      };
    },

    adjusteBackground() {
      const self = this;
      const { _chart: chart } = this.$refs.chart.$data;
      const chartLen = chart.scales['x-axis-0'].max - chart.scales['x-axis-0'].min;
      const scaleW = chart.scales['x-axis-0'].width;

      let gradientStartPos = 0;

      if (chart.scales['x-axis-0'].max > 0 && chart.scales['x-axis-0'].min < 0) {
        gradientStartPos = (Math.abs(chart.scales['x-axis-0'].min) / chartLen) * scaleW;
      } else if (chart.scales['x-axis-0'].min < 0) {
        gradientStartPos = scaleW;
      }

      this.datasets.forEach((item, i) => {
        const { color } = self.categories[item.categoryIndex];

        if (typeof color === 'object') {
          const absValue = item.value - chart.scales['x-axis-0'].min;
          const colorEnd = (absValue / chartLen) * scaleW;

          const bgColor = document
            .createElement('canvas')
            .getContext('2d')
            .createLinearGradient(gradientStartPos, 0, colorEnd, 0);
          bgColor.addColorStop(0, color[0]);
          bgColor.addColorStop(0.5, color[1]);
          self.data.datasets[0].backgroundColor[i] = bgColor;
        }
      });
    }
  },

  mounted() {
    this.fillData();
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
@import '../../scss/organisms/chart_bar_performance';
</style>
