<template>
  <div class="language-form">
    <CpSelectBox :value="language" :values="languages" @change="(v) => valueChanged('language', v)">
      <Translation resource_key="applicant.profile.skills.language.name" />
    </CpSelectBox>
    <RangeSlider :value="languageLevel"
                 :values="languageLevels"
                 @change="(v) => valueChanged('languageLevel', v)"
    >
      <Translation resource_key="applicant.profile.skills.language.level" />
    </RangeSlider>
    <RemoveElementButton :callback="onRemove" />
  </div>
</template>

<script>
import Translation from '../../atoms/Translation';
import CpSelectBox from '../../molecules/CpSelectBox';
import RangeSlider from '../../molecules/RangeSlider';
import { RemoveElementButton } from '../../atoms/CpButton';
import formUtils from '../../../lib/mixins/formUtils';
import htmlUtils from '../../../lib/mixins/htmlUtils';
import util from '../../../lib/utils';

export default {
  name: 'LanguageForm',

  components: {
    Translation,
    CpSelectBox,
    RangeSlider,
    RemoveElementButton
  },

  mixins: [formUtils, htmlUtils],

  props: {
    value: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String
    },
    callbackRemove: {
      type: Function,
      required: true
    }
  },

  computed: {
    language() {
      const choice = this.findValueByLabelFromChoices(this.value.language, this.languages);
      return (choice) ? choice.value : '';
    },
    languages() {
      const result = {
        ...this.form.languageSkills.language.preferred_choices,
        ...this.form.languageSkills.language.choices
      };
      return Object.values(result).map((val, key) => {
        const lang = { ...val };
        lang.originalValue = val.value;
        lang.value = key;
        return lang;
      });
    },

    languageLevel() {
      const choice = this.findOriginalValueFromChoices(
        this.value.languageLevel,
        this.languageLevels
      );
      return (choice) || null;
    },
    languageLevels() {
      return this.form.languageSkills.languageLevel.choices.map((c) => {
        const result = { ...c };
        result.label = c.label.replace('- ', '');
        return result;
      })
    }
  },

  methods: {
    onRemove() {
      this.callbackRemove(this.value.value);
    },
    onChange(val) {
      this.$emit('change', val);
    },
    valueChanged(path, value) {
      let newVal = { ...this.value };
      let val = value;
      if (path === 'language') {
        val = this.languages.find((l) => l.value === parseInt(value, 10)).label;
      } else if (path === 'languageLevel') {
        val = this.languageLevels.find((l) => l.value === parseInt(value, 10)).original;
      }
      const inputedValue = util.buildObjectFromPath(path, val);
      newVal = util.mergeDeep(newVal, inputedValue);
      this.$emit('change', newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';

.language-form {
  width: 100%;
  position: relative;
  margin-bottom: 40px;

  /deep/ .slot {
    line-height: 20px;
    font-style: italic;
  }

  .icon-check {
    color: $color-blue;
    position: absolute;
    left: 5px;
    top: 10px;
    z-index: 10;
  }

  .remove-element-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  .removable-textfield {
    display: inline-block;
    width: 100%;
    margin-bottom: 8px;

    /deep/ input[type='text'] {
      width: calc(100% - 35px);
      border: none;
      padding-left: 38px;
    }
  }

  /deep/ .cp-select-wrapper select {
    font-size: 17px;
    line-height: 24px;
  }
}
</style>
