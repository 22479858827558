<template>
  <button class="cp-burger" @click="openNavigationOverlay"></button>
</template>

<script>
export default {
  name: 'BurgerBlack',
  methods: {
    openNavigationOverlay(e) {
      e.preventDefault();
      document.body.classList.add('main-nav-open');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/burger';

.cp-burger {
  border-top-color: $color-black;
  border-bottom-color: $color-black;

  &:before {
    background-color: $color-black;
  }
}
</style>
