<template>
  <div class="callout-image">
    <svg width="0" height="0" style="position: absolute; top: 0; left: 0;">
      <clipPath id="clip01">
        <!-- eslint-disable-next-line -->
        <path d="M29.9172083,4 C45.8877415,4 58.8344167,16.6814603 58.8344167,32.3248343 C58.8344167,40.6266925 55.1881534,48.0943661 49.3787242,53.2751394 L53.050975,69.2702703 L37.9093184,59.5539883 C35.3710194,60.2674919 32.6896784,60.6496685 29.9172083,60.6496685 C13.9466752,60.6496685 1,47.9682083 1,32.3248343 C1,16.6814603 13.9466752,4 29.9172083,4 Z" id="pic_bubble"></path>
      </clipPath>
    </svg>
    <svg width="60" height="65" viewBox="0 0 60 65">
      <image :xlink:href="src" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" clip-path="url(#clip01)"/>
    </svg>
  </div>
</template>

<script>

export default {
  name: 'CalloutImage',
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>
