<template>
  <div class="header">
    <h2 v-if="hasTitle" class="title">
      <slot name="title"></slot>
    </h2>
    <div v-if="hasTaglines" class="taglines">
      <p>
        <slot name="taglines"></slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CpHeader',
  computed: {
    hasTitle() {
      return !!this.$slots.title;
    },

    hasTaglines() {
      return !!this.$slots.taglines;
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/molecules/header';
</style>
