<template>
  <div class="work-environment-stickers">
    <h2><Translation resource_key="company.profile.at-a-glance" /></h2>
    <ul class="work-environment-stickers-inner cp-row" v-inview:once.class="['appeared']">
      <li class="work-environment-sticker columns-3-item">
        <StickerRound bgimage="/assets/img/icon-employee.svg">
          <Translation resource_key="company.profile.ataglance.employee"/>
          <span class="value">
            {{ profile.employeesNumber }}
          </span>
        </StickerRound>
      </li>
      <li class="work-environment-sticker columns-3-item">
        <StickerRound bgimage="/assets/img/icon-official-language.svg">
          <Translation resource_key="company.profile.ataglance.official-language" hidden="true" @translated="({value}) => { officialLanguageLabel = value; }"/>
          <span v-html="officialLanguageLabel"></span>
          <span class="value">
            {{ formatSkillLanguage() }}
          </span>
        </StickerRound>
      </li>
      <li class="work-environment-sticker columns-3-item">
        <StickerRound bgimage="/assets/img/icon-sales-volume.svg">
          <Translation resource_key="company.profile.ataglance.sales-volume"/>
          <span class="value">
            {{ profile.sales }}
          </span>
        </StickerRound>
      </li>
    </ul>
  </div>
</template>

<script>
import Translation from '../atoms/Translation';
import StickerRound from '../molecules/StickerRound';

// Mock server data
if (typeof window.Jobpassion === 'undefined') {
  window.Jobpassion = {};
}

export default {
  name: 'WorkEnvironmentStickers',

  components: {
    StickerRound,
    Translation
  },

  props: {
    profile: {
      type: Object,
      requreid: true
    }
  },

  data() {
    return {
      officialLanguageLabel: 'default val'
    };
  },

  methods: {
    formatComanyWebsite() {
      const displayText = this.profile.companyWebsite
        ? this.profile.companyWebsite.replace(/https?:\/\//, '').replace(/\/$/, '')
        : '';
      return `<a href="${this.profile.companyWebsite}">${displayText}</a>`;
    },
    formatSkillLanguage() {
      return this.profile.languageSkills.map((skill) => skill.language).join(', ');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';

.work-environment-stickers {
  display: flex;
  flex-direction: column;
  max-width: 894px;
  margin-top: 60px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;

  h2 {
    font-size: 30px;
    font-weight: 900;
    margin-top: 65px;
    margin-bottom: 40px;
  }

  .work-environment-stickers-inner {
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    .work-environment-sticker {
      width: 260px;

      /deep/ .cp-sticker-round-icon {
        position: absolute;
        bottom: 20px;
        height: 55%;
      }

      /deep/ .cp-sticker-round-text {
        color: $color-darkgrey;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin: 0;
        padding-top: 35px;
      }

      .value {
        display: block;
        color: $color-blacky;
        font-weight: bold;
        line-height: 25px;
        font-size: 17px;
        margin: 0;
      }
    }
  }
}

@include bp-max-medium() {
  .work-environment-stickers {
    margin-top: 0;

    h2 {
      margin-top: 30px;
      font-size: 24px;
    }
  }
}

@include bp-from-to($bp-extra-small, $bp-small) {
  .work-environment-stickers .work-environment-stickers-inner {
    flex-wrap: wrap;

    .work-environment-sticker.columns-3-item {
      width: 48%;
      margin-left: 12px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-left: 24%;
      }

      .cp-sticker-round /deep/ {
        .cp-sticker-round-icon {
          bottom: 15px;
          height: 44%;
        }

        .cp-sticker-round-text {
          padding-top: 25px;

          span {
            width: 130%;
            margin-left: -15px;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
}

@include bp-max-extra-small() {
  .work-environment-stickers .work-environment-stickers-inner {
    flex-direction: column;

    .work-environment-sticker.columns-3-item {
      width: 100%;
      margin-left: 0;
      margin-bottom: 20px;

      &:last-child {
        margin-left: 0%;
        margin-bottom: 0;
      }
    }
  }
}
</style>
