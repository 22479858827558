<template>
  <transition name="modal" :duration="{ leave: 1000 }">
    <div class="cv-upload">
      <ModalBase
        :additional_classes="{'outer' : 'cv-upload-modal'}"
        :loading="uploading"
        v-if="!hasError"
      >
        <div class="cv-upload-modal-inner">
          <h2 class="modal-title">Jetzt meinen Traumjob finden</h2>
          <p class="modal-subline">Lade hier Deinen Lebenslauf als PDF- oder Word-Dokument hoch.</p>
          <form v-on:submit.prevent="onSubmit" enctype="multipart/form-data">
            <div class="cv-upload-form-inner">
              <input
                type="file"
                name="cv"
                id="cv-upload-file"
                @change="onFileChange"
                ref="cv"
                :accept="acceptable_filetypes"
                required
              >
              <label for="cv-upload-file" class="cp-btn medium-large black regular">
                Datei auswählen
              </label>
              <p id="cv-file-name" :class="{ selected: cv_selected }">{{uploaded_cv}}</p>
              <div class="form-footer">
                <CpButton
                  color="blue"
                  size="large"
                  weight="bold"
                  type="submit"
                  :disabled="!cv_selected"
                >Analyse starten</CpButton>
              </div>
              <p>
                <router-link
                  to="/free-text"
                  class="simple-arrow-link"
                >
                  <Translation resource_key='applicantlanding_header.cvupload.nocv' />
                </router-link>
              </p>
            </div>
          </form>
        </div>
      </ModalBase>
      <ServerErrorMessage :closeCallback="onClose" v-if="hasError" />
    </div>
  </transition>
</template>

<script>
import CpButton from '../atoms/CpButton';
import Translation from '../atoms/Translation';
import ModalBase from '../molecules/ModalBase';
import ServerErrorMessage from '../molecules/ServerErrorMessage';

export default {
  name: 'CVUpload',

  components: {
    CpButton,
    Translation,
    ModalBase,
    ServerErrorMessage
  },

  data() {
    return {
      uploaded_cv: 'Keine Datei ausgewählt',
      cv_selected: false,
      uploading: false,
      hasError: false
    };
  },
  computed: {
    /**
     * acceptable_filetypes
     * TODO: here should be checked acceptable file type.
     * cv.type will be filled with application/pdf, application/msword, and so on.
     * here you can find media-type strigs:
     * http://www.iana.org/assignments/media-types/media-types.xhtml
     */
    acceptable_filetypes() {
      return [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        // "application/rtf",
        // "text/plain"
      ].join();
    }
  },
  methods: {
    onFileChange(e) {
      if (e.target.files.length) {
        this.cv_selected = true;
        this.uploaded_cv = e.target.files[0].name;
      } else {
        this.cv_selected = false;
        this.uploaded_cv = 'Keine Datei ausgewählt';
      }
    },
    onSubmit() {
      const cv = this.$refs.cv.files[0];

      this.$store
        .dispatch('applicant/analyseCV', {
          file: cv,
          filename: this.uploaded_cv,
          uploadProgressCallback: (a) => {
            console.log(a);
          }
        })
        .then(() => {
          this.$router.push('/analyse');
        })
        .catch(() => {
          // activate butter bar
          this.hasError = true;
        });

      this.uploading = true;
    },
    onClose() {
      this.$router.push('/');
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/templates/cv_upload_modal';
</style>

<style lang="scss">
@import '../../scss/atoms/breakpoints.mixin';
@import '../../scss/atoms/colors';

.cv-uploading,
.cv-uploading .cp-loading {
  height: 200px;

}

.cv-upload {
  a.simple-arrow-link {
    // font-weight: 600;
    color: $color-blacky;
    font-size: 17px;
    text-decoration: none;
  }
}

@include bp-max-medium() {
  input[type=file] {
    width: 50%;
  }
}
</style>
