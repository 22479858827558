<template>
  <section class="experiences-vue">
    <div class="title-wrapper">
      <h2><Translation resource_key="applicant.profile.job.experiences.title"/></h2>
    </div>
    <div class="wrapper" :class="{'edit': editing}" >
      <EditableArea :values="values" :form="form" :callbackSave="onSave" :callbackCancel="onCancel" @editing="editStatusChanged" >
        <ExperiencesDisplay slot="display" :values="values" :fresh="fresh" />
        <ExperiencesEdit slot="edit" :values="bufferedValue" :form="form" :callbackChange="onChange" />
      </EditableArea>
    </div>
  </section>
</template>

<script>
import _ from 'lodash';
import EditableArea from '../../molecules/EditableArea';
import ExperiencesDisplay from './ExperiencesDisplay';
import ExperiencesEdit from './ExperiencesEdit';
import Translation from '../../atoms/Translation';
import dateOrderUtils from '../../../lib/mixins/dateOrderUtils';

export default {
  name: 'Experiences',

  components: {
    EditableArea,
    ExperiencesDisplay,
    ExperiencesEdit,
    Translation
  },

  mixins: [dateOrderUtils],

  props: {
    values: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    fresh: {
      type: Boolean,
      default: false
    },
    callbackOnSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      bufferedValue: {},
      editing: false
    };
  },

  created() {
    this.setOriginalValue();
  },

  methods: {
    setOriginalValue() {
      // make object from array
      const result = {};
      this.values.jobExperiences.map((obj, idx) => {
        result[`${idx}`] = obj;
        return obj;
      });
      this.bufferedValue = result;
    },

    onSave() {
      const self = this;
      return new Promise((resolve, _reject) => {
        /* TODO:
          in this method will be replaced with vuex action.
          this implementation is only for storybook
          */

        const filteredValues = self.filterfilledEntries();

        const result = _.cloneDeep(self.values);
        result.jobExperiences = Object.values(filteredValues);
        self.callbackOnSave(result).then(() => {
          self.setOriginalValue();
          resolve()
        });
      });
    },
    onCancel() {
      this.setOriginalValue();
    },

    onChange(values) {
      this.bufferedValue = values;

      const result = { ...this.values };
      result.jobExperiences = values;
      this.$emit('change', result);
    },

    editStatusChanged(editing) {
      this.editing = editing;
    },

    filterfilledEntries() {
      const result = {}
      const filteredExperiences = Object.values(this.bufferedValue)
        .filter((v) => v.jobExperienceName.length && ('employerName' in v) && v.employerName.length)
        .map((itm) => {
          const item = { ...itm };
          // from should be earlier than till
          [item.from, item.till] = this.sortDateOrderByMomentFormat(itm.from, itm.till);
          return item;
        });
      this.eliminateOverlappedPeriods(filteredExperiences)
        .map((item, idx) => {
          result[idx] = item;
          return item
        });
      return result;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_breakpoints.mixin';

.experiences-vue {
  position: relative;
  padding: 60px 0;

  .title-wrapper,
  .wrapper {
    max-width: 840px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    word-wrap: break-word;

    &.edit {
      max-width: 1200px;
    }

    .editable-area {
      position: static;
    }
  }
}

@include bp-max-medium() {
  .experiences-vue {
    padding: 50px 30px;

    .title-wrapper,
    .wrapper {
      margin: 0 auto;
      padding: 0;
    }

    .wrapper {
      padding-top: 20px;
    }

    /deep/ {

      h2 {
        font-size: 24px !important;
        line-height: 34px !important;
        margin-bottom: 30px !important;
      }

      .column-2 .experience-time  {
        font-size: 14px !important;
        line-height: 22px !important;
        font-weight: bold;
      }

      .experience-name {
        font-size: 18px !important;
        line-height: 28px !important;
        font-weight: bold;
      }
    }
  }
}
</style>
