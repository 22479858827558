<template>
  <nav class="wizard-progress-ctrl">
    <span class="prev" :class="{hidden: !prev}" @click="prev_clicked()"><Editable
      resource_key="wizard-progress.nav.prev"/></span>
    <span class="next" :class="{hidden: !next, 'gray': !hasJobSelected}" @click="next_clicked()"><Editable
      resource_key="wizard-progress.nav.next"/></span>
  </nav>
</template>

<script>
import Editable from '../molecules/Editable';

export default {
  name: 'WizardProgressNav',

  components: {
    Editable
  },

  props: {
    prev: {
      type: Boolean,
      required: true
    },
    next: {
      type: Boolean,
      required: true
    },
    callback: {
      type: Function,
      required: true
    }
  },

  computed: {
    hasJobSelected() {
      return this.$store.state.applicant.selected.length > 0;
    }
  },

  methods: {
    prev_clicked() {
      this.callback(-1);
    },
    next_clicked() {
      if (this.hasJobSelected) {
        this.callback(1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';

.wizard-progress-ctrl {
  position: absolute;
  left: 0;
  top: 46px;
  width: 100%;
  height: 45px;
  overflow: hidden;
}

.prev,
.next {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  color: $color-white;
  top: 0;
  z-index: 200;
  font-size: 15px;
  transition: transform 0.3s;
  height: 45px;
  line-height: 45px;

  > div {
    display: inline-block;
  }
}

.prev {
  left: 0;
  color: $color-dustygrey;
  background-color: $color-lightgrey;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 0 17px 0 10px;

  &:before {
    font-family: fontello;  // stylelint-disable-line
    content: '\e80c';
    font-size: 12px;
    margin-right: 8px;
  }

  &.hidden {
    transform: translateX(-100%);
  }
}

.next {
  right: 0;
  background-color: $color-black;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding: 0 10px 0 17px;

  &:after {
    font-family: fontello;  // stylelint-disable-line
    content: '\e802';
    font-size: 12px;
    margin-left: 8px;
  }

  &.hidden {
    transform: translateX(100%);
  }

  &.gray {
    background-color: $color-silvergrey;
    cursor: default;
  }
}

@include bp-max-medium() {
  .wizard-progress-ctrl {
    top: 50px;
  }

  .prev,
  .next {
    height: 35px;
    line-height: 35px;
  }
}
</style>
