<template>
  <div class="cp-sticker-round">
    <div class="cp-sticker-round-inner">
      <div class="cp-sticker-round-icon" :style="iconStyle"></div>
      <p class="cp-sticker-round-text">
        <slot></slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StickerRound',

  props: ['bgimage'],

  computed: {
    iconStyle() {
      const styles = [];

      if (typeof this.bgimage === 'string') {
        styles.push(`background-image: url(${this.bgimage});`);
      }

      return styles.join(' ');
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/atoms/_colors';
@import '../../scss/molecules/sticker_round';
</style>
