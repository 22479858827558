import PasswordChange from '../../components/organisms/Applicant/myaccount/PasswordChange';
import RemoveAccount from '../../components/organisms/Applicant/myaccount/RemoveAccount'

export default [
  {
    path: 'password',
    name: 'PasswordChange',
    component: PasswordChange,
    props: {
      showButton: false,
      closeTo: { name: 'MyAccount' }
    }
  },
  {
    path: 'removeAccount',
    name: 'RemoveAccount',
    component: RemoveAccount,
    props: {
      closeTo: { name: 'MyAccount' }
    }
  }
];
