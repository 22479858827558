<template>
  <div class="further-qualification-form">
    <div class="column time">
      <div class="row">
        <CpSelectBox :value="valueOrBlank(this.qualification)"
                     :values="qualifications" @change="(v) => valueChanged('qualification', v)"
        >
          <Translation resource_key="applicant.profile.further.qualification.name" />
        </CpSelectBox>
      </div>
      <div class="row data">
        <LabelTextField :value="valueOrBlank(value.comment)"
                        placeholder="Erläuterung eingeben"
                        @change="(v) => valueChanged('comment', v)"
        >
          <Translation resource_key="applicant.profile.further.qualification.data" />
        </LabelTextField>
      </div>
      <div class="row">
        <LabelTextField :value="valueOrBlank(value.provider)"
                        placeholder="Anbieter / Institut/ Uni eingeben"
                        @change="(v) => valueChanged('provider', v)"
        >
          <Translation resource_key="applicant.profile.further.qualification.provider" />
        </LabelTextField>
      </div>
      <div class="row">
        <RemoveElementButton :callback="onRemove" />
      </div>
    </div>
  </div>
</template>

<script>
import Translation from '../../atoms/Translation';
import LabelTextField from '../../molecules/LabelTextField';
import CpSelectBox from '../../molecules/CpSelectBox';
import { RemoveElementButton } from '../../atoms/CpButton';
import formUtils from '../../../lib/mixins/formUtils';
import htmlUtils from '../../../lib/mixins/htmlUtils';
import util from '../../../lib/utils';

export default {
  name: 'FurtherQualificationForm',
  components: {
    Translation,
    LabelTextField,
    CpSelectBox,
    RemoveElementButton
  },

  mixins: [formUtils, htmlUtils],

  data() {
    return {
      today: new Date()
    };
  },

  props: {
    value: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      requreid: true
    },
    callbackRemove: {
      type: Function,
      required: true
    }
  },

  computed: {
    qualification() {
      const choice = this.findValueByLabelFromChoices(
        this.value.qualification,
        this.qualifications
      );
      return (choice) ? choice.value : '';
    },
    qualifications() {
      return this.form.furtherQualifications.qualification.choices;
    }
  },

  methods: {
    onRemove() {
      this.callbackRemove(this.value.value);
    },
    valueChanged(path, value) {
      let newVal = { ...this.value };
      const inputedValue = util.buildObjectFromPath(path, value);
      newVal = util.mergeDeep(newVal, inputedValue);
      this.$emit('change', newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';

.further-qualification-form {
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 40px;
  }

  .detail {
    width: calc(100% - 385px);
  }

  .label-textfield {
    margin-bottom: 0;

    /deep/ {
      label > span {
        font-size: 14px;
        line-height: inherit;
        color: $color-dustygrey;
      }

      input[type='text'] {
        background-color: $color-lightgrey;
        font-weight: 700;
      }
    }
  }

  .row.data .label-textfield /deep/ input[type='text'] {
    color: $color-blue;
  }

  .remove-element-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
}
</style>
