<template>
  <div class="educations-edit">
    <div class="column graduation">
      <h3 class="label">
        <Translation resource_key="applicant.profile.education.graduation.title" />
      </h3>
      <div class="row">
        <CpSelectBox :value="highestDegree" :values="highestDegrees" @change="(v) => highestDegreeChanged(v)">
          <Translation resource_key="applicant.profile.education.highest.degree" />
        </CpSelectBox>
      </div>
      <template v-if="hasStudied">
        <MultipleElementForm
          :value="studies"
          :form="form"
          :component="StudyForm"
          :callbackAdd="() => { onAdd('studyPrograms'); }"
          :callbackRemove="(index) => { onRemove('studyPrograms', index); }"
          @change="(value) => { onChange('studyPrograms', value); }"
        />
      </template>
      <template v-else>
        <GraduationForm
          :value="highestDegreeGrade"
          :form="form"
          @change="highestDegreeGradeChanged"
        />
      </template>
      <h3 class="label">
        <Translation resource_key="applicant.profile.education.vocational.training.title" />
      </h3>
      <MultipleElementForm
        :value="vocationalTrainings"
        :form="form"
        :component="VocationalTrainingForm"
        :callbackAdd="() => { onAdd('professions'); }"
        :callbackRemove="(index) => { onRemove('professions', index); }"
        @change="(value) => { onChange('professions', value); }"
      />
    </div>
    <div class="column further-qualification">
      <h3 class="label">
        <Translation resource_key="applicant.profile.education.further.qualification.title" />
      </h3>
      <MultipleElementForm
        :value="furtherQualifications"
        :form="form"
        :component="FurtherQualificationForm"
        :callbackAdd="() => { onAdd('furtherQualifications'); }"
        :callbackRemove="(index) => { onRemove('furtherQualifications', index); }"
        @change="(value) => { onChange('furtherQualifications', value); }"
      />
    </div>
  </div>
</template>

<script>
import Translation from '../../atoms/Translation';
import StudyForm from '../../organisms/ApplicantProfile/StudyForm';
import GraduationForm from '../../organisms/ApplicantProfile/GraduationForm';
import VocationalTrainingForm from '../../organisms/ApplicantProfile/VocationalTrainingForm';
import FurtherQualificationForm from '../../organisms/ApplicantProfile/FurtherQualificationForm';
import MultipleElementForm from '../../molecules/MultipleElementForm';
import CpSelectBox from '../../molecules/CpSelectBox';
import formUtils from '../../../lib/mixins/formUtils';
import breakPoints from '../../../lib/mixins/breakPoints';

export default {
  name: 'EducationsEdit',

  components: {
    Translation,
    CpSelectBox,
    MultipleElementForm,
    StudyForm, // eslint-disable-line vue/no-unused-components
    GraduationForm,
    VocationalTrainingForm, // eslint-disable-line vue/no-unused-components
    FurtherQualificationForm // eslint-disable-line vue/no-unused-components
  },

  mixins: [formUtils, breakPoints],

  data() {
    return {
      StudyForm,
      GraduationForm,
      VocationalTrainingForm,
      FurtherQualificationForm
    };
  },

  props: {
    values: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    callbackChange: {
      type: Function,
      required: true
    }
  },

  computed: {
    highestDegree() {
      const choice = this.findValueByLabelFromChoices(this.values.highestDegree, this.highestDegrees);
      return (choice) ? choice.value : '';
    },
    highestDegrees() {
      return Object.entries(this.form.highestDegree.choices).map(([k, v]) => ({ label: v, value: k }));
    },

    highestDegreeGrade() {
      return ('highestDegreeGrade' in this.values) ? this.values.highestDegreeGrade : '';
    },

    hasStudied() {
      return this.highestDegree === '4';
    },
    studies() {
      return (typeof this.values.studyPrograms !== 'undefined') ? this.values.studyPrograms : {};
    },
    vocationalTrainings() {
      return (typeof this.values.professions !== 'undefined') ? this.values.professions : {};
    },
    furtherQualifications() {
      return (typeof this.values.furtherQualifications !== 'undefined') ? this.values.furtherQualifications : {};
    }
  },

  methods: {
    onAdd(key) {
      let maxIndex = -1;
      if (typeof this.values[key] !== 'undefined') {
        const keys = Object.keys(this.values[key])
        if (keys.length !== 0) {
          maxIndex = keys.map((i) => Math.trunc(i)) // convert string to int
            .reduce((max, n) => (n > max ? n : max));
        }
      }
      const newObj = this.createNewObject(key);
      this.callbackChange(key, { ...this.values[key], [maxIndex + 1]: newObj });
    },

    createNewObject(type) {
      const prototype = {
        studyPrograms: {
          degree: 'Diplom',
          nameText: '',
          grade: 'Sehr guter Abschluss',
          from: new Date(),
          till: new Date()
        },
        professions: {
          employer: '',
          mastercraftsman: 'no',
          from: new Date(),
          till: new Date()
        },
        furtherQualifications: {
          qualification: 'Zertifikat',
          provider: '',
          comment: ''
        }
      }
      return { ...prototype[type] };
    },

    onRemove(key, idx) {
      const newVal = { ...this.values[key] };
      delete newVal[idx];
      this.callbackChange(key, newVal);
    },

    onChange(key, { index, value }) {
      const newVal = { ...this.values[key] };
      newVal[index] = value;
      this.callbackChange(key, newVal);
    },
    highestDegreeGradeChanged(value) {
      this.callbackChange('highestDegreeGrade', value);
    },
    highestDegreeChanged(value) {
      const choice = this.findValueFromChoices(value, this.highestDegrees);
      if (choice.value === '4' && Object.keys(this.studies).length === 0) {
        this.onAdd('studyPrograms');
      }
      this.callbackChange('highestDegree', choice.label);
    }

  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_breakpoints.mixin';

.educations-edit {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;

  .column {
    width: calc(50% - 60px);

    h3.label {
      font-size: 17px;
      color: $color-blue;
      font-weight: 400;
    }

    .cp-select-box {
      margin-bottom: 20px;

      /deep/ label span {
        font-size: 14px;
        line-height: inherit;
        color: #9b9b9b;
      }
    }

    .multiple-element-form {
      &:first-of-type {
        margin-bottom: 60px;
      }

      /deep/ {
        label {
          font-size: 14px;
          line-height: inherit;
          color: #9b9b9b;
          font-style: italic;
        }

        //.cp-select-box label span{
        //margin-bottom: 20px;
        //}

        .cp-select-box:not(.month):not(.year) {
          width: 100%;
          max-width: none;
          //margin-bottom: 1.4em;

          &:nth-of-type(n + 2) {
            &:before {
              padding-top: 0;
            }

            .icon-down-open-big {
              top: 2em;
            }
          }

          label {
            margin-top: -1.4em;
            display: inline-block;
          }

          ul {
            width: 100%;

            // TODO: detect class which is assigned opened
            &:hover {
              z-index: 10;

              + i {
                z-index: 10;
              }
            }

            li {
              width: 100%;
              line-height: 51px;
              padding-left: 15px;
              background-color: $color-lightgrey;
            }
          }

          .icon-down-open-big {
            top: 3.3em;
          }
        }

        .range-slider .rangeSlider-container {
          width: 100%;
        }

        .add-element-button {
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 40px;
        }

        .professional-knowledge-form input {
          width: calc(100% - 50px);
          margin-bottom: 10px;
          margin-right: 5px;
          border: 0;
          padding-left: 15px;
        }

        .switch-checkbox-container {
          margin-bottom: 20px;
        }
      }
    }
  }
}

/deep/ {
  .cp-select-wrapper select {
    background-color: $color-lightgrey;
  }
}

@include bp-max-medium() {
  .educations-edit {
    width: 100%;
    display: flex;
    flex-direction: column;

    .column {
      width: calc(100%);
    }
  }
}
</style>
