<template>
  <div class="image-form">
    <div class="cv-upload-form-inner">
      <div class="image-wrapper">
        <img :src="imagePath" />
      </div>
      <input
        type="file"
        name="image"
        id="image-file"
        @change="onFileChange"
        ref="cv"
        :accept="acceptableFiletypes"
        :disabled="disabled"
      >
      <label for="image-file" class="cp-btn medium-large black regular" :title="hoverText">
        Datei ändern
      </label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ImageForm',

  props: {
    value: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      uploadedImage: 'Keine Datei ausgewählt',
      selectedImage: null
    };
  },

  computed: {
    /**
     * acceptable_filetypes
     * TODO: here should be checked acceptable file type.
     * cv.type will be filled with application/pdf, application/msword, and so on.
     * here you can find media-type strigs:
     * http://www.iana.org/assignments/media-types/media-types.xhtml
     */
    acceptableFiletypes() {
      // return ['jpeg', 'jpg', 'png', 'image/png'].join();
      return 'image/*';
    },
    imagePath() {
      return this.selectedImage ? this.selectedImage : this.value;
    },
    hoverText() {
      return this.disabled
        ? 'Bevor Du Dein Foto hochlädst, nenne uns zunächst Deine aktuelle Tätigkeit im Bereich Berufs- und Praxiserfahrung.'
        : '';
    }
  },

  methods: {
    onFileChange(e) {
      if (e.target.files.length) {
        this.uploadedImage = e.target.files[0].name;

        if (e.target.files && e.target.files[0]) {
          const reader = new FileReader();
          const self = this;
          reader.onload = (readEvent) => {
            self.selectedImage = readEvent.target.result;
          };
          reader.readAsDataURL(e.target.files[0]);
        }

        const extension = this.uploadedImage.substring(this.uploadedImage.lastIndexOf('.') + 1);
        this.$emit('change', {
          attached: true,
          name: 'file',
          file: e.target.files[0],
          extension
        });
      } else {
        this.uploadedImage = 'Keine Datei ausgewählt';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';

.image-form {
  width: 280px;

  .image-wrapper {
    max-width: 280px;
    max-height: 320px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  input[type='file'] {
    display: none;
  }

  label {
    display: block;
    padding: 0;
    margin: 20px auto;
    border: 1px solid $color-dustygrey;
    background-color: $color-dustygrey;
    color: $color-white;
    width: 200px;
    cursor: pointer;
    text-align: center;
  }

  input:disabled + label {
    cursor: not-allowed;
    box-shadow: none !important;
    color: $color-silvergrey;
  }
}
</style>
