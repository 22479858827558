<template>
  <div class="auto-complete-text-field" @keyup.up="upPressed" @keyup.down="downPressed">
    <label>
      <span class="slot">
        <slot></slot>
      </span>
    </label>
    <input
      type="text"
      :class="{ 'blank' : blank }"
      :placeholder="placeholder"
      :tabindex="tabindex"
      :ref="referrer"
      @change="onChanged"
      @keyup="onChanged"
      v-model="inputedValue"
    />
    <ul ref='ul' :class="{ forceClose }">
      <li v-for="(val, index) in valueList"
          :key="index"
          :ref="'li' + index"
          @click="select(val)"
          @mouseenter="mouseEntered"
          @mouseleave="mouseLeaved"
          @keypress.up="upPressed"
          @keypress.down="downPressed"
          @blur="onBlur">
        <span v-html="highlight(val)"></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AutoCompleteTextField',

  data() {
    return {
      selected: false,
      forceClose: false,
      triggerMouseLeave: false,
      inputedValue: ''
    };
  },

  props: {
    values: {
      type: Array,
      required: true
      // TODO: define validation, check if values are Array of:
      // - String
      // - or Object which contains label + value
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    value: {
      type: String
    },
    referrer: {
      type: String
    },
    placeholder: {
      type: String
    },
    tabindex: {
      type: String,
      default: undefined
    },
    callback: {
      type: Function
    }
  },

  created() {
    if (this.value) {
      this.inputedValue = this.value;
      this.selected = true;
    }
  },

  computed: {
    blank() {
      return this.inputedValue.length === 0;
    },
    selectedValue() {
      return this.$refs[`li${this.selectedIndex}`][0].textContent.trim();
    },
    valueList() {
      if (this.selected) {
        return [];
      }
      const result = this.values.map((v) => v[this.labelKey]);
      if (result.length > 0 && this.inputedValue.length > 0) {
        const lcInputed = this.inputedValue.toLowerCase();
        const filteredByInputed = result.filter((v) => v.toLowerCase().indexOf(lcInputed) !== -1);
        const numOfDisplay = (filteredByInputed.length > 4) ? 4 : filteredByInputed.length;
        return filteredByInputed.slice(0, numOfDisplay);
      }
      return [];
    }
  },

  methods: {
    select(val) {
      this.inputedValue = val;
      this.selected = true;
      this.$emit('change', val); // FIXME actually should be forwarded value from valueList, not text
    },

    mouseEntered() {
      /*
      if (this.triggerMouseLeave) {
        // this.forceClose = false;
        this.triggerMouseLeave = false;
        this.callback(this.selectedValue);
      } */
    },

    mouseLeaved() {
      if (this.triggerMouseLeave) {
        // this.forceClose = false;
        this.triggerMouseLeave = false;
        this.notifyValueChanged();
      }
    },

    upPressed() {
      this.selectedIndex -= 1;
      if (this.selectedIndex < 0) {
        this.selectedIndex = 0;
        return;
      }
      this.notifyValueChanged();
    },

    downPressed() {
      this.selectedIndex += 1;
      if (this.selectedIndex >= this.values.length) {
        this.selectedIndex = this.values.length - 1;
        return;
      }
      this.notifyValueChanged();
    },

    /**
     * FIXME: it does not work...
     */
    setFirstElementStyle(index) {
      if (!this.$refs || !('ul' in this.$refs)) {
        return '';
      }
      // applied only first element
      if (index !== 0) {
        return '';
      }

      const { ul } = this.$refs;
      if (ul.parentElement.querySelector(':hover') !== ul) {
        return '';
      }

      const { top: ulTop } = ul.getBoundingClientRect();
      const { top: selectedTop } = ul.querySelector('.selected').getBoundingClientRect();
      return { 'margin-top': `${ulTop - selectedTop}px` };
    },

    onChanged() {
      console.log(this.inputedValue);
      this.selected = false;
      if (this.valueList.length !== 0) {
        const [valueText] = this.valueList;
        // turn selected true, when inputedValue is equal with text in the list
        this.selected = valueText === this.inputedValue;
        // if true, emit 'change' event
        if (this.selected) {
          this.$emit('change', this.inputedValue); // FIXME actually should be forwarded value from valueList, not text
        }
      }
      this.$emit('inputed', this.inputedValue); // FIXME actually should be forwarded value from valueList, not text
    },

    onBlur() {
      if (this.inputedValue.length && this.valueList.length > 0) {
        this.inputedValue = this.valueList.shift();
      }
    },

    highlight(val) {
      const result = val;
      const lowerResult = result.toLowerCase();
      const lowerInput = this.inputedValue.toLowerCase();
      const beforeMatchedIndex = lowerResult.indexOf(lowerInput);
      const afterMatchedIndex = beforeMatchedIndex + lowerInput.length;
      return `${result.slice(0, beforeMatchedIndex)}<em>${result.slice(beforeMatchedIndex, afterMatchedIndex)}</em>${result.slice(afterMatchedIndex)}`;
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/atoms/colors';

.auto-complete-text-field {
  position: relative;

  label {
    position: relative;
  }

  span.slot {
    display: block;
    font-style: italic;
    font-size: 12px;
    line-height: inherit;
    color: $color-dustygrey;
  }

  input[type='text'] {
    display: block;
    background-color: $color-lightgrey;
    border: none;
    padding-left: 15px;
    line-height: 50px;
    font-size: 17px;
    width: 100%;

    &.blank {
      font-weight: 400 !important;
      font-style: italic;
      background-color: transparent !important;
      border: 1px solid #aaaaaa;
    }
  }

  .icon-down-open-big {
    position: absolute;
    right: 10px;
    margin-left: 10px;
    z-index: 1;
  }

  ul {
    position: absolute;
    z-index: 2;
    width: 100%;

    &:hover {

      li {
        display: list-item;
      }
    }

    &.forceClose li {
      display: none;
    }

    li {
      z-index: 1;
      background-color: $color-ultralightgrey;
      line-height: 50px;
      padding-left: 15px;
      cursor: pointer;

      &:hover {
        background-color: $color-white;
      }

      &.selected {
        display: list-item;

        > div {
          display: inline-block;
        }
      }

      &.placeholder {
        background-color: $color-white;
        border: 1px solid $color-dustygrey;
        color: $color-dustygrey;
        font-style: italic;
        font-weight: 100;
      }

      span {
        font-style: italic;

        em {
          font-weight: 800;
        }
      }
    }
  }
}
</style>
