<template>
  <footer class="job-suggestion-footer">
    <p>
      <img src="/assets/img/checkbox.svg" />
      <Editable resource_key="jobsuggestion.footer.text" />
    </p>
    <CpButton :callback="onClick" color="blue" size="large">
      <Editable resource_key="jobsuggestion.footer.button" />
    </CpButton>
  </footer>
</template>

<script>
import CpButton from '../atoms/CpButton';
import Editable from '../molecules/Editable';

export default {
  name: 'JobSuggestionFooter',
  components: {
    Editable,
    CpButton
  },
  props: {
    loadingCallback: {
      type: Function
    }
  },
  methods: {
    onClick(_e) {
      const {
        location,
        distance,
        workingTimeType: employmentType,
        searchText: keyword
      } = this.$store.state.applicant.jobSuggestionForm;
      const { lat, lng: long } = location;
      const joblistIds = this.$store.state.applicant.selected;
      this.$store
        .dispatch('applicant/getRefinedJobList', {
          joblistIds,
          lat,
          long,
          distance: (distance === '') ? undefined : distance,
          employmentType,
          keyword
        })
        .then(() => {
          this.loadingCallback(false);
        });
      this.loadingCallback(true);
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/atoms/colors';
@import '../../scss/atoms/_typographie';
@import '../../scss/atoms/_breakpoints.mixin';

.job-suggestion-footer {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0px 15px 20px 20px;
  background-color: $color-blacky-transparent;
  color: $color-white;
  z-index: 2;
  font-size: 24px;
  line-height: 28px;
  font-family: $font-main;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;

    > div {
      margin-left: 20px;
    }
  }

  /deep/ .cp-btn {
    margin: 20px 0 !important;
  }
}

@include bp-max-medium() {
  .job-suggestion-footer {
    flex-flow: column;
    justify-content: flex-start;
    font-size: 17px;
    line-height: 24px;
    padding: 15px;
    height: 140px;

    p {
      margin-bottom: 10px;
    }

    /deep/ .cp-btn.large {
      margin: 0 !important;
      max-width: 100%;
      line-height: 50px;
    }
  }
}

@include bp-max-extra-small() {
  .job-suggestion-footer {
    height: 100px;
    padding-top: 5px;
    padding-bottom: 5px;

    p {
      margin-bottom: 5px;

      img {
        width: 40px;
      }

      /deep/ span {
        font-size: 14px;
        line-height: 21px;
      }
    }

    /deep/ .cp-btn.large {
      line-height: 40px;

      /deep/ span {
        line-height: 40px;
      }
    }
  }
}
</style>
