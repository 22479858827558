export default {
  NAME: 'dosier',
  STATE: {
    DOSIER_ENTITY: 'entity',
    DOSIER_FORMS: 'forms',
    DOSIER_FRESH: 'fresh'
  },
  MUTATIONS: {
    SET_DOSIER_ENTITY: 'setDosierEntity',
    SET_DOSIER_FRESH: 'setDosierFresh',
    SET_DOSIER_FORMS: 'setDosierForms'
  },
  ACTIONS: {
    LOAD_DOSIER: 'loadDosier',
    UPDATE_DOSIER: 'updateDosier'
  }
};
