<template>
  <div class="label-textfield">
    <label>
      <span class="slot">
        <slot></slot>
      </span>
      <i class="fontello" :class="iconName" v-if="icon"></i>
      <input
        :class="{ 'blank' : blank }"
        :type="inputType"
        :placeholder="placeholder"
        :value="value"
        :maxlength="maxlength" @input="updateValue"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: 'LabelTextField',

  props: {
    value: {
      type: String,
      required: true
    },
    placeholder: {
      type: String
    },
    icon: {
      type: String
    },
    inputType: {
      type: String,
      default: 'text',
      validator(val) {
        return ['email', 'number', 'text', 'tel', 'url'].includes(val);
      }
    },
    maxlength: {
      type: Number,
      default: 512
    },
    validate: {
      type: Function
    }
  },
  computed: {
    iconName() {
      return `icon-${this.icon}`;
    },
    blank() {
      return this.value.length === 0;
    }
  },
  methods: {
    updateValue(e) {
      if (this.validate) {
        const valid = this.validate(e.target.value);
        if (!valid) {
          return false;
        }
      }
      this.$emit('change', e.target.value);
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';

$color-placeholder: $color-silvergrey;

.label-textfield {
  margin-bottom: 10px;

  label {
    position: relative;
    display: block;
  }

  span.slot {
    display: block;
    font-style: italic;
    font-size: 12px;
    line-height: 1.5em;
  }

  input[type='email'],
  input[type='tel'],
  input[type='url'],
  input[type='number'],
  input[type='text'] {
    display: block;
    background-color: $color-silvergrey;
    border: none;
    padding-left: 15px;
    line-height: 50px;
    font-size: 17px;
    width: 100%;

    &.blank {
      font-weight: 400 !important;
      font-style: italic;
      background-color: transparent !important;
      border: 1px solid #aaaaaa;

      &:-ms-input-placeholder {
        color: $color-placeholder;
      }

      &::-ms-input-placeholder {
        color: $color-placeholder;
      }

      &::-webkit-input-placeholder {
        color: $color-placeholder;
      }

      &::placeholder {
        color: $color-placeholder;
      }
    }
  }

  i {
    display: flex;
    position: absolute;
    // top: 1.4em;
    z-index: 1;
    left: 5px;
    top: 50%;
    padding-top: 18px;
    transform: translateY(-50%);
  }

  i ~ input[type='email'],
  i ~ input[type='tel'],
  i ~ input[type='url'],
  i ~ input[type='number'],
  i ~ input[type='text'] {
    padding-left: 40px;
  }
}
</style>
