<template>
  <div class="responsiv-data-grid">
    <template v-if="bpMaxMedium">
      <div class="row"
           v-for="(content, idx) in lefts"
           :key="`l${idx}`"
           :class="{'title' : asTitle(idx, content) }"
      >
        <div class="column">
          <CompanyDataBlock v-if="content">
            <template v-if="'key' in content">
              <Translation slot="data" :resource_key="content.key"/>
            </template>
            <template v-if="!('key' in content)">
              <Translation slot="title" :resource_key="content.title" v-if="'title' in content"/>
              <template slot="data" v-if="'data' in content">
                {{ content.data }}
              </template>
            </template>
          </CompanyDataBlock>
        </div>
      </div>
      <template v-if="rights">
        <div class="row"
             v-for="(content, idx) in rights" :key="`r${idx}`"
             :class="{'title' : asTitle(idx, content) }"
        >
          <div class="column right">
            <CompanyDataBlock  v-if="content">
              <template v-if="'key' in content">
                <Translation slot="data" :resource_key="content.key"/>
              </template>
              <template v-if="!('key' in content)">
                <Translation slot="title" :resource_key="content.title" v-if="'title' in content" />
                <template slot="data" v-if="'data' in content">
                  {{ content.data }}
                </template>
              </template>
            </CompanyDataBlock>
          </div>
        </div>
      </template>
    </template>

    <template v-else>
      <!-- desktop -->
      <div class="row" v-for="(content, idx) in contents"
           :key="idx" :class="{'title' : asTitle(idx, content) }"
      >
        <div class="column">
          <CompanyDataBlock v-if="content.left">
            <template v-if="'key' in content.left">
              <Translation slot="data" :resource_key="content.left.key"/>
            </template>
            <template v-if="!('key' in content.left)">
              <Translation slot="title"
                           :resource_key="content.left.title"
                           v-if="'title' in content.left"
              />
              <template slot="data" v-if="'data' in content.left">
                {{ content.left.data }}
              </template>
            </template>
          </CompanyDataBlock>
        </div>
        <div class="column right">
          <CompanyDataBlock  v-if="content.right">
            <template v-if="'key' in content.right">
              <Translation slot="data" :resource_key="content.right.key"/>
            </template>
            <template v-if="!('key' in content.right)">
              <Translation slot="title"
                           :resource_key="content.right.title"
                           v-if="'title' in content.right"
              />
              <template slot="data" v-if="'data' in content.right">
                {{ content.right.data }}
              </template>
            </template>
          </CompanyDataBlock>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import Translation from '../atoms/Translation';
import CompanyDataBlock from './CompanyDataBlock';
import breakPoints from '../../lib/mixins/breakPoints';

export default {
  name: 'ResponsiveDataGrid',
  components: {
    Translation,
    CompanyDataBlock
  },

  mixins: [breakPoints],

  props: {
    contents: {
      type: Array,
      required: true
    }
  },

  computed: {
    lefts() {
      return this.contents.map((c) => c.left).filter((c) => c); // filter without undefined item
    },
    rights() {
      return this.contents.map((c) => c.right).filter((c) => c); // filter without undefined item
    }
  },

  methods: {
    asTitle(idx, content) {
      if (idx !== 0) {
        return false;
      }
      if ('left' in content || 'right' in content) {
        const c = content.left || content.right;
        return !('title' in c && 'data' in c);
      }
      return !('title' in content && 'data' in content);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin'; // for /deep/ should be have here
@import '../../scss/atoms/layout'; // for /deep/ should be have here

.row {
  display: flex;
  justify-content: center;

  &.title {
    margin-top: 40px;
    height: 50px;

    /deep/ p {
      color: $color-blue !important;
    }

    .column.right {
      height: auto;
    }
  }

  .column {
    width: 48%;
    margin-right: 2%;

    &.right {
      height: 70px;
    }

    /deep/ div {
      height: 100%;
    }
  }
}

@include bp-max-tablet() {
  .row .column {
    width: 100%;
  }
}
</style>
