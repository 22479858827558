<template>
  <transition name="modal" :duration="{ leave: 1000 }">
    <div class="job-offer">
      <ModalBase :closeTo="closeTo" displayCloseButton="true" :waitingMessage="false">
        <div class="job-offer-page" v-if="hasJobOffer()">
          <JobOfferHeader :jobOffer="jobOffer" :showButton="showButton" />
          <CompanyOverview
            :profile="jobOffer.companyProfile"
            :logo="jobOffer.logo"
            :image="jobOffer.images[0]"
          />
          <YourMission :jobOffer="jobOffer" />
          <YourExpectation :jobOffer="jobOffer" />
          <CompanyExpectation :jobOffer="jobOffer" />
          <JobOfferFooter :jobOffer="jobOffer" v-if="showButton" />
        </div>
      </ModalBase>
    </div>
  </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import ModalBase from '../molecules/ModalBase';
import JobOfferHeader from './JobOfferHeader';
import JobOfferFooter from './JobOfferFooter';
import CompanyOverview from './CompanyOverview';
import YourMission from './YourMission';
import YourExpectation from './YourExpectation';
import CompanyExpectation from './CompanyExpectation';
import JobOfferStore from '../../store/modules/joboffer';

const { mapState, mapActions } = createNamespacedHelpers('joboffer');
const { types } = JobOfferStore;

export default {
  name: 'JobOffer',

  components: {
    ModalBase,
    JobOfferHeader,
    JobOfferFooter,
    CompanyOverview,
    YourMission,
    YourExpectation,
    CompanyExpectation
  },

  data() {
    return {
      job: null
    }
  },

  computed: {
    ...mapState([
      types.STATE.JOBOFFER
    ])
  },
  /**
   * FIXME; consider how to use both props for storybook + mapState for app?
   */
  props: {
    /* jobOffer: {
      type: Object,
      required: false
    } */
    /**
     * this props for Storybook, with it we must mock API calling
     */
    jobOfferId: {
      type: Number,
      required: false
    },
    showButton: {
      type: Boolean,
      default: true
    },
    closeTo: {
      type: [String, Object],
      default: '/'
    }
  },

  created() {
    const jobOfferId = this.jobOfferId || this.$route.params.id;
    this.loadJobOffer({ jobOfferId, locale: this.$careerpeakr.locale });
  },

  methods: {
    hasJobOffer() {
      return Object.keys(this.jobOffer).length > 0;
    },

    ...mapActions({
      loadJobOffer: types.ACTIONS.LOAD_JOBOFFER
    })
  }
}
</script>

<style lang="scss">
@import '../../scss/atoms/colors';
@import '../../scss/atoms/_breakpoints.mixin';

.job-offer .modal-inner {
  max-width: 1200px;
  padding: 0;
}

.job-offer .modal-inner .container {
  padding: 0;
}

.job-offer-page {
  max-width: 1200px;
  background-color: $color-white;
  margin: 0 auto;
}

@include bp-max-medium() {
  .job-offer {
    .modal-outer {
      padding: 0;

      &:before {
        display: none;
      }
    }

    .modal-inner {
      width: 100%;
      height: 100%;

      .container {
        max-height: 100%;
      }

      .cp-modal-close {
        left: auto;
        top: 10px;
      }
    }
  }
}

</style>
