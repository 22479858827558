<template>
  <address class="cp-address">
    <h2 class="cp-address-name">
      <slot name="title"></slot>
    </h2>
    <p class="cp-address-contact">
      <slot name="contact"></slot>
    </p>
    <p class="cp-address-links">
      <slot name="links"></slot>
    </p>
  </address>
</template>

<script>
export default {
  name: 'ContactAddress',
  computed: {
    hasTitle() {
      return !!this.$slots.title;
    },

    hasTaglines() {
      return !!this.$slots.taglines;
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/molecules/contact_address';
</style>
