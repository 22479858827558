<template>
  <div class="further-skills-display">
    <ul>
      <li v-for="(val, idx) in values" :key="idx" >
        <i class="fontello icon-check"></i>
        {{ val.skillName }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FurtherSkillsDisplay',
  props: {
    values: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';

.icon-check {
  color: $color-blue;
}

.further-skills-display ul {
  max-width: 785px;
  border-top: 1px solid $color-silvergrey;

  li {
    width: 100%;
    border-bottom: 1px solid $color-silvergrey;
    line-height: 50px;
    padding-left: 5px;
    padding-bottom: 13px;
  }
}
</style>
