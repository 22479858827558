<template>
  <transition name="modal" :duration="{ leave: 1000 }">
    <div class="free-text">
      <ModalBase
        :additional_classes="{'outer' : 'free-text-modal'}"
        :loading="requesting"
        v-if="!hasServerError"
      >
        <div class="free-text-modal-inner">
          <h2 class="modal-title">Keinen Lebenslauf zur Hand?</h2>
          <p class="modal-subline">Dann nenne uns hier Deine wichtigsten Kenntnisse und Fähigkeiten.</p>
          <p class="modal-subline">Beispielsweise: Was hast Du gelernt oder studiert? Welche Fremdsprache(n) sprichst Du? Welche beruflichen Tätigkeiten übst Du aus? Schreibe in jedes Textfeld eine Fähigkeit oder Kenntnis.</p>
          <form v-on:submit.prevent="submit">
            <div class="free-text-form-inner">
              <div class="form-row">
                <input
                  type="text"
                  placeholder="1. berufliche Fähigkeit eingeben*"
                  class="cp-default-input"
                  required
                  @change="onTextChange"
                >
              </div>
              <div class="form-row">
                <input
                  type="text"
                  placeholder="2. berufliche Fähigkeit eingeben*"
                  class="cp-default-input"
                  required
                  @change="onTextChange"
                >
              </div>
              <div class="form-row">
                <input
                  type="text"
                  placeholder="3. berufliche Fähigkeit eingeben*"
                  class="cp-default-input"
                  required
                  @change="onTextChange"
                >
              </div>
              <div class="form-row">
                <input
                  type="text"
                  placeholder="4. berufliche Fähigkeit eingeben*"
                  class="cp-default-input"
                  required
                  @change="onTextChange"
                >
              </div>
              <div class="form-row">
                <input
                  type="text"
                  placeholder="5. berufliche Fähigkeit eingeben*"
                  class="cp-default-input"
                  required
                  @change="onTextChange"
                >
              </div>
              <div class="form-row">
                <input
                  type="text"
                  placeholder="6. berufliche Fähigkeit eingeben*"
                  class="cp-default-input"
                  required
                  @change="onTextChange"
                  @keyup="onTextChange"
                  @keyup.enter="submit"
                >
              </div>
              <div class="form-footer">
                <p class="form-note">
                  <sup>*</sup>
                  Pflichtfelder. Es müssen alle Felder ausgefüllt sein,
                  um die Jobanalyse zu starten.
                </p>
                <CpButton
                  color="blue"
                  size="large"
                  weight="bold"
                  type="submit"
                  :disabled="!form_valid"
                >Analyse starten</CpButton>
              </div>
            </div>
          </form>
        </div>
      </ModalBase>
      <ServerErrorMessage :closeCallback="onClose" v-if="hasServerError" />
    </div>
  </transition>
</template>

<script>
import CpButton from '../atoms/CpButton';
import ModalBase from '../molecules/ModalBase';
import ServerErrorMessage from '../molecules/ServerErrorMessage';

export default {
  name: 'FreeText',

  components: {
    CpButton,
    ModalBase,
    ServerErrorMessage
  },

  data() {
    return {
      form_valid: false,
      requesting: false,
      hasServerError: false
    };
  },

  methods: {
    onTextChange(_e) {
      const requireds = this.$el.querySelectorAll('input[required]');
      let isValid = true;

      for (let i = 0; i < requireds.length; i += 1) {
        if (!requireds[i].validity.valid) {
          isValid = false;
          break;
        }
      }

      this.form_valid = isValid;
    },

    submit() {
      const requireds = this.$el.querySelectorAll('input[required]');
      const freetext = [];
      let isValid = true;

      for (let i = 0; i < requireds.length; i += 1) {
        if (!requireds[i].validity.valid) {
          isValid = false;
          break;
        }

        freetext.push(requireds[i].value);
      }

      if (isValid) {
        this.$store
          .dispatch('applicant/analyseText', {
            text: freetext,
            uploadProgressCallback: (a) => {
              console.log(a);
            }
          })
          .then(() => {
            this.$router.push('/analyse');
          })
          .catch(() => {
            this.hasServerError = true;
          });
        this.requesting = true;
      }
    },
    onClose() {
      this.$router.push('/');
    }

  }
};
</script>

<style lang="scss">
@import '../../scss/templates/free_text_modal';
</style>
