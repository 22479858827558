<template>
  <transition name="modal" :duration="{ leave: 1000 }">
    <section class="modal" @keyup.esc="closeModal($event)">
      <div class="modal-outer" :class="getClass('outer')">
        <div class="modal-inner" v-if="!loading">
          <div class="container">
            <slot></slot>
          </div>
          <router-link :to="closeTo" class="cp-modal-close">close</router-link>
        </div>
        <CpLoading v-if="loading"/>
        <div class="loading-text" v-if="loading">
          <template v-if="waitingMessage">
            Wir gleichen Deine beruflichen Fähigkeiten mit den Anforderungen
            aus aktuellen Stellenanzeigen ab.
            Dieser Vorgang kann bis zu 30 Sekunden dauern.
          </template>
        </div>
      </div>
      <div class="modal-background" @click="closeModal($event)"></div>
    </section>
  </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import CpLoading from '../../atoms/CpLoading';

const { mapMutations } = createNamespacedHelpers('application');

export default {
  name: 'ModalBase',
  components: {
    CpLoading
  },
  props: {
    additional_classes: {
      default: null
      // TODO: write validation?
    },
    loading: {
      type: Boolean,
      default: false
    },
    waitingMessage: {
      type: Boolean,
      default: true
    },
    closeTo: {
      type: [String, Object],
      default: '/'
    },
    forceOpened: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scrolltop: 0
    };
  },
  created() {
    this.scrolltop = window.scrollY;
    this.openModalState({ scrolltop: this.scrolltop });
  },
  destroyed() {
    this.closeModalState();
    this.$nextTick(() => {
      window.scrollTo(0, this.scrolltop);
    });
  },
  methods: {
    closeModal(e) {
      console.log('closeModal called');
      console.log(e);
      if (this.forceOpened) {
        return;
      }
      this.$router.push(this.closeTo);
    },
    getClass(key) {
      return this.additional_classes ? this.additional_classes[key] : null;
    },
    hasClass(key) {
      return this.additional_classes ? this.getClass(key) : false;
    },
    ...mapMutations({
      openModalState: 'openModal',
      closeModalState: 'closeModal'
    })
  }
};
</script>

<style lang="scss">
@import './modal';

.loading-text {
  color: #ffffff;
  max-width: 980px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: calc(50% + 100px);
}
</style>
