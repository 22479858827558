import WrongPassword from '../components/templates/BadCredidential/WrongPassword';
import ResetPassword from '../components/templates/BadCredidential/ResetPassword';

export default {
  routes: [
    {
      path: '/',
      name: 'WrongPassword',
      component: WrongPassword
    },
    {
      path: '/reset',
      name: 'ResetPassword',
      component: ResetPassword
    }
  ]
};
