<template>
  <div class="animated-label-textfield">
    <label>
      <span :class="{'slot' : true, 'blank' : blank, 'required': required}" >
        <slot></slot>
      </span>
      <i class="fontello" :class="iconName" v-if="icon"></i>
      <input
        :class="{ 'blank' : blank }"
        :type="inputType"
        :value="value"
        :maxlength="maxlength"
        :required="required"
        @input="updateValue"
        @focus="onFocus"
        @blur="onBlur"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: 'AnimatedLabelTextField',

  props: {
    value: {
      type: String,
      required: true
    },
    icon: {
      type: String
    },
    inputType: {
      type: String,
      default: 'text',
      validator(val) {
        return ['email', 'number', 'password', 'text', 'tel', 'url'].includes(val);
      }
    },
    maxlength: {
      type: Number,
      default: 512
    },
    validate: {
      type: Function
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      focused: false,
      bufferedValue: this.value,
      bufferedPlaceholder: ''
    }
  },
  computed: {
    iconName() {
      return `icon-${this.icon}`;
    },
    blank() {
      return !this.focused && this.value.length === 0;
    }
  },
  created() {
    if (this.placeholder) {
      this.bufferedPlaceholder = this.placeholder;
    }
  },
  methods: {
    updateValue(e) {
      if (this.validate) {
        const valid = this.validate(e.target.value);
        if (!valid) {
          return false;
        }
      }
      this.$emit('input', e.target.value);
      this.$emit('change', e.target.value);
      return true;
    },
    onFocus(e) {
      this.focused = true;
      this.$emit('focus', e.target.value);
    },
    onBlur(e) {
      this.focused = false;
      this.$emit('blur', e.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';

$color-placeholder: $color-silvergrey;

.animated-label-textfield {
  margin-bottom: 10px;
  position: relative;

  &.error {
    input[type='email'],
    input[type='tel'],
    input[type='url'],
    input[type='number'],
    input[type='password'],
    input[type='text'] {
      border: 3px solid $color-red;

      &.blank {
        border: 3px solid $color-red;
      }
    }
  }

  label {
    position: relative;
    display: block;
  }

  span.slot {
    position: absolute;
    display: block;
    font-style: italic;
    font-size: 12px;
    line-height: 1.5em;
    z-index: 1;
    transition: top 0.5s, left  0.5s, font-size 0.5s, color 0.5s;
    top: 1px;
    left: 4px;

    &.blank {
      font-size: 17px;
      top: 15px;
      left: 15px;
      color: $color-dustygrey;
      transition: top 0.5s, left  0.5s, font-size 0.5s, color 0.5s;
    }

    &.required:after {
      content: '*';
    }
  }

  input[type='email'],
  input[type='tel'],
  input[type='url'],
  input[type='number'],
  input[type='password'],
  input[type='text'] {
    top: 18px;
    display: block;
    background-color: $color-silvergrey;
    border: none;
    padding-left: 15px;
    line-height: 50px;
    font-size: 17px;
    width: 100%;
    transition: background-color 0.5s;

    &.blank {
      font-weight: 400 !important;
      font-style: italic;
      background-color: transparent !important;
      border: 1px solid $color-silvergrey;
      transition: background-color 0.5s;

      &:-ms-input-placeholder {
        color: $color-placeholder;
      }

      &::-ms-input-placeholder {
        color: $color-placeholder;
      }

      &::-webkit-input-placeholder {
        color: $color-placeholder;
      }

      &::placeholder {
        color: $color-placeholder;
      }

      &:after {
        content: '';
        height: 54px;
        position: relative;
      }
    }
  }

  i {
    display: flex;
    position: absolute;
    // top: 1.4em;
    z-index: 1;
    left: 5px;
    top: 50%;
    padding-top: 18px;
    transform: translateY(-50%);
  }

  i ~ input[type='email'],
  i ~ input[type='tel'],
  i ~ input[type='url'],
  i ~ input[type='number'],
  i ~ input[type='password'],
  i ~ input[type='text'] {
    padding-left: 40px;
  }
}
</style>
