<template>
  <div class="study-form">
    <div class="column">
      <div class="row">
        <MonthYearSelectBox :value="convertSelectBoxDate(value.from)"
                            :givenYears="givenYears"
                            class="study-program-begin"
                            @change="(v) => valueChanged('from', v)"
        >
          <Translation resource_key="applicant.profile.education.start" />
        </MonthYearSelectBox>
        <MonthYearSelectBox :value="convertSelectBoxDate(value.till)"
                            :givenYears="givenYears"
                            class="study-program-end"
                            @change="(v) => valueChanged('till', v)"
        >
          <Translation resource_key="applicant.profile.education.end" />
        </MonthYearSelectBox>
      </div>
      <div class="row name">
        <LabelTextField :value="valueOrBlank(value.nameText)"
                        @change="(v) => valueChanged('nameText', v)"
        >
          <Translation resource_key="applicant.profile.education.name" />
        </LabelTextField>
      </div>
      <div class="row">
        <LabelTextField :value="valueOrBlank(value.university)"
                        @change="(v) => valueChanged('university', v)"
        >
          <Translation resource_key="applicant.profile.education.university" />
        </LabelTextField>
      </div>
      <div class="row">
        <!-- autocomplete box-->
        <CpSelectBox :value="degree" :values="degrees" @change="(v) => valueChanged('degree', v)">
          <Translation resource_key="applicant.profile.education.degree" />
        </CpSelectBox>
      </div>
      <div class="row">
        <!-- autocomplete box-->
        <CpSelectBox :value="grade" :values="grades" @change="(v) => valueChanged('grade', v)">
          <Translation resource_key="applicant.profile.education.grade" />
        </CpSelectBox>
      </div>
      <div class="row">
        <RemoveElementButton :callback="onRemove" />
      </div>
    </div>
  </div>
</template>

<script>
import Translation from '../../atoms/Translation';
import LabelTextField from '../../molecules/LabelTextField';
import MonthYearSelectBox from '../../molecules/MonthYearSelectBox';
import CpSelectBox from '../../molecules/CpSelectBox';
import { RemoveElementButton } from '../../atoms/CpButton';
import formUtils from '../../../lib/mixins/formUtils';
import htmlUtils from '../../../lib/mixins/htmlUtils';
import dateOrderUtils from '../../../lib/mixins/dateOrderUtils';
import utils from '../../../lib/utils';

export default {
  name: 'StudyForm',
  components: {
    Translation,
    LabelTextField,
    MonthYearSelectBox,
    CpSelectBox,
    RemoveElementButton
  },

  mixins: [formUtils, htmlUtils, dateOrderUtils],

  data() {
    return {
      today: new Date()
    };
  },

  props: {
    value: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      requreid: true
    },
    callbackRemove: {
      type: Function,
      required: true
    }
  },

  computed: {
    degree() {
      const choice = this.findValueByLabelFromChoices(this.value.degree, this.degrees);
      return (choice) ? choice.value : '';
    },
    degrees() {
      return this.form.studyPrograms.degree.choices;
    },

    grade() {
      const choice = this.findValueByLabelFromChoices(this.value.grade, this.grades);
      return (choice) ? choice.value : '';
    },
    grades() {
      return Object.entries(this.form.studyPrograms.grade.choices).map(([value, label]) => ({ label, value })); // eslint-disable-line max-len
    },

    givenYears() {
      return utils.range(1970, 2030).map((y) => y.toString());
    }
  },

  methods: {
    onRemove() {
      this.callbackRemove(this.value.value);
    },
    valueChanged(path, value) {
      let newVal = { ...this.value };
      const inputedValue = utils.buildObjectFromPath(path, value);
      newVal = utils.mergeDeep(newVal, inputedValue);
      this.$emit('change', newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';

.study-form {
  margin-bottom: 40px;

  .detail {
    width: calc(100% - 385px);
  }

  .month-year-select-box {
    /deep/ {
      label {
        font-size: 14px;
        font-style: italic;
        line-height: inherit;
        color: $color-dustygrey;
      }
    }
  }

  .row.name /deep/ input[type='text'] {
    color: $color-blue;
  }

  .label-textfield {
    margin-bottom: 0;

    /deep/ {
      label > span {
        font-size: 14px;
        line-height: inherit;
        color: $color-dustygrey;
      }

      input[type='text'] {
        background-color: $color-lightgrey;
        font-weight: 700;
      }
    }
  }

  .remove-element-button {
    display: block;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
