<template>
  <ul class="job-suggestion-tabs">
    <li :class="{'active' : state.all}" @click="onClick('all')">
      <Editable resource_key="jobsuggestion.tabs.all" />
    </li>
    <li :class="{'active' : state.new}" @click="onClick('new')">
      <Editable resource_key="jobsuggestion.tabs.new" />
    </li>
    <li :class="{'active' : state.favorite}" @click="onClick('favorite')">
      <i class="fontello icon-heart"></i>
      <Editable resource_key="jobsuggestion.tabs.marked" />
    </li>
    <li :class="{'active' : state.trash}" @click="onClick('trash')">
      <i class="fontello icon-trash-empty"></i>
      <Editable resource_key="jobsuggestion.tabs.trash" />
    </li>
  </ul>
</template>

<script>
import Editable from '../molecules/Editable';

export default {
  name: 'JobSuggestionTabs',
  components: {
    Editable
  },
  props: {
    state: {
      type: Object,
      required: true
    },
    callback: {
      type: Function,
      required: true
    }
  },
  methods: {
    onClick(clickedKey) {
      Object.keys(this.state).map((key) => {
        this.state[key] = false;
        return key;
      });
      this.state[clickedKey] = true;
      this.callback();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';

.job-suggestion-tabs {
  list-style: none;
  margin-bottom: 0;
  border-bottom: 1px solid $color-silvergrey;
  padding-left: 18px;
  margin-left: 20px;
  margin-right: 20px;

  li {
    display: inline-block;
    color: $color-dustygrey;
    font-size: 14px;
    padding: 4px 16px;
    cursor: pointer;
    &.active {
      color: $color-blacky;
      font-weight: 600;
      border: 1px solid $color-silvergrey;
      border-bottom: none;
    }
    .fontello ~ div {
      display: inline-block;
      margin-left: 5px;
    }
  }
}

@include bp-max-medium() {
  .job-suggestion-tabs {
    margin: 0;
  }
}

@include bp-max-extra-small() {
  .job-suggestion-tabs li {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
