<template>
  <section id="your-expectation" class="cp-section">
    <div class="your-expectation-inner">
      <CpHeader class="">
        <template slot="title">
          <Translation resource_key="joboffer.your-expectation.title"/>
        </template>
      </CpHeader>

      <div class="">
        <h3 class="job-description-title">
          <Translation resource_key="joboffer.divisionDescription" datakey="descTitle" />
          <span v-html="descTitle" />
          {{ jobOffer.divisionProfile.divisionName }}
        </h3>
        <div class="joboffer-division-description" v-html="jobOffer.divisionProfile.description" />
      </div>

      <Translation datakey="titleWorkingCulture" resource_key="joboffer.division.workingCulture"/>
      <Translation datakey="titleAdditionalBenefits"
                   resource_key="joboffer.division.additionalBenefits"
      />

      <ResponsiveDataGrid :contents="divisionContents" />
    </div>

    <div class="your-expectation-inner further">
      <h4 v-if="jobOffer.jobOffer.specificBenefitsForApplicant">
        <Translation resource_key="joboffer.further-benefits"/>
      </h4>
      <div class="" v-if="jobOffer.jobOffer.specificBenefitsForApplicant">
        <div class="joboffer-description free-text"
             v-html="removeStyleAttr(jobOffer.jobOffer.specificBenefitsForApplicant)"
        />
      </div>

      <Translation resource_key="joboffer.employeesNumber" datakey="employeesNumber"/>
      <Translation resource_key="joboffer.yearsOld" datakey="yearsOld"/>
      <Translation datakey="titleCurrentTeam" resource_key="joboffer.division.currentTeam"/>
      <Translation datakey="titleCurrentLeader" resource_key="joboffer.division.currentLeader"/>

      <ResponsiveDataGrid :contents="teamContents" />

    </div>

  </section>
</template>

<script>
import CpHeader from '../molecules/CpHeader';
// import CompanyDataBlock from '../molecules/CompanyDataBlock';
import ResponsiveDataGrid from '../molecules/ResponsiveDataGrid';
import Translation from '../atoms/Translation';
import removeStyleAttr from '../../lib/mixins/htmlUtils';
import { viewPort } from '../../lib/utils';

export default {
  name: 'YourExpectation',

  components: {
    CpHeader,
    // CompanyDataBlock,
    ResponsiveDataGrid,
    Translation
  },

  mixins: [removeStyleAttr],

  props: {
    jobOffer: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      descTitle: '',
      employeesNumber: '',
      yearsOld: '',
      titleCurrentTeam: '',
      titleCurrentLeader: '',
      titleWorkingCulture: '',
      titleAdditionalBenefits: ''
    }
  },

  computed: {
    divisionContents() {
      const { jobOffer } = this.jobOffer;
      const left = [
        {
          data: this.titleWorkingCulture
        },
        {
          title: 'joboffer.divisionProfiles.workType',
          data: this.jobOffer.divisionProfile.workType
        },
        {
          title: 'joboffer.divisionProfiles.personalAddressing',
          data: this.jobOffer.divisionProfile.personalAddressing
        },
        {
          title: 'joboffer.divisionProfiles.dressCode',
          data: this.jobOffer.divisionProfile.dressCode
        },
        {
          title: 'joboffer.divisionProfiles.officeType',
          data: this.jobOffer.divisionProfile.officeType
        },
        {
          title: 'joboffer.divisionProfiles.internationalColleagues',
          data: this.jobOffer.divisionProfile.internationalColleagues
        }
      ];
      const right = [];
      right.push({ data: this.titleAdditionalBenefits });
      if (jobOffer.hasCompanyCar === 'yes') {
        right.push({ key: 'joboffer.hasCompanyCar' });
      }
      if (jobOffer.hasCompanyPension === 'yes') {
        right.push({ key: 'joboffer.hasCompanyPension' });
      }
      if (jobOffer.hasEducation === 'yes') {
        right.push({ key: 'joboffer.hasEducation' });
      }
      if (jobOffer.hasFamilySupport === 'yes') {
        right.push({ key: 'joboffer.hasFamilySupport' });
      }
      if (jobOffer.hasFlexibleOfficeTime === 'yes') {
        right.push({ key: 'joboffer.hasFlexibleOfficeTime' });
      }
      if (jobOffer.hasHomeOffice === 'yes') {
        right.push({ key: 'joboffer.hasHomeOffice' });
      }
      if (jobOffer.hasSabbatical === 'yes') {
        right.push({ key: 'joboffer.hasSabbatical' });
      }

      const biggerArray = (left.length > right.length) ? left : right;
      const result = [];
      biggerArray.map((elm, idx) => {
        result.push({ left: left[idx], right: right[idx] });
        return elm;
      });

      return result;
    },

    teamContents() {
      const left = [];
      left.push({ data: this.titleCurrentTeam });
      if ('teamSize' in this.jobOffer.divisionProfile) {
        left.push({
          title: 'joboffer.divisionProfiles.teamSize',
          data: `${this.jobOffer.divisionProfile.teamSize} ${this.employeesNumber}`
        });
      }
      if ('languageSkills' in this.jobOffer.divisionProfile) {
        left.push({
          title: 'joboffer.predominantDivisionLanguage',
          data: this.jobOffer.divisionProfile.languageSkills.map((l) => l.language).join(', ')
        });
      }

      const right = [];
      if (this.jobOffer.hasBossInfo === 'yes') {
        right.push({ data: this.titleCurrentLeader });
        if ('bossSex' in this.jobOffer.divisionProfile) {
          right.push({
            title: 'joboffer.divisionProfiles.bossSex',
            data: this.jobOffer.divisionProfile.bossSex
          });
        }
        if ('bossAge' in this.jobOffer.divisionProfile) {
          right.push({
            title: 'joboffer.divisionProfiles.bossAge',
            data: `${this.jobOffer.divisionProfile.bossAge} ${this.yearsOld}`
          });
        }
        if ('bossEducation' in this.jobOffer.divisionProfile) {
          right.push({
            title: 'joboffer.divisionProfiles.bossEducation',
            data: this.jobOffer.divisionProfile.bossEducation
          });
        }
      }

      const biggerArray = (left.length > right.length) ? left : right;
      const result = [];
      biggerArray.map((elm, idx) => {
        result.push({ left: left[idx], right: right[idx] });
        return elm;
      });

      return result;
    },

    isMobile() {
      return viewPort().width < 768; //  === bp-max-medium
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';
@import '../../scss/atoms/layout';

#your-expectation {
  background-color: $color-ultralightgrey;
}

.your-expectation-inner {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;

  .header /deep/ .title {
    font-size: 30px;
  }

  /deep/ .row .title p {
    color: #00c1f8 !important;
  }

  /deep/ .row:not(.title) .column.right p:before {
    content: '\e817';
    font-family: fontello; // stylelint-disable-line
    color: $color-blue;
    margin-right: 15px;
  }

  &.further {
    font-size: 17px !important;
    line-height: 28px !important;

    .h4 {
      line-height: 28px;
    }
  }
}

.columns-2-item {
  width: calc((100% - var(--pading-edge) * 2 - 100px) / 2);
}

.row .column {
  width: 48%;
  margin-left: 2%;
  font-weight: 700;

  &:first-child {
    margin-left: 0;
  }
}

@include bp-max-large() {
  #employer-advantage {
    margin-top: 0;
  }

  .employer-advantage-inner {
    min-width: auto;
    margin-left: -0;

    .employer-advantage-item:nth-child(2n-1) {
      margin-right: 0;
    }
  }
}

@include bp-max-tablet() {
  #your-expectation {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .your-expectation-inner {
    padding-left: var(--padding-edge);
    padding-right: var(--padding-edge);

    /deep/ .responsiv-data-grid {
      margin-top: 50px;
    }

    /deep/ h4 {
      font-size: 18px;
      line-height: 28px;
    }
  }
}

@include bp-max-medium() {
  #your-expectation {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .your-expectation-inner /deep/ {
    .header h2.title {
      font-size: 24px;
    }

    .responsiv-data-grid {
      margin-top: 50px;
    }

    h4 {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .job-description-title h3 {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 30px;
  }

  .row {
    flex-direction: column;

    .column {
      width: 100%;
    }
  }
}
</style>
