<template>
  <div class="cp-loading">
    <ul>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';

.cp-loading {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 50%;
  width: 90px;

  ul {
    margin: 0;
    list-style: none;
    width: 90px;
    position: relative;
    padding: 0;
    height: 10px;

    li {
      position: absolute;
      width: 2px;
      height: 0;
      background: $gradient-blue;
      bottom: 0;
    }
  }
}

@keyframes sequence1 {
  0% {
    height: 10px;
  }
  50% {
    height: 50px;
  }
  100% {
    height: 10px;
  }
}

@keyframes sequence2 {
  0% {
    height: 20px;
  }
  50% {
    height: 65px;
  }
  100% {
    height: 20px;
  }
}

.cp-loading li:nth-child(1) {
  left: 0;
  animation: sequence1 1s ease infinite 0;
}
.cp-loading li:nth-child(2) {
  left: 15px;
  animation: sequence2 1s ease infinite 0.1s;
}
.cp-loading li:nth-child(3) {
  left: 30px;
  animation: sequence1 1s ease-in-out infinite 0.2s;
}
.cp-loading li:nth-child(4) {
  left: 45px;
  animation: sequence2 1s ease-in infinite 0.3s;
}

.cp-loading li:nth-child(5) {
  left: 60px;
  animation: sequence1 1s ease-in-out infinite 0.4s;
}
.cp-loading li:nth-child(6) {
  left: 75px;
  animation: sequence2 1s ease infinite 0.5s;
}
.cp-loading li:nth-child(7) {
  left: 90px;
  animation: sequence1 1s ease infinite 0.5s;
}
</style>
