<template>
  <CpButton v-if="has_edited" :callback="commit" class="commit-button">commit</CpButton>
</template>

<script>
import { mapState } from 'vuex';
import CpButton from '../../atoms/CpButton';
import translations from '../../../store/modules/translations';

export default {
  name: 'CommitButton',
  components: {
    CpButton
  },
  props: {
    resource_key: {
      type: String
    }
    /* FIXME at last props should be have only dict + key
     * or take dict from parent or other related component
     */
  },
  created() {},
  data() {
    return {
      // original_content: ,
      content: this.content,
      original_content: null,
      editing: false
    };
  },
  computed: {
    ...mapState({
      has_edited: (state) => {
        console.log(state);
        return state.translations.updatesStatus === translations.types.STATUS.NEED_TO_UPDATE;
      }
    })
  },
  methods: {
    commit() {
      this.$store.dispatch('translations/update', window.translations);
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../../scss/atoms/colors';
.commit-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
