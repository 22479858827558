<template>
  <div class="experience-form">
    <div class="column time">
      <div class="row">
        <MonthYearSelectBox class="begin"
                            :value="convertSelectBoxDate(value.from)"
                            :givenYears="givenYears"
                            @change="(v) => valueChanged('from', convertDateToStr(v))"
        >
          <Translation resource_key="applicant.profile.experiences.start" />
        </MonthYearSelectBox>
        <MonthYearSelectBox class="end"
                            :value="convertSelectBoxDate(value.till)"
                            :givenYears="givenYears"
                            @change="(v) => valueChanged('till', convertDateToStr(v))"
                            v-if="value.currentJob !== 'yes'"
        >
          <Translation resource_key="applicant.profile.experiences.end" />
        </MonthYearSelectBox>
      </div>
      <YesNoSwitch id="myid" name="myname"
                   :value="value.currentJob === 'yes'"
                   @change="(v) => yesNoValueChanged('currentJob', v)"
      >
        <Translation resource_key="applicant.profile.experiences.current.job" />
      </YesNoSwitch>
    </div>
    <div class="line">
      <span class="point" :class="{'current' : value.currentJob === 'yes' }"></span>
      <RemoveElementButton :callback="onRemove" />
    </div>
    <div class="column detail">
      <div class="row">
        <LabelTextField :value="valueOrBlank(value.jobExperienceName)"
                        placeholder="Stellentitel eingeben"
                        @change="(v) => valueChanged('jobExperienceName', v)"
                        required
        >
          <Translation resource_key="applicant.profile.experiences.position.name" />
        </LabelTextField>
      </div>
      <div class="row employer-name">
        <LabelTextField :value="valueOrBlank(value.employerName)"
                        placeholder="Name des Arbeitgebers eingeben"
                        @change="(v) => valueChanged('employerName', v)"
                        required
        >
          <Translation resource_key="applicant.profile.experiences.employer.name" />
        </LabelTextField>
      </div>
      <div class="row">
        <!-- autocomplete box-->
        <AutoCompleteTextField
          :value="valueOrBlankWithoutPreset(
            value.jobExperienceDynaJobsSector, 'je_jobExperienceDynaJobsSector.'
          )"
          placeholder="Branche eingeben"
          :values="jobExperienceDynaJobsSectorChoices"
          @change="(v) => valueChanged('jobExperienceDynaJobsSector', v)"
        >
          <Translation resource_key="applicant.profile.experiences.branch" />
        </AutoCompleteTextField>
      </div>
      <div class="row">
        <RichTextArea :value="valueOrBlank(value.description)"
                      placeholder="Aufgabe beschreiben"
                      @change="(v) => valueChanged('description', v)"
        >
          <Translation resource_key="applicant.profile.experiences.description" />
        </RichTextArea>
      </div>
    </div>
  </div>
</template>

<script>
import Translation from '../../atoms/Translation';
import LabelTextField from '../../molecules/LabelTextField';
import AutoCompleteTextField from '../../molecules/AutoCompleteTextField';
import MonthYearSelectBox from '../../molecules/MonthYearSelectBox';
import YesNoSwitch from '../../molecules/YesNoSwitch';
import RichTextArea from '../../molecules/RichTextArea';
import { RemoveElementButton } from '../../atoms/CpButton';
import formUtils from '../../../lib/mixins/formUtils';
import htmlUtils from '../../../lib/mixins/htmlUtils';
import util from '../../../lib/utils';
import breakPoints from '../../../lib/mixins/breakPoints';

export default {
  name: 'ExperienceForm',

  components: {
    Translation,
    LabelTextField,
    AutoCompleteTextField,
    MonthYearSelectBox,
    YesNoSwitch,
    RichTextArea,
    RemoveElementButton
  },

  mixins: [formUtils, htmlUtils, breakPoints],

  props: {
    value: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      default: null
    },
    callbackRemove: {
      type: Function,
      required: true
    }
  },

  computed: {
    jobExperienceDynaJobsSectorChoices() {
      return this.form.jobExperiences.jobExperienceDynaJobsSector.choices;
    },
    givenYears() {
      return util.range(1970, 2030).map((y) => y.toString());
    }
  },

  methods: {
    valueChanged(path, value) {
      let newVal = { ...this.value };
      const inputedValue = util.buildObjectFromPath(path, value);
      newVal = util.mergeDeep(newVal, inputedValue);
      this.$emit('change', newVal);
    },
    yesNoValueChanged(path, value) {
      this.valueChanged(path, value ? 'yes' : 'no');
    },
    convertSelectBoxDate(value) {
      if (value !== 'now') {
        return new Date(value);
      }
      return new Date();
    },
    convertDateToStr(value) {
      return value.toISOString();
    },
    valueOrBlankWithoutPreset(val, preset) {
      return (val === preset) ? '' : this.valueOrBlank(val);
    },
    onRemove() {
      this.callbackRemove();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_breakpoints.mixin';

.experience-form {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 120px;

  &:last-of-type {
    margin-bottom: 90px;
  }

  .detail {
    width: calc(100% - 355px);
  }

  .line {
    top: 50px;
    position: relative;
    border-right: 1px solid #acacac;
    margin-right: 25px;
    margin-left: 25px;

    .point {
      position: absolute;
      width: 14px;
      height: 14px;
      border: 0 none;
      padding: 3px;
      border-radius: 100%;
      top: 0;
      right: -15px;
      z-index: 2;
      transform: translateX(-50%);
      background: transparent $gradient-blue;
      transition: all 0.2s;

      &:before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 10px;
        height: 10px;
        background-color: $color-white;
        border-radius: 100%;
        z-index: 5;
      }

      &.current {
        width: 20px;
        height: 20px;
        padding: 6px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        top: -3px;
        right: -20px;

        &:before {
          content: '';
          position: absolute;
          top: 5px;
          left: 5px;
          width: 10px;
          height: 10px;
          background-color: $color-white;
          border-radius: 100%;
          z-index: 5;
        }
      }
    }

    .remove-element-button {
      position: absolute;
      bottom: -30px;
      left: -55px;

      /deep/ span {
        display: inline-block;
        color: $color-white;
      }
    }
  }

  .month-year-select-box /deep/ {
    > label {
      font-style: italic;
    }

  }

  .yes-no-switch /deep/ label {
    font-size: 14px;
    font-style: italic;
    color: $color-dustygrey;
  }

  .auto-complete-text-field,
  .label-textfield {
    margin-bottom: 0;

    /deep/ {
      label > span {
        font-size: 14px;
        line-height: inherit;
        color: $color-dustygrey;
      }

      input[type='text'] {
        background-color: $color-lightgrey;
        font-weight: 700;
      }
    }
  }

  .row.employer-name .label-textfield /deep/ input {
    color: $color-blue;
  }

  .rich-text-area {
    /deep/ {
      label {
        font-weight: 400;
      }

      .ck-content {
        font-size: 17px;
      }
    }
  }

  @include bp-max-medium(){
    padding: 0 5px;
    width: 100%;

    .detail {
      width: calc(90%);
    }

    .time{
      width: 100%;
    }

    .line {
      top: 50px;
      position: relative;
      margin-left: 1px;

      .remove-element-button {
        left: -12px !important;
      }

      .point {
        position: absolute;
        width: 14px;
        height: 14px;
        border: 0 none;
        padding: 3px;
        border-radius: 100%;
        top: 0;
        right: -14px;
        z-index: 2;
        transform: translateX(-50%);
        background: transparent $gradient-blue;
        transition: all 0.2s;

        &:before {
          content: '';
          position: absolute;
          top: 2px;
          left: 2px;
          width: 10px;
          height: 10px;
          background-color: $color-white;
          border-radius: 100%;
          z-index: 5;
        }
      }
    }

  }
}
</style>
