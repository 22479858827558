import JobOffer from '../components/templates/JobOffer';
import CompanyProfile from '../components/templates/CompanyProfile';
import Appointment from '../components/templates/Applicant/status/Appointment';
import AppointmentForm from '../components/templates/AppointmentForm';
import ThankYouAppointment from '../components/templates/ThankYouAppointment';

export default {
  routes: [
    {
      path: '',
      name: 'StatusHome'
    },
    {
      path: '/jobOffer/:id',
      name: 'JobOffer',
      component: JobOffer,
      props: {
        showButton: false
      }
    },
    {
      path: '/company/:id',
      name: 'CompanyProfile',
      component: CompanyProfile
    },
    {
      path: '/appointment/:sourcerId',
      component: Appointment,
      children: [
        {
          path: '',
          name: 'AppointmentForm',
          component: AppointmentForm
        },
        {
          path: 'thankyou',
          name: 'AppointmentDone',
          component: ThankYouAppointment
        }
      ]
    }
  ]
};
