<template>
  <div class="onboarding-info-display">
    <CompanyDataBlock>
      <Translation slot="title" resource_key="applicant.profile.onboading.availability" />
      <template slot="data">
        {{ values.telephoneInterview.availability | stripHtml }}
      </template>
    </CompanyDataBlock>
    <CompanyDataBlock>
      <Translation slot="title" resource_key="applicant.profile.onboading.period.of.notice" />
      <template slot="data">
        {{ values.telephoneInterview.periodNotice }}
      </template>
    </CompanyDataBlock>
    <CompanyDataBlock>
      <Translation slot="title" resource_key="applicant.profile.onboading.mobility" />
      <template slot="data">
        {{ values.telephoneInterview.mobility | stripHtml }}
      </template>
    </CompanyDataBlock>
    <CompanyDataBlock>
      <Translation slot="title" resource_key="applicant.profile.onboading.motivation.of.change" />
      <div slot="data" v-html="values.telephoneInterview.generalImpression"></div>
    </CompanyDataBlock>
    <CompanyDataBlock>
      <Translation slot="title" resource_key="applicant.profile.onboading.salary.expectations" />
      <template slot="data">
        {{ values.telephoneInterview.salaryExpectations | stripHtml }}
      </template>
    </CompanyDataBlock>
    <CompanyDataBlock>
      <Translation slot="title" resource_key="applicant.profile.onboading.interview.appointment" />
      <template slot="data">
        {{ values.telephoneInterview.interviewAppointment }}
      </template>
    </CompanyDataBlock>
    <CompanyDataBlock>
      <Translation slot="title" resource_key="applicant.profile.onboading.private.notes" />
      <div slot="data" v-html="values.telephoneInterview.privateNotes"></div>
    </CompanyDataBlock>
  </div>
</template>

<script>
import Translation from '../../atoms/Translation';
import CompanyDataBlock from '../../molecules/CompanyDataBlock';
import htmlUtils from '../../../lib/mixins/htmlUtils';

export default {
  name: 'OnboardingInfoDisplay',
  components: {
    CompanyDataBlock,
    Translation
  },
  mixins: [htmlUtils],
  props: {
    values: {
      type: Object,
      required: true
    }
  },
  computed: {
    inOtherApplicationProcesses() {
      if ('inOtherApplicationProcesses' in this.values.telephoneInterview) {
        return this.values.telephoneInterview.inOtherApplicationProcesses ? 'Ja' : 'Nein';
      }
      return 'Nein';
    }
  }
};
</script>
