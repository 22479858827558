import { render, staticRenderFns } from "./EducationsEdit.vue?vue&type=template&id=258e75ac&scoped=true&"
import script from "./EducationsEdit.vue?vue&type=script&lang=js&"
export * from "./EducationsEdit.vue?vue&type=script&lang=js&"
import style0 from "./EducationsEdit.vue?vue&type=style&index=0&id=258e75ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "258e75ac",
  null
  
)

export default component.exports