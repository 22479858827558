<template>
  <div class="user-info" v-if="profile">
    <div :style="{backgroundImage: `url(${avatarUrl})`}" alt="avatar" class="user-picture"></div>
    <div class="user-names">
      <h2>{{ fullName }}</h2>
      <p>
        <router-link :to="{ name: 'ApplicantProfile', params: { applicantId: profile.id } }" class="simple-arrow-link">
          <Translation resource_key="userinfo.link_to_profile" />
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import Editable from '../molecules/Editable';
import Translation from '../atoms/Translation';

export default {
  name: 'UserInfo',
  components: {
    // Editable,
    Translation
  },
  computed: {
    ...mapState({
      profile(state) {
        return state.applicant.profile;
      },
      avatarUrl(state) {
        if (state.applicant
          && state.applicant.profile
          && (typeof state.applicant.profile.imageUrl === 'string')
          && state.applicant.profile.imageUrl.length > 0) {
          return state.applicant.profile.imageUrl;
        } if (state.applicant && state.applicant.profile) {
          return (state.applicant.profile.gender >= 1 && state.applicant.profile.gender <= 3)
            ? `/assets/img/profile-picture-placeholder${state.applicant.profile.gender}.png`
            : '/assets/img/profile-picture-placeholder.png';
        }
        return '/assets/img/profile-picture-placeholder.png';
      },

      fullName() {
        return (this.profile.title)
          ? `${this.profile.title} ${this.profile.firstName} ${this.profile.lastName}`
          : `${this.profile.firstName} ${this.profile.lastName}`;
      }
    })
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';

.user-info {
  display: block;
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-top: 53.18%;
  }

  h2 {
    font-size: 26px;
    line-height: 1;
    font-weight: 900;
    margin-top: 10px;
    margin-bottom: 0;
    color: $color-white;
    border-bottom: 1px solid $color-white;
    padding: 2px 0 11px;
  }
  p {
    margin-top: 4px;
    margin-bottom: 0;
    font-size: 16px;
  }
  a {
    margin-top: 0;
    color: $color-white;
    text-decoration: none;
  }
}

.user-picture {
  position: absolute;
  left: 0;
  top: 0;
  width: 33.33%;
  height: 100%;
  background: transparent no-repeat center;
  background-size: cover;
  max-width: 224px;
}

.user-names {
  position: absolute;
  left: 33.33%;
  right: 0;
  top: 0;
  height: 100%;
  background: $gradient-blue-horizontal;
  padding: 20px;
}

@include bp-max-medium() {
  .user-names h2 {
    font-size: 17px;
    margin-top: 12px;
  }
}
</style>
