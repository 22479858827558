<template>
  <section class="further-skills" v-if="hasTelephoneInterview()">
    <div class="wrapper">
      <h2><Translation resource_key="applicant.profile.further.skills" /></h2>
      <EditableArea :values="bufferedValue" :form="form" :callbackSave="onSave" :callbackCancel="onCancel" >
        <FurtherSkillsDisplay slot="display" :values="matchedQualities" />
        <FurtherSkillsEdit slot="edit" :values="bufferedValue" :form="form" :callbackChange="onChange" />
      </EditableArea>
    </div>
  </section>
</template>

<script>
import _ from 'lodash';
import Translation from '../../atoms/Translation';
import EditableArea from '../../molecules/EditableArea';
import FurtherSkillsDisplay from './FurtherSkillsDisplay';
import FurtherSkillsEdit from './FurtherSkillsEdit';

export default {
  name: 'FurtherSkills',

  components: {
    Translation,
    EditableArea,
    FurtherSkillsDisplay,
    FurtherSkillsEdit
  },

  data() {
    return {
      form: {},
      bufferedValue: null
    };
  },

  props: {
    values: {
      type: Object,
      required: true
    },
    callbackOnSave: {
      type: Function,
      required: true
    }
  },

  created() {
    this.setOriginalValue();
  },

  computed: {
    matchedQualities() {
      return ('matchedQualities' in this.values.telephoneInterview)
        ? this.values.telephoneInterview.matchedQualities : [];
    }
  },
  methods: {
    hasTelephoneInterview() {
      return 'telephoneInterview' in this.values;
    },

    setOriginalValue() {
      const matchedQualities = ('matchedQualities' in this.values.telephoneInterview)
        ? this.values.telephoneInterview.matchedQualities : [];

      // make object from array
      this.bufferedValue = matchedQualities.reduce((obj, item) => {
        obj[item.id] = item.skillName; // eslint-disable-line
        return obj;
      }, {});
    },

    /**
     * here we will compose from inputed values with original JSON data,
     * then emit to upper component. upper component will recieve and call vuex store.
     */
    onSave() {
      const self = this;
      return new Promise((resolve, _reject) => {
        /* TODO:
          in this method will be replaced with vuex action.
          this implementation is only for storybook
          */
        // remove blank text field
        Object.entries(self.bufferedValue)
          .filter(([_key, val]) => val.length === 0)
          .every(([key, _val]) => delete self.bufferedValue[key]);

        // revert into original format as Array
        const changedValues = Object.entries(self.bufferedValue).map(([k, v]) => ({
          id: k,
          skillName: v
        }));
        const result = _.cloneDeep(self.values);
        result.telephoneInterview.matchedQualities = changedValues;
        self.callbackOnSave(result).then(() => {
          self.setOriginalValue();
          resolve()
        });
      });
    },

    onCancel() {
      this.setOriginalValue();
      const self = this;
      Object.entries(this.bufferedValue)
        .filter(([_key, val]) => val.length === 0)
        .every(([key, _val]) => delete self.bufferedValue[key]);
      // this.$emit('cancel', this.bufferedValue);
    },
    /**
     * here we will save inputed values on to buffer.
     * don't give it to upper component here.
     */
    onChange(values) {
      this.bufferedValue = values;
      /*
      const changedValues = Object.entries(this.bufferedValue).map(([k, v]) => ({
        id: k,
        skillName: v
      }));
      */
      // const result = { ...this.values };
      // result.telephoneInterview.matchedQualities = changedValues;
      // this.$emit('change', result);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_breakpoints.mixin';

.further-skills {
  position: relative;
  background-color: $color-blue-ultralight;
  padding: 60px 0;

  .wrapper {
    max-width: 840px;
    width: 100%;
    margin: 0 auto;

    /deep/ {
      h2 {
        font-weight: 800;
        font-size: 28px;
        margin-top: 0;
        margin-bottom: 28px;
      }

      .editable-area {
        position: static;
      }
    }
  }
}

@include bp-max-medium(){
  .further-skills {
    padding: 40px 0;

    .wrapper {
      padding: 0 15px;
      word-wrap: break-word;
    }
  }
}
</style>
