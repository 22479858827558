<template>
  <div class="dashboard">
    <section class="dashboard-main">
      <h2 class="dashboard-title">Exklusive Jobangebote unserer Mandanten</h2>
      <div class="dashboard-container clearfix">
        <div class="dashboear-container-left">
          <ListingTab>
            <ListingTabChild title="Job- und Bewerbungsstatus">
              <ApplicationStatusList type="manualmatching">
                <div class="no-job-recommendation-notice">
                  <p>
                    <strong>Leider haben wir aktuell kein passendes Jobangebot.</strong>
                  </p>
                  <p>
                    Es kommen täglich neue Stellen unserer Mandanten hinzu.
                    Wir melden uns umgehend, sobald wir eine passende Stelle haben.
                  </p>
                  <p>
                    <strong>Wichtig:</strong>
                    Nur bei einem vollständigen und aktuellen Bewerberprofil
                    können wir passende Stellen präsentieren.
                  </p>
                  <p>
                    <CpButtonAnchor to="dosier" color="blue" size="large" weight="bold">
                      Zum Bewerberprofil
                    </CpButtonAnchor>
                  </p>
                </div>
              </ApplicationStatusList>
            </ListingTabChild>
            <ListingTabChild title="Meine abgelehnten Jobs">
              <ApplicationStatusList type="deletedMatches">
                <p class="job-not-found">
                  Kein Job abgelehnt
                </p>
              </ApplicationStatusList>
            </ListingTabChild>
          </ListingTab>
        </div>
        <div class="dashboear-container-right">
          <GreetingCard
            :username="userName"
            :sourcerId="sourcerId"
            :sourcername="sourcerName"
            :sourceremail="sourcerEmail"
            :sourcerphone="sourcerPhone"
            :sourceravatar="sourcerAvatar"
            :contactaction="contactFormAction"
            :appointmenturl="appointmentURL"
          ></GreetingCard>
        </div>
      </div>
    </section>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ListingTab from '../../organisms/ListingTab';
import ListingTabChild from '../../atoms/ListingTabChild';
import ApplicationStatusList from '../ApplicationStatusList';
import GreetingCard from '../GreetingCard';
import CpButtonAnchor from '../../atoms/CpButton/CpButtonAnchor';

export default {
  name: 'Status',

  components: {
    ListingTab,
    ListingTabChild,
    ApplicationStatusList,
    GreetingCard,
    CpButtonAnchor
  },

  data() {
    return {
      contactFormAction: '#',
      appointmentURL: '#'
    }
  },

  created() {
    this.$store.dispatch('applicant/getProfile');
    this.$store.dispatch('applicant/getApplicationStatusBase');
    this.$store.dispatch('applicant/getApplicationStatusMatchingJobOffers');
    this.$store.dispatch('applicant/getApplicationStatusDeletedMatches');

    /* global Routing */
    if (Routing) {
      this.contactFormAction = Routing.generate('applicant_contact_request_response', { _locale: this.$careerpeakr.locale, partOfRegistration: 0 });
      this.appointmentURL = Routing.generate('applicant_appointmentSuggestions', { _locale: this.$careerpeakr.locale, id: 0 });
    }
  },

  computed: {
    ...mapState('applicant', {
      userName: (state) => state.applicationstatus.user.name,
      sourcerId: (state) => state.applicationstatus.sourcer.id,
      sourcerName: (state) => state.applicationstatus.sourcer.name,
      sourcerEmail: (state) => state.applicationstatus.sourcer.email,
      sourcerPhone: (state) => state.applicationstatus.sourcer.phone,
      sourcerAvatar: (state) => state.applicationstatus.sourcer.image
    })
  }
};
</script>

<style scoped lang="scss">
  @import '../../../scss/atoms/colors';

  .careerboard-header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 10;
  }

  .dashboard-main {
    max-width: 1248px;
    padding: 30px 24px 100px;
    margin: 0 auto;
  }

  .dashboard-title {
    margin-bottom: 24px;
    font-size: 26px;
    font-weight: 400;
  }

  .dashboear-container-left {
    float: left;
    width: calc(68% - 32px);

    @media screen and (max-width: 1023px) {
      width: calc(60% - 12px);
    }
  }

  .dashboear-container-right {
    float: right;
    width: calc(32% - 32px);

    @media screen and (max-width: 1023px) {
      width: calc(40% - 12px);
    }

    @media screen and (max-width: 767px) {
      margin-top: 60px;
    }
  }

  .dashboear-container-left,
  .dashboear-container-right {
    @media screen and (max-width: 767px) {
      width: auto;
      float: none;
    }
  }

  .application-status + .application-status {
    margin-top: 20px;
  }

  .job-not-found {
    padding: 200px 0;
    background-color: $color-white;
    text-align: center;
    color: $color-silvergrey;
    font-weight: 900;
    line-height: 1;
    font-size: 36px;
    margin: 0;
  }

  .no-job-recommendation-notice {
    padding: 20px 40px 40px;

    p {
      margin: 0;
      padding: 1em 0 0;
    }

    @media screen and (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
</style>
