<template>
  <div class="abroad-experience-form">
    <CpSelectBox :value="country" :values="countries" @change="(v) => valueChanged('country', v)">
      <Translation resource_key="applicant.profile.abroad.experience.country" />
    </CpSelectBox>
    <CpSelectBox :value="type" :values="types" @change="(v) => valueChanged('type', v)">
      <Translation resource_key="applicant.profile.abroad.experience.type" />
    </CpSelectBox>
    <RangeSlider :value="duration"
                 :values="durations"
                 max-label="5 Jahre +"
                 min-label="1 Monat"
                 @change="(v) => valueChanged('duration', v)"
    >
      <Translation resource_key="applicant.profile.abroad.experience.duration" />
    </RangeSlider>
    <RemoveElementButton :callback="onRemove" />
  </div>
</template>

<script>
import Translation from '../../atoms/Translation';
import CpSelectBox from '../../molecules/CpSelectBox';
import RangeSlider from '../../molecules/RangeSlider';
import { RemoveElementButton } from '../../atoms/CpButton';
import formUtils from '../../../lib/mixins/formUtils';
import htmlUtils from '../../../lib/mixins/htmlUtils';
import util from '../../../lib/utils';

export default {
  name: 'AbroadExperienceForm',

  components: {
    Translation,
    CpSelectBox,
    RangeSlider,
    RemoveElementButton
  },

  mixins: [formUtils, htmlUtils],

  props: {
    value: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      requreid: true
    },
    placeholder: {
      type: String
    },
    callbackRemove: {
      type: Function,
      required: true
    }
  },

  computed: {
    country() {
      const choice = this.findValueByLabelFromChoices(this.value.country, this.countries);
      return (choice) ? choice.value : '';
    },
    countries() {
      return this.form.abroadExperiences.country.choices
    },

    type() {
      const choice = this.findValueByLabelFromChoices(this.value.type, this.types);
      return (choice) ? choice.value : '';
    },
    types() {
      return this.form.abroadExperiences.type.choices
    },

    duration() {
      return this.findValueByLabelFromChoices(this.value.duration, this.durations);
    },
    durations() {
      // eslint-disable-next-line max-len
      // return this.form.abroadExperiences.duration.choices.map((c, idx) => ({ value: idx, label: c }));
      // return Object.values(this.form.abroadExperiences.duration.choices).map((({ label, value }) => ({ label, value })));
      return this.form.abroadExperiences.duration.choices;
    }
  },

  methods: {
    onRemove() {
      this.callbackRemove(this.value.value);
    },
    valueChanged(path, value) {
      let newVal = { ...this.value };
      const inputedValue = util.buildObjectFromPath(path, value);
      newVal = util.mergeDeep(newVal, inputedValue);
      this.$emit('change', newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';

.abroad-experience-form {
  width: 100%;
  position: relative;
  margin-bottom: 40px;

  .cp-select-box /deep/ label span {
    font-style: italic;
  }

  .remove-element-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
