<template>
  <li class="job-list-item" :data-jobId="job.id" :class="{'selected': selected(job.id)}">
    <div class="cp-small-textbox">
      <input type="checkbox" @click="toggleSelected(job.id)" :checked="selected(job.id) "/>
      <h4 class="cp-small-textbox-caption">
        <template v-if="hasApplicantProfile">
          {{job.employer}}
        </template>
        <template v-if="!hasApplicantProfile">
          <Editable resource_key="job.anonymous" slot="caption" />
        </template>
        <!-- span "isNew(job.created_at)" class="caption-sticker">NEU</span -->
        <NewSticker :displayLogic="() => {return isNew(job.created_at)}" />
      </h4>
      <h3 class="cp-small-textbox-title" @click="openJobDetail(job)" >{{job.title}}</h3>
      <p class="cp-small-textbox-text">{{job.city}}</p>
      <div class="fontello icons">
        <i v-if="!marked(job.id) && like"
           class="fontello icon-heart-empty"
           @click="toggleMark(job.id)"
        />
        <i v-if="marked(job.id) && like" class="fontello icon-heart" @click="toggleMark(job.id)" />
        <i v-if="!trashed(job.id)" class="fontello icon-trash-empty" @click="toggleTrash(job.id)" />
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex';
import NewSticker from '../atoms/NewSticker';
import Editable from './Editable';
import types from '../../store/modules/applicant/types';

export default {
  name: 'JobListItem',

  components: {
    NewSticker,
    Editable
  },

  props: {
    job: {
      type: Object,
      require: true
    },
    like: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapState({
      hasApplicantProfile(state) {
        return state.applicant.profile && state.applicant.profile.registeredApplicantProfile;
      }
    })
  },

  methods: {
    marked(id) {
      return this.$store.state.applicant.favorite
        ? this.$store.state.applicant.favorite.includes(id)
        : false;
    },
    toggleMark(id) {
      if (this.marked(id)) {
        this.$store.dispatch('applicant/unmarkFavorite', { id });
      } else {
        this.$store.dispatch('applicant/markFavorite', { id });
      }
    },
    toggleTrash(id) {
      this.$store.dispatch('applicant/markTrash', { id });
    },
    trashed(id) {
      return this.$store.state.applicant.trash
        ? this.$store.state.applicant.trash.includes(id)
        : false;
    },
    toggleSelected(id) {
      this.$store.commit(`applicant/${types.MUTATIONS.TOGGLE_SELECTED}`, { id });
      const { profileId } = this.$store.state.applicant;
      const optimizationChanceIds = [];
      const jobIds = this.$store.state.applicant.selected;
      this.$store.dispatch('applicant/analyse', {
        profileId,
        jobIds,
        optimizationChanceIds
      });
    },
    selected(id) {
      return this.$store.state.applicant.selected
        ? this.$store.state.applicant.selected.includes(id)
        : false;
    },
    isNew(timestamp) {
      if (this.$store.state.applicant.profile) {
        const lastLogin = new Date(this.$store.state.applicant.profile.lastLogin);
        const publishedAt = new Date(timestamp);
        return lastLogin.getTime() < publishedAt.getTime();
      }
      return false;
    },
    openJobDetail(job) {
      this.$store.dispatch('applicant/getJobDetail', job.id).then((data) => {
        console.log(data);
        this.$router.push({ name: 'CareerBoardJobDetail', params: { id: job.id } });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/icons';
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';

.job-list-item {
  position: relative;

  &.selected {
    background: $gradient-blue-transparent-horizontal;
    color: $color-white;
    border-top: 1px solid $color-white;
    &:first-child {
      border-top: 0 none;
    }
    .cp-small-textbox {
      .cp-small-textbox-caption,
      .cp-small-textbox-title,
      .cp-small-textbox-text {
        color: $color-white;
        font-weight: 400;
      }
      .cp-small-textbox-caption {
        border-color: $color-white;
      }
    }

    .icon-heart-empty,
    .icon-trash-empty {
      color: $color-white;
    }
  }

  .cp-small-textbox {
    padding: 4px 42px 20px;
    background-color: transparent;
    box-shadow: none;

    .cp-small-textbox-caption {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0 0 5px;
      font-size: 14px;
      line-height: 1;
      color: $color-blue-light;
      border-bottom: 1px solid $color-silvergrey;
      font-weight: 400;
    }

    .cp-small-textbox-title {
      font-weight: 400;
      margin: 0;
      cursor: pointer;
    }

    .cp-small-textbox-text {
      margin: 0;
      color: $color-dustygrey;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4em;
    }
  }

  input[type='checkbox'] {
    position: absolute;
    top: 12px;
    left: 10px;
    width: 20px;
    height: 20px;
    z-index: 1;
  }

  .icons {
    position: absolute;
    top: 10px;
    right: 0;
    width: 80px;
    height: 2em;
    cursor: pointer;

    i:first-of-type {
      margin-right: 5px;
    }
  }

  .icon-heart,
  .icon-heart-empty {
    color: $color-red;
  }

  .icon-trash-empty {
    color: $color-silvergrey;
  }
}

@include bp-max-medium {
  .job-list-item .icons {
    right: 0;
    display: flex;
    padding-right: 10px;
    flex-flow: row-reverse;
  }

  .cp-small-textbox {
    padding-top: 0;
    padding-bottom: 14px;
  }
}
</style>

<style>
div[slot='caption'] {
  display: inline-block;
}
</style>
