<template>
  <div class="job-listing">
    <ul class="job-list" v-if="loaded">
      <transition-group name="fade">
        <li is="JobListItem" class="job-list-item" v-for="job in jobList" :job="job" :key="job.id" :like="false" />
      </transition-group>
    </ul>
    <transition name="quick-fade">
      <div class="loading" v-if="loadingStatus">
        <CpLoading />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import CpLoading from '../atoms/CpLoading';
import JobListItem from '../molecules/JobListItem';

export default {
  name: 'JobSuggestionList',

  components: {
    CpLoading,
    JobListItem
  },

  props: {
    filter: {
      type: Function,
      default: (_a) => true
    },
    selectedTab: {
      type: String,
      default: 'all'
    },
    loadingStatus: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      jobList(state) {
        if (state.applicant.jobList) {
          this.$nextTick(() => {
            this.$forceUpdate();
          });
          return this.filter(state.applicant.jobList);
        }
        this.laodingStatus = true;
        return null;
      },
      hasApplicantProfile(state) {
        return state.applicant.profile && state.applicant.profile.registeredApplicantProfile;
      },
      loaded(state) {
        return state.applicant.jobList;
      }
    })
  },

  watch: {
    loaded(oldVal, newVal) {
      console.log('is_loading watch called');
      if (!newVal) {
        this.$forceUpdate();
      }
    },
    tabState(_oldVal, _newVal) {
      console.log('tabState watch called');
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/icons';
@import '../../scss/atoms/colors';
@import '../../scss/organisms/job_listing';

.job-listing {
  position: relative;
}

.job-list {
  margin-top: 0;
  padding: 0 20px;
  box-shadow: none;
  height: calc(100vh - 430px);
  overflow: scroll;

  .job-list-item {
    position: relative;
    &:last-of-type {
      padding-bottom: 110px;
    }
  }
}
.loading {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: $color-white-transparent;
}

// transitions
.fade-leave-active {
  opacity: 0;
  transition: opacity 1s;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1s;
}

.quick-fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s;
}

.quick-fade-enter {
  opacity: 0;
}

.quick-fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s;
}
</style>
