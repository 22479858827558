<template>
  <div class="removable-textfield">
    <input type="text" :placeholder="placeholder" :value="value" @input="updateValue" v-focus />
    <RemoveElementButton :callback="onRemove" size="small" />
  </div>
</template>

<script>
import { RemoveElementButton } from '../atoms/CpButton';

export default {
  name: 'RemovableTextField',

  components: {
    RemoveElementButton
  },

  props: {
    value: {
      type: String,
      required: true
    },
    placeholder: {
      type: String
    },
    callbackRemove: {
      type: Function,
      required: true
    }
  },

  methods: {
    onRemove() {
      this.callbackRemove(this.value);
    },
    updateValue(e) {
      this.$emit('change', e.target.value);
    },
    setFocus() {

    }
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus()
      }
    }
  }

};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';

.removable-textfield {
  input[type='text'] {
    background-color: $color-lightgrey;
    height: 51px;
  }
}
</style>
