<template>
  <nav class="cp-footer-menu clearfix">
    <p class="cp-footer-menu-item">
      <a href="/de/agb">AGB</a>
    </p>
    <p class="cp-footer-menu-item">
      <a href="/de/privacy">Datenschutzerklärung</a>
    </p>
    <p class="cp-footer-menu-item">
      <a href="/de/termsconditions">Nutzungsbedingungen</a>
    </p>
    <p class="cp-footer-menu-item">
      <a href="/de/imprint">Impressum</a>
    </p>
  </nav>
</template>

<script>
export default {
  name: 'FooterMenu'
};
</script>

<style lang="scss">
@import '../../scss/atoms/_colors';
@import '../../scss/molecules/footer_menu';
</style>
