<template>
  <section class="appointment-contact-form">
    <h3>
      <Translation resource_key="joboffer.appointment.contact.title" />
    </h3>
    <p>
      <Translation resource_key="joboffer.appointment.contact.text" />
    </p>
    <Translation
      datakey="namefirstPlaceholder"
      resource_key="joboffer.appointment.placeholder.namefirst"
    />
    <Translation
      datakey="namelastPlaceholder"
      resource_key="joboffer.appointment.placeholder.namelast"
    />
    <Translation
      datakey="telephonePlaceholder"
      resource_key="joboffer.appointment.placeholder.telephone"
    />
    <Translation
      datakey="emailPlaceholder"
      resource_key="joboffer.appointment.placeholder.email"
    />
    <div class="contact-fields">
      <p v-if="errors.length > 0">
        {{ errors }}
      </p>
      <input
        name="namefirst" type="text" v-if="!loggedIn"
        :class="{'namefirst': true, 'cp-default-input': true, error: (0 <= errors.indexOf('namefirst'))}"
        :placeholder="namefirstPlaceholder" :value="applicantTel" required />
      <input
        name="namelast" type="text" v-if="!loggedIn"
        :class="{'namelast': true, 'cp-default-input': true, error: (0 <= errors.indexOf('namelast'))}"
        :placeholder="namelastPlaceholder" :value="applicantTel" required />
      <input
        name="tel" type="tel"
        :class="{'tel': true, 'cp-default-input': true, error: (0 <= errors.indexOf('tel'))}"
        :placeholder="telephonePlaceholder" :value="applicantTel" required />
      <input
        name="email" type="email"
        :class="{'email': true, 'cp-default-input': true, error: (0 <= errors.indexOf('email'))}"
        :placeholder="emailPlaceholder" :value="applicantEmail" required />
    </div>
    <h3>
      <Translation resource_key="joboffer.appointment.contact.write.message" />
    </h3>
    <Translation
      datakey="textAreaPlaceholder"
      resource_key="joboffer.appointment.placeholder.text"
    />
    <textarea name="message" :placeholder="textAreaPlaceholder" class="cp-default-textarea">
    </textarea>
    <p class="form-note">
      <Translation resource_key="joboffer.appointment.required.field" />
    </p>
    <div class="center">
      <CpButton
        type="submit"
        color="blue"
        size="large"
        weight="bold"
      ><Translation name="message" resource_key="joboffer.appointment.contact.submit" />
      </CpButton>
    </div>
  </section>
</template>
<script>
import CpButton from '../atoms/CpButton';
import Translation from '../atoms/Translation';

export default {
  name: 'AppointmentContactForm',
  components: {
    Translation,
    CpButton
  },
  data() {
    return {
      namefirstPlaceholder: '',
      namelastPlaceholder: '',
      telephonePlaceholder: '',
      emailPlaceholder: '',
      textAreaPlaceholder: ''
    };
  },
  props: {
    sourcer: {
      type: Object,
      require: true
    },
    applicant: {
      type: Object,
      require: false
    },
    errors: {
      type: Array,
      default() { return [] }
    }
  },
  computed: {
    applicantEmail() {
      return this.applicant ? this.applicant.email : '';
    },
    applicantTel() {
      return this.applicant ? this.applicant.tel : '';
    },
    loggedIn() {
      return this.applicant && this.applicant.name !== '';
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_breakpoints.mixin';
@import '../../scss/atoms/colors';

.appointment-contact-form {
  .contact-fields {
    display: flex;
    // flex-direction: row;
    flex-wrap: wrap;
  }

  .center {
    display: flex;
  }

  input[type=tel],
  input[type=text],
  input[type=email] {
    max-width: calc(50% - 8px);

    &.error {
      border-color: $color-red;
    }
  }

  input[type=text]:first-of-type,
  input[type=tel] {
    margin-right: 15px;
  }

  input[type=text] {
    margin-bottom: 15px;
  }

  .cp-default-textarea {
    height: 120px;
    margin-bottom: 30px;
    line-height: 1.46em;
    padding: 18px;
  }

  .form-note {
    text-align: center;
  }

  .cp-btn.large {
    max-width: 480px;
    margin: 0 auto;
  }
}

@include bp-max-medium() {
  .appointment-contact-form {
    form > div {
      flex-direction: column;
    }

    input[type=text],
    input[type=tel],
    input[type=email] {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 15px;

      &:first-of-type {
        margin-right: 0;
      }
    }

    .center .cp-btn.large {
      line-height: 24px;
      padding: 22px 17px;
    }
  }
}
</style>
