<template>
  <div class="company-profile-header">
    <CompanyJumboImage :image="profile.images[0]" />
    <CompanyMetaData :profile="profile.profile" :logo="profile.logo" />
  </div>
</template>

<script>
import CompanyJumboImage from '../molecules/CompanyJumboImage';
import CompanyMetaData from '../organisms/CompanyMetaData';
// Mock server data
if (typeof window.Jobpassion === 'undefined') {
  window.Jobpassion = {};
}

export default {
  name: 'CompanyProfileHeader',

  components: {
    CompanyJumboImage,
    CompanyMetaData
  },

  props: {
    profile: {
      type: Object,
      requreid: true
    }
  }
};
</script>
