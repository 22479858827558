<template>
  <div class="documents-edit">
    <MultipleElementForm
      :value="values"
      :component="RemovableFileField"
      :callbackAdd="onAdd"
      @change="onChange"
      :callbackRemove="onRemove"/>
  </div>
</template>

<script>
import RemovableFileField from '../../organisms/ApplicantProfile/RemovableFileField';
import MultipleElementForm from '../../molecules/MultipleElementForm';

export default {
  name: 'DocumentsEdit',

  components: {
    MultipleElementForm,
    RemovableFileField // eslint-disable-line vue/no-unused-components
  },

  data() {
    return {
      RemovableFileField
    };
  },

  props: {
    values: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    callbackChange: {
      type: Function,
      required: true
    }
  },

  methods: {
    onAdd() {
      let maxIndex = -1;
      const keys = Object.keys(this.values)
      if (keys.length !== 0) {
        maxIndex = keys.map((i) => Math.trunc(i)) // convert string to int
          .reduce((max, n) => (n > max ? n : max), 0);
      }
      const hasCV = Object.values(this.values).find((value) => value.type === 'cv');
      this.callbackChange({ ...this.values, [maxIndex + 1]: { type: (hasCV) ? 'furtherDocument' : 'cv' } });
    },
    onRemove(idx) {
      const newVal = { ...this.values };
      delete newVal[idx];
      if (Object.values(newVal).length === 0) {
        // add cv field if values are empty
        newVal['0'] = { type: 'cv', value: '' };
      }
      this.callbackChange(newVal);
    },
    onChange({ index, value }) {
      const newVal = { ...this.values };
      newVal[index] = value;
      this.callbackChange(newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_breakpoints.mixin';

.documents-edit /deep/ {
  .remove-element-button,
  .add-element-button {
    display: block;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;

    @include bp-max-small() {
      word-wrap: break-word;
      margin: 0;
      margin-bottom: 20px;

      .cp-btn-inner {
        padding: 0 !important;
        line-height: 0px !important;
      }
    }

    @include bp-max-medium {
      margin-left: auto;
      margin-right: auto;

      .cp-btn-inner {
        padding: 0 !important;
        line-height: 0px !important;
      }
    }
  }

  .file-upload {
    font-weight: bold;

    &.custom-file-name {
      font-size: 17px;
      line-height: 24px;
    }
  }
}

</style>
