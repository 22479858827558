<template>
  <div class="skills-edit">
    <div class="column language">
      <h3 class="label">
        <Translation resource_key="joboffer.language" />
      </h3>
      <MultipleElementForm
        :value="languageSkills"
        :form="form"
        :component="LanguageForm"
        :callbackAdd="() => { onAdd('languageSkills'); }"
        :callbackRemove="(index) => { onRemove('languageSkills', index); }"
        @change="(value) => { onChange('languageSkills', value); }"
      />
      <h3 class="label">
        <Translation resource_key="joboffer.professional-knowledge" />
      </h3>
      <MultipleElementForm
        :value="skills"
        :form="form"
        :component="ProfessionalKnowledgeForm"
        :callbackAdd="() => { onAdd('skills'); }"
        :callbackRemove="(index) => { onRemove('skills', index); }"
        @change="(value) => { onChange('skills', value); }"
      />

    </div>
    <div class="column abroad-experiences">
      <h3 class="label">
        <Translation resource_key="applicant.profile.abroad.experiences.title" />
      </h3>
      <MultipleElementForm
        :value="abroadExperiences"
        :form="form"
        :component="AbroadExperienceForm"
        :callbackAdd="() => { onAdd('abroadExperiences'); }"
        :callbackRemove="(index) => { onRemove('abroadExperiences', index); }"
        @change="(value) => { onChange('abroadExperiences', value); }"
      />
    </div>
  </div>
</template>

<script>
import Translation from '../../atoms/Translation';
import LanguageForm from '../../organisms/ApplicantProfile/LanguageForm';
import ProfessionalKnowledgeForm from '../../organisms/ApplicantProfile/ProfessionalKnowledgeForm';
import AbroadExperienceForm from '../../organisms/ApplicantProfile/AbroadExperienceForm';
import MultipleElementForm from '../../molecules/MultipleElementForm';
import RangeSlider from '../../molecules/RangeSlider';

export default {
  name: 'SkillsEdit',

  components: {
    /* eslint-disable vue/no-unused-components */
    Translation,
    LanguageForm,
    ProfessionalKnowledgeForm,
    AbroadExperienceForm,
    MultipleElementForm,
    RangeSlider
    /* eslint-enable vue/no-unused-components */
  },

  data() {
    return {
      LanguageForm,
      ProfessionalKnowledgeForm,
      AbroadExperienceForm
    };
  },

  props: {
    values: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    callbackChange: {
      type: Function,
      required: true
    }
  },

  computed: {
    languageSkills() {
      return this.values.languageSkills;
    },
    skills() {
      return this.values.skills;
    },
    abroadExperiences() {
      return this.values.abroadExperiences;
    }
  },

  methods: {
    // apply this method to each array value
    onAdd(key) {
      let maxIndex = -1;
      const keys = Object.keys(this.values[key])
      if (keys.length !== 0) {
        maxIndex = keys.map((i) => Math.trunc(i)) // convert string to int
          .reduce((max, n) => (n > max ? n : max));
      }
      // todo make value up to key
      const newObj = this.createNewObject(key);
      this.callbackChange(key, { ...this.values[key], [maxIndex + 1]: newObj });
    },

    createNewObject(type) {
      if (type === 'skills') {
        return '';
      }
      const prototype = {
        abroadExperiences: {
          country: 'Frankreich',
          duration: '1 Monat',
          type: 'Praktikum'
        },
        languageSkills: {
          languageLevel: 'basic',
          language: 'Deutsch'
        }
      };
      return this.findAlternative(type, prototype[type]);
    },

    findAlternative(type, prototype) {
      const result = { ...prototype };

      if (type === 'languageSkills') {
        const { preferred_choices: preferedChoices } = this.form.languageSkills.language;
        const languages = Object.values(this.values.languageSkills).map((ls) => ls.language);
        const langNotIn = Object.values(preferedChoices)
          .find((pc) => languages.indexOf(pc.label) === -1);
        if (langNotIn) {
          result.language = langNotIn.label;
        }
      } else if (type === 'abroadExperiences') {
        const { choices: preferedChoices } = this.form.abroadExperiences.country;
        const countries = Object.values(this.values.abroadExperiences).map((ax) => ax.country);
        const countryNotIn = preferedChoices.find((pc) => countries.indexOf(pc.label) === -1);
        if (countryNotIn) {
          result.country = countryNotIn;
        }
      }

      return result;
    },

    onRemove(key, idx) {
      // console.log('remove clicked', key, idx, this.values[key]);
      const newVal = { ...this.values[key] };
      delete newVal[idx];
      this.callbackChange(key, newVal);
    },

    onChange(key, { index, value }) {
      const newVal = { ...this.values[key] };
      newVal[index] = value;
      this.callbackChange(key, newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_breakpoints.mixin';

.skills-edit {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;

  h3 {
    color: $color-blue;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
  }

  .column {
    width: calc(50% - 60px);

    .multiple-element-form {
      &:first-of-type {
        margin-bottom: 60px;
      }

      /deep/ input {
        line-height: 24px;
        font-weight: bold;
      }

      /deep/ {
        label {
          font-size: 14px;
          line-height: inherit;
          color: #9b9b9b;
        }

        .range-slider,
        .cp-select-box {
          margin-bottom: 20px;
        }

        .cp-select-box {
          width: 100%;
          max-width: none;
          margin-bottom: 1.4em;

          &:nth-of-type(n + 2) {
            &:before {
              padding-top: 0;
            }

            //.icon-down-open-big {
            //   top: 2em;
            //}
          }

          label {
            margin-top: -1.4em;
            display: inline-block;
          }

          ul {
            width: 100%;

            // TODO: detect class which is assigned opened
            &:hover {
              z-index: 10;

              + i {
                z-index: 10;
              }
            }

            li {
              width: 100%;
              line-height: 51px;
              padding-left: 15px;
              background-color: $color-lightgrey;
            }
          }

          .icon-down-open-big {
            top: 3.3em;
          }
        }

        .range-slider .rangeSlider-container {
          width: 100%;
        }

        .add-element-button {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }

        .professional-knowledge-form input {
          width: calc(100% - 50px);
          margin-bottom: 10px;
          margin-right: 5px;
          border: 0;
          padding-left: 15px;
        }
      }
    }
  }
  /*
  .multiple-element-form /deep/ .add-element-button {
    margin-left: 200px;
  }
  */
}

@include bp-max-medium() {
  .skills-edit {
    width: 100%;
    flex-direction: column;

    .column {
      width: calc(100%);

      .multiple-element-form {
        margin-bottom: 60px;
      }
    }

    /deep/ .cp-btn-inner {
      line-height: 15px !important;
    }

    /deep/ .multiple-element-form .add-element-button{
      padding: 0 !important;
    }
  }
}
</style>
