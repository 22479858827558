import axios from 'axios';

const URL = '/api/v2/applicant/cv';
// const URL = 'http://careerpeakr.test/api/v2/dict'

export default {
  // id is quickhack. later should be removed.
  analyse_cv(file, filaname, progressCb) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', file);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressCb
        })
        .then((response) => {
          // handle success
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  },

  analyse_freetext(text, progressCb) {
    const formData = new FormData();
    formData.append('text', text.join());
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL}`, formData, { onUploadProgress: progressCb })
        .then((response) => {
          // handle success
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  }
};
