<template>
  <div class="job-listing">
    <ul class="job-list">
      <li class="job-list-item" v-for="job in jobs" :key="job.id">
        <SmallTextbox>
          <!-- TODO: check if it is anonymised, if user is not logged in -->
          <template slot="caption">
            {{job.employer}}
            <span v-if="calcDates(job.created_at)" class="caption-sticker">NEU</span>
          </template>
          <template slot="title">{{job.title}}</template>
          <template slot="text">{{job.city}}
            <template v-if="anonymous">
              (Für weitere Informationen zur Stelle bitte registrieren.)
            </template>
          </template>
        </SmallTextbox>
      </li>
    </ul>
  </div>
</template>

<script>
import SmallTextbox from '../molecules/SmallTextbox';

export default {
  name: 'JobListing',

  components: {
    SmallTextbox
  },

  props: {
    jobs: {
      type: Array,
      default: () => []
    },
    anonymous: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    calcDates(createdAt) {
      /**
       * Show "new" sticker, if the job is newer than one month old
       * @TODO Decide, how long a job should be labeled as "new".
       */
      return Date.now() - 2592000000 < new Date(createdAt).getTime();
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/organisms/job_listing';
@import '../../scss/atoms/breakpoints.mixin';

@include bp-max-medium {
  .job-listing ul.job-list {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
