<template>
  <header class="joboffer-header">
    <JobOfferMenu :jobOffer="jobOffer" />
    <div class="joboffer-header-inner">
      <div class="cp-row">
        <h2>
          <router-link :to="{name: 'CompanyProfile', params: {id: jobOffer.companyProfileId}}">
            {{jobOffer.companyProfile.address.name}}
          </router-link>
        </h2>
        <h1> {{ jobOffer.jobOffer.name }} </h1>
      </div>
      <JobOfferMetaData :jobOffer="jobOffer" />
      <div class="cp-row align-center button" v-if="showButton">
        <CpButtonAnchor :to="'/register/' + jobOffer.jobOfferId"
                        size="large" color="blue" weight="bold"
        >
          <Translation resource_key="joboffer.header.button" />
        </CpButtonAnchor>
      </div>
    </div>
  </header>
</template>

<script>
import Translation from '../atoms/Translation';
import JobOfferMenu from '../organisms/JobOfferMenu';
import JobOfferMetaData from '../organisms/JobOfferMetaData';
import { CpButtonAnchor } from '../atoms/CpButton';

export default {
  name: 'JobOfferHeader',

  components: {
    CpButtonAnchor,
    Translation,
    JobOfferMenu,
    JobOfferMetaData
  },

  props: {
    jobOffer: {
      type: Object,
      requreid: true
    },
    showButton: {
      type: Boolean,
      default: true
    }
  }
}

</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors.scss';
@import '../../scss/atoms/_breakpoints.mixin';

header.joboffer-header {
  position: relative;
  padding: 30px 0 60px;

  .joboffer-header-inner {
    max-width: 908px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

    h1 {
      font-size: 46px;
      font-weight: 400;
      line-height: 1.26;
      margin: 0.5em 0;
    }

    h2 a {
      display: inline-block;
      color: $color-blue;
      font-weight: 400;
      border-bottom: 1px solid $color-silvergrey;
      text-decoration: none;
      margin: 0;
    }
  }

  .cp-row.button {
    margin-top: 40px;
  }
}

@include bp-max-tablet() {
  header.joboffer-header {
    padding-left: var(--padding-edge);
    padding-right: var(--padding-edge);
  }
}

@include bp-max-medium() {
  header.joboffer-header .joboffer-header-inner {
    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 18px;
    }
  }
}

</style>
