import { render, staticRenderFns } from "./LanguageSkillsGraph.vue?vue&type=template&id=fbee5fca&scoped=true&"
import script from "./LanguageSkillsGraph.vue?vue&type=script&lang=js&"
export * from "./LanguageSkillsGraph.vue?vue&type=script&lang=js&"
import style0 from "./LanguageSkillsGraph.vue?vue&type=style&index=0&id=fbee5fca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbee5fca",
  null
  
)

export default component.exports