<template>
  <a class="cp-social-link" :class="linkClass" :target="(openExtern) ? '_blank' : '_self'" :rel="noopener"></a>
</template>

<script>
export default {
  name: 'SocialLink',

  props: {
    channel: {
      type: String,
      default: 'facebook'
    },
    openExtern: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    linkClass() {
      const cssClasses = ['fontello'];

      if (typeof this.channel === 'string') {
        cssClasses.push(`icon-${this.channel}`);
      }

      return cssClasses.join(' ');
    },
    noopener() {
      return (this.openExtern) ? 'noopener' : 'nofollow';
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/atoms/social_link';
.cp-social-link {
  text-decoration: none;
}
</style>
