<template>
  <div id="reward-recommendation-contactform">
    <div class="wrapper">
      <div class="container">
        <h3>Danke Für Ihre Empfehlung</h3>
        <div class="reward-recommendation-contactform-inner" v-if="!successMessage">
          <p class="headline-text">
            Hier können Sie uns die Kontaktdaten Ihres vorgeschlagenen Kandidaten (m/w/d) angeben.
          </p>
          <form ref="form" @submit.prevent="onSubmit">
            <div class="contactform-inner">
              <div class="form-1-row">
                <div class="contactform-item form-colum-2">
                  <AnimatedLabelTextField name="form[namefirst]"
                                          type="text"
                                          v-model="form.firstName.value"
                                          :class="{ 'error': form.firstName.hasError }"
                                          @blur="validateName(form.firstName)">
                    <Translation resource_key="joboffer.appointment.placeholder.namefirst" />
                  </AnimatedLabelTextField>
                </div>
                <div class="contactform-item form-colum-2">
                  <AnimatedLabelTextField name="form[namelast]"
                                          type="text"
                                          v-model="form.lastName.value"
                                          :class="{ 'error': form.lastName.hasError }"
                                          @blur="validateName(form.lastName)">
                    <Translation resource_key="joboffer.appointment.placeholder.namelast" />
                  </AnimatedLabelTextField>
                </div>
              </div>
              <div class="contactform-item">
                <AnimatedLabelTextField name="form[email]"
                                        type="email"
                                        v-model="form.email.value"
                                        :class="{ 'error': form.email.hasError }"
                                        @blur="validateEmail(form.email)">
                  <Translation resource_key="joboffer.appointment.placeholder.email" />
                </AnimatedLabelTextField>
              </div>
              <div class="contactform-item textarea">
                <AnimatedLabelTextArea
                  type="text"
                  v-model="form.infos.value"
                  value
                  name="form[message]">
                  <Translation resource_key="joboffer.reward.recommendation.form.message" />
                </AnimatedLabelTextArea>
              </div>
              <div class="contactform-confirmation">
                <p><b>
                  Und nennen Sie uns bitte noch Ihre E-Mail Adresse für die Bestätigung:
                </b></p>
                <div class="form-1-row">
                  <div class="contactform-item form-colum-2">
                    <!-- FIXME: render names, if user is logged in -->
                    <AnimatedLabelTextField name="form[mynamefirst]"
                                            type="text"
                                            v-model="form.myFirstName.value"
                                            :class="{ 'error': form.myFirstName.hasError }"
                                            @blur="validateName(form.myFirstName)">
                      <Translation resource_key="joboffer.reward.recommendation.form.your.namefirst" />
                    </AnimatedLabelTextField>
                  </div>
                  <div class="contactform-item form-colum-2">
                    <!-- FIXME: render names, if user is logged in -->
                    <AnimatedLabelTextField name="form[mynamelast]"
                                            type="text"
                                            v-model="form.myLastName.value"
                                            :class="{ 'error': form.myLastName.hasError }"
                                            @blur="validateName(form.myLastName)">
                      <Translation resource_key="joboffer.reward.recommendation.form.your.namelast" />
                    </AnimatedLabelTextField>
                  </div>
                </div>
                <div class="contactform-item">
                  <!-- FIXME: render names, if user is logged in -->
                  <AnimatedLabelTextField
                    name="form[myEmail]"
                    type="email"
                    v-model="form.myEmail.value"
                    :class="{ 'error': form.myEmail.hasError }"
                    @blur="validateEmail(form.myEmail)">
                    <Translation resource_key="joboffer.reward.recommendation.form.your.email" />
                  </AnimatedLabelTextField>
                </div>
              </div>
              <div class="cp-row align-center ">
                <div class="form-footer">
                  <ul class="error-messages" v-if="hasError()" >
                    <li class="message" v-for="(error, index) in errors" :key="index">
                      {{ error.message }}
                    </li>
                  </ul>
                  <p class="category">*Pflichtfelder</p>
                  <CpButton color="blue"
                            type="button"
                            size="large"
                            :callback="onSubmit"
                  >
                    <span class="submit-button">Empfehlung senden</span>
                  </CpButton>
                </div>
              </div>

            </div>
          </form>
        </div>
        <div class="success-message" v-if="successMessage" v-html="successMessage"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AnimatedLabelTextField from '../molecules/AnimatedLabelTextField';
import AnimatedLabelTextArea from '../molecules/AnimatedLabelTextArea';
import CpButton from '../atoms/CpButton/CpButton';
import Translation from '../atoms/Translation';

export default {
  name: 'RewardRecommendationContactForm',

  components: {
    CpButton,
    Translation,
    AnimatedLabelTextField,
    AnimatedLabelTextArea

  },

  props: {
    jobOfferId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      form: {
        firstName: { name: 'form[namefirst]', value: '', hasError: false },
        lastName: { name: 'form[namelast]', value: '', hasError: false },
        email: { name: 'form[email]', value: '', hasError: false },
        infos: { name: 'form[message]', value: '', hasError: false },
        myFirstName: { name: 'form[mynamefirst]', value: '', hasError: false },
        myLastName: { name: 'form[mynamelast]', value: '', hasError: false },
        myEmail: { name: 'form[myEmail]', value: '', hasError: false }
      },
      errorMessages: {
        notFullfilled: {
          shown: false,
          message: 'Bitte Pflichtfelder ausfüllen.'
        },
        errorEmail: {
          shown: false,
          message: 'Bitte Email Adresse mit dem richitigen Format eingeben.'
        },
        errorSameEmails: {
          shown: false,
          message: 'Bitte zwei verschiedene E-Mail Adressen im richtigen Format eingeben.'
        }
      },
      successMessage: null
    }
  },

  computed: {
    errors() {
      const result = Object.values(this.errorMessages).filter((emsg) => emsg.shown);
      return result;
    }
  },

  methods: {
    hasError() {
      const errors = Object.values(this.errorMessages).filter((emsg) => emsg.shown);
      return (errors) ? errors.length > 0 : false;
    },

    toggleOffErrorOnFields() {
      Object.keys(this.errorMessages).map((key) => {
        this.$set(this.errorMessages[key], 'shown', false);
        return key;
      });

      Object.keys(this.form).map((key) => {
        this.$set(this.form[key], 'hasError', false);
        return key;
      });
    },

    validateName(nameObj) {
      const valid = (nameObj.value !== '');
      this.$set(nameObj, 'hasError', !valid);
      this.$set(this.errorMessages.notFullfilled, 'shown', !valid);
      return valid;
    },

    validateEmail(emailObj) {
      const mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const valid = mailformat.test(emailObj.value);
      this.$set(emailObj, 'hasError', !valid);
      this.$set(this.errorMessages.errorEmail, 'shown', !valid);
      return valid;
    },

    validateNotSame(targetOne, myOne, errorMessage) {
      const valid = targetOne.value !== myOne.value;
      this.$set(targetOne, 'hasError', !valid);
      this.$set(myOne, 'hasError', !valid);
      this.$set(errorMessage, 'shown', !valid);
      return valid;
    },

    validate() {
      this.toggleOffErrorOnFields();
      /* eslint-disable no-bitwise */
      let result = this.validateName(this.form.firstName);
      result &= this.validateName(this.form.lastName);
      result &= this.validateName(this.form.myFirstName);
      result &= this.validateName(this.form.myLastName);
      result &= this.validateEmail(this.form.email);
      result &= this.validateEmail(this.form.myEmail);
      result &= this.validateNotSame(this.form.email, this.form.myEmail, this.errorMessages.errorSameEmails);
      /* eslint-enable no-bitwise  */
      return result;
    },

    buildFormData() {
      const self = this;
      const result = new FormData();
      result.append('_csrf_token', window.security.csrf_token);
      Object.keys(this.form).map((key) => {
        result.append(self.form[key].name, self.form[key].value);
        return key;
      });
      return result;
    },

    onSubmit(e) {
      e.preventDefault();
      if (!this.validate()) {
        return false;
      }
      const formData = this.buildFormData();
      axios.post(
        `/${this.$careerpeakr.locale}/reward/${this.jobOfferId}`,
        formData
      ).then((response) => {
        if (response.status === 200) {
          this.successMessage = response.data;
        } else {
          this.parseError(response);
        }
        return false;
      });
      return false;
    },

    parseError(err) {
      console.log(err);
    }
  }
}

</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';
@import '../../scss/atoms/_typographie';

#reward-recommendation-contactform {
  background-color: $color-bg-grey;

  .wrapper {
    padding: 36px 0 10px;
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
  }

  .form-1-row {
    display: flex;
    justify-content: space-evenly;
  }

  .form-colum-2 {
    width: 50%;

    &:first-child {
      margin-right: 10px;
    }
  }

  .category {
    margin: 0;
  }

  h3 {
    font-size: 28px;
    line-height: 22px;
    font-weight: $font-black;
    margin-bottom: 20px;

  }

  p.headline-text {
    font-size: 17px;
    line-height: 28px;
    font-weight: $font-regular;
  }

  .contactform-item {
    input {
      font-size: 17px;
      color: $color-blacky;
    }
  }
}

span.submit-button {
  font-size: 17px;
  font-weight: 600;
  color: $color-white;
}

.animated-label-textfield /deep/ {
  label {
    background-color: $color-white;

    input:invalid {
      background-color: $color-red;
    }
  }
  span.slot.blank {
    font-size: 17px;
    font-style: normal;
    color: $color-darkgrey;
  }
}

.animated-label-textarea /deep/ {
  label {
    background-color: $color-white;
  }
  span.slot.blank {
    font-size: 17px;
    font-style: normal;
    color: $color-darkgrey;
  }
}

.contactform-inner {
  padding: 13px 0 60px;
}

.success-message {
  margin-bottom: 60px;
}

@include bp-max($bp-large-with-padding) {
  #reward-recommendation-contactform .wrapper {
    padding: 20px 0px 0px;
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;

    .contactform-inner {
      padding: 40px 0 50px;
    }

    .form-1-row {
      display: flex;
      flex-direction: column;
    }

    .form-colum-2 {
      width: 100%;
      padding: 0 ;

      &:first-child {
        margin-right: 0 ;
      }

      .category {
        margin: 15px 0 0 0;
      }

    }
    .form-footer {
      margin: 30px auto 0;
    }
  }
}

</style>
