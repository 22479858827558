<template>
  <div class="company-social-media-link">
    <SocialLink :href="company.facebookLink" channel="facebook" v-if="company.facebookLink" />
    <SocialLink :href="company.kununuLink" channel="kununu" v-if="company.kununuLink" />
    <SocialLink :href="company.xingLink" channel="xing" v-if="company.xingLink" />
    <SocialLink :href="company.linkedInLink" channel="linkedin" v-if="company.linkedInLink" />
    <SocialLink :href="company.twitterLink" channel="twitter" v-if="company.twitterLink"/>
    <SocialLink :href="company.youtubeLink" channel="youtube" v-if="company.youtubeLink"/>
  </div>
</template>

<script>
import SocialLink from '../atoms/SocialLink';

export default {
  name: 'CompanySocialMediaLinks',
  components: {
    SocialLink
  },
  props: {
    company: Object
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';

.company-social-media-link {
  a {
    color: $color-blacky;
  }
}
</style>
