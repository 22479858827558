<template>
  <transition name="modal" :duration="{ leave: 1000 }">
    <div class="password-change">
      <ModalBase closeTo="/settings" displayCloseButton="true" :waitingMessage="false">
        <div class="password-change-inner">
          <h2>
            <Translation resource_key="applicant.myaccount.password.change.header" />
          </h2>
          <form @submit.prevent="onYesClicked">
            <AnimatedLabelTextField inputType="password"
                                    v-model="form.oldPassword.value"
                                    :required="true"
                                    :class="{ 'error': form.oldPassword.hasError }"
                                    @blur ="validateRequiredField(form.oldPassword)"
            >
              <Translation resource_key="applicant.myaccount.password.change.old" />
            </AnimatedLabelTextField>
            <AnimatedLabelPasswordField name="plainPassword[first]"
                                        type="password"
                                        :class="{ 'error': form.password.hasError }"
                                        :secureLength="8"
                                        :toggle="true"
                                        :required="true"
                                        v-model="form.password.value"
                                        @score="(s) => scoreCatched('password', s)"
                                        @feedback="showFeedback"
                                        @change="(v) => updateFormValue('password', v)"
            >
              <Translation resource_key="applicant.myaccount.password.change.new" />
            </AnimatedLabelPasswordField>
            <AnimatedLabelPasswordField name="plainPassword[second]"
                                        type="password"
                                        :class="{ 'error': form.password.hasError }"
                                        :secureLength="8"
                                        :toggle="true"
                                        :required="true"
                                        v-model="form.passwordRepeat.value"
                                        @score="(s) => scoreCatched('passwordRepeat', s)"
                                        @feedback="showFeedback"
                                        @change="(v) => updateFormValue('passwordRepeat', v)"
            >
              <Translation resource_key="applicant.myaccount.password.change.new.repeat" />
            </AnimatedLabelPasswordField>
            <div class="form-footer">
              <ul class="error-messages" v-if="hasError()" >
                <li class="message" v-for="(error, index) in errors" :key="index">
                  {{ error.message }}
                </li>
              </ul>
              <span class="form-sucess-footer" v-if="successMessage.shown">
                {{ successMessage.message }}
              </span>
            </div>
            <div class="buttons">
              <p class="form-note">
                <sup>*</sup>Pflichtfelder
              </p>
              <CpButton size="large" :disabled="!canChange">
                <Translation resource_key="applicant.myaccount.password.change.button" />
              </CpButton>
              <router-link :to="{ name: 'MyAccount' }">
                <Translation resource_key="applicant.myaccount.password.change.not.now" />
              </router-link>
            </div>
          </form>
        </div>
      </ModalBase>
    </div>
  </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import CpButton from '../../../atoms/CpButton';
import Translation from '../../../atoms/Translation';
import AnimatedLabelTextField from '../../../molecules/AnimatedLabelTextField'
import AnimatedLabelPasswordField from '../../../molecules/AnimatedLabelPasswordField'
import ModalBase from '../../../molecules/ModalBase';
import types from '../../../../store/modules/applicant/types';
import api from '../../../../api/applicant/profile';

const {
  mapState
} = createNamespacedHelpers(types.MODULE_NAME);

export default {
  name: 'PasswordChange',

  components: {
    CpButton,
    Translation,
    AnimatedLabelTextField,
    AnimatedLabelPasswordField,
    ModalBase
  },

  data() {
    return {
      form: {
        oldPassword: { value: '', hasError: false, score: 0 },
        password: {
          value: '',
          hasError: false,
          score: 0,
          placeholder: ''
        },
        passwordRepeat: {
          value: '',
          hasError: false,
          score: 0,
          placeholder: ''
        }
      },
      errorMessages: {
        notFullfilled: {
          shown: false,
          message: 'Bitte Pflichtfelder ausfüllen.'
        },
        emailInUse: {
          shown: false,
          message: 'Diese E-Mail-Adresse wird bereits verwendet.'
        },
        passwordsNotSame: {
          shown: false,
          message: 'Beide Passwort-Felder müssen identisch sein.'
        },
        passwordsNumber: {
          shown: false,
          message: 'Passwort-Felder müssen identisch sein.'
        },
        feedBacks: {
          shown: false,
          message: ''
        },
        passwordScores: {
          shown: false,
          message: 'Passwort ist zu schwach.'
        }
      },
      successMessage: {
        shown: false,
        message: 'Passwort wurde geändert'
      }
    }
  },

  computed: {
    ...mapState({
      profile: (state) => state.profile
    }),
    errors() {
      const result = Object.values(this.errorMessages).filter((emsg) => emsg.shown);
      console.log(result);
      return result;
    },
    allBlank() {
      return this.form.oldPassword.value.length === 0
        && this.form.password.value.length === 0
        && this.form.passwordRepeat.value.length === 0;
    },
    canChange() {
      return !this.allBlank && this.errors.length === 0;
    }
  },

  methods: {

    onYesClicked(e) {
      // old form call was:
      // POST https://www.careerpeakr.test/de/user/change-password
      // fos_user_change_password_form[current_password]:
      // fos_user_change_password_form[plainPassword][first]:
      // fos_user_change_password_form[plainPassword][second]:

      e.preventDefault();
      if (!this.validate()) {
        return false;
      }
      api.changePassword(this.$careerpeakr.locale, {
        current_password: this.form.oldPassword.value,
        plainPassword: {
          first: this.form.password.value,
          second: this.form.passwordRepeat.value
        }
      }).then((data) => {
        if (data.event === 'invalid') {
          this.displayErrorMessages(data.form);
          return false;
        }
        this.$set(this.successMessage, 'shown', true);
        console.log('success!')
        return false;
      }).then(() => {
        setTimeout(() => this.$router.push({ name: 'MyAccount' }), 3000)
        return true;
      }).catch((err) => {
        this.parseErrorResponse(err.response);
        console.error(err);
      });
      return false;
    },
    parseErrorResponse(err) {
      console.log(err);
    },
    hasError() {
      const errors = Object.values(this.errorMessages).filter((emsg) => emsg.shown);
      return (errors) ? errors.length > 0 : false;
    },

    showFeedback({ suggestions, warning }) {
      console.log('warning', warning);
      console.log('suggestions', suggestions);
      // remove all feedbacks, if suggestions are updated.
      const self = this;
      Object.keys(this.errorMessages).filter((key) => key.indexOf('feedBacks') !== -1).map((key) => {
        delete self.errorMessages[key];
        return key;
      });

      suggestions.map((suggestion, index) => {
        self.$set(self.errorMessages, `feedBacks${index}`, {});
        self.$set(self.errorMessages[`feedBacks${index}`], 'message', suggestion);
        self.$set(self.errorMessages[`feedBacks${index}`], 'shown', true);
        return suggestion;
      });
    },

    updateFormValue(key, val) {
      this.form[key].value = val;
      this.validatePassword(this.form.password);
      this.validatePassword(this.form.passwordRepeat);
    },

    scoreCatched(path, score) {
      console.log(`score: ${path}`, score);
      this.form[path].score = score;
    },

    toggleOffErrorOnFields() {
      Object.keys(this.errorMessages).map((key) => {
        this.$set(this.errorMessages[key], 'shown', false);
        return key;
      });

      Object.keys(this.form).map((key) => {
        this.$set(this.form[key], 'hasError', false);
        return key;
      });
    },

    validateSamePasswords() {
      const valid = this.form.password.value === this.form.passwordRepeat.value;
      this.$set(this.form.password, 'hasError', !valid);
      this.$set(this.form.passwordRepeat, 'hasError', !valid);
      this.$set(this.errorMessages.passwordsNotSame, 'shown', !valid);
      return valid
    },

    validateRequiredField(fieldObject) {
      const valid = (fieldObject.value !== '');
      this.$set(fieldObject, 'hasError', !valid);
      this.$set(this.errorMessages.notFullfilled, 'shown', !valid);
      return valid;
    },

    validatePassword(passwordObj) {
      let valid = this.validateRequiredField(passwordObj);
      /* eslint-disable no-bitwise  */
      valid &= passwordObj.score >= 3
      /* eslint-enable no-bitwise  */
      this.$set(passwordObj, 'hasError', !valid);
      this.$set(this.errorMessages.passwordScores, 'shown', !valid);
      /* eslint-disable no-bitwise  */
      if (this.form.passwordRepeat.value.length > 0) {
        valid &= this.validateSamePasswords();
      }
      /* eslint-enable no-bitwise  */
      return valid;
    },

    validate() {
      this.toggleOffErrorOnFields();
      /* eslint-disable no-bitwise  */
      // must be filled or checked
      let valid = this.validatePassword(this.form.password);
      valid &= this.validatePassword(this.form.passwordRepeat);
      valid &= this.validateRequiredField(this.form.oldPassword)
      /* eslint-enable no-bitwise  */
      return valid;
    },

    displayErrorMessages(form) {
      if (this.displayErrorMessage(form.children.current_password)) {
        this.form.oldPassword.hasError = true;
      }
      if (this.displayErrorMessage(form.children.plainPassword.children.first)) {
        this.form.password.hasError = true;
      }
      if (this.displayErrorMessage(form.children.plainPassword.children.second)) {
        this.form.passwordRepeat.hasError = true;
      }
    },
    /**
     * check form Object from API and display error message if it is not valid
     * @return true if value is invalid
     */
    displayErrorMessage(elm) {
      if (elm.vars.valid) {
        return false;
      }

      const self = this;
      elm.vars.errors.errors.map((err, idx) => {
        const errMsgKey = `form-${elm.vars.name}-${idx}`;
        this.$set(self.errorMessages, errMsgKey, {});
        this.$set(self.errorMessages[errMsgKey], 'message', err);
        this.$set(self.errorMessages[errMsgKey], 'shown', true);
        return err;
      });
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../scss/atoms/_colors';

.password-change /deep/ .modal-inner {
  max-width: 700px;
  margin: 0 auto;
  padding: 30px;
  text-align: center;

  .password-change-inner {
    max-width: 445px;
    margin: 0 auto;

    h2 {
      font-size: 38px;
      font-weight: 800;
      line-height: 1.4em;
      text-align: center;
    }

    p {
      font-size: 14px;
      line-height: 1.4em;
    }

    .animated-label-textfield /deep/ {
      span.blank {
        top: 17px;
      }

      input {
        height: 60px;
      }
    }

    .Password {
      border: none !important;
      margin-bottom: 15px;
    }

    .form-sucess-footer {
      color: $color-deepgreen;
    }

    .buttons {
      display: flex;
      margin-top: 35px;
      flex-flow: column;
      align-items: center;

      .form-note {
        text-align: center;
      }

      a {
        display: block;
        text-decoration: none;
        color: $color-darkgrey;
        height: 70px;
        line-height: 67px;
        font-size: 17px;
      }
    }
  }
}
</style>
