import JobSuggestion from '../../components/templates/JobSuggestion';
import JobDetail from '../../components/templates/JobDetail';

export default [
  {
    path: '',
    name: 'CareerBoardJobSuggestion',
    components: {
      'job-suggestion': JobSuggestion
    }
  },
  {
    path: 'competence-analyse',
    name: 'CareerBoardCompetenceAnalyse'
  },
  {
    path: 'optimize',
    name: 'CareerBoardOptimize'
  },
  {
    path: 'job/:id',
    name: 'CareerBoardJobDetail',
    components: {
      'job-suggestion': JobSuggestion,
      modal: JobDetail
    },
    props: {
      'job-suggestion': false,
      modal: true
    }
  }
];
