<template>
  <div class="competence-radar-chart" ref="chart-wrapper">
    <div class="cp-radar-chart-inner">
      <ChartRadar :chartData="data" :options="options" ref="chart" v-on:chart:render="onRender"
                  v-on:chart:update="renderLabels"></ChartRadar>
    </div>
    <div class="cp-radar-chart-labels">
      <span v-for="(label, i) in labels" class="radar-chart-label-num" ref="label-num" :key="i">
        {{ i+1 }}
      </span>
      <span v-for="(label, i) in labels"
            class="radar-chart-label"
            ref="label"
            :class="{'question': '?' === label}"
            :key="`${i}-${label}`"
      >
        {{ label }}
      </span>
    </div>
  </div>
</template>

<script>
import ChartRadar from '../molecules/ChartRadar';

/**
   * The property "labels" should be a simple array of strings like ["Label A", "Label B"...].
   * And "datasets" is supposed to be an array of dataset object like:
   * [
   *  {
   *    data: [10,20,30,50,3,90],
   *    borderColor: '#FF0000',
   *    backgroundColor: 'rgba(0,0,0,0.6)'
   *  },
   *  ...
   * ]
   *
   * The "data" attribute of a dataset should be a simple array of numbers. Each number should not
   * be over 100. The attributes "borderColor" and "backgroundColor" accept a Hex or RGBA color
   * code or a gradient object of HTML canvas
   */

export default {
  name: 'ChartRadarCompetence',

  components: {
    ChartRadar
  },

  props: {
    labels: {
      type: Array
    },

    datasets: {
      type: Array
    }
  },

  data() {
    const self = this;

    return {
      data: {},
      isMounted: false,
      options: {
        layout: {
          padding: {
            left: 5,
            right: 5,
            top: 5,
            bottom: 5
          }
        },
        aspectRatio: 1,
        legend: {
          display: false
        },

        elements: {
          line: {
            tension: 0.4,
            borderWidth: 1
          },
          point: {
            backgroundColor: '#3c3c3c',
            borderWidth: 0
          }
        },

        scale: {
          ticks: {
            min: 0,
            max: 100,
            beginAtZero: true,
            stepSize: 20,
            display: false
          },

          pointLabels: {
            display: false
          },

          angleLines: {
            color: 'rgba(0,0,0,0)'
          }
        },

        tooltips: {
          enabled: false,

          custom(tooltipModel) {
            // Tooltip Element
            let $tooltip = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!$tooltip) {
              $tooltip = document.createElement('div');
              $tooltip.id = 'chartjs-tooltip';
              $tooltip.innerHTML = '<table></table>';
              self.$refs['chart-wrapper'].appendChild($tooltip);
            }

            if (tooltipModel.opacity === 0) {
              $tooltip.style.opacity = 0;
              return;
            }

            if (tooltipModel.body && tooltipModel.title) {
              const title = tooltipModel.title[0];
              const body = tooltipModel.body[0].lines[0];

              $tooltip.innerHTML = `<p class="chartsjs-tooptip-inner"><b>${body}</b>%<br>${title}</p>`;
            }
            /* eslint-disable no-underscore-dangle */
            const centerX = this._chart.scale.xCenter;
            const centerY = this._chart.scale.yCenter;
            /* eslint-enable no-underscore-dangle */
            const vDist = centerY - tooltipModel.caretY;
            const hDist = centerX - tooltipModel.caretX;
            const vDiff = Math.abs(vDist);
            const hDiff = Math.abs(hDist);
            let caretClass = 'center-bottom';

            if (vDiff < hDiff) {
              if (hDist > 0) {
                caretClass = 'right-center';
              } else {
                caretClass = 'left-center';
              }
            } else if (vDist < 0) {
              caretClass = 'center-top';
            }

            $tooltip.classList.remove('center-bottom', 'right-center', 'left-center', 'center-top');
            $tooltip.classList.add(caretClass);

            // `this` will be the overall tooltip
            /* eslint-disable no-underscore-dangle */
            const positionY = this._chart.canvas.offsetTop;
            const positionX = this._chart.canvas.offsetLeft;
            /* eslint-enable no-underscore-dangle */

            // Display, position, and set styles for font
            $tooltip.style.opacity = 1;
            $tooltip.style.position = 'absolute';
            $tooltip.style.left = `${positionX + tooltipModel.caretX}px`;
            $tooltip.style.top = `${positionY + tooltipModel.caretY}px`;
          }
        },

        onResize: this.onResize
      },
      resizeTimer: null
    };
  },

  watch: {
    datasets() {
      this.fillData();
    }
  },

  methods: {
    fillData() {
      const datasets = [];

      this.datasets.forEach((item, i) => {
        datasets[i] = item;
        datasets[i].backgroundColor = this.getChartBackground(item.backgroundColor);
      });

      this.data = {
        labels: this.labels,
        datasets: this.datasets
      };
    },

    onRender() {
      this.renderChart();
    },

    onResize() {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(this.renderChart.bind(this), 200);
    },

    renderChart() {
      /**
         * Render labels
         */
      this.renderLabels();

      /**
         * Update background gradient
         */
      this.datasets.forEach((item, i) => {
        this.data.datasets[i].backgroundColor = this.getChartBackground(item.backgroundColor);
      });
    },

    renderLabels() {
      const { _chart: chart } = this.$refs.chart.$data;
      const paddingLeft = parseInt(window.getComputedStyle(this.$refs['chart-wrapper'], null).getPropertyValue('padding-left'), 10);
      const paddingTop = parseInt(window.getComputedStyle(this.$refs['chart-wrapper'], null).getPropertyValue('padding-top'), 10);

      /**
         * Render labels
         */
      for (let i = 0; i < this.labels.length; i += 1) {
        const point = chart.scale.getPointPositionForValue(i, 100);
        const $el = this.$refs.label[i];
        const $elNum = this.$refs['label-num'][i];
        const chartXCenterDiff = point.x - chart.scale.xCenter;
        const chartYCenterDiff = point.y - chart.scale.yCenter;
        const absCenterX = chart.scale.xCenter + paddingLeft;
        const absCenterY = chart.scale.yCenter + paddingTop;

        // calc position of string label
        $el.style.left = `${point.x + paddingLeft}px`;
        $el.style.top = `${point.y + paddingTop}px`;

        let posX = -50;
        let posY = -50;
        let align = 'center';

        if (chartXCenterDiff < -5) {
          posX = -100;
          align = 'right';
        } else if (chartXCenterDiff > 5) {
          posX = 0;
          align = 'left';
        }

        if (chartYCenterDiff < -5) {
          posY = -100;
        } else if (chartYCenterDiff > 5) {
          posY = 0;
        }

        $el.style.transform = `translate(${posX}%, ${posY}%)`;
        $el.style.textAlign = align;

        // calc position of number label (for the mobile layout)
        const distance = Math.sqrt((chartXCenterDiff ** 2) + (chartYCenterDiff ** 2)) + 18;
        const radian = Math.atan2(chartYCenterDiff, chartXCenterDiff);
        $elNum.style.left = `${Math.floor((Math.cos(radian) * distance) + absCenterX)}px`;
        $elNum.style.top = `${Math.floor((Math.sin(radian) * distance) + absCenterY)}px`;
      }
    },

    getChartBackground(colors) {
      if (!this.isMounted) {
        return '#000000';
      }

      if (typeof colors === 'object' && colors.length >= 2) {
        const { _chart: chart } = this.$refs.chart.$data;

        const bgColor = document
          .createElement('canvas')
          .getContext('2d')
          .createLinearGradient(0, 0, 0, chart.scale.width);
        bgColor.addColorStop(0, colors[0]);
        bgColor.addColorStop(0.5, colors[1]);

        return bgColor;
      }

      return colors;
    }
  },

  mounted() {
    this.isMounted = true;
    this.fillData();
  }
};
</script>

<style lang="scss">
  @import '../../scss/organisms/chart_radar_competence';

  @media screen and (max-width: 767px) {
    .competence-radar-chart {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 0;
    }

    .cp-radar-chart-labels {
      margin-top: 60px;
      counter-reset: label-counter;

      .radar-chart-label {
        display: block;
        position: relative !important;
        left: auto !important;
        right: auto !important;
        top: auto !important;
        bottom: auto !important;
        text-align: left !important;
        transform: none !important;
        counter-increment: label-counter;
        max-width: none;
        margin-left: -20px;
        margin-right: -20px;

        &:before {
          display: inline-block;
          content: counter(label-counter);
          background-color: $color-darkgrey;
          color: $color-white;
          border-radius: 50%;
          font-size: 12px;
          width: 20px;
          height: 20px;
          line-height: 21px;
          text-align: center;
          margin-right: 5px;
        }
      }
    }

    .radar-chart-label-num {
      display: block;
    }
  }
</style>
