<template>
  <div id="reward-recommendation-process">
    <div class="container">
      <ul class="reward-recommendation-process-inner cp-row">
        <li class="reward-recommendation-process-item columns-2-item">
          <div class="img-container">
            <div class="img-wrapper">
              <img src="/assets/img/icon-01-candidate.svg" alt="">
            </div></div>
          <CpHeader class="cp-row">
            <template slot="taglines">
              <Editable resource_key="joboffer.reward.recommendation.process.item-1.taglines"/>
            </template>
          </CpHeader>
        </li>
        <li class="reward-recommendation-process-item columns-2-item">
          <div class="img-container">
            <div class="img-wrapper">
              <img src="/assets/img/icon-02-Profile.svg" alt="">
            </div></div>
          <CpHeader class="cp-row">
            <template slot="taglines">
              <Editable resource_key="joboffer.reward.recommendation.process.item-2.taglines"/>
            </template>
          </CpHeader>
        </li>
        <li class="reward-recommendation-process-item columns-2-item">
          <div class="img-container">
            <div class="img-wrapper">
              <img src="/assets/img/icon-03-Interview.svg" alt="">
            </div></div>
          <CpHeader class="cp-row">
            <template slot="taglines">
              <Editable resource_key="joboffer.reward.recommendation.process.item-3.taglines"/>
            </template>
          </CpHeader>
        </li>
        <li class="reward-recommendation-process-item columns-2-item">
          <div class="img-container">
            <div class="img-wrapper">
              <img src="/assets/img/icon-04-Reward.svg" alt="">
            </div></div>
          <CpHeader class="cp-row">
            <template slot="taglines">
              <Editable resource_key="joboffer.reward.recommendation.process.item-4.taglines"/>
            </template>
          </CpHeader>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CpHeader from '../molecules/CpHeader';
import Editable from '../molecules/Editable';

export default {
  name: 'RewardRecommendationProcess',

  components: {
    CpHeader,
    Editable
  }
}

</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';
@import '../../scss/atoms/_typographie';

#reward-recommendation-process {
  padding: 50px 0 55px 0;

  li.reward-recommendation-process-item.columns-2-item {
    max-width: 560px;

    &:nth-child(2n-1) {
      margin-right: 30px;
    }
    &:nth-child(3) {
      margin-top: 40px;
    }

    &:last-child {
      padding-left: 0;
      margin-top: 40px;
    }

    .img-wrapper {
      display: inline-block;
    }

    /deep/ .taglines p {
      font-size: 17px;
      line-height: 28px;
      font-weight: $font-regular;
    }

    .number-sticker {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      color: $color-white;
      background-color: $color-blacky;
      font-size: 28px;
      text-align: center;
      line-height: 48px;
      font-weight: 400;
      margin-bottom: 8px;
      margin-right: -20px;
      display: inline-block;
    }
  }
}

img {
  width: 145px;
  height: 140px;
}

@include bp-max($bp-large-with-padding) {
  #reward-recommendation-process {
    .reward-recommendation-process-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    li.reward-recommendation-process-item.columns-2-item {
      width: 100%;
      margin-left: 15px;

      &:nth-child(2) {
        margin-top: 50px;
      }

      &:nth-child(3) {
        margin-top: 50px;
      }

      &:nth-child(4) {
        margin-top: 50px;
      }

      &:nth-child(2n-1) {
        margin-right: 0px;
      }
    }
  }
}

@include bp-max-medium() {
  #reward-recommendation-process {
    padding: 25px 10px 45px;

    li.reward-recommendation-process-item.columns-2-item {
      width: 100%;

      &:nth-child(2) {
        margin-top: 40px;
      }
    }
  }
}

</style>
