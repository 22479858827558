<template>
  <div class="removable-file-field">
    <FileFrom :value="value" :index="index" @change="callbackOnChange" />
    <RemoveElementButton :callback="onRemove" v-if="index !== 0" />
  </div>
</template>

<script>
import { RemoveElementButton } from '../../atoms/CpButton';
import FileFrom from '../../molecules/FileForm';

export default {
  name: 'RemovableFileField',

  components: {
    RemoveElementButton,
    FileFrom
  },

  props: {
    value: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    placeholder: {
      type: String
    },
    callbackRemove: {
      type: Function,
      required: true
    }
  },

  methods: {
    onRemove() {
      this.callbackRemove(this.value.value);
    },
    callbackOnChange(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';

.removable-file-field {
  width: 100%;
  position: relative;

  .icon-check {
    color: $color-blue;
    position: absolute;
    left: 5px;
    top: 10px;
    z-index: 10;
  }

  .removable-textfield {
    display: inline-block;
    width: 100%;
    margin-bottom: 8px;

    /deep/ input[type='text'] {
      width: calc(100% - 35px);
      border: none;
      padding-left: 38px;
    }
  }
}

</style>
