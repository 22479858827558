<template>
  <div class="wizard-progressbar">
    <WizardProgressNav :prev="prev" :next="next" :callback="change_selection" />
    <WizardProgressStatus :current="current" />
  </div>
</template>

<script>
import WizardProgressNav from '../organisms/WizardProgressNav';
import WizardProgressStatus from '../organisms/WizardProgressStatus';

const wizardRoutes = [
  'CareerBoardJobSuggestion',
  'CareerBoardCompetenceAnalyse',
  'CareerBoardOptimize'
];

export default {
  name: 'WizardProgressBar',
  components: {
    WizardProgressNav,
    WizardProgressStatus
  },
  props: {
    callback: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      prev: false,
      current: 0,
      next: true
    };
  },
  methods: {
    change_selection(direction) {
      this.current += direction;
      this.prev = this.current !== 0;
      this.next = this.current !== wizardRoutes.length - 1;
      this.$router.push({ name: wizardRoutes[this.current] });
      this.callback(direction);
    }
  }
};
</script>

<style lang="css" scoped>
.wizard-progressbar {
  position: relative;
  background-color: rgba(255,255,255,0.95);
}
</style>
