<template>
  <div class="vocational-training-form">
    <div class="column">
      <div class="row">
        <MonthYearSelectBox :value="convertSelectBoxDate(value.from)"
                            :givenYears="givenYears" class="from"
                            @change="(v) => valueChanged('from', v)"
        >
          <Translation resource_key="applicant.profile.education.vocational.training.start" />
        </MonthYearSelectBox>
        <MonthYearSelectBox :value="convertSelectBoxDate(value.till)"
                            :givenYears="givenYears" class="till"
                            @change="(v) => valueChanged('till', v)"
        >
          <Translation resource_key="applicant.profile.education.vocational.training.end" />
        </MonthYearSelectBox>
      </div>
      <div class="row name">
        <LabelTextField :value="valueOrBlank(value.nameText)"
                        placeholder="Berufsbezeichnung eingeben"
                        @change="(v) => valueChanged('nameText', v)"
        >
          <Translation resource_key="applicant.profile.education.vocational.training.name" />
        </LabelTextField>
      </div>
      <div class="row">
        <LabelTextField :value="valueOrBlank(value.employer)"
                        placeholder="Name des Arbeitgebers eingeben"
                        @change="(v) => valueChanged('employer', v)"
        >
          <Translation resource_key="applicant.profile.education.vocational.training.employer" />
        </LabelTextField>
      </div>
      <div class="row">
        <!-- autocomplete box-->
        <YesNoSwitch id="myid" name="myname"
                     :value="value.mastercraftsman === 'yes'"
                     @change="(v) => yesNoValueChanged('mastercraftsman', v)"
        >
          <Translation
            resource_key="applicant.profile.education.vocational.training.mastercraftsman"
          />
        </YesNoSwitch>
      </div>
      <div class="row">
        <RemoveElementButton :callback="onRemove" />
      </div>
    </div>
  </div>
</template>

<script>
import Translation from '../../atoms/Translation';
import LabelTextField from '../../molecules/LabelTextField';
import MonthYearSelectBox from '../../molecules/MonthYearSelectBox';
import YesNoSwitch from '../../molecules/YesNoSwitch';
import { RemoveElementButton } from '../../atoms/CpButton';
import formUtils from '../../../lib/mixins/formUtils';
import htmlUtils from '../../../lib/mixins/htmlUtils';
import dateOrderUtils from '../../../lib/mixins/dateOrderUtils';
import util from '../../../lib/utils';

export default {
  name: 'VocationalTrainingForm',
  components: {
    Translation,
    LabelTextField,
    MonthYearSelectBox,
    YesNoSwitch,
    RemoveElementButton
  },

  mixins: [formUtils, htmlUtils, dateOrderUtils],

  data() {
    return {
      today: new Date()
    };
  },

  props: {
    value: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      requreid: true
    },
    callbackRemove: {
      type: Function,
      required: true
    }
  },

  computed: {
    givenYears() {
      return util.range(1970, 2030).map((y) => y.toString());
    }
  },

  methods: {
    onRemove() {
      this.callbackRemove(this.value.value);
    },
    valueChanged(path, value) {
      let newVal = { ...this.value };
      const inputedValue = util.buildObjectFromPath(path, value);
      newVal = util.mergeDeep(newVal, inputedValue);
      this.$emit('change', newVal);
    },
    yesNoValueChanged(path, value) {
      this.valueChanged(path, value ? 'yes' : 'no');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';

.vocational-training-form {
  margin-bottom: 40px;

  .detail {
    width: calc(100% - 385px);
  }

  .month-year-select-box {
    /deep/ {
      label {
        font-size: 14px;
        line-height: inherit;
        color: $color-dustygrey;
      }
    }
  }

  .row.name /deep/ input[type='text'] {
    color: $color-blue;
  }

  .label-textfield {
    margin-bottom: 0;

    /deep/ {
      label > span {
        font-size: 14px;
        line-height: inherit;
        color: $color-dustygrey;
      }

      input[type='text'] {
        background-color: $color-lightgrey;
        font-weight: 700;
      }
    }
  }

  .yes-no-switch {
    margin-top: 5px;
  }

  .remove-element-button {
    display: block;
    margin-top: 21px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
