<template>
  <div class="future-prospect">
    <h4 class="future-prospect-caption"><slot></slot></h4>
    <div class="future-prospect-inner">
      <div class="future-prospect-item"
           v-for="(prospect, idx) in prospects"
           :class="'prospect' + prospect.value"
           :key="idx"
      >
        <p class="future-prospect-label">{{prospect.label}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChartFutureProspect',

  props: {
    prospects: {
      type: Array,
      default() {
        return [
          {
            value: 1,
            label: 'Heute'
          },
          {
            value: 0,
            label: 'Morgen'
          }
        ];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';

.future-prospect {
  color: $color-white;
}

.future-prospect-caption {
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  padding: 0;
  margin: 0 0 15px;
}

.future-prospect-inner {
  position: relative;
  background-color: rgba(255, 255, 255, 0.07);
  text-align: center;
  padding: 14px 12px 12px;
}

.future-prospect-item {
  display: inline-block;
  width: 86px;
  vertical-align: top;
  background: transparent no-repeat center top;
  background-size: 80px auto;
  padding-top: 86px;

  &.prospect3 {
    background-image: url(/assets/img/icon_cic_up.png);
  }

  &.prospect2 {
    background-image: url(/assets/img/icon_cic_mid.png);
  }

  &.prospect1 {
    background-image: url(/assets/img/icon_cic_down.png);
  }
}

.future-prospect-label {
  font-size: 14px;
  line-height: 1;
  margin: 0;
}
</style>
