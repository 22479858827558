<template>
  <nav class="cp-header-subnav">
    <!--<CpButtonAnchor href="#" color="transparent" size="narrow">DE</CpButtonAnchor>-->
    <LoginButton :overlayCloseCallback="overlayCloseCallback" :mode="mode" />
  </nav>
</template>

<script>
import LoginButton from '../organisms/LoginButton';

export default {
  name: 'HeaderSubnav',
  components: {
    LoginButton
  },
  props: {
    overlayCloseCallback: {
      type: Function,
      default: null
    },
    mode: {
      type: String,
      default: ''
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../scss/atoms/breakpoints.mixin';

.cp-header-subnav {
  position: absolute;
  right: 0;
  top: 0;
}

@include bp-max-medium() {

  .cp-header-subnav {
    &.mobile-hidden {
      display: none;
    }

    &.desktop-hidden {
      display: block;
      margin: 0 auto;
      padding: 0;
      left: 0;
      top: 0;
      z-index: 11000;
    }
  }
}

</style>
