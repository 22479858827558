import dosier from '../../../api/applicant/dosier';
import types from './types';

const hasFile = (entity) => 'image' in entity || 'cv' in entity || 'furtherDocuments' in entity
/* image avatar
  const result = [];
  if ('image' in entity && 'attached' in entity.image) {
    result.push(entity.image);
  }

  // cv
  if ('cv' in entity && 'attached' in entity.cv) {
    result.push(entity.cv);
  }

  // furtherDocuments
  if ('furtherDocuments' in entity) {
    if (entity.furtherDocuments.length) {
      entity.furtherDocuments.map((fd) => {
        if (fd.attached) {
          result.push(fd);
        }
        return fd;
      });
    }
  }
  */

export default {
  /**
   * load applicant dosier, and call mutation to set
   * @param    {Object} context given by Vuex Framework
   * @property {Object} commit  given by Vuex Framework
   * @param    {Object} payload object which contains request parameter
   * @property {Numner} id      id to get dosier
   * @property {String} locale  locale for displaying
   * @return {[type]}   none. setMailUnread Mutation occures.
   */
  [types.ACTIONS.LOAD_DOSIER]({ commit }, { id, locale }) {
    return new Promise((resolve, reject) => {
      dosier
        .getDosier(id, locale)
        .then((result) => {
          commit(types.MUTATIONS.SET_DOSIER_ENTITY, { dosier: result });
          commit(types.MUTATIONS.SET_DOSIER_FRESH, { dosier: result });
          commit(types.MUTATIONS.SET_DOSIER_FORMS, { dosier: result });
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  /**
   * load applicant dosier, and call mutation to set
   * @param    {Object} context given by Vuex Framework
   * @property {Object} commit  given by Vuex Framework
   * @param    {Object} payload object which contains request parameter
   * @property {Numner} id      id to get dosier
   * @property {String} locale  locale for displaying
   * @return {[type]}   none. setMailUnread Mutation occures.
   */
  [types.ACTIONS.LOAD_FORM]({ commit }, { id, formName, locale }) {
    return new Promise((resolve, reject) => {
      dosier
        .getDosierForm(id, formName, locale)
        .then((result) => {
          commit(types.MUTATIONS.SET_DOSIER_FORM, { form: result });
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  /**
   * update applicant dosier,
   * @param    {Object} context given by Vuex Framework
   * @property {Object} commit  given by Vuex Framework
   * @param    {Object} payload object which contains request parameter
   * @property {Numner} id      id to get dosier
   * @property {String} locale  locale for displaying
   * @return {[type]}   none. setMailUnread Mutation occures.
   */
  [types.ACTIONS.UPDATE_DOSIER]({ commit }, { id, entity }) {
    return new Promise((resolve, reject) => {
      dosier
        .updateDosier(id, entity, hasFile(entity))
        .then((result) => {
          commit(types.MUTATIONS.SET_DOSIER_ENTITY, { dosier: result });
          commit(types.MUTATIONS.SET_DOSIER_FRESH, { dosier: result });
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  }
};
