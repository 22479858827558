<template>
  <div class="company-description">
    <CompanyConcept :profile="profile" />
    <ProductAndService :profile="profile" />
    <WhyGoodInHand :profile="profile" />
  </div>
</template>

<script>
import CompanyConcept from '../organisms/CompanyConcept';
import ProductAndService from '../organisms/ProductAndService';
import WhyGoodInHand from '../organisms/WhyGoodInHand';

// Mock server data
if (typeof window.Jobpassion === 'undefined') {
  window.Jobpassion = {};
}

export default {
  name: 'CompanyDescription',

  components: {
    CompanyConcept,
    ProductAndService,
    WhyGoodInHand
  },

  props: {
    profile: {
      type: Object,
      requreid: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_breakpoints.mixin';

/deep/ .free-text {
  font-size: 17px;
  line-height: 28px;
}

@include bp-max-small() {
  .company-description /deep/ h3 {
    font-size: 25px;
  }
}
</style>
