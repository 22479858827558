<template>
  <transition-group name="modal" :duration="{ leave: 1000 }">
    <div class="job-offer-content" v-if="hasJobOffer()" key="0">
      <JobOfferHeader :jobOffer="jobOfferEntity" :showButton="false" />
      <CompanyOverview
        :profile="jobOfferEntity.companyProfile"
        :logo="jobOfferEntity.logo"
        :image="jobOfferEntity.images[0]"
      />
      <YourMission :jobOffer="jobOfferEntity" />
      <YourExpectation :jobOffer="jobOfferEntity" />
      <CompanyExpectation :jobOffer="jobOfferEntity" />
      <!-- JobOfferFooter / -->
    </div>
    <div style="height: 100vh; position: relative;" v-if="!hasJobOffer()" key="1">
      <CpLoading  />
    </div>
  </transition-group>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import CpLoading from '../atoms/CpLoading';
import JobOfferHeader from './JobOfferHeader';
import CompanyOverview from './CompanyOverview';
import YourMission from './YourMission';
import YourExpectation from './YourExpectation';
import CompanyExpectation from './CompanyExpectation';
import JobOfferStore from '../../store/modules/joboffer';
import utils from '../../lib/utils'

const { mapState, mapActions } = createNamespacedHelpers('joboffer');
const { types } = JobOfferStore;

export default {
  name: 'JobOfferContent',

  components: {
    CpLoading,
    JobOfferHeader,
    CompanyOverview,
    YourMission,
    YourExpectation,
    CompanyExpectation
  },

  data() {
    return {
      job: null
    }
  },

  computed: {
    ...mapState([
      types.STATE.JOBOFFER
    ]),

    jobOfferEntity() {
      let jobOffer = null;
      if (this.$store.state.applicant && this.$store.state.applicant.appointment) {
        ({ jobOffer } = this.$store.state.applicant.appointment);
      }
      return (jobOffer) || this.jobOffer;
    }
  },

  created() {
    if (utils.hasProperty(window, 'careerpeakr') && utils.hasProperty(window.careerpeakr, 'jobOfferId')) {
      this.loadJobOffer({
        jobOfferId: window.careerpeakr.jobOfferId,
        locale: this.$careerpeakr.locale
      });
    } else {
      this.loadJobOffer({ jobOfferId: this.$route.params.id, locale: this.$careerpeakr.locale });
    }
  },

  methods: {
    hasJobOffer() {
      return Object.keys(this.jobOfferEntity).length > 0
    },

    closeDetail() {
      this.$router.go(-1);
    },

    ...mapActions({
      loadJobOffer: types.ACTIONS.LOAD_JOBOFFER
    })
  }
}
</script>

<style lang="scss">
@import '../../scss/atoms/colors';
@import '../../scss/atoms/_breakpoints.mixin';

.job-offer .modal-inner {
  max-width: 1200px;
  padding: 0;
}

.job-offer .modal-inner .container {
  padding: 0;
}

.job-offer-content {
  max-width: 1200px;
  background-color: $color-white;
  margin: 0 auto;
}

@include bp-max-medium() {
  .job-offer {
    .modal-outer {
      padding: 0;

      &:before {
        display: none;
      }
    }

    .modal-inner {
      width: 100%;

      .cp-modal-close {
        left: auto;
        top: 10px;
      }
    }
  }
}

</style>
