<template>
  <div class="job-detail">
    <ModalBase :closeCallback="closeDetail" displayCloseButton="true">
      <div class="job-detail-container">
        <h2> Job Detail</h2>
        <!-- JobDetailHeader :job="job" / -->
        <div class="job-detail-body free-text" v-html="convertR2BR(jobDetail.body)"></div>
        <!-- JobDetailFooter :job="job" -->
      </div>
    </ModalBase>
  </div>
</template>

<script>
import AutoLinker from 'autolinker';

import ModalBase from '../molecules/ModalBase';
// import { JobDetailHeader, JobDetailFooter } from '../organisms/JobDetail';

export default {
  name: 'JobDetail',
  components: {
    ModalBase
    // JobDetailHeader,
    // JobDetailFooter
  },
  data() {
    return {
      job: null,
      jobDetail: null
    }
  },
  created() {
    if (this.$store.state.applicant.jobList) {
      this.job = this.$store.state.applicant.jobList.find((j) => j.id === this.$route.params.id);

      if (this.job) {
        console.log(`job found:${this.job.id}`);
        console.log(this.$store.state.applicant.jobListDetail);
        this.jobDetail = this.$store.state.applicant.jobListDetail;
      } else {
        console.log(`job not found:${this.job.id}`);
      }
    }
  },
  methods: {
    closeDetail() {
      this.$router.go(-1);
    },
    convertR2BR(body) {
      const result = body.replace(/\s{3,}/g, '<br>').replace(/(?:\r\n|\r|\n)/g, '<br>');
      return AutoLinker.link(result);
    }
  },
  destroyed() {
    console.log('destroyed called');
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';
@import '../../scss/atoms/layout';

.job-detail-container {
  max-width: 1200px;
  background-color: $color-white;
  margin: 0 auto;
}
</style>
