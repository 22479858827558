<template>
  <div class="rich-text-area">
    <label>
      <slot></slot>
    </label>
    <ckeditor :editor="editor" :value="value" @input="updateValue" :config="editorConfig" />
  </div>
</template>

<script>
import Vue from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';

Vue.use(CKEditor);

export default {
  name: 'RichTextArea',
  props: {
    placeholder: {
      type: String,
      default: 'here you can write your text'
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      // editorConfig: {}
      editorConfig: {
        autoload: false,
        async: true,
        language: window.translations.locale,

        placeholder: this.placeholder,
        toolbar: [
          'undo',
          'redo',
          '|',
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'blockQuote'
        ],
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1'
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2'
            }
          ]
        },
        styles: {
          options: ['full', 'alignLeft', 'alignRight']
        },
        uiColor: '#fafafc',
        disableNativeSpellChecker: false
      }
    };
  },
  methods: {
    updateValue(e) {
      this.$emit('change', e);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/typographie';

.rich-text-area {
  // max-width: 790px;

  label {
    font-size: $fs-medium;
    font-style: italic;
    font-weight: $font-bold;
    color: $color-dustygrey;
  }

  .ck-editor {
    color: $color-darkgrey;

    .ck-content {
      p {
        font-size: $fs-regular;
      }
    }
  }

  /deep/ .ck.ck-placeholder:before,
  /deep/ .ck .ck-placeholder:before {
    font-style: italic;
  }
}
</style>
