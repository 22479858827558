<template>
  <div class="experiences-edit">
    <MultipleElementForm
      :value="values"
      :form="form"
      :component="ExperienceForm"
      :callbackAdd="onAdd"
      :callbackRemove="onRemove"
      @change="onChange"
    />
  </div>
</template>

<script>
import ExperienceForm from '../../organisms/ApplicantProfile/ExperienceForm';
import MultipleElementForm from '../../molecules/MultipleElementForm';
// import util from '../../../lib/utils';

export default {
  name: 'ExperiencesEdit',

  components: {
    MultipleElementForm,
    ExperienceForm // eslint-disable-line vue/no-unused-components
  },

  data() {
    return {
      ExperienceForm
    };
  },

  props: {
    values: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    callbackChange: {
      type: Function,
      required: true
    }
  },

  methods: {
    onAdd() {
      let maxIndex = -1;
      const keys = Object.keys(this.values)
      if (keys.length !== 0) {
        maxIndex = keys.map((i) => Math.trunc(i)) // convert string to int
          .reduce((max, n) => (n > max ? n : max));
      }
      const newObj = {
        from: new Date(),
        till: new Date(),
        currentJob: 'no',
        jobExperienceName: '',
        employerName: '',
        jobExperienceDynaJobsSector: '',
        description: ''
      };
      this.callbackChange({ ...this.values, [maxIndex + 1]: newObj });
    },
    onRemove(idx) {
      console.log(idx);
      const newVal = { ...this.values };
      delete newVal[idx];
      this.callbackChange(newVal);
    },
    onChange({ index, value }) {
      const newVal = { ...this.values };
      newVal[index] = value;
      this.callbackChange(newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_breakpoints.mixin';

.experiences-edit {
  padding-bottom: 20px;

  .multiple-element-form {

    /deep/ {
      .cp-select-box ul li {
        background-color: $color-lightgrey;
      }

      .add-element-button {
        margin-left: 200px;
      }
    }
  }
}

@include bp-max-medium() {
  .experiences-edit {
    .multiple-element-form {
      display: flex;
      flex-flow: column;

      /deep/ .add-element-button {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
</style>
