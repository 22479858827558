<template>
  <div class="sourcer-avatar">
    <CalloutImage :src="sourcer.image" />
    <span class="sourcer-name">
      {{ sourcer.namefirst }}
      {{ sourcer.namelast }}
    </span>
  </div>
</template>

<script>
import CalloutImage from '../atoms/CalloutImage';

export default {
  name: 'SourcerAvatar',
  components: {
    CalloutImage
  },
  props: {
    sourcer: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';
.sourcer-avatar {
  width: 70px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
}
</style>
