/**
 * strip html-tags
 * this filter aimes to make compatibility with data by old UI.
 * @param html String with HTML
 * @returns text without html tags
 */
const stripHtml = (html) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export default {
  methods: {
    removeStyleAttr(html) {
      // eslint-disable-next-line no-useless-escape
      const result = html.replace(/(style=")([a-zA-Z0-9:;\.\s\(\)\-\,#]*)(")/gi, '');
      return result;
    },
    stripHtml(html) {
      return stripHtml(html);
    }
  },
  filters: {
    stripHtml(html) {
      return stripHtml(html);
    }
  }
};
