<template>
  <button class="cp-btn" :class="styleClasses" @click="onClick" :disabled="disabled">
    <span class="cp-btn-inner"><slot></slot></span>
  </button>
</template>

<script>
export default {
  name: 'CpButton',
  props: {
    callback: {
      type: Function
    },
    color: {
      type: String,
      default: 'blue'
    },
    size: {
      type: String,
      default: 'medium'
    },
    weight: {
      type: String,
      default: 'regular'
    },
    disabled: {
      default: false
    }
  },

  computed: {
    styleClasses() {
      const classes = [];

      if (typeof this.color === 'string') {
        classes.push(this.color);
      }

      if (typeof this.size === 'string') {
        classes.push(this.size);
      }

      if (typeof this.weight === 'string') {
        classes.push(this.weight);
      }

      return classes.join(' ');
    }
  },

  methods: {
    onClick(e) {
      if (typeof this.callback === 'function') {
        const result = this.callback(e);
        if (undefined !== result) {
          return result;
        }
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
@import './buttons';
</style>
