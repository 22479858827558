<template>
  <section class="fulfill-applicant-profile">
    <div class="left">
      <h3 v-if="withHeader">
        <Translation resource_key="joboffer.fulfill-profile-title" />
      </h3>
      <p>
        <Translation resource_key="joboffer.fulfill-profile-text" />
      </p>
    </div>
    <div class="center">
      <CpButtonAnchor
        :href="nextURI"
        color="blue"
        size="large"
        weight="bold"
      >
        <Translation resource_key="joboffer.fulfill-profile-button" />
      </CpButtonAnchor>
      <p>
        <a :href="nextNoopURI">
          <Translation resource_key="joboffer.fulfill-no-thankyou" />
        </a>
      </p>
    </div>
  </section>
</template>

<script>
import CpButtonAnchor from '../atoms/CpButton/CpButtonAnchor'
import Translation from '../atoms/Translation'

export default {
  name: 'FulfillApplicantProfile',

  components: {
    CpButtonAnchor,
    Translation
  },
  props: {
    withHeader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sentEmailText: ''
    }
  },
  computed: {
    jobOfferId() {
      if ('careerpeakr' in window && 'jobOfferId' in window.careerpeakr) {
        return window.careerpeakr.jobOfferId;
      }
      return this.$route.params.id;
    },
    nextURI() {
      return (this.loggedIn)
        ? `/${this.$careerpeakr.locale}/applicant/app/dosier`
        : `/#/register/${this.jobOfferId}`;
    },
    nextNoopURI() {
      return (this.loggedIn)
        ? `/${this.$careerpeakr.locale}/applicant/app/status`
        : '/';
    },
    loggedIn() {
      return (this.$store.state && this.$store.state.applicant && this.$store.state.applicant.profile && this.isValidId(this.$store.state.applicant.profile.id))
        ? this.$store.state.applicant.profile : null;
    }
  },
  methods: {
    onClick() {
      if (this.loggedIn) {
        window.location.href = `/${this.$careerpeakr.locale}/applicant/app/dosier`;
        return false;
      }
      window.location.href = '/#/register';
      return false;
    },
    isValidId(id) {
      return ((typeof id) === 'number') && id > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_colors';

.fulfill-applicant-profile {
  width: 100%;
  max-width: 980px;
  background: $color-white;
  font-size: 17px;

  .center {
    text-align: center;
    max-width: 792px;
    margin: 20px auto;
  }

  .left {
    text-align: left;
    max-width: 792px;
    margin: 40px auto;
  }

  h2,
  h3,
  em {
    margin: 0;
    font-weight: 800;
  }

  h2 {
    font-size: 38px;
  }

  h3 {
    font-size: 21px;
  }

  em {
    font-style: normal;
  }
  a {
    text-decoration: none;
    color: $color-black;

    /deep/ .cp-btn-inner {
      color: $color-white;
    }
  }
}
</style>
