/**
 * Array of String or Object with label (String) and value (Number|String) properties
 */
export default () => {
  console.error('dont use default export of this file CpSelectBox_values');
};

export function validateCpSelectBoxValues(values) {
  if (values instanceof Array) {
    return values.every((v) => {
      const optionType = typeof v;

      if (optionType === 'object') {
        const valueType = typeof v.value;
        const labelType = typeof v.label;
        return (valueType === 'number' || valueType === 'string') && labelType === 'string';
      }

      return optionType === 'string'
    });
  }

  return false;
}
