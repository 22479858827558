<template>
  <div class="product-service">
    <div class="wrapper">
      <h3><Translation resource_key="company.profile.cp_descriptionProducts" /></h3>
      <div class="free-text" v-html="removeStyleAttr(profile.descriptionProducts)"></div>
    </div>
  </div>
</template>

<script>
import Translation from '../atoms/Translation';
import removeStyleAttr from '../../lib/mixins/htmlUtils';

// Mock server data
if (typeof window.Jobpassion === 'undefined') {
  window.Jobpassion = {};
}

export default {
  name: 'ProductAndService',
  components: {
    Translation
  },

  mixins: [removeStyleAttr],

  props: {
    profile: {
      type: Object,
      requreid: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin'; // for /deep/ should be have here
@import '../../scss/atoms/layout'; // for /deep/ should be have here

.product-service {
  background-color: $color-bg-grey;
  color: $color-darkgrey;
  font-size: 17px;
  line-height: 28px;
  // padding-left: var(--padding-edge);
  // padding-right: var(--padding-edge);

  .wrapper {
    max-width: 894px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    padding-bottom: 50px;

    h3 {
      color: $color-blacky;
      font-size: 30px;
      line-height: 43px;
      margin: 0;
    }

    h4,
    strong {
      color: $color-blacky;
      font-size: 21px;
      font-weight: 900;
    }

    /deep/ div p {
      color: $color-darkgrey;
      font-weight: normal;
    }
  }
}

@include bp-max-medium() {
  .product-service {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@include bp-max-small() {
  .product-service .wrapper h3 {
    font-size: 25px;
    line-height: 36px;
  }
}

@include bp-max-extra-small() {
  .product-service .wrapper h3 {
    font-size: 19px;
    line-height: 28px;

    /deep/ div p {
      font-size: 14px;
      line-height: 21px;
    }
  }
}
</style>
