import EmployerContact from '../components/templates/EmployerContact';
import Registration from '../components/templates/Registration';
import CompanyProfile from '../components/templates/CompanyProfile';
import JobOffer from '../components/templates/JobOffer';

export default {
  routes: [
    {
      path: '/contact',
      name: 'EmployerContact',
      component: EmployerContact
    },
    {
      path: '/register/:jobOfferId?',
      name: 'Registration',
      component: Registration
    },
    {
      path: '/company/:id',
      name: 'CompanyProfile',
      component: CompanyProfile
    },
    {
      path: '/jobOffer/:id',
      name: 'JobOffer',
      component: JobOffer
    }
  ]
};
