import analyse from './analyse';
import cv from './cv';
import favorite from './favorite';
import jobList from './joblist';
import refinedJobList from './refinedJoblist';
import optimise from './optimise';
import trash from './trash';
import profile from './profile';
import dosier from './dosier';
import jobDetail from './jobDetail';

export default {
  profile,
  dosier,
  cv,
  analyse,
  favorite,
  jobList,
  jobDetail,
  optimise,
  refinedJobList,
  trash
}
