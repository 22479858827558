import Vue from 'vue';
import Router from 'vue-router';
import CommonLandingRoute from './common';
import StartPageRoute from './startpage';
import CareerBoardRoute from './careerboard';
import EmployerRoute from './employer';
import DashboardRoute from './dashboard';
import JobOfferRoute from './joboffer';
import ApplicantRoute from './applicant';
import BadCredentialRoute from './bad-credential';

Vue.use(Router);

export default {
  common: new Router(CommonLandingRoute),
  startpage: new Router(StartPageRoute),
  careerboard: new Router(CareerBoardRoute),
  employer: new Router(EmployerRoute),
  dashboard: new Router(DashboardRoute),
  joboffer: new Router(JobOfferRoute),
  applicant: new Router(ApplicantRoute),
  badCredential: new Router(BadCredentialRoute)
};
