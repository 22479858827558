<template>
  <div :class="{'application-status': true, 'loading': loading}">
    <div class="joboffer-company">
      <router-link :to="{name: 'CompanyProfile', params: {id: companyId}}">
        {{ company }}
      </router-link>
    </div>
    <div class="joboffer-title">{{ title }}</div>
    <div class="joboffer-info">
      <span class="joboffer-location">{{ place }}</span>
      <span class="joboffer-type">{{ type }}</span>
    </div>
    <div class="joboffer-detail clearfix">
      <ul class="joboffer-detail-left">
        <li>
          <div class="joboffer-detail-caption">Branche</div>
          <div class="joboffer-detail-main">{{ branch }}</div>
        </li>
        <li>
          <div class="joboffer-detail-caption">Unternehmensgröße</div>
          <div class="joboffer-detail-main">{{ size }}</div>
        </li>
        <li>
          <div class="joboffer-detail-caption">Karrierestufe</div>
          <div class="joboffer-detail-main">{{ level }}</div>
        </li>
        <li>
          <div class="joboffer-detail-caption">Nötige Reisebereitschaft</div>
          <div class="joboffer-detail-main">{{ travel }}</div>
        </li>
      </ul>
      <ul class="joboffer-detail-right">
        <li>
          <div class="joboffer-detail-caption">Arbeitsbeginn</div>
          <div class="joboffer-detail-main">{{ begin }}</div>
        </li>
        <li>
          <div class="joboffer-detail-caption">Arbeitsstunde pro Woche</div>
          <div class="joboffer-detail-main">{{ hours }}</div>
        </li>
      </ul>
      <div class="joboffer-company-logo" :style="`background-image: url(${companylogo})`">
      </div>
    </div>
    <div class="joboffer-footer">
      <router-link :to="{name: 'JobOffer', params: {id: jobOfferId}}">
        Ganzes Jobangebot ansehen
      </router-link>
    </div>
    <div class="application-status-graph">
      <JobOfferStatusBar :status="status"
                         :jobOfferId="jobOfferId"
                         :userId="userId"
                         v-on:updated="statusUpdated"
                         v-on:publish-data="publishData">
      </JobOfferStatusBar>
    </div>
    <button class="reject-joboffer" @click="setNotInterested">Kein Interesse</button>
  </div>
</template>

<script>
import JobOfferStatusBar from '../organisms/JobOfferStatusBar';

export default {
  name: 'ApplicationStatus',

  components: {
    JobOfferStatusBar
  },

  props: {
    jobOfferId: Number,
    userId: Number,
    company: String,
    companyId: Number,
    companylogo: String,
    title: String,
    place: String,
    type: String,
    branch: String,
    size: String,
    level: String,
    travel: String,
    begin: String,
    hours: String,
    loading: Boolean,
    status: {
      type: Number,
      default: 0
    }
  },

  methods: {
    setNotInterested(_e) {
      // eslint-disable-next-line no-alert
      if (window.confirm(`Wollen Sie die Stelle "${this.title}" löschen?`)) {
        this.$emit('removed');
      }
    },

    statusUpdated() {
      this.$emit('updated');
    },

    publishData() {
      this.$emit('publish-data');
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../../scss/atoms/colors';

  .application-status {
    position: relative;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    padding: 35px 39px 40px;
    background-color: $color-white;

    &.disabled {
      * {
        color: $color-dustygrey;
      }

      .reject-joboffer {
        display: none;
      }
    }

    &.loading {
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.75);
        content: '';
        z-index: 5;
      }

      &:after {
        position: absolute;
        left: 50%;
        top: 50%;
        display: block;
        width: 50px;
        height: 50px;
        background: transparent url('/assets/img/loader.svg') no-repeat center;
        background-size: contain;
        margin: -25px 0 0 -25px;
        content: '';
        animation: rotation 1.4s linear infinite;
        z-index: 6;
      }
    }

    @media screen and (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .joboffer-company {
    a {
      display: inline-block;
      font-size: 17px;
      color: $color-blue-light;
      border-bottom: 1px solid $color-silvergrey;
      text-decoration: none;

      @media screen and (max-width: 767px) {
        font-size: 13px;
      }
    }
  }

  .joboffer-title {
    font-size: 30px;
    margin-top: 14px;

    @media screen and (max-width: 1023px) {
      font-size: 24px;
      line-height: 1.16;
    }
  }

  .joboffer-info {
    font-size: 16px;
    color: $color-dustygrey;
    margin-top: 5px;

    span:before {
      font-family: fontello;  // stylelint-disable-line
      margin-right: 8px;
    }
  }

  .joboffer-location:before {
    content: '\F031';
  }

  .joboffer-type {
    margin-left: 20px;
    &:before {
      content: '\e81c';
    }
  }

  .joboffer-detail {
    position: relative;
    line-height: 1.5;
    margin-top: 20px;

    ul {
      border-bottom: 1px solid $color-silvergrey;
    }

    li {
      border-top: 1px solid $color-silvergrey;
      padding: 6px 0;
    }

    @media screen and (max-width: 1023px) {
      padding-top: 116px;

      .joboffer-detail-left {
        border-bottom: 0 none;
      }
    }
  }

  .joboffer-company-logo {
    position: absolute;
    right: 20px;
    top: 20px;
    width: calc(50% - 52px);
    height: 76px;
    background: transparent no-repeat center;
    background-size: contain;

    @media screen and (max-width: 1023px) {
      width: auto;
      left: 20px;
      background-position: left center;
    }
  }

  .joboffer-detail-left {
    float: left;
  }

  .joboffer-detail-right {
    float: right;
    padding-top: 116px;
  }

  .joboffer-detail-left,
  .joboffer-detail-right {
    width: calc(50% - 12px);

    @media screen and (max-width: 1023px) {
      width: auto;
      float: none;
      padding-top: 0;
    }
  }

  .joboffer-detail-caption {
    font-size: 14px;
    color: $color-darkgrey;
  }

  .joboffer-detail-main {
    font-size: 16px;
    font-weight: 700;
  }

  .joboffer-footer {
    font-size: 14px;
    text-align: right;

    a {
      color: $color-blue-light;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .reject-joboffer {
    position: absolute;
    right: 39px;
    top: 20px;
    font-size: 12px;
    color: $color-dustygrey;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      font-family: 'fontello';  // stylelint-disable-line
      content: '\E804';
      margin-left: 3px;
      font-size: 120%;
    }
  }
</style>
