<template>
  <div class="arrow-label" :style="styles">
    <div class="arrow-label-inner">
      <span class="arrow-label-num">{{ position }}</span>
      <span class="arrow-label-text">
        <slot></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArrowLabel',
  props: {
    position: Number,
    background: String,
    numcolor: String,
    bordercolor: String
  },
  computed: {
    styles() {
      return {
        '--background': this.background,
        '--numcolor': this.numcolor,
        '--bordercolor': this.bordercolor
      }
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/atoms/_colors';

.arrow-label {
  --background: #29c0d5;
  --numcolor: $color-black;
  --bordercolor: $color-white;
  background: none;
  border: 0;
  font-size: 14px;
  line-height: 1.2;
  height: 60px;
  position: relative;
  text-align: center;
  padding: 0 0 0 20px;
  font-weight: 700;
  overflow: hidden;
  color: var(--bordercolor);

  &:before,
  &:after {
    background: var(--background);
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 50%;
    border-right: 2px solid var(--bordercolor);
  }

  &:before {
    transform: skew(30deg);
    top: 0;
    transform-origin: right bottom;
  }

  &:after {
    transform: skew(-30deg);
    bottom: 0;
    transform-origin: right top;
  }

  &.square {
    &:before,
    &:after {
      transform: none;
      border-right: 0 none;
    }
  }

  /*&.disabled {
    color: #C4C4C4;

    &:before,
    &:after {
      background-color: #efefef;
      border-right-color: #C4C4C4;
    }

    .arrow-label-num {
      background-color: #c4c4c4;
      color: #efefef;
    }
  }*/
}

.arrow-label-inner {
  position: relative;
  width: 90%;
  height: 100%;
  z-index: 10;
  margin: 0 auto;

  &:after {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: '';
  }
}

.arrow-label-num {
  color: var(--numcolor);
  display: inline-block;
  vertical-align: middle;
  background-color: var(--bordercolor);
  border-radius: 50%;
  width: calc(1.2em + 2px);
  padding: 1px 0;
  line-height: 1.2em;
}

.arrow-label-text {
  display: inline-block;
  vertical-align: middle;
  max-width: calc(100% - 34px);
  margin-left: 8px;
  text-align: left;
}
</style>
