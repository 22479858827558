const formUtils = {
  methods: {
    valueOrBlank(val) {
      return (typeof val !== 'undefined') && (val !== null) ? val : '';
    },
    findValueFromChoices(value, choices) {
      return choices.find((v) => v.value === value);
    },
    findValueByLabelFromChoices(value, choices) {
      return choices.find((v) => v.label === value);
    },
    findOriginalValueFromChoices(value, choices) {
      return choices.find((v) => v.original === value);
    }
  }
};

export default formUtils;
