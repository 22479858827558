<template>
  <ul class="applicant-user-menu">
    <li><UserLogout /></li>
  </ul>
</template>

<script>
// import LanguageSwitch from '../../molecules/LanguageSwitch';
import UserLogout from '../../molecules/UserLogout';

export default {
  name: 'ApplicantUserMenu',
  components: {
    // LanguageSwitch, // it should be mounted here
    UserLogout
  },
  methods: {
    linkClicked(e) {
      this.$emit('linkClicked', e);
    }
  }

};
</script>

<style lang="scss">
  .applicant-user-menu {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;

    li {
      display: inline-block;
    }
  }
</style>
