<template>
  <div class="educations-display">
    <div class="educations">
      <div class="label">
        <Translation resource_key="applicant.profile.education.graduation.title" />
      </div>
      <div class="studies" v-if="!hasNoInput()">
        <template v-if="highestDegreeValue === 4">
          <div class="degree-label" v-if="studies.length > 0">
            <i class="fontello icon-graduation-cap"></i>
            <Translation :resource_key="highestDegreeTitle" />
          </div>
          <ul>
            <li v-for="(education, index) in studies" :key="index" >
              <Study :value="education" :current="index === 0" />
            </li>
          </ul>
        </template>
        <div v-else class="schulabschluss">
          <div class="degree-label">
            <i class="fontello icon-graduation-cap"></i>
            <Translation :resource_key="highestDegreeTitle" />
          </div>
          <div class="highest-degree">{{ values.highestDegree }}</div>
          <div>{{ values.highestDegreeGrade }}</div>
        </div>
      </div>
      <div class="vocation-trainings" v-if="vocationalTrainings.length > 0">
        <div class="degree-label">
          <i class="fontello icon-tools"></i>
          <Translation resource_key="applicant.profile.education.vocational.training.title" />
        </div>
        <ul>
          <li v-for="(training, index) in vocationalTrainings" :key="index" >
            <VocationalTraining :value="training" :current="index === 0" />
          </li>
        </ul>
      </div>
      <div v-if="hasNoInput()">
        <p><i class="fresh">Angabe ergänzen</i></p>
      </div>
    </div>
    <div class="further-qualifications">
      <div class="label">
        <Translation resource_key="applicant.profile.education.further.qualification.title" />
      </div>
      <ul v-if="further.length > 0">
        <FurtherQualification v-for="(fq, index) in further" :key="index" :value="fq" />
      </ul>
      <div v-else>
        <p v-if="fresh"><i class="fresh">Angabe ergänzen</i></p>
      </div>
    </div>
  </div>
</template>

<script>
import Translation from '../../atoms/Translation';
import Study from '../../organisms/ApplicantProfile/Study';
import VocationalTraining from '../../organisms/ApplicantProfile/VocationalTraining';
import FurtherQualification from '../../organisms/ApplicantProfile/FurtherQualification';
import formUtils from '../../../lib/mixins/formUtils';

export default {
  name: 'EducationsDisplay',

  components: {
    Translation,
    Study,
    VocationalTraining,
    FurtherQualification
  },

  mixins: [formUtils],

  props: {
    values: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    fresh: {
      type: Boolean,
      defalut: false
    }
  },

  data() {
    return {
      Study,
      VocationalTraining
    }
  },

  computed: {
    highestDegreeValue() {
      const choice = this.findValueByLabelFromChoices(this.values.highestDegree, this.highestDegrees);
      return (typeof choice === 'undefined') ? 4 : parseInt(choice.value, 10);
    },

    highestDegreeTitle() {
      return `applicant.profile.education.graduation.title.${this.highestDegreeValue}`
    },

    highestDegrees() {
      // convert for example from {1: Mittelreife} to: {value:1, label: Mittelreife }
      return Object.entries(this.form.highestDegree.choices).map(([key, val]) => ({ value: key, label: val }));
    },
    studies() {
      return this.values.studyPrograms;
    },
    vocationalTrainings() {
      return this.values.professions;
    },
    educations() {
      return this.values.professions.concat(this.values.studyPrograms);
    },
    further() {
      return this.values.furtherQualifications;
    }
  },
  methods: {
    hasNoInput() {
      return (this.educations.length === 0) && (this.highestDegreeValue === 4);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_colors';
@import '../../../scss/atoms/_typographie';
@import '../../../scss/atoms/_icons';
@import '../../../scss/atoms/_breakpoints.mixin';

.educations-display {
  display: flex;
  font-size: 17px;

  .educations,
  .further-qualifications {
    flex-grow: 5;
    width: 100%;
  }

  .educations {
    margin-right: 3%;

    .studies {
      border-bottom: 1px solid $color-silvergrey;
      margin-bottom: 15px;
    }

    .degree-label {
      font-weight: $font-bold;
      color: $color-blacky;
      // margin-left: -33px;

      .fontello {
        display: inline-block;
        width: 24px;
        margin-right: 6px;
      }
    }

    ul {
      vertical-align: top;
      padding-right: 30px;

      li {
        padding-bottom: 15px;
      }
    }

    .schulabschluss {
      padding-bottom: 15px;

      .highest-degree,
      .highest-degree + div {
        padding-left: 33px;
      }

      .highest-degree {
        font-weight: $font-bold;
        color: $color-blue-light;
      }
    }
  }

  .label {
    border-bottom: 1px solid $color-silvergrey;
    padding-bottom: 10px;
    margin-bottom: 15px;
    color: $color-blue-light;
  }

  i.fresh {
    font-style: italic;
    color: $color-dustygrey;
  }
}

@include bp-max-medium() {
  .educations-display {
    display: flex;
    flex-direction: column;
  }

  .educations,
  .further-qualifications {
    width: 100%;
  }

  .educations {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0 10px 0 !important;
    margin-bottom: 30px;
  }
}
</style>
