import axios from 'axios';

const URL = '/api/v2/applicant/joblist';

export default {
  getJobList(profileId, joblistIds, location, distance, workingTimeType) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL}`, {
          profileId,
          joblistIds,
          location,
          distance,
          workingTimeType
        })
        .then((response) => {
          // handle success
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          reject(error);
        });
    });
  }
};
