<template>
  <div class='navigation-overlay'>
    <div class="navigation-overlay-close" ref="closer" @click="closeOverlay">
      <button class="close-btn"></button>
    </div>
    <nav class="overlay-nav">
      <HeaderSubnav key='3' class="desktop-hidden" :overlayCloseCallback="closeOverlay" :mode="mode" />
      <a :href="`/`" class="overlay-nav-item">Mit uns durchstarten</a>
      <a :href="`/${$careerpeakr.locale}/forcandidates`" class="overlay-nav-item">Als Kandidat</a>
      <a :href="`/${$careerpeakr.locale}/foremployers`" class="overlay-nav-item">Als Arbeitgeber</a>
    </nav>
    <div class="overlay-nav-footer">
      <FooterMenu />
    </div>
  </div>
</template>

<script>
import FooterMenu from '../molecules/FooterMenu';
import HeaderSubnav from '../templates/HeaderSubnav';

export default {
  name: 'NavigationOverlay',

  components: {
    FooterMenu,
    HeaderSubnav
  },

  props: {
    mode: {
      type: String,
      default: '',
      validator(val) {
        console.log('validator: ', val);
        return val === ''
          || val === 'applicant'
          || val === 'employer'
          || val === 'common'
      }
    }
  },

  methods: {
    closeOverlay(e) {
      if (e) {
        e.preventDefault();
      }
      document.body.classList.remove('main-nav-open');
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/organisms/navigation_overlay';
</style>
