<template>
  <section id="your-mission" class="cp-section">
    <div class="your-mission-inner">
      <CpHeader class="">
        <template slot="title">
          <Translation resource_key="joboffer.your_mission.title"/>
        </template>
      </CpHeader>
      <div class="">
        <div class="joboffer-description free-text"
             v-html="removeStyleAttr(jobOffer.jobOffer.description)"
        />
      </div>

      <Translation datakey="workLocation" resource_key="joboffer.work-location" />
      <Translation datakey="businessTripsMagnitude"
                   resource_key="joboffer.businessTripsMagnitude"
      />
      <Translation datakey="careerStep" resource_key="joboffer.career-step" />
      <Translation datakey="abroadExperiences" resource_key="joboffer.abroadExperiences" />
      <Translation datakey="workingtimePerWeek" resource_key="joboffer.workingtime-per-week" />
      <Translation datakey="startDate" resource_key="joboffer.startDate" />
      <Translation datakey="permanentEmployment" resource_key="joboffer.permanent-employment" />
      <Translation datakey="fixedTermContract" resource_key="joboffer.fixedTermContract" />
      <Translation datakey="hours" resource_key="joboffer.hours" />
      <Translation datakey="asap" resource_key="joboffer.asap" />
      <Translation datakey="no" resource_key="joboffer.no" />
      <Translation datakey="yes" resource_key="joboffer.yes" />
      <ResponsiveDataGrid :contents="missionContents" />
    </div>
  </section>
</template>

<script>
import CpHeader from '../molecules/CpHeader';
// import CompanyDataBlock from '../molecules/CompanyDataBlock';
import ResponsiveDataGrid from '../molecules/ResponsiveDataGrid';
import Translation from '../atoms/Translation';
import removeStyleAttr from '../../lib/mixins/htmlUtils';

export default {
  name: 'YourMission',

  components: {
    CpHeader,
    // CompanyDataBlock,
    ResponsiveDataGrid,
    Translation
  },

  mixins: [removeStyleAttr],

  props: {
    jobOffer: {
      type: Object,
      required: true
    }
  },

  data() {
    // placeholders for locale string by translate
    return {
      workLocation: '',
      businessTripsMagnitude: '',
      careerStep: '',
      abroadExperiences: '',
      workingtimePerWeek: '',
      startDate: '',
      permanentEmployment: '',
      fixedTermContract: '',
      hours: '',
      asap: '',
      yes: '',
      no: ''
    }
  },

  computed: {
    jobLocations() {
      return this.jobOffer.jobOffer.locations.join(', ');
    },

    expectedWorkingTime() {
      return this.jobOffer.jobOffer.timeMagnitude.join(' - ');
    },

    missionContents() {
      const left = [
        {
          title: this.workLocation,
          data: this.jobOffer.jobOffer.locations.join(', ')
        },
        {
          title: this.careerStep,
          data: this.jobOffer.jobOffer.jobLevel.join(', ')
        },
        {
          title: this.workingtimePerWeek,
          data: `${this.expectedWorkingTime} ${this.hours}`
        },
        {
          title: this.permanentEmployment,
          data: this.yes
        }
      ];

      const right = [
        {
          title: this.businessTripsMagnitude,
          data: this.jobOffer.jobOffer.businessTripsMagnitude
        }
      ];

      if (this.jobOffer.jobOffer.businessTripsPlanned === 'yes') {
        right.push({
          title: this.abroadExperiences,
          data: this.jobOffer.jobOffer.businessTripsLocation.join(', ')
        });
      }

      const dateStr = (this.jobOffer.jobOffer.startDate !== 'asap')
        ? this.jobOffer.jobOffer.startDate
        : this.asap;
      right.push({
        title: this.startDate,
        data: dateStr
      });

      const contractStr = (this.jobOffer.jobOffer.fixedTermContract === 'yes')
        ? this.jobOffer.jobOffer.fixedTermContractUntil
        : this.no;
      right.push({
        title: this.fixedTermContract,
        data: contractStr
      });

      const biggerArray = (left.length > right.length) ? left : right;
      const result = [];
      biggerArray.map((elm, idx) => {
        result.push({ left: left[idx], right: right[idx] });
        return elm;
      });

      return result;
    }
  },

  filters: {
    join(list) {
      return list.join(', ');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin'; // for /deep/ should have here
@import '../../scss/atoms/layout'; // for /deep/ should have here

#your-mission {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;

  .header /deep/ {
    .title {
      font-size: 30px;
    }

    .lists {
      font-size: 100px;
    }
  }

  .joboffer-description {
    margin-top: 1.3em;
    margin-bottom: 1.3em;
    font-size: 17px;
    line-height: 28px;
  }

  .your-mission-inner /deep/ .row:first-of-type .column {
    border-top: 1px solid #c4c4c4;
  }

  /deep/.row {
    display: flex;

    .column {
      width: 48%;
      margin-right: 2%;

      /deep/ div {
        height: 100%;
      }
    }
  }
}

.columns-2-item {
  width: calc((100% - var(--pading-edge) * 2 - 100px) / 2);
}

@include bp-max-large() {
  #employer-advantage {
    margin-top: 0;
  }

  .employer-advantage-inner {
    min-width: auto;
    margin-left: -0;

    .employer-advantage-item:nth-child(2n-1) {
      margin-right: 0;
    }
  }
}

@include bp-max-tablet() {
  #your-mission {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .your-mission-inner {
    padding-left: var(--padding-edge);
    padding-right: var(--padding-edge);
  }
}

@include bp-max-medium() {
  #your-mission {
    margin-top: 40px;
    margin-bottom: 50px;

    /deep/ {
      .row {
        flex-direction: column;

        .column {
          width: 100%;
        }
      }
    }
  }

  #your-mission .your-mission-inner /deep/ h2.title {
    font-size: 24px;
    line-height: 34px;
  }

}
</style>
