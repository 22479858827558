<template>
  <header class="cp-plain-header clearfix">
    <transition-group name="fade" tag="div" appear>
      <MainNavigationBlack key='2' />
      <h2 class="cp-header-logo" key='0'>
        <CpLogoBlack />
      </h2>
    </transition-group>
  </header>
</template>

<script>
import CpLogoBlack from '../atoms/CpLogoBlack';
import MainNavigationBlack from './MainNavigationBlack';

export default {
  name: 'PlainHeader',
  components: {
    CpLogoBlack,
    MainNavigationBlack
  }
};
</script>

<style lang="scss" scoped>
  .cp-plain-header {
    display: block;
    position: relative;
    padding-top: 35px;
  }

  .main-navigation {
    display: inline-block;
    margin-left: 35px;
    vertical-align: middle;
  }

  .cp-header-logo {
    display: inline-block;
    margin: 0 0 0 35px;
    vertical-align: middle;
  }
</style>
