<template>
  <div class="experiences-display">
    <ul>
      <li v-for="(exerience, index) in experiences" :key="index" :class="{ 'experience' : true, 'first': index === 0 }">
        <ExperienceTime :value="exerience" :current="index === 0" />
        <ExperienceDetail :value="exerience" :fresh="fresh" />
      </li>
    </ul>
  </div>
</template>

<script>
import ExperienceTime from '../../organisms/ApplicantProfile/ExperienceTime';
import ExperienceDetail from '../../organisms/ApplicantProfile/ExperienceDetail';

export default {
  name: 'ExperiencesDisplay',

  components: {
    ExperienceTime,
    ExperienceDetail
  },

  props: {
    values: {
      type: Object,
      required: true
    },
    fresh: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    experiences() {
      return this.values.jobExperiences.length > 0 ? this.values.jobExperiences : [{}];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/atoms/_breakpoints.mixin';

.experiences-display ul li {
  font-size: 17px;

  &.first /deep/ {
    .experience-time {
      border-right: none;
    }

    .experience-detail .divider {
      top: 10px;
    }
  }

  .experience-detail /deep/ .detail p:first-child {
    margin-top: 0;
  }

  /deep/ .experience-detail .experience-item .experience-name {
    font-weight: bold;
    line-height: 26px;
    color: #3c3c3c;
  }

  /deep/ .experience-detail .experience-item .column-2 {
    font-size: 14px;
    line-height: 22px;
  }
}
</style>
